import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import i18n from "@i18n/i18n";
import { colors, settings } from "@styles/globalStyles";
import { Client } from "@custom-types/Client";
import AvatarBase from "@components/avatar/AvatarBase";
import BoldText from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import Constants from "expo-constants";
import RegularText from "@base/RegularText";
import QRBase from "@base/QRBase";
import ShareService from "@core/services/ShareService";
import { Circle } from "react-native-svg";
import CircleButton from "@base/CircleButton";
import InlineButton from "@base/InlineButton";
import store from "@store/index";
import { hideModalBottom, showSnackbar } from "@store/actions/global";
import { Platform } from "react-native";

const { t } = i18n;

interface Props {
    client: Partial<Client>;
}

interface State {
    link: string;
}

export default class ProfileQR extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            link: `https://share.${Constants.expoConfig?.extra?.url}?type=profile&id=${this.props.client._id}` || "",
        };
    }

    handleShare = () => {
        
        ShareService.getInstance().handleShare(
            `@${this.props.client?.alias} (${t("profile_contact_QR", { name: "XO" })})`,
            this.state.link,
        );

        if (Platform.OS == "web") {
            store.dispatch(hideModalBottom());
            store.dispatch(showSnackbar({ type: "SUCCESS", message: t("link_copied") }));
        }
    };

    render() {
        return (
            <>
                <View style={styles.wrapper}>
                    <View style={styles.avatarWrapper}>
                        <AvatarBase
                            size={80}
                            alias={this.props.client?.alias}
                            uri={this.props.client?.profileImagePath?.thumbnail}
                        ></AvatarBase>
                    </View>

                    <BoldText fontSize={20} align={"center"}>
                        {"@"}
                        {trimHelper(this.props.client?.alias, 24)}
                    </BoldText>
                    <RegularText align={"center"} fontSize={12}>
                        {t("profile_contact_QR", { name: "XO" })}
                    </RegularText>

                    <View style={styles.qrWrapper}>
                        <QRBase value={this.state.link} size={180} />
                    </View>
                </View>

                <View style={styles.button}>
                    <InlineButton icon={"share"} onPress={this.handleShare} title={t("share")}></InlineButton>
                </View>
            </>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        marginHorizontal: 10,
        marginTop: 30,
        paddingTop: 60,
        paddingBottom: 30,
    },

    avatarWrapper: {
        position: "absolute",
        top: -40,
    },
    qrWrapper: {
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 30,
    },

    button: {
        marginHorizontal: 5,
        paddingTop: 25,
    },
});
