import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import TransactionType from "@custom-types/TransactionType";
import SwapType from "@custom-types/SwapType";

export const ON_INIT = 'ON_INIT';
export const RELOAD = 'RELOAD';
export const ON_RESET = 'ON_RESET';
export const SYNC = 'SYNC';
export const SYNCED = 'SYNCED';
export const ON_CHANGE = 'ON_CHANGE';
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SELECT_CURRENCY = 'SELECT_CURRENCY';
export const PREPARE_TRANSACTION = 'PREPARE_TRANSACTION';
export const PRE_PREPARE_TRANSACTION = 'PRE_PREPARE_TRANSACTION';
export const ON_TRANSACTION_READY = 'ON_TRANSACTION_READY';
export const CONFIRM_TRANSACTION = 'CONFIRM_TRANSACTION';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const SELECT_TRANSACTION = 'SELECT_TRANSACTION';
export const CLEAN_OPERATION = 'CLEAN_OPERATION';
export const UPDATE_CURRENCY_PATH = 'UPDATE_CURRENCY_PATH';
export const UPDATE_CURRENCY_ENABLED = 'UPDATE_CURRENCY_ENABLED';
export const UPDATE_MNEMONIC = 'UPDATE_MNEMONIC';
export const SWITCH_NETWORK = 'SWITCH_NETWORK';
export const SELECT_EXCHANGE_FROM = 'SELECT_EXCHANGE_FROM';
export const SELECT_EXCHANGE_TO = 'SELECT_EXCHANGE_TO';
export const PREPARE_EXCHANGE = 'PREPARE_EXCHANGE';
export const SYNC_TRANSACTIONS = 'SYNC_TRANSACTIONS';
export const SYNC_PURCHASE_ORDERS = 'SYNC_PURCHASE_ORDERS';
export const PREPARE_BUY = 'PREPARE_BUY';
export const SELECT_PURCHASE_ORDER = 'SELECT_PURCHASE_ORDER';
export const SELECT_BUTTON_ACTION_TYPE = 'SELECT_BUTTON_ACTION_TYPE';

export const onInit = () => {
    return {type: ON_INIT}
}

export const reload = (callBack: () => void) => {
    return { type: RELOAD, callBack }
}

export const onReset = (wallet: Wallet) => {
    return {type: ON_RESET, wallet: wallet}
}

export const onChange = (wallet: Wallet) => {
    return {type: ON_CHANGE, wallet: wallet}
}

export const setCurrencies = (currencies: Array<Currency>) => {
    return {type: SET_CURRENCIES, currencies:currencies}
}

export const selectCurrency = (id: string) => {
    return {type: SELECT_CURRENCY, currency:id}
}

export const setButtonActionsType = (buttonActionsType: string) => {
    return {type: SELECT_BUTTON_ACTION_TYPE, buttonActionsType: buttonActionsType}
}

export const cleanOperation = () => {
    return {type: CLEAN_OPERATION}
}

export const prePrepareTransaction = (parameters: {address: string, amount: number}) => {
    return {type: PRE_PREPARE_TRANSACTION, parameters}
}

export const prepareTransaction = (parameters: {transaction: TransactionType, onSuccess: any, onError: any}) => {
    return {type: PREPARE_TRANSACTION, parameters}
}

export const onTransactionReady = (skeleton: any) => {
    return {type: ON_TRANSACTION_READY, skeleton: skeleton}
}

export const confirmTransaction = (parameters: {currency: Currency, skeleton: any, onSuccess: any, onError: any}) => {
    return {type: CONFIRM_TRANSACTION, parameters}
}

export const getTransactions = (currency: Currency, txid?: string) => {
    return {type: GET_TRANSACTIONS, currency:currency, txid:txid}
}

export const selectTransaction = (id: string) => {
    return {type: SELECT_TRANSACTION, transaction:id}
}

export const updateCurrencyPath = (parameters: {currency: Currency, account: string, change: string, index: string, smart: boolean, onSuccess?:any}) => {
    return {type: UPDATE_CURRENCY_PATH, parameters:parameters}
}

export const updateCurrencyEnabled = (parameters: {currency: Currency, enabled: boolean}) => {
    return {type:UPDATE_CURRENCY_ENABLED, parameters:parameters}
}

export const switchNetwork = (parameters: {testnet: boolean, onSuccess?:any}) => {
    return {type: SWITCH_NETWORK, parameters:parameters}
}

export const sync = (currency?:Currency) => {
    return {type: SYNC, currency: currency}
}

export const syncTransactions = (currency:Currency) => {
    return {type: SYNC_TRANSACTIONS, currency: currency}
}

export const syncPurchaseOrders = (currency:Currency) => {
    return {type: SYNC_PURCHASE_ORDERS, currency: currency}
}

export const synced = () => {
    return {type: SYNCED}
}

export const selectExchangeFrom = (parameters: {currency: Currency}) => {
    return {type: SELECT_EXCHANGE_FROM, parameters:parameters}
}

export const selectExchangeTo = (parameters: {currency: Currency}) => {
    return {type: SELECT_EXCHANGE_TO, parameters:parameters}
}

export const prepareExchange = (parameters: {swap: SwapType, onSuccess: any, onError: any}) => {
    return {type: PREPARE_EXCHANGE, parameters}
}

export const prepareBuy = (parameters: { data : any }) => {
    return {type: PREPARE_BUY, parameters}
}

export const selectPurchaseOrder = (id: string) => {
    return {type: SELECT_PURCHASE_ORDER, order:id}
}
