import NFT from "@custom-types/NFTModel";
import { RELOAD } from "@store/actions/wallet";
import {
    SET_TEMPORAL_NFTS,
    SET_PRE_TEMPORAL_NFT,
    SET_SELECTED_NFT,
    SET_REQUEST_STATUS_NFTS,
} from "@store/actions/nfts.action";

export enum MarketPlaceStatus {
    Updated = "updated",
    WaitingListing = "waiting_listing",
    WaitingCancel = "waiting_cancel",
    Reload = "reload",
}

interface NFTState {
    selectedNFT: NFT;
    preTemporalNFT: NFT;
    temporalNfts: Array<NFT>;
    statusMarketPlace: {
        status: string;
        nft: NFT;
    };
}

const initialState: NFTState = {
    selectedNFT: null,
    preTemporalNFT: null,
    temporalNfts: [],
    statusMarketPlace: { status: MarketPlaceStatus.Updated, nft: null },
};

const NFTReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_NFT:
            return {
                ...state,
                selectedNFT: action.selectedNFT || state.selectedNFT,
            };
        case SET_PRE_TEMPORAL_NFT:
            return {
                ...state,
                preTemporalNFT: action.preTemporalNFT || state.preTemporalNFT,
            };
        case SET_TEMPORAL_NFTS:
            return {
                ...state,
                temporalNfts: action.temporalNfts || state.temporalNfts,
            };
        case SET_REQUEST_STATUS_NFTS:
            return {
                ...state,
                statusMarketPlace: action.statusMarketPlace || state.statusMarketPlace,
            };

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default NFTReducer;
