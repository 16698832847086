import { PathNode } from "@core/utils/PathNode";
import TransactionType from "@custom-types/TransactionType";
import Currency from "./Currency";

interface ISkeleton {
    amount: number;
    sendingTo: string;
    sendingFrom: string;
    fee: any;
    swapAmount?: string;
}

interface ITransaction {
    type: number;
    to: string;
    amount: any;
    from: string;
    id: string;
    confirmations: number;
    fee: any;
    date: string;
}

export default abstract class CurrencyImplementation {
    protected currency: Currency;
    constructor(currency: Currency) {
        this.currency = currency;
    }

    getCurrency() {
        return this.currency;
    }

    getFormats(): Array<string> {
        return [];
    }

    getKeys(addressNode: PathNode): { publicKey: string; privateKey: string } {
        return {
            publicKey: "",
            privateKey: "",
        };
    }

    signMessage(addressNode: PathNode, hexMessage: string) {
        return null;
    }

    signTypedData(addressNode: PathNode, JsonData: string) {
        return null;
    }

    abstract generateAddress(node: PathNode, options: { chainId?: number; owner?: boolean; format?: string }): string;
    abstract signTransaction(addressNode: any, skeleton: any): Promise<any>;
    abstract parseTransaction(tx: Object): ITransaction;
    abstract parseDate(time: number): string;
    abstract parseSkeleton(skeleton: any): ISkeleton;
    abstract isValidAddress(address: string): boolean;
}
