import React, { Component, useCallback } from "react";
import { View,  StyleSheet } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { NavigationType } from "@custom-types/NavigationType";
import RegularText from "@base/RegularText";
import * as Clipboard from "expo-clipboard";
import Currency from "@core/currencies/Currency";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import i18n from "@i18n/i18n";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Row from "@base/Row";
import BigNumber from "bignumber.js";
import Label from "@base/Label";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import ProfileService from "@core/services/ProfileService";
import { Client } from "@custom-types/Client";
import AvatarBase from "@components/avatar/AvatarBase";
import PressableBase from "@base/PressableBase";

interface Props {
    currency: Currency;
    transaction: any;
}

interface State {
    fromClient: Partial<Client>;
    toClient: Partial<Client>;
    showMessage: boolean;
}

const { t } = i18n;

export default class TransactionDetailComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onCopy = this.onCopy.bind(this);
        this.state = {
            fromClient: null,
            toClient: null,
            showMessage: false,
        };
    }

    componentDidMount = async () => {
        const fromClient: any = await this.getProfileByAddress(this.props.transaction?.from);
        this.setState({ fromClient: fromClient || null });
        const toClient: any = await this.getProfileByAddress(this.props.transaction?.to);
        this.setState({ toClient: toClient || null });
    };

    onCopy = (item) => {
        Clipboard.setStringAsync(item);
        this.setState({ showMessage: true });
        setTimeout(() => {
            this.setState({ showMessage: false });
        }, 3000);
    };

    getProfileByAddress = async (address: string) => {
        return await ProfileService.getInstance().getProfileByAddress(this.props.currency, address || "");
    };

    render() {
        return (
            <>
                {this.props.transaction && (
                    <View>
                        <Card>
                            <RegularText color={colors.text} align="center">
                                {t("status")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.props.transaction.confirmations >= 6
                                    ? "Confirmed"
                                    : "Pending (" + this.props.transaction.confirmations + "/6)"}
                            </BoldText>
                        </Card>
                        <Card>
                            <RegularText color={colors.text} align="center">
                                {t("date")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.props.currency.getImplementation().parseDate(this.props.transaction.time)}
                            </BoldText>
                        </Card>

                        {this.state.fromClient ? (
                            <Card>
                                <Row style={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                                    <AvatarBase
                                        alias={this.state.fromClient?.alias}
                                        uri={this.state.fromClient?.profileImagePath?.thumbnail}
                                        size={45}
                                    ></AvatarBase>

                                    <View style={{ paddingLeft: 10 }}>
                                        <RegularText color={colors.text} align="left">
                                            {t("from")}
                                            <BoldText color={colors.text}>
                                                {" @"}
                                                {trimHelper(this.state.fromClient?.alias, 35, true)}
                                            </BoldText>
                                        </RegularText>
                                        <RegularText
                                            fixedWidth={300}
                                            color={colors.text}
                                            align="left"
                                            selectable={true}
                                        >
                                            {this.props.transaction.from}
                                        </RegularText>
                                    </View>
                                </Row>
                            </Card>
                        ) : (
                            <Card>
                                <RegularText color={colors.text} align="center">
                                    {t("from")}
                                </RegularText>

                                <BoldText fixedWidth={300} color={colors.text} align="center" selectable={true}>
                                    {this.props.transaction.from}
                                </BoldText>
                            </Card>
                        )}

                        {this.state.toClient ? (
                            <Card>
                                <Row style={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                                    <AvatarBase
                                        alias={this.state.toClient?.alias}
                                        uri={this.state.toClient?.profileImagePath?.thumbnail}
                                        size={45}
                                    ></AvatarBase>

                                    <View style={{ paddingLeft: 10 }}>
                                        <RegularText color={colors.text} align="left">
                                            {t("to")}
                                            <BoldText color={colors.text}>
                                                {" @"}
                                                {trimHelper(this.state.toClient?.alias, 35, true)}
                                            </BoldText>
                                        </RegularText>
                                        <RegularText
                                            fixedWidth={300}
                                            color={colors.text}
                                            align="left"
                                            selectable={true}
                                        >
                                            {this.props.transaction.to}
                                        </RegularText>
                                    </View>
                                </Row>
                            </Card>
                        ) : (
                            <Card>
                                <RegularText color={colors.text} align="center">
                                    {t("to")}
                                </RegularText>

                                <BoldText fixedWidth={300} color={colors.text} align="center" selectable={true}>
                                    {this.props.transaction.to}
                                </BoldText>
                            </Card>
                        )}
                        <Card>
                            <RegularText color={colors.text} align="center">
                                {t("amount")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {new BigNumber(this.props.transaction.amount).toString()}{" "}
                                {this.props.currency.getPName()}
                            </BoldText>
                            <Label
                                text={`$ ${this.props.currency.toFiat(this.props.transaction.amount)}`}
                                fontSize={12}
                            />
                        </Card>
                        <Card>
                            <RegularText color={colors.text} align="center">
                                {t("fee")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.props.transaction.fee}{" "}
                                {this.props.transaction.smart
                                    ? this.props.currency.getPName()
                                    : this.props.currency.getUnderlyingCurrency().getPName()}
                            </BoldText>
                            <Label
                                text={`$ ${this.props.currency
                                    .getUnderlyingCurrency()
                                    .toFiat(this.props.transaction.fee)}`}
                                fontSize={12}
                            />
                        </Card>

                        {this.props.transaction.id && (
                            <Card>
                                <RegularText color={colors.text} align="center">
                                    {t("transaction_id")}
                                </RegularText>
                                <Row style={{ alignItems: "center", alignContent: "center" }}>
                                    <BoldText color={colors.text} align="center">
                                        {trimHelper(this.props.transaction.id, 30, true)}
                                    </BoldText>
                                    <PressableBase
                                        onPress={() => this.onCopy(this.props.transaction.id)}
                                        style={styles.button}
                                    >
                                        <MaterialCommunityIcons size={20} name={"content-copy"} color={colors.text} />
                                    </PressableBase>
                                </Row>
                                {this.state.showMessage && (
                                    <RegularText align="center" color={colors.secondary} fontSize={14}>
                                        {t("copied")}
                                    </RegularText>
                                )}
                            </Card>
                        )}
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    addressContainer: {
        marginTop: 5,
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
        justifyContent: "center",
    },
    qr: {
        flexDirection: "column",
        alignItems: "center",
        marginVertical: 20,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.secondaryShadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
});
