import React, { Component } from "react"
import { connect } from "react-redux"
import { colors, settings } from "@styles/globalStyles"
import { Header } from "@components/header/Header"
import { HeaderType } from "@custom-types/HeaderType"
import Container from "@base/Container"
import { StyleSheet, View } from "react-native"
import InlineButton from "@base/InlineButton"
import { Contact } from "@custom-types/Contact"
import { ContactService } from "@core/services/ContactService"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator"
import FloatingTextInput from "@base/FloatingTextInput"
import { showSnackbar } from "@store/actions/global"
import store from "@store/index"

interface Props {
    selectedContact: Contact
    navigation: any
    route: any
}

interface State {
    name: string
    alias: string
    message: string
    formType: string
}

const { t } = i18n

export class _ContactFormScreen extends Component<Props, State> {
    contactService: ContactService

    constructor(props) {
        super(props)

        this.changeInputValue = this.changeInputValue.bind(this)
        this.onPress = this.onPress.bind(this)

        this.state = {
            name: "",
            alias: "",
            message: "",
            formType: this.props.route?.params?.type,
        }
    }

    async componentDidMount() {
        if (this.props.selectedContact && this.state.formType === "edit") {
            this.setState({
                ...this.state,
                name: this.props.selectedContact.name,
                alias: this.props.selectedContact.client.alias,
            })
        }

        if (this.props.route?.params?.client) {
            this.setState({
                ...this.state,
                alias: this.props.route.params.client.alias,
            })
        }

        this.contactService = await ContactService.getInstance()
    }

    changeInputValue(value, field) {
        this.setState({
            ...this.state,
            [field]: value,
        })
    }

    async onPress() {
        this.setState({
            ...this.state,
            alias: this.state.alias.trim(),
            name: this.state.name.trim(),
        })

        const { alias, name } = this.state
        if (name.length === 0) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t('name_error') }));
            return
        }

        if (alias.length === 0) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t('alias_error') }));
            return
        }

        try {
            if (this.state.formType === "new") {

                await this.contactService.create({
                    alias,
                    name,
                })
            }

            if (this.state.formType === "edit") {
                await this.contactService.update({
                    _id: this.props.selectedContact._id,
                    name: this.state.name,
                    alias: this.state.alias,
                })
                this.props.navigation.navigate(MessagesNavigatorScreens.Contacts.routeName)
            }
            this.props.navigation.navigate(MessagesNavigatorScreens.Contacts.routeName)
        } catch (error: any) {
            store.dispatch(showSnackbar({ type: "ERROR", message: error || t('error') }));
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={this.state.formType === "edit" ? t('edit_contact') : t('add_contact')} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <View>
                        <FloatingTextInput
                            onChangeText={(text) => this.changeInputValue(text, "name")}
                            value={this.state.name}
                            label={t('name')}
                            multiline={false}
                        />
                        <FloatingTextInput
                            editable={false}
                            onChangeText={(text) => this.changeInputValue(text, "alias")}
                            value={this.state.alias}
                            label={t('alias')}
                            multiline={false}
                        />
                    </View>
                    <InlineButton
                        title={t('save')}
                        onPress={this.onPress}
                        style={{ marginHorizontal: 0, marginBottom: 20 }}
                    />
                </Container>
            </ScreenWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedContact: state.chat.selectContact,
    }
}

const mapDispatchToProps = (dispatch) => ({})

const ContactsFormScreen = connect(mapStateToProps, mapDispatchToProps)(_ContactFormScreen)

export default ContactsFormScreen

const styles = StyleSheet.create({})
