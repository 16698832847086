import Currency, { IConstructorCurrency } from '@core/currencies/Currency';
import { Networks } from '@core/network/Networks';
import Constants from "expo-constants"
import ETHImplementation from '@core/currencies/ethereum/eth/ETHImplementation';
import { ProviderType } from '@custom-types/ProviderType';
import { isCurrencyEnabled } from '@core/utils/CurrencyEnabled';

export default class BNB extends Currency {
    constructor(
        parameters: IConstructorCurrency
    ) {
        super({
            name: 'BNB',
            simbol: 'BNB',
            fullName: 'BNB',
            decimals: 18,
            implementation: ETHImplementation,
            chainId: parameters.testnet ? 97 : 56,
            networkID: parameters.testnet ? 61 : 60,
            color: '#e6b02d',
            icon: 'binance',
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "BNB", true),
            blockchain: 'bsc',
            walletConnect: true,
          
        })
    }

}
