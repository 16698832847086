import store from "@store/index";
import { ChatService } from "@core/services/ChatService";
import { ContactService } from "@core/services/ContactService";
import { loading, ready, setSearch } from "@store/actions/global";
import { ApiService } from "@core/services/ApiService";
import { Chat } from "@custom-types/Chat";
import { selectChat } from "@store/actions/chat.actions";
import axios from "axios";
import { appendFileToFormData, getEnv } from "@utils/helpers/global/global";
import { Platform, Image } from "react-native";
import { addDiscoverPosts, setDiscoverPosts, setFollowerRequests } from "@store/actions/social.actions";
import { FollowerRequestType } from "@custom-types/FollowerRequestType";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import Post from "@custom-types/Post";

interface Props {
    chats: Array<Chat>;
}

export default class SocialNetworkService {
    static instance: SocialNetworkService;
    chatService: ChatService;
    contactService: ContactService;

    constructor() {}

    public static getInstance() {
        if (!SocialNetworkService.instance) {
            SocialNetworkService.instance = new SocialNetworkService();
        }
        return SocialNetworkService.instance;
    }

    async contactUser(clientID) {
        this.chatService = await ChatService.getInstance();
        this.contactService = await ContactService.getInstance();
        await this.contactService.getContactsList();
        store.dispatch(setSearch(""));
        this.chatService.updateAllMessagesToReceived();
        const chats: Array<Chat> = await store.getState().chat.items;
        const selectChatsAsArray = Object.values(chats);
        let chat = selectChatsAsArray.find((c: Chat) => c.to._id == clientID);

        if (!(chat instanceof Chat)) {
            let resp = await this.chatService.create({ clientId: clientID });
            const chats: Array<Chat> = await store.getState().chat.items;
            const selectChatsAsArray = Object.values(chats);
            chat = selectChatsAsArray.find((c: Chat) => c._id === resp._id);
        }

        if (chat instanceof Chat) {
            store.dispatch(selectChat(chat));
            return "redirect";
        }
    }

    async newPost(post) {
        try {
            const form = new FormData();

            if (Platform.OS == "web") {
                const base64 = await fetch(post.image);
                const blob = await base64.blob();
                form.append("image", blob);
            } else {
                form.append("image", {
                    uri: post.image,
                    type: "image/jpeg",
                    name: "file" + Math.random(),
                } as any);
            }

            form.append("type", "standard");
            form.append("description", post.description);
            form.append("hashtags", JSON.stringify(post.hashtags));

            const endpoint = `${getEnv("API_URL")}posts`;
            const resp = await axios.post(endpoint, form, {
                headers: { ...(await ApiService.getAuthHeaders()), "Content-Type": "multipart/form-data" },
            });

            return resp.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getPosts(page) {
        try {
            const endpoint = `${getEnv("API_URL")}posts?page=${page}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getPostById(id: string) {
        try {
            const endpoint = `${getEnv("API_URL")}posts/${id}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getPostsDiscover() {
        if (!ModuleControlService.getInstance().isModuleEnabled(Modules.socialNetworkModule)) return;
        try {
            const posts = store.getState()?.social?.discoverPosts?.map((p) => p.id);
            const endpoint = `${getEnv("API_URL")}posts/discover?ids=${posts}`;

            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });
            if (response.data) {
                store.dispatch(addDiscoverPosts(response.data.docs));
            }
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getUserPosts(clientID, page) {
        try {
            const endpoint = `${getEnv("API_URL")}posts/profile/${clientID}/?page=${page}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async searchUser(alias, page) {
        try {
            const endpoint = `${getEnv("API_URL")}search/client?alias=${alias}&page=${page}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async searchPost(post, page) {
        try {
            const endpoint = `${getEnv("API_URL")}posts/search/description?description=${post}&page=${page}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async searchHashtags(hashtag, page) {
        try {
            const endpoint = `${getEnv("API_URL")}posts/search/hashtag?name=${hashtag}&page=${page}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getUserProfile(clientID) {
        try {
            const endpoint = `${getEnv("API_URL")}profile/${clientID}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getAccount(clientID) {
        try {
            const endpoint = `${getEnv("API_URL")}account/${clientID}`;
            const response = await axios.get(endpoint, { headers: ApiService.headers() });
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async deletePost(id) {
        try {
            const endpoint = `${getEnv("API_URL")}posts/${id}`;
            const response = await axios.delete(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response;
        } catch (e) {
            console.warn(e);
        }
    }

    async reportPost(id) {
        try {
            const endpoint = `${getEnv("API_URL")}posts/${id}/report`;
            const response = await axios.post(endpoint, {}, { headers: await ApiService.getAuthHeaders() });

            return response;
        } catch (e) {
            console.warn(e);
        }
    }

    async unfollowUser(clientID) {
        try {
            const endpoint = `${getEnv("API_URL")}unfollow/${clientID}/`;
            const response = await axios.delete(endpoint, { headers: await ApiService.getAuthHeaders() });
            return response.status;
        } catch (e) {
            console.warn(e);
        }
    }

    async likePost(id, liked = false) {
        try {
            let discoverPosts = store.getState()?.social?.discoverPosts?.map((p: Post) => {
                if (p.id == id) {
                    p.likes.liked = !liked;
                }
                return p;
            });

            store.dispatch(setDiscoverPosts(discoverPosts));
            const endpoint = `${getEnv("API_URL")}posts/${id}/${liked ? "unlike" : "like"}`;
            let response;
            if (!liked) {
                response = await axios.post(endpoint, {}, { headers: await ApiService.getAuthHeaders() });
            } else {
                response = await axios.delete(endpoint, { headers: await ApiService.getAuthHeaders() });
            }

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async followUser(id) {
        var body = null;
        try {
            const endpoint = `${getEnv("API_URL")}follow/${id}/`;
            const response = await axios.post(endpoint, body, { headers: await ApiService.getAuthHeaders() });
            return response;
        } catch (e) {
            console.warn(e);
        }
    }

    async getFollowed(clientID, page) {
        try {
            const endpoint = `${getEnv("API_URL")}client/${clientID}/followed?page=${page}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getFollowers(clientID, page) {
        try {
            const endpoint = `${getEnv("API_URL")}client/${clientID}/followers?page=${page}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getLikes(postID, page) {
        try {
            const endpoint = `${getEnv("API_URL")}posts/${postID}/likes/clients?page=${page}`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async blockUser(id) {
        try {
            var body = null;
            const endpoint = `${getEnv("API_URL")}client/${id}/block`;
            const response = await axios.post(endpoint, body, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async unblockUser(id) {
        try {
            var body = null;
            const endpoint = `${getEnv("API_URL")}client/${id}/block`;
            const response = await axios.delete(endpoint, { headers: await ApiService.getAuthHeaders() });
            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getBlockedUsers() {
        try {
            const endpoint = `${getEnv("API_URL")}client/blocked`;
            const response = await axios.get(endpoint, { headers: await ApiService.getAuthHeaders() });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getFollowerRequests() {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}follow-requests/pending`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(setFollowerRequests(response.data || []));
            store.dispatch(ready());
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async requestFollow(clientID: string): Promise<FollowerRequestType> {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}follow-requests`;
            const body = { receiver: clientID };
            const response = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());
            return response.data;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async cancelRequestFollow(id: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}follow-requests/${id}`;
            const response = await axios.delete(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());

            return response.data;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async rejectFollowRequest(id: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}follow-requests/${id}/reject`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                },
            );
            store.dispatch(ready());

            return response.data;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async acceptFollowRequest(id: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}follow-requests/${id}/accept`;
            const response = await axios.post(
                url,
                {},
                {
                    headers: await ApiService.getAuthHeaders(),
                },
            );
            store.dispatch(ready());

            return response;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }
}
