import { NotificationModel } from "@custom-types/NotificationModel";
import { RELOAD } from "@store/actions/wallet";
import { SET_NOTIFICATIONS, UPDATE_NOTIFICATION_READ } from "@store/actions/notification.actions";

interface state {
    notifications: NotificationModel[],
}

const initialState: state = {
    notifications: [],
};

const notificationReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: [...action.notifications]
            }
        case UPDATE_NOTIFICATION_READ:
            const index = state.notifications.findIndex(notification => notification._id === action.notificationId)
            const newState = [...state.notifications]
            newState[index].isRead = true

            return {
                ...state,
                notifications: [...newState]
            }

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default notificationReducer;