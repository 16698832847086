import React, { Component } from "react";
import { StyleSheet, ViewStyle, Pressable } from "react-native";
import { debounce } from "lodash";

interface Props {
    style?: ViewStyle | Array<ViewStyle>;
    children?;
    disabled?: boolean;
    onPressPriority?: "low" | "half" | "high";
    accessibilityLabel?: string;
    disableOpacity?: boolean;
    onPress?: () => void;
    onLongPress?: () => void;
    onPressIn?: () => void;
    onPressOut?: () => void;
}

interface State {}

export default class PressableBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    debouncedOnPress = debounce(
        () => {
            if (this.props.onPress) {
                this.props.onPress();
            }
        },
        (this.props.onPressPriority == "low" && 1000) ||
            (this.props.onPressPriority == "half" && 500) ||
            (this.props.onPressPriority == "high" && 10) ||
            //default value
            300,
        {
            leading: true,
            //trailing: false
            //Used to cancel presses during debounce
        },
    );

    render() {
        return (
            <Pressable
                disabled={this.props.disabled}
                onPress={this.debouncedOnPress}
                onLongPress={this.props.onLongPress}
                onPressIn={this.props.onPressIn}
                onPressOut={this.props.onPressOut}
                accessibilityLabel={this.props.accessibilityLabel}
                style={({ pressed }) => [
                    {
                        opacity: pressed && !this.props.disableOpacity ? 0.6 : 1,
                    },
                    this.props.style,
                ]}
            >
                {this.props.children}
            </Pressable>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        flexDirection: "row",
    },
});
