import React, { Component } from "react";
import { View, Text, StyleSheet, Button, FlatList } from "react-native";
import CurrencyCard from "@components/cards/CurrencyCard";
import { colors } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import Currency from "@core/currencies/Currency";
import store from "@store/index";
import {
    onInit,
    onReset,
    selectExchangeFrom,
    selectExchangeTo,
    sync,
    updateCurrencyEnabled,
} from "@store/actions/wallet";

import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Wallet from "@core/wallet/Wallet";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import SwitchCard from "@components/cards/SwitchCard";
import { loading, ready } from "@store/actions/global";
import AppStorage from "@utils/storage";
import { SearchBar } from "@components/chat/SearchBar";
import { connect } from "react-redux";
import { getColorOpacity } from "@utils/helpers/global/global";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import BotCard from "@base/BotCard";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    navigation: NavigationType;
    onSearch: (search) => any;
    search: string;
}

interface State {
    currencies: Array<Currency>;
    filteredCurrencies: Array<Currency>;
}

const { t } = i18n;

export class _SelectCurrencyEnableScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onSwitch = this.onSwitch.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.state = {
            currencies: [],
            filteredCurrencies: [],
        };
    }

    async componentDidMount() {
        store.dispatch(loading());
        const currencies = Wallet.getInstance().getCurrencies();
        this.setState({ currencies: currencies, filteredCurrencies: currencies });
        store.dispatch(ready());
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            this.onSearch();
        }
    }

    onSearch() {
        if (this.props.search.length > 0) {
            const filteredCurrencies = this.state.currencies.filter((c) =>
                c.getName().startsWith(this.props.search.toUpperCase())
            );
            this.setState({ filteredCurrencies: filteredCurrencies });
        } else {
            this.setState({ filteredCurrencies: this.state.currencies });
        }
    }

    async onSwitch(currency) {
        store.dispatch(
            updateCurrencyEnabled({ currency: currency, enabled: currency.enabled == null ? true : !currency.enabled })
        );
    }

    renderItem = ({ item }) => {
        return (
            <SwitchCard
                title={item.getPName()}
                left={
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <CurrencyIcon styles={{ marginRight: 15 }} currency={item} />

                        <BoldText fontSize={20}>{item.getPName()}</BoldText>
                        <NetworkCurrencySymbol currency={item}></NetworkCurrencySymbol>
                    </View>
                }
                onToggle={() => this.onSwitch(item)}
                isOn={item.isEnabled()}
            />
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header {...this.props} title={t("enable_currencies")} type={HeaderType.Light} />
                <SearchBar />
                <Container style={styles.container}>
                    <FlatList
                        data={this.state.filteredCurrencies}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.getId()}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        initialNumToRender={this.state.currencies.length}
                        maxToRenderPerBatch={10}
                        extraData={this.state.currencies}
                        ListEmptyComponent={<BotCard message={t("empty_list")} />}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: "hidden",
        marginTop: 12,
    },
});

const mapStateToProps = (state) => {
    return {
        search: state.global.search,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SelectCurrencyEnableScreen = connect(mapStateToProps, mapDispatchToProps)(_SelectCurrencyEnableScreen);

export default SelectCurrencyEnableScreen;
