import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { Client } from "@custom-types/Client";
import { appendFileToFormData, getEnv } from "@utils/helpers/global/global";
import store from "@store/index";
import Wallet from "@core/wallet/Wallet";
import BTC from "@core/currencies/btc/BTC";
import ETH from "@core/currencies/ethereum/eth/ETH";
import RBTC from "@core/currencies/rsk/rbtc/RBTC";
import TRX from "@core/currencies/tron/trx/TRX";
import { getChatSeed, getEcKeyFromSeed, getEcPubKeyFromKey, setAuthLocalStorage } from "@core/utils/AuthAux";
import AppStorage from "@utils/storage";
import { setClient } from "@store/actions/auth";
import { Token } from "@custom-types/Token";
import Currency from "@core/currencies/Currency";
import Constants from "expo-constants";
import MATIC from "@core/currencies/polygon/matic/MATIC";
import AuthStorage from "@utils/storage/storages/AuthStorage";
import ARB from "@core/currencies/arbitrum/arb/ARB";
import * as AuthAux from "@core/utils/AuthAux";
import { OAuthService } from "./oauth/OAuthService";

export class ClientService {
    private static instance;
    protected resource = "client";

    private constructor() {}

    public static getInstance(): ClientService {
        if (!ClientService.instance) {
            const service = new ClientService();
            ClientService.instance = service;
        }

        return ClientService.instance;
    }

    async all(alias) {
        const resp = await axios.get(`${getEnv("API_URL")}${this.resource}?alias=${alias}&contact=false`, {
            headers: await ApiService.getAuthHeaders(),
        });

        return resp.data.map((ca) => Client.create(ca));
    }

    async setAlias(alias) {
        await axios.post(
            `${getEnv("API_URL")}${this.resource}/alias`,
            { alias },
            { headers: await ApiService.getAuthHeaders() },
        );

        await this.setAliasInStorages(alias);
    }

    async setEthCompatibility(ethCompatibility: boolean) {
        try {
            const body = { ethCompatibility: ethCompatibility };

            const resp = await axios.patch(`${getEnv("API_URL")}v3/client`, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            if (resp && resp?.data) {
             
                await this.setEthCompatibilityInStorages(resp?.data);
            }
        } catch (e) {
            console.warn(e);
        }
    }

    private async setAliasInStorages(alias) {
        const auth = await AuthStorage.get();
        auth.client.alias = alias;

        await setAuthLocalStorage({ client: auth.client });
        store.dispatch(setClient(auth.client));
    }

    private async setEthCompatibilityInStorages(client: Client) {
        OAuthService.getInstance().setClient(Client.create(client));
    }

    public async setClient(client: Partial<Client>) {
        const auth = (await AuthStorage.get()) || { client: null };
        auth.client = client;
        await setAuthLocalStorage({ client: auth.client });
        store.dispatch(setClient(auth.client));
    }

    async setProfileImage(pickerResult) {
        try {
            const form = new FormData();
            await appendFileToFormData(form, pickerResult.uri);

            const endpoint = `${getEnv("API_URL")}${this.resource}/profile/image`;
            const resp = await axios.post(endpoint, form, {
                headers: { ...(await ApiService.getAuthHeaders()), "Content-Type": "multipart/form-data" },
            });

            const client = store.getState().auth.client;

            client.profileImagePath = resp.data.profileImagePath;
            await this.setClient(client);
        } catch (e) {
            console.warn(e);
        }
    }

    setAddress(
        currencyId: string,
        constructor: { new (params: any): Currency },
        client: Client,
        network: "TESTNET" | "MAINNET",
        seed: any,
    ) {
        const id = currencyId.toLowerCase();
        const networkId = network.toLowerCase();
        const isTestnet = network == "TESTNET";
        if (!client.addresses[networkId][id]) {
            let currency: Currency;
            currency = new constructor({
                seed: seed,
                testnet: isTestnet,
                fiatCurrency: "USD",
            });
            const formats = currency.getFormats();
            const address = currency.getAddress({ format: formats ? formats[0] : null });
            client.addresses[networkId][id] = address;
            client.updated = false;
        }
    }

    setAddresses(client: Client, seed: any) {
        if (!client.addresses || !client.version || client.version < 386) {
            client.addresses = { testnet: {}, mainnet: {} };
        }
        const currencies: Array<{ id: string; constructor: { new (params: any): Currency } }> = [
            { id: "BTC", constructor: BTC },
            { id: "RBTC", constructor: RBTC },
            { id: "ETH", constructor: ETH },
            { id: "TRX", constructor: TRX },
            { id: "MATIC", constructor: MATIC },
            { id: "ARB", constructor: ARB },
        ];

        currencies.map((c) => {
            this.setAddress(c.id, c.constructor, client, "TESTNET", seed);
            this.setAddress(c.id, c.constructor, client, "MAINNET", seed);
        });
    }

    setTokenNotification(client: Client, token) {
        if (!token || (client.tokenNotification && client.tokenNotification == token)) return;

        client.tokenNotification = token;
        client.updated = false;
    }

    setPublicKey(client: Client, publicKey: string) {
        if (client.publicKey) return;

        client.publicKey = publicKey;
        client.updated = false;
    }

    setChatPublicKey(client: Client) {
        if (client.keyChat) return client.keyChat;

        const ecKey = AuthAux.getEcKeyFromSeed(AuthAux.getChatSeed());
        client.keyChat = getEcPubKeyFromKey(ecKey);
        client.updated = false;
        return client.keyChat;
    }

    async updateClient(client, token?) {
        /*client.version = Number(Constants.expoConfig.version.split(".").join(""));
        try {
            const resp = await axios.patch(`${getEnv("API_URL")}client`, client, {
                headers: await ApiService.getAuthHeaders(token),
            });
            return resp.data;
        } catch (e) {
            // mostrar mensaje en pantalla
            console.warn(e);
            throw new Error(e);
        }*/
    }
}
