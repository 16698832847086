import { RELOAD } from "@store/actions/wallet";
import { Blog } from "@custom-types/BlogModel";
import { SET_BLOGS } from "@store/actions/blog.actions";

interface state {
    blogs: {
        docs: Blog[];
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
}

const initialState: state = {
    blogs: {
        docs: [],
        paginator: { page: 1, totalDocs: 0, totalPages: 1 },
    },
};

const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BLOGS:
            return {
                ...state,
                blogs: {
                    docs: action.blogs,
                    paginator: action.paginator,
                },
            };

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default blogReducer;
