import Currency, { IConstructorCurrency } from "@core/currencies/Currency"
import { Networks } from "@core/network/Networks"
import ETHImplementation from "../eth/ETHImplementation"
import { isCurrencyEnabled } from "@core/utils/CurrencyEnabled"

export default class LOCKER extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            name: "LOCKER",
            simbol: "LOCKER",
            fullName: "Locker",
            decimals: 18,
            implementation: ETHImplementation,
            networkID: parameters.testnet ? 61 : 60,
            color: "#6e5c28",
            icon: "locker",
            networkSymbol: "ETH",
            underlyingCurrency: "eth",
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "LOCKER", false),
        })
    }
}
