import RegularText from "@base/RegularText";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import Currency from "@core/currencies/Currency";
import BoldText from "@base/BoldText";
import Row from "@base/Row";
import i18n from "@i18n/i18n";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    currency: Currency;
    amount?: string;
    address: string;
    transaction?: string;
    owner: boolean;
    currencyName?: string;
}

interface State {}
const { t } = i18n;
export default class TransactionCustomMessage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const currency = this.props.currency;
        return (
            <View style={styles.container}>
                {currency && (
                    <View style={{ position: "absolute", top: 2, right: 10 }}>
                        <NetworkCurrencySymbol
                            containerStyles={{ alignItems: "flex-end" }}
                            styles={{
                                paddingVertical: 4,
                                paddingHorizontal: 8,
                            }}
                            fontSize={8}
                            currency={this.props.currency}
                        ></NetworkCurrencySymbol>
                    </View>
                )}
                <View style={{ marginBottom: 10 }}>
                    <RegularText align={"left"} fontSize={12}>
                        {this.props.owner ? t("sent") : t("received")}
                    </RegularText>
                </View>
                <Row
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {currency && <CurrencyIcon size={40} styles={{ marginRight: 10 }} currency={currency} />}
                    <View>
                        {this.props.amount && (
                            <BoldText fixedWidth={150} fontSize={18}>
                                {this.props.amount}
                            </BoldText>
                        )}
                    </View>
                    <View style={{ marginLeft: 5 }}>
                        <BoldText fontSize={16}>{this.props.currencyName}</BoldText>
                    </View>
                </Row>

                <View style={{ marginTop: 5 }}>
                    <RegularText align={"left"} fontSize={12}>
                        {t("address")}
                    </RegularText>
                </View>
                <View style={{}}>
                    <RegularText align={"left"} fontSize={10}>
                        {this.props.address}
                    </RegularText>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 15,
        paddingVertical: 5,
    },
    row: {
        display: "flex",
    },
});
