import React, { Component } from "react";
import {
    EmitterSubscription,
    Keyboard,
    KeyboardAvoidingView,
    Platform,
    StatusBar,
    StyleSheet,
    TouchableWithoutFeedback,
    View,
} from "react-native";
import { colors } from "@styles/globalStyles";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import {
    Actions,
    Avatar,
    Bubble,
    Composer,
    ComposerProps,
    GiftedChat,
    IMessage,
    InputToolbar,
    Send,
    SendProps,
} from "react-native-gifted-chat";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import Container from "@base/Container";
import OptionsButton from "@components/chat/OptionsButton";
import CustomMessage from "@components/chat/CustomMessage";
import { SimpleLineIcons } from "@expo/vector-icons";
import { Chat } from "@custom-types/Chat";
import { ChatService } from "@core/services/ChatService";
import { getNameAndIfExistsContact } from "@utils/helpers/chat/chat.helper";

import { Message } from "@custom-types/Message";
import { ChatTicks } from "@components/chat/ChatTicks";
import debounce from "lodash.debounce";
import { selectSerializedMessagesFromSelectedChat } from "@store/reducers/chat.reducer";
import store from "@store/index";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { setUnreadMessages } from "@store/actions/chat.actions";
import Icon from "@components/icons";
import Constants from "expo-constants";
import i18n from "@i18n/i18n";
import AvatarBase from "@components/avatar/AvatarBase";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import { SocialNetworkNavigatorScreens } from "@navigation/SocialNetworkNavigator";
import { consoleLog } from "@utils/helpers/global/global";
import RegularText from "@base/RegularText";
import NFTService from "@core/services/NFTService";
import { setSelectedNFT } from "@store/actions/nfts.action";
import { NTFsNavigatorScreens } from "@navigation/NFTsNavigator";
import {
    hideModal,
    hideModalBottom,
    loading,
    ready,
    showModal,
    showModalBottom,
    showPopup,
    showSnackbar,
} from "@store/actions/global";
import { nameSocialLengthHelper } from "@utils/helpers/social/social.helper";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import { selectCurrency, selectTransaction } from "@store/actions/wallet";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { SerializedMessage } from "@custom-types/SerializedMessage";
import * as Clipboard from "expo-clipboard";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import CircleButton from "@base/CircleButton";
import Wallet from "@core/wallet/Wallet";
import { ASSET, REDEEMABLE } from "@custom-types/ExpercienceModel";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import OptionsButtonsModal from "@components/chat/OptionsButtonsModal";
import Currency from "@core/currencies/Currency";
import TransactionDetailComponent from "@screens/wallet/transaction/TransactionDetailComponent";

interface Props {
    navigation: NavigationType;
    selectedChat: Chat;
    messages: any;
    client: any;
}

interface State {
    name: any;
    addContact: any;
    options: boolean;
    hidden: boolean;
    loadEarlier: boolean;
    keyboard: boolean;
    iconActions: string;
    loadMessages: boolean;
}

const { t } = i18n;

export class _ChatScreen extends Component<Props, State> {
    focusListener: any;
    chatService: ChatService;

    constructor(props) {
        super(props);
        this.onSend = this.onSend.bind(this);
        this.renderAvatar = this.renderAvatar.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.optionsPress = this.optionsPress.bind(this);
        this.addContactFromClient = this.addContactFromClient.bind(this);
        this.renderCustomMessage = this.renderCustomMessage.bind(this);
        this.onPressAlias = this.onPressAlias.bind(this);
        this.onLoadEarlier = this.onLoadEarlier.bind(this);
        this.onPressNFT = this.onPressNFT.bind(this);
        this.onPressReddemable = this.onPressReddemable.bind(this);
        this.onPressAsset = this.onPressAsset.bind(this);
        this.openTransaction = this.openTransaction.bind(this);
        this.onLongPressMessage = this.onLongPressMessage.bind(this);
        this.renderBubble = this.renderBubble.bind(this);
        this.onLongPress = this.onLongPress.bind(this);
        this.onLoadEarlier = debounce(this.onLoadEarlier, 1000);

        this.state = {
            name: getNameAndIfExistsContact(this.props.selectedChat).name,
            addContact: !getNameAndIfExistsContact(this.props.selectedChat).exists,
            options: false,
            hidden: false,
            loadEarlier: this.props.selectedChat.messages.length < this.props.selectedChat.messagesCount,
            keyboard: false,
            iconActions: "lightning-charge-1",
            loadMessages: Platform.OS !== "web",
        };
    }

    async componentDidMount() {
        this.chatService = await ChatService.getInstance();
        this.chatService.updateMessagesToReadChatSelected();

        this.focusListener = this.props.navigation.addListener("focus", () => {
            if (!this.props.selectedChat) {
                this.props.navigation.navigate("MessagesList");
            }
        });

        setTimeout(() => {
            this.setState({ loadMessages: true });
        }, 100);
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps != this.props && this.props.selectedChat) {
            this.chatService.updateMessagesToReadChatSelected();
            this.setState({
                loadEarlier: this.props.selectedChat.messages.length < this.props.selectedChat.messagesCount,
            });
        }
    }

    async onSend(msg) {
        this.setState({ options: false });
        this.chatService.sendMessage(msg[0]);
    }

    addContactFromClient() {
        const client = this.props.selectedChat.to;
        this.props.navigation.navigate(MessagesNavigatorScreens.ContactForm.routeName, {
            type: "new",
            client,
        });
    }

    onLoadEarlier() {
        this.chatService.getEarlierMessages();
    }

    onLongPress(context, message) {
        const options = [t("copy_message"), t("report_message"), t("cancel_message")];
        const cancelButtonIndex = options.length - 1;
        context.actionSheet().showActionSheetWithOptions(
            {
                options,
                cancelButtonIndex,
            },
            (buttonIndex) => {
                switch (buttonIndex) {
                    case 0:
                        Clipboard.setStringAsync(message?.text);
                        break;
                    case 1:
                        this.onLongPressMessage(message);
                        break;
                }
            },
        );
    }

    onLongPressMessage(message?: SerializedMessage) {
        if (message?.user?._id !== this.props.client?._id) {
            store.dispatch(
                showModal({
                    avatarIcon: "robot",
                    title: t("hi"),
                    message: t("report_message_text"),

                    description: t("report_message_description", { name: message?.user?.name }),
                    question: t("report_message_question"),
                    btnTitle: t("report_message"),
                    subButtonTitle: t("block_user"),
                    onPress: () => this.reportMessage(message),
                    onPressSubButton: () => this.blockUser(message?.user?._id),
                }),
            );
        }
    }

    async blockUser(clientID) {
        store.dispatch(loading());
        store.dispatch(hideModal());
        const blockUser = await SocialNetworkService.getInstance().blockUser(clientID);
        store.dispatch(ready());
        store.dispatch(showPopup({ type: "SUCCESS" }));
        this.props.navigation.navigate(MessagesNavigatorScreens.MessagesList.routeName);
    }

    async reportMessage(message) {
        store.dispatch(loading());
        store.dispatch(hideModal());
        (await ChatService.getInstance()).reportMessage(message);
        store.dispatch(ready());
        store.dispatch(showPopup({ type: "SUCCESS" }));
    }

    renderBubble(props) {
        return (
            <Bubble
                {...props}
                //onLongPress={() => this.onLongPressMessage(props?.currentMessage)}
                linkStyle={{
                    left: {
                        color: colors.blue,
                        textDecorationLine: "none",
                    },
                    right: {
                        color: colors.blue,
                        textDecorationLine: "none",
                    },
                }}
                textStyle={
                    props.currentMessage.custom
                        ? {
                              left: { display: "none" },
                              right: { display: "none" },
                          }
                        : {
                              left: {
                                  display: "flex",
                                  color: colors.text,
                                  marginLeft: 10,
                              },
                              right: {
                                  display: "flex",
                                  marginLeft: 10,
                                  marginTop: 6,
                                  color: colors.text,
                              },
                          }
                }
                wrapperStyle={{
                    left: {
                        backgroundColor: colors.shadow,
                    },
                    right: {
                        backgroundColor: colors.secondaryShadow,
                    },
                }}
            />
        );
    }

    async onPressNFT(nftID: string, currencyId: string) {
        if (ModuleControlService.getInstance().isModuleEnabled(Modules.nftsModule)) {
            try {
                const nftData = await NFTService.getInstance().getNFT(
                    nftID,
                    Wallet.getInstance().findCurrencyById(currencyId).getBlockchain().toLocaleLowerCase(),
                    true,
                );
                if (nftData) {
                    store.dispatch(setSelectedNFT(nftData));
                    //await this.props.navigation.navigate(TabsNavigatorScreens.NFTs.routeName);
                    await this.props.navigation.navigate(MessagesNavigatorScreens.MessagesNFT.routeName, {
                        backTo: MessagesNavigatorScreens.Chat.routeName,
                        activeBuyable: true,
                    });
                }
            } catch (e) {
                console.warn(e);
            }
        } else {
            store.dispatch(
                showModal({
                    avatarIcon: "robot",
                    title: t("warning"),
                    subtitle: t("not_view_nft_msg"),
                }),
            );
        }
    }

    async onPressReddemable(redeemable: REDEEMABLE) {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.Redeemable.routeName,
            params: { redeemable: redeemable },
        });
    }

    async onPressAsset(asset: ASSET) {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.Asset.routeName,
            params: { asset: asset },
        });
    }

    async openTransaction(currencyID, data) {
        if (!data?.hash) {
            return;
        }
        store.dispatch(loading());
        const hash = data?.hash;
        const currency: Currency = Wallet.getInstance().findCurrencyById(currencyID);
        const transaction = await currency.getTransactionByHash(hash);

        store.dispatch(ready());
        if (currency && (transaction?.from || transaction?.id || transaction?.to)) {
            store.dispatch(
                showModalBottom({
                    modalContent: (
                        <TransactionDetailComponent currency={currency} transaction={transaction} {...this.props} />
                    ),
                }),
            );
        } else {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("transaction_open_error") }));
        }
    }

    async onPressAlias() {
        if (ModuleControlService.getInstance().isModuleEnabled(Modules.socialNetworkModule)) {
            this.props.navigation.navigate("Profile", {
                screen: "ProfileMain",
                params: { clientID: this.props.selectedChat?.to?._id },
            });
        }
    }

    renderCustomMessage(props) {
        if (props.currentMessage.custom && props.currentMessage.data?.type) {
            return (
                <CustomMessage
                    {...props}
                    onPressNFT={this.onPressNFT}
                    onPressReddemable={this.onPressReddemable}
                    onPressAsset={this.onPressAsset}
                    openTransaction={this.openTransaction}
                    navigation={this.props.navigation}
                    textStyle={{
                        left: { display: "none" },
                        right: { display: "none" },
                    }}
                />
            );
        }
    }

    renderTicks(message) {
        return <ChatTicks message={message} />;
    }

    renderInputToolbar(props) {
        return <InputToolbar {...props} containerStyle={styles.input} />;
    }

    optionsPress = () => {
        store.dispatch(
            showModalBottom({
                modalContent: <OptionsButtonsModal {...this.props} />,
            }),
        );
    };

    renderActions(props) {
        return (
            <Actions
                {...props}
                containerStyle={styles.actions}
                icon={() => (
                    <CircleButton
                        icon={"lightning-charge-1"}
                        iconSize={20}
                        style={{ width: 40, height: 40, marginBottom: 0 }}
                        onPress={this.optionsPress}
                    />
                )}
            />
        );
    }

    renderSend(props) {
        return (
            <Send {...props} alwaysShowSend={false} containerStyle={{ zIndex: 9999 }}>
                <CircleButton
                    icon={"send-chat"}
                    iconSize={18}
                    style={{ width: 40, height: 40, marginBottom: 0, paddingTop: 2, zIndex: 1 }}
                    onPress={null}
                    disableOnPress={true}
                />
            </Send>
        );
    }

    renderFooter(props) {
        return <View style={{ margin: 5 }}></View>;
    }

    renderAvatar(props) {
        return (
            <AvatarBase
                alias={this.props.selectedChat?.to?.alias}
                uri={this.props.selectedChat?.to?.profileImagePath?.thumbnail}
                size={35}
            />
        );
    }

    renderComposer = (
        props: ComposerProps & {
            onSend: SendProps<IMessage>["onSend"];
            text: SendProps<IMessage>["text"];
        },
    ) => (
        <Composer
            {...props}
            multiline={false}
            textInputStyle={[styles.textInput]}
            placeholderTextColor={colors.grey}
            composerHeight={40}
            textInputProps={{
                ...props.textInputProps,
                blurOnSubmit: Platform.OS === "web",
                onSubmitEditing:
                    Platform.OS === "web"
                        ? () => {
                              if (props.text && props.onSend) {
                                  props.onSend({ text: props.text.trim() }, true);
                              }
                          }
                        : undefined,
            }}
        />
    );

    render() {
        return (
            <ScreenWrapper>
                <View style={{ height: "100%", zIndex: 9999 }}>
                    <Header
                        title={nameSocialLengthHelper(this.props.selectedChat?.to?.alias || this.state.name, 14)}
                        type={HeaderType.Light}
                        titleBtn={{
                            title: nameSocialLengthHelper(
                                "@" + this.props.selectedChat?.to?.alias || this.state.name,
                                12,
                            ),
                            onPress: this.onPressAlias,
                        }}
                        titleAvatar={{
                            uri:
                                this.props.selectedChat?.to?.profileImagePath?.thumbnail ||
                                this.props.selectedChat?.to?.profileImagePath?.square,
                            alias: this.props.selectedChat?.to?.alias,
                            size: 26,
                        }}
                        {...this.props}
                        rightBtn={
                            this.state.addContact
                                ? {
                                      icon: "person-plus",
                                      iconType: "custom",
                                      size: 24,
                                      onPress: this.addContactFromClient,
                                  }
                                : null
                        }
                        leftBtn={{
                            onPress: () => {
                                this.props.navigation.navigate(MessagesNavigatorScreens.MessagesList.routeName);
                            },
                            icon: "arrow-left",
                            size: 24,
                        }}
                    />

                    <Container
                        style={{
                            flex: 1,
                            paddingBottom: 5,
                        }}
                    >
                        <GiftedChat
                            messages={this.state.loadMessages ? this.props.messages : []}
                            // @ts-ignore
                            wrapInSafeArea={false}
                            maxComposerHeight={500}
                            onSend={this.onSend}
                            user={{ _id: this.props.client?.id }}
                            renderBubble={this.renderBubble}
                            renderAvatar={this.renderAvatar}
                            renderInputToolbar={this.renderInputToolbar}
                            renderSend={this.renderSend}
                            renderComposer={this.renderComposer}
                            renderActions={this.renderActions}
                            renderCustomView={this.renderCustomMessage}
                            renderTicks={this.renderTicks}
                            onLongPress={this.onLongPress}
                            loadEarlier={this.state.loadEarlier}
                            onLoadEarlier={() => this.onLoadEarlier()}
                            placeholder={t("type_a_message")}
                            messagesContainerHeight={1}
                            bottomOffset={Constants.statusBarHeight - 34}
                        />
                    </Container>
                </View>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedChat: state.chat.selected,
        messages: selectSerializedMessagesFromSelectedChat(state),
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ChatScreen = connect(mapStateToProps, mapDispatchToProps)(_ChatScreen);

export default ChatScreen;

const styles = StyleSheet.create({
    bubbleLeft: {
        backgroundColor: colors.shadow,
    },
    bubbleRight: {
        backgroundColor: colors.secondaryShadow,
    },
    input: {
        backgroundColor: "transparent",

        borderColor: colors.white,
        margin: 5,
        borderTopWidth: 0,
    },
    textInput: {
        paddingTop: 0,
        marginLeft: 10,
        marginRight: 0,
        paddingRight: 15,
        paddingLeft: Platform.OS == "web" ? 20 : 15,
        marginBottom: 0,
        backgroundColor: colors.shadow,
        color: colors.text,
        borderRadius: 50,
    },

    send: {
        marginLeft: 10,
        marginBottom: 0,
        backgroundColor: colors.secondary,
        borderRadius: 50,
        padding: 5,
        width: 40,
        height: 40,
    },

    buttonSend: {
        position: "absolute",
        top: -25,
        right: 6,
        alignItems: "center",
        color: colors.white,
    },

    actions: {
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: colors.secondary,
        borderRadius: 50,
        padding: 5,
        width: 40,
        height: 40,
        alignContent: "center",
        justifyContent: "center",
    },
});
