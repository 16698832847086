import CurrencyImplementation from "./CurrencyImplementation";
import { PathNode } from "@core/utils/PathNode";
import HDNode from "hdkey";
import DataService from "@core/services/DataService";
import AbstractCurrency from "./AbstractCurrency";
import TransactionType from "@custom-types/TransactionType";
import { Network } from "bitcoinjs-lib";
import BigNumber from "bignumber.js";
import Wallet from "@core/wallet/Wallet";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import SwapType from "@custom-types/SwapType";
import PairType from "@custom-types/PairType";
import PairsType from "@custom-types/PairsType";
import Constants from "expo-constants";
import store from "@store/index";
import { ApiService } from "@core/services/ApiService";
import { Client } from "@custom-types/Client";
import { ModuleControlService, Services } from "@core/services/ModuleControlService";

export interface CurrencyStore {
    name: string;
    account: string;
    change: string;
    index: string;
    smart: boolean;
    enabled: boolean;
}

interface ICurrency {
    id?: string;
    name: string;
    simbol: string;
    fullName: string;
    decimals: number;
    seed: Buffer;
    implementation: any;
    networkID: number;
    color: string;
    icon: string;
    networkSymbol?: string;
    fiatCurrency: FiatCurrency;
    testnet: boolean;
    network: Network;
    account: string;
    change: string;
    index: string;
    provider?: string;
    underlyingCurrency?: string;
    chainId?: number;
    smartAllowed?: boolean;
    smart?: boolean;
    address?: string;
    buyable?: boolean;
    buyableBy?: Array<any>;
    sellableBy?: Array<any>;
    enabled?: boolean;
    walletConnect?: boolean;
    blockchain?: string;
    nftSupport?: boolean;
    ethCompatibilitySupport?: boolean;
}

export interface IConstructorCurrency {
    seed: Buffer;
    fiatCurrency: FiatCurrency;
    testnet: boolean;
    account?: string;
    change?: string;
    index?: string;
    smart?: boolean;
    address?: string;
    enabled?: boolean;
    ethCompatibilitySupport?: boolean;
}

const defaultAccount = "0";
const defaultChange = "0";
const defaultIndex = "0";

export default class Currency extends AbstractCurrency {
    protected color: string;
  
    protected networkSymbol: string;
    protected implementation: CurrencyImplementation;
    protected networkID: number;
    protected seed: Buffer;
    protected fiatCurrency: FiatCurrency;
    private dataService: DataService;
    private apiService: ApiService;
    private hdNode: HDNode;
    private addressCounter: number = 1;
    private balance = 0;
    private unconfirmedBalance = 0;
    private fiat = 0;
    private priceHistoryDaily = [];
    private priceHistoryWeekly = [];
    private pairs: PairsType = { testnet: [], mainnet: [] };
    private testnet = false;
    private networkNode = null;
    private accountNode = null;
    private network: Network;
    private transactions: Array<any>;
    private account: string;
    private change: string;
    private index: string;
    private provider = null;
    private underlyingCurrency = null;
    private chainId: number;
    private smartAllowed: boolean;
    private smart: boolean;
    private addresses: Array<{ [key: string]: string }>;
    private enabled: boolean;
    private ethCompatibilitySupport: boolean;
    private buyable = false;
    private buyableBy = [];
    private sellableBy = [];
    private purchaseOrders: Array<any>;
    private formats = [];
    private approving = false;
    private walletConnect = false;
    private blockchain: string;
    private nftSupport = false;

    constructor(parameters: ICurrency) {
        super(
            parameters.id ? parameters.id : parameters.name,
            parameters.name,
            parameters.simbol,
            parameters.icon,
            parameters.fullName,
            parameters.decimals,
        );

        this.seed = parameters.seed;
        this.networkID = parameters.networkID;
        this.color = parameters.color;
        this.icon = parameters.icon;
        this.networkSymbol = parameters.networkSymbol;
        this.network = parameters.network;
        this.hdNode = HDNode.fromMasterSeed(parameters.seed, this.network.bip32);
        this.implementation = new parameters.implementation(this);
        this.dataService = new DataService();
        this.fiatCurrency = parameters.fiatCurrency;
        this.testnet = parameters.testnet;
        this.buyableBy = parameters.buyableBy || [];
        this.sellableBy = parameters.sellableBy || [];
        this.transactions = [];
        this.purchaseOrders = [];
        this.account = parameters.account || defaultAccount;
        this.change = parameters.change || defaultChange;
        this.index = parameters.index || defaultIndex;
        this.provider = parameters.provider || "";
        this.underlyingCurrency = parameters.underlyingCurrency || this.name;
        this.chainId = parameters.chainId;
        this.smart = parameters.smart || false;
        this.smartAllowed = parameters.smartAllowed || false;
        this.enabled = parameters.enabled;
        this.ethCompatibilitySupport = parameters.ethCompatibilitySupport || false;
        this.addresses = [];
        this.walletConnect = parameters.walletConnect || false;
        this.blockchain = parameters.blockchain || null;
        this.nftSupport = parameters.nftSupport || false;
    }

    getSeed() {
        return this.seed;
    }

    isSmartAllowed() {
        return this.smartAllowed;
    }

    isSmart() {
        return this.smartAllowed && this.smart;
    }

    setSmart(smart: boolean) {
        this.smart = smart;
    }

    setApproved(approving: boolean) {
        this.approving = approving;
    }

    isApproving(): boolean {
        return this.approving;
    }

    getFormats(): Array<string> {
        return this.implementation.getFormats();
    }

    isMultiFormat() {
        return this.implementation.getFormats().length > 0;
    }

    getUnderlyingCurrency(): Currency {
        return Wallet.getInstance().findCurrencyByName(this.underlyingCurrency);
    }

    getBlockchain(): string {
        return this.blockchain || this.getUnderlyingCurrency().getBlockchain();
    }

    getUnderlyingCurrencyID() {
        return this.underlyingCurrency;
    }

    getPName(): string {
        return (this.isTestnet() ? "t" : "") + this.name;
    }
    getPFullName(): string {
        return super.getFullName() + (this.isTestnet() ? " (Testnet)" : "");
    }

    getAccount() {
        return this.account;
    }

    setAccount(account: string) {
        this.account = account;
    }

    setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    getChange() {
        return this.change;
    }

    setChange(change: string) {
        this.change = change;
    }

    getIndex() {
        return this.index;
    }

    setIndex(index: string) {
        this.index = index;
    }

    getNetworkID() {
        if (this.hasEthCompatibility()) {
            return Wallet.getInstance().findCurrencyById("ETH").getNetworkID();
        }
        if (this.underlyingCurrency && this.underlyingCurrency !== this.id) {
            return Wallet.getInstance().findCurrencyById(this.underlyingCurrency).getNetworkID();
        }
        return this.networkID;
    }

    getStaticNetworkID() {
        return this.networkID;
    }

    hasEthCompatibility() {
        const client: Client = store.getState().auth.client;
        return client?.ethCompatibility && this.ethCompatibilitySupport;
    }

    hasEthCompatibilitySupport() {
        return this.ethCompatibilitySupport;
    }

    getImplementation() {
        return this.implementation;
    }

    getFullName(): string {
        return super.getFullName();
    }

    getNetwork() {
        return this.network;
    }

    getProvider() {
        return this.testnet ? this.provider.testnet : this.provider.mainnet;
    }

    isTestnet() {
        return this.testnet;
    }

    isBuyable() {
        return this.buyableBy.some((element) => {
            const providers = Constants.expoConfig?.extra?.buyProviders;
            return providers && providers.includes(element);
        });
    }

    isBuyableBy(provider?: string) {
        return Constants.expoConfig?.extra?.buyProviders?.includes(provider) && this.buyableBy.includes(provider);
    }

    isSellable() {
        return this.sellableBy.some((element) => {
            const providers = Constants.expoConfig?.extra?.sellProviders;
            return providers && providers.includes(element);
        });
    }

    isSellableBy(provider?: string) {
        return Constants.expoConfig?.extra?.sellProviders?.includes(provider) && this.sellableBy.includes(provider);
    }

    getChainId() {
        return this.chainId;
    }

    hasWalletConnect() {
        return this.walletConnect;
    }

    hasNftSupport() {
        return this.nftSupport;
    }

    getNetworkType() {
        return this.testnet ? "testnet" : "mainnet";
    }

    getColor() {
        return this.color;
    }

    getNetworkSymbol(): string {
        return this.networkSymbol;
    }
    isValidAddress(address: string) {
        return this.implementation.isValidAddress(address);
    }

    parseTransaction(tx: Object) {
        return this.implementation.parseTransaction(tx);
    }

    getTransactions() {
        return this.transactions;
    }

    getTransaction(index: number) {
        return this.transactions[index];
    }

    async getTransactionByHash(hash: string) {
        try {
            if(!hash) {
                return;
            }
            const url = `txs/address/${this.getAddressesForAllFormats()}/hash/${hash}`;
            const headers = await ApiService.getAuthHeaders();

            const res = await ApiService.get(this, url, { headers: headers, timeout: 7000 });
            return res.data;
        } catch (e) {}
    }

    setTransactions(transactions: Array<any>) {
        this.transactions = transactions;
        return this;
    }

    getPurchaseOrders() {
        return this.purchaseOrders;
    }

    getPurchaseOrder(index: number) {
        return this.purchaseOrders[index];
    }

    setPurchaseOrders(orders: Array<any>) {
        this.purchaseOrders = orders;
        return this;
    }

    toFiat(amount: number) {
        const n = this.fiat * amount;
        return n.toFixed(n < 0.01 && n > 0 ? 3 : 2);
    }

    getFiat(): number {
        return this.fiat;
    }

    setFiat(value: number) {
        this.fiat = value;
        return this;
    }

    setPriceHistoryDaily(prices: any) {
        this.priceHistoryDaily = prices;
        return this;
    }

    setPriceHistoryWeekly(prices: any) {
        this.priceHistoryWeekly = prices;
        return this;
    }

    getPriceHistoryDaily() {
        return this.priceHistoryDaily;
    }

    getPriceHistoryWeekly() {
        return this.priceHistoryWeekly;
    }

    getPair(name: string): PairType {
        const pairs = this.getPairs();
        if (pairs) return pairs.find((pair) => pair.name.toUpperCase() == name.toUpperCase());
    }

    getPairs(): Array<PairType> {
        return this.pairs[this.isTestnet() ? "testnet" : "mainnet"];
    }

    isExchangeAvailable() {
        return (
            ModuleControlService.getInstance().isServiceEnabled(Services.exchangeService) && this.getPairs().length > 0
        );
    }

    setPairs(pairs: PairsType) {
        if (!pairs) {
            pairs = {
                mainnet: [],
                testnet: [],
            };
        }
        this.pairs = pairs;
        return this;
    }

    getBalance() {
        return this.balance;
    }

    setBalance(value: number) {
        this.balance = value;
        return this;
    }

    getUnconfirmedBalance() {
        return this.unconfirmedBalance;
    }

    setUnconfirmedBalance(value: number) {
        this.unconfirmedBalance = value;
        return this;
    }

    getFormatBalance() {
        return this.format(this.balance);
    }

    getFormatTotalBalance() {
        const balance = new BigNumber(this.balance);
        const unconfirmedBalance = new BigNumber(this.unconfirmedBalance);
        return this.format(balance.plus(unconfirmedBalance).toNumber());
    }

    getUnconfirmedFiatBalance() {
        return this.fromDecimals(this.unconfirmedBalance) * this.fiat || 0;
    }

    getFiatBalance() {
        return this.fromDecimals(this.balance) * this.fiat || 0;
    }

    getFiatTotalBalance() {
        return this.getFiatBalance() + this.getUnconfirmedFiatBalance();
    }

    getAddress(options?: { path?: string; owner?: boolean; format?: string }) {
        const path = options?.path || this.getCurrentPath();
        if (!(this.addresses && this.addresses[path])) {
            let chainId = this.chainId;
            if (this.name !== this.underlyingCurrency) {
                const u = this.getUnderlyingCurrency();
                chainId = u.chainId;
                return u.getAddress({ path: path });
            }
            const addr = this.implementation.generateAddress(this.generateAddressNode(path), {
                chainId: chainId,
                owner: options ? options.owner : false,
                format: options?.format,
            });
            this.addresses[path] = addr;
            return addr;
        }

        return this.addresses[path];
    }

    getAddressesForAllFormats() {
        if (this.isMultiFormat()) {
            return this.getFormats()
                .map((format) => this.getAddress({ format: format }))
                .join(",");
        }
        return this.getAddress();
    }

    isEnabled() {
        return this.enabled;
    }

    getPublicKey() {
        const pathNode = this.generateAddressNode();
        return pathNode.node.xpub;
    }

    getCurrentPath(purpose = "44") {
        return `m/${purpose}'/${this.getNetworkID()}'/${this.account}'/${this.change}/${this.index}`;
    }

    getDefaultPath() {
        return `m/44'/${this.getNetworkID()}'/${defaultAccount}'/${defaultChange}/${defaultIndex}`;
    }

    generateAddressNode(path?: string) {
        if (!path) {
            path = this.getCurrentPath();
        }
        return new PathNode(path, this.hdNode.derive(path).toJSON());
    }

    getReceiveAddress(options?: { path?: string; owner?: boolean; format?: string }) {
        return `${this.getFullName().toLowerCase()}:${this.getAddress(options)}`;
    }

    loadStateFromStore(): Promise<any> {
        return Promise.resolve(null);
    }

    newTransaction(transaction: TransactionType) {
        let url = `${this.isSmart() ? "smart/" : ""}txs/skeleton`;
        let value = new BigNumber(this.toDecimals(transaction.amount)).toString();
        const body = {
            addressFrom: transaction.addressFrom,
            output: [
                {
                    address: transaction.addressTo,
                    value: value.split(".")[0],
                },
            ],
            feeType: "fastestFee", //halfHourFee, hourFee
            addressOwner: transaction.addressOwner, //Smart Wallet
            data: transaction.data || "",
            estimateGas: transaction.estimateGas || "",
        };
        return ApiService.post(this, url.toLowerCase(), body);
    }

    newSwap(swap: SwapType) {
        let url = `exchange/${swap.to.getId()}`;
        return ApiService.post(this, url.toLowerCase(), {
            from: swap.from.getAddressesForAllFormats(),
            to: swap.to.getAddress(),
            amount: new BigNumber(swap.from.toDecimals(swap.amount)).toString(),
        });
    }

    async getExchangePrice(currency: Currency) {
        let url = `exchange/${currency.getId()}`;
        return ApiService.get(this, url.toLowerCase());
    }

    async sendTransaction(skeleton: any) {
        const signedTransaction = await this.signTransaction(skeleton);
        let url = `${this.isSmart() ? "smart/" : ""}txs/broadcast`;
        return await ApiService.post(this, url.toLowerCase(), {
            txs: signedTransaction,
            skeleton,
        });
    }

    async signTransaction(skeleton: any) {
        const addressNode = this.generateAddressNode();
        return await this.implementation.signTransaction(addressNode, skeleton);
    }

    async signMessage(hexMessage: string, path?: string) {
        const addressNode = this.generateAddressNode(path);
        return await this.implementation.signMessage(addressNode, hexMessage);
    }

    async signTypedData(JsonData: string) {
        const addressNode = this.generateAddressNode();
        return await this.implementation.signTypedData(addressNode, JsonData);
    }

    async syncBalance() {
        try {
            const url = `address/${this.getAddressesForAllFormats()}/balance`;
            const headers = await ApiService.getAuthHeaders();
            const res = await ApiService.get(this, url, { headers, timeout: 7000 }).catch((e) => {
                console.warn(e);
                return {
                    data: {
                        balance: 0,
                        unconfirmedBalance: 0,
                        transactions: [],
                    },
                };
            });
            if (this.getBalance() != res.data.balance || this.getUnconfirmedBalance() != res.data.unconfirmedBalance) {
                this.setBalance(res.data.balance ? res.data.balance : 0);
                this.setUnconfirmedBalance(res.data.unconfirmedBalance ? res.data.unconfirmedBalance : 0);
            }
        } catch (e) {
            //this.syncBalance()
        }

        return this;
    }

    async syncTransactions() {
        try {
            const url = `address/${this.getAddressesForAllFormats()}/txs`;
            const headers = await ApiService.getAuthHeaders();
            const currency = this;
            const res = await ApiService.get(this, url, { headers: headers, timeout: 7000 }).catch(() => {
                return {
                    data: currency.getTransactions(),
                };
            });
            this.setTransactions(res.data ? res.data : []);
        } catch (e) {}

        return this;
    }

    getKeys() {
        const keys = this.implementation.getKeys(this.generateAddressNode(this.getDefaultPath()));
        return keys;
    }

    public toJSON(): CurrencyStore {
        return {
            name: this.getId(),
            account: this.getAccount(),
            change: this.getChange(),
            index: this.getIndex(),
            smart: this.isSmart(),
            enabled: this.isEnabled(),
        };
    }

    setFromJSON(data: Partial<CurrencyStore>) {
        this.setAccount(data?.account || defaultAccount);
        this.setChange(data?.change || defaultChange);
        this.setIndex(data?.index || defaultIndex);
        this.setSmart(data?.smart || false);
        this.setEnabled(data?.enabled || false);
    }
}
