import RegularText from "@base/RegularText";
import Row from "@base/Row";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import Icon from "@components/icons";
import Post from "@custom-types/Post";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { setTimeVariables } from "@utils/helpers/social/social.helper";
import AvatarBase from "@components/avatar/AvatarBase";
import ImageBase from "@components/image/ImageBase";
import { getColorOpacity } from "@utils/helpers/global/global";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    post: Post;
    highlightedHashtag?: boolean;
    openPost?: (post) => any;
    openHashtag?: (hashtag) => any;
    isOwner?: boolean;
    isLikeable?: boolean;
    smallAvatar?: boolean;
    autoSizeWidth?: boolean;
    heightImage?: number;
    title?: string;
}

interface State {
    liked: boolean;
    options: boolean;
    deleted: boolean;
    reported: boolean;
    date: string;
}

const { t } = i18n;

export default class PostSmallCard extends Component<Props, State> {
    socialNetworkService: SocialNetworkService;
    setTimeVariables;

    constructor(props: Props) {
        super(props);

        this.socialNetworkService = SocialNetworkService.getInstance();
        this.onLike = this.onLike.bind(this);
        this.openProfile = this.openProfile.bind(this);
        this.state = {
            liked: this.props.post.likes ? this.props.post.likes.liked : false,
            options: false,
            deleted: false,
            reported: this.props.post.reported ? this.props.post.reported : false,
            date: setTimeVariables(this.props.post.date),
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.state.liked !== this.props.post.likes?.liked) {
            this.setState({ liked: this.props.post.likes?.liked });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps != this.props || nextState != this.state) {
            return true;
        }
        return false;
    }

    onLike() {
     
        var liked = this.state.liked;
        this.setState({
            liked: !liked,
        });

        this.socialNetworkService.likePost(this.props.post.id, liked);
    }

    onDelete = async () => {
        const delet = await this.socialNetworkService.deletePost(this.props.post.id);
        if (delet) {
            this.setState({ deleted: true });
        }
    };

    openProfile = () => {
        this.props.navigation.navigate("Profile", {
            screen: "ProfileMain",
            params: { clientID: this.props.post.author.clientID },
        });
        // this.props.navigation.navigate(SocialNetworkNavigatorScreens.Profile.routeName, {
        //     screen: ProfileNavigatorScreens.ProfileMain.routeName,
        //     params: { clientID: this.props.post.author.clientID },
        // });
    };

    render() {
        return (
            <View style={{ flex: 1 }}>
                {!this.state.deleted && (
                    <View style={[styles.wrapper, {height: this.props?.heightImage || 240}]}>
                        {!this.state.reported && (
                            <PressableBase
                                onPress={
                                    this.state.options
                                        ? () => this.setState({ options: !this.state.options })
                                        : () => this.props.openPost(this.props.post)
                                }
                                onLongPress={
                                    this.props.isOwner
                                        ? () => this.setState({ options: !this.state.options })
                                        : () => ""
                                }
                            >
                                <ImageBase
                                    autoSizeWidth={this.props.autoSizeWidth}
                                    uri={this.props.post.image?.square}
                                    style={{ height: this.props?.heightImage || 240, resizeMode: "cover" }}
                                />
                            </PressableBase>
                        )}
                        {this.state.reported && (
                            <PressableBase onPress={() => this.setState({ reported: false })}>
                                <ImageBase
                                    uri={this.props.post.image?.square}
                                    style={{ height: 240, resizeMode: "cover" }}
                                    blurRadius={10}
                                />
                                <Icon
                                    name={"eye-slash"}
                                    size={80}
                                    color={colors.white}
                                    style={{
                                        position: "absolute",
                                        opacity: 0.6,
                                        top: 75,
                                        left: "32%",
                                    }}
                                />
                            </PressableBase>
                        )}

                        <View
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                            }}
                        >
                            {this.props.isLikeable && (
                                <PressableBase
                                    style={{ height: 40, width: 40, alignContent: "center", padding: 10 }}
                                    onPress={this.onLike}
                                >
                                    {this.state.liked ? (
                                        <Icon
                                            name="heart-fill"
                                            size={16}
                                            color={colors.red}
                                            style={{ alignSelf: "center" }}
                                        />
                                    ) : (
                                        <Icon
                                            name="heart"
                                            size={16}
                                            color={colors.text}
                                            style={{ alignSelf: "center" }}
                                        />
                                    )}
                                </PressableBase>
                            )}
                        </View>

                        <View
                            style={{
                                position: "absolute",
                                bottom: 10,
                                paddingHorizontal: 5,
                                justifyContent: "flex-start",
                                overflow: "hidden",
                            }}
                        >
                            {this.state.date && (
                                <View style={{ alignSelf: "flex-start" }}>
                                    <RegularText
                                        align="center"
                                        fontSize={12}
                                        style={{
                                            backgroundColor: colors.shadow,
                                            paddingHorizontal: 6,
                                            borderRadius: 4,
                                            paddingVertical: 2,
                                            overflow: "hidden",
                                        }}
                                    >
                                        {this.state.date}
                                    </RegularText>
                                </View>
                            )}

                            <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                                {this.props.post?.hashtag &&
                                    this.props.post.hashtag.map((hashtag) => {
                                        return (
                                            <PressableBase
                                                key={`${hashtag}${Math.random() * (100 - 1) + 1}`}
                                                onPress={
                                                    this.props.openHashtag
                                                        ? () => this.props.openHashtag(hashtag.name)
                                                        : () => ""
                                                }
                                            >
                                                <View
                                                    style={[
                                                        styles.hashtag,
                                                        {
                                                            backgroundColor: this.props.highlightedHashtag
                                                                ? getColorOpacity(colors.grey, 0.6)
                                                                : colors.shadow,
                                                        },
                                                    ]}
                                                >
                                                    <RegularText fontSize={12} key={hashtag.name}>
                                                        #{hashtag.name}
                                                    </RegularText>
                                                </View>
                                            </PressableBase>
                                        );
                                    })}
                            </ScrollView>
                        </View>
                        {this.props.smallAvatar && (
                            <View
                                style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 5,
                                    paddingHorizontal: 5,
                                    justifyContent: "flex-end",
                                }}
                            >
                                <AvatarBase
                                    onPress={this.openProfile}
                                    uri={this.props.post.author.avatar ? this.props.post.author.avatar : ""}
                                    alias={this.props.post.author.alias}
                                    size={25}
                                />
                            </View>
                        )}

                        {this.state.options && (
                            <View
                                style={{
                                    position: "absolute",
                                    overflow: "hidden",
                                    zIndex: 9999,
                                    right: 5,
                                    top: 5,
                                    backgroundColor: colors.secondary,
                                    borderRadius: 15,
                                    borderTopRightRadius: 5,
                                    padding: 15,
                                }}
                            >
                                <PressableBase onPress={this.onDelete}>
                                    <Row>
                                        <Icon
                                            name={"trash"}
                                            size={18}
                                            color={colors.white}
                                            style={{ marginRight: 10, marginTop: 2 }}
                                        ></Icon>
                                        <RegularText>{t("delete")}</RegularText>
                                    </Row>
                                </PressableBase>
                            </View>
                        )}
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: colors.secondaryShadow,
        flex: 1,
        marginHorizontal: 5,
        marginVertical: 5,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
    },

    hashtag: {
        color: colors.text,
        marginRight: 10,
        alignSelf: "center",
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: 10,
        textAlign: "center",
        marginTop: 5,
        justifyContent: "center",
    },
});
