import React, { Component } from "react"
import { View, StyleSheet, FlatList, RefreshControl } from "react-native"
import { connect } from "react-redux"
import { Client } from "@custom-types/Client"
import PostCard from "./components/PostCard"
import Post from "@custom-types/Post"
import { NavigationType } from "@custom-types/NavigationType"
import SearchBar, { SearchType } from "./components/SearchBar"
import SocialNetworkService from "./service/SocialNetworkService"
import Header from "@components/header/Header"
import { HeaderType } from "@custom-types/HeaderType"
import Container from "@base/Container"
import UserCard from "./components/UserCard"
import PostSmallCard from "./components/PostSmallCard"
import store from "@store/index"
import { setSearch } from "@store/actions/global"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import { SocialNetworkNavigatorScreens } from "@navigation/SocialNetworkNavigator"
import debounce from "lodash.debounce";

interface Props {
    client: Client
    navigation: NavigationType
}

interface State {
    viewType: SearchType
    clients: Array<Client>
    posts: Array<Post>
    smallPosts: Array<Post>
}

export class _SocialNetworkSearchScreen extends Component<Props, State> {
    socialNetworkService: SocialNetworkService
    focusListener: any

    constructor(props: Props) {
        super(props)
        this.onSearch = debounce(this.onSearch, 500);
        this.socialNetworkService = SocialNetworkService.getInstance();
        this.state = {
            viewType: SearchType.USER,
            clients: [],
            posts: [],
            smallPosts: [],
        }
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            store.dispatch(setSearch(""))
        })
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove()
        } catch (e) { }
        store.dispatch(setSearch(""));
    }

    setType = (searchType) => {
        this.setState({ viewType: searchType })
    }

    onSearch = async (search) => {
        if (this.state.viewType == SearchType.USER) {
            var clients = await this.socialNetworkService.searchUser(search, 1)
            if (clients) {
                this.setState({ clients: clients.docs })
            }
        }

        if (this.state.viewType == SearchType.POST) {
            var posts = await this.socialNetworkService.searchPost(search, 1)
            if (posts) {
                this.setState({ posts: posts.docs })
            }
        }

        if (this.state.viewType == SearchType.HASHTAG) {
            var posts = await this.socialNetworkService.searchHashtags(search, 1)

            if (posts) {
                this.setState({ smallPosts: posts.docs })
            }
        }
    }

    renderItemUser = ({ item }) => {
        return <UserCard client={item} isOwner={item._id == this.props.client?._id} navigation={this.props.navigation} />
    }

    renderItemPosts = ({ item }) => {
        return <PostCard post={item} onFollow={this.onFollow} navigation={this.props.navigation} />
    }

    onFollow = async (clientID) => {
        const follow = await this.socialNetworkService.followUser(clientID)

        if (follow) {
            const posts = this.state.posts
            posts.map((item) => {
                if (item.author.clientID == clientID) {
                    return (item.author.follow = !item.author.follow)
                }
            })

            this.setState({ posts: posts })
        }
    }

    renderItemHashtags = ({ item }) => {
        return (
            <PostSmallCard
                highlightedHashtag={true}
                openHashtag={(hashtag) => this.openHashtag(hashtag)}
                post={item}
                openPost={() => null}
                navigation={this.props.navigation}
            />
        )
    }
    openHashtag = async (hashtag) => {
        this.props.navigation.navigate(SocialNetworkNavigatorScreens.Discover.routeName, { hashtag: hashtag })
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={" "} type={HeaderType.Light} {...this.props} />
                <SearchBar setSearch={this.setType} onSearch={this.onSearch} />
                <Container style={{ flex: 1, paddingHorizontal: 0 }}>
                    {this.state.viewType == SearchType.USER && (
                        <View style={{ paddingHorizontal: 20 }}>
                            <FlatList
                                data={this.state.clients}
                                renderItem={this.renderItemUser}
                                keyExtractor={(item) => item.id}
                                contentContainerStyle={{ paddingBottom: 20 }}
                                initialNumToRender={10}
                                maxToRenderPerBatch={10}
                            // refreshing={this.state.syncing}
                            // onRefresh={this.onRefresh}
                            // onEndReachedThreshold={1}
                            // onEndReached={this.loadMore}
                            />
                        </View>
                    )}

                    {this.state.viewType == SearchType.POST && (
                        <FlatList
                            data={this.state.posts}
                            renderItem={this.renderItemPosts}
                            keyExtractor={(item) => item.id}
                            contentContainerStyle={{ paddingBottom: 20 }}
                            initialNumToRender={2}
                            maxToRenderPerBatch={10}
                        // refreshing={this.state.syncing}
                        // onRefresh={this.onRefresh}
                        // onEndReachedThreshold={1}
                        // onEndReached={this.loadMore}
                        />
                    )}

                    {this.state.viewType == SearchType.HASHTAG && (
                        <FlatList
                            data={this.state.smallPosts}
                            renderItem={this.renderItemHashtags}
                            keyExtractor={(item) => item.id}
                            contentContainerStyle={{
                                paddingBottom: 20,
                                //flex:1,
                            }}
                            numColumns={2}
                            initialNumToRender={10}
                            maxToRenderPerBatch={6}
                        // refreshing={this.state.syncing}
                        // onRefresh={this.onRefresh}
                        // onEndReachedThreshold={1}
                        // onEndReached={this.loadMore}
                        />
                    )}
                </Container>
            </ScreenWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    }
}

const mapDispatchToProps = (dispatch) => ({})

const SocialNetworkSearchScreen = connect(mapStateToProps, mapDispatchToProps)(_SocialNetworkSearchScreen)

export default SocialNetworkSearchScreen

const styles = StyleSheet.create({})
