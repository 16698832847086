import Currency, { IConstructorCurrency } from "@core/currencies/Currency"
import { FiatCurrency } from "@core/fiat/FiatCurrencies"
import { Networks } from "@core/network/Networks"
import ETHImplementation from "../eth/ETHImplementation"
import { ProviderType } from "@custom-types/ProviderType"
import Constants from "expo-constants"
import { isCurrencyEnabled } from "@core/utils/CurrencyEnabled"

export default class DAI extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            name: "DAI",
            simbol: "DAI",
            fullName: "Dai",
            decimals: 18,
            implementation: ETHImplementation,
            networkID: parameters.testnet ? 61 : 60,
            color: "#eea739",
            icon: "dai",
            networkSymbol: "ETH",
            underlyingCurrency: "eth",
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "DAI", true),
            buyableBy: [ProviderType.KRIPTONMARKET, ProviderType.SIMPLEX],
        })
    }
}
