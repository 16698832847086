import React, { Component } from "react";
import { View, StyleSheet, Platform, ViewStyle, Image, ImageStyle } from "react-native";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import { colors } from "@styles/globalStyles";
import Icon from "@components/icons";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";

interface Props {
    currency: Currency | FiatCurrency;
    styles?: ImageStyle;
    size?: number;
    iconSize?: number;
}

interface State {
    sourceError: boolean;
}

const { t } = i18n;

export default class CurrencyIcon extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sourceError: false,
        };
    }

   

    render() {
        return (
            <>
                {this.props.currency && (
                    <>
                        <View
                            style={[
                                {
                                    width: this.props.size || 36,
                                    height: this.props.size || 36,
                                    borderRadius: this.props.size / 2 || 18,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    overflow: "hidden",
                                    // @ts-ignore
                                    backgroundColor: this.props.currency?.getColor ? this.props.currency?.getColor() : colors.secondary,
                                },
                                this.props.styles,
                            ]}
                        >
                            {typeof(this.props.currency.getIcon()) == "string" && (Platform.OS == "web" ? this.state.sourceError : true) ? (
                                <Icon
                                    style={{ zIndex: 9999 }}
                                    name={this.props.currency.getIcon()}
                                    size={this.props.iconSize || 25}
                                    color="white"
                                />
                            ) : (
                                <Image
                                    style={{
                                        width: this.props.size || 36,
                                        height: this.props.size || 36,
                                    }}
                                    resizeMode="cover"
                                    //@ts-ignore
                                    source={this.props.currency.getIcon()}
                                    onError={() => this.setState({sourceError: true})}
                                    fadeDuration={0}
                                />
                            )}
                        </View>
                    </>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({});
