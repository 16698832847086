export const tr = {
    en: "İngilizce",
    es: "Spanish",
    tr: "Türkçe",
    welcome: "Hoşgeldiniz",
    hi: "Merhaba!",
    pending: "Askıda olması",
    processed: "İşlenmiş",
    error: "Hata",
    preminted: "Önceden Basılmış",
    minted: "Darphane",
    membership: "ÜYELİKLER",
    bot_marketplace_warning:
        "Bu NFT başka bir Pazaryerinde (%{name}) yayınlandı, bu yayını buradan iptal edebilirsiniz",
    ticket: "BİLETLER",
    voucher: "Kuponlar",
    download: "İndirmek",
    bot_seed_warnign:
        "Yeni bir tohum oluştururken uygulamayı yeniden başlatmanız gerekecek, geçmişteki eski tohumunuzu kontrol edebileceksiniz.",
    bot_continue: "Devam etmek istiyor musun?",
    bot_seed_change: "Yeni tohum ifadesi (Tohum) yüklendikten sonra uygulama yeniden başlatılacaktır.",
    bot_save_warning: "Değişikliklerin etkili olması için kaydet butonuna basmayı unutmayın",
    security_kit_a: "Değişikliklerin etkili olması için kaydet butonuna basmayı unutmayın",
    security_kit_b: "Tohumumu zaten yazdım",
    security_kit_c: "Şimdi tohum ifadenizi görmek ve kelimeleri yazmak ister misiniz?",
    security_kit_seed_a: "Tohum ifadesi nedir?",
    security_kit_seed_b:
        "Kripto para birimlerimizin ve cüzdanlarımızın yönetimini kolaylaştıracak en ilginç tekliflerden biri, tohum cümlesinin veya tohum cümlesinin oluşturulmasıdır. Bu sayede cüzdanımızdaki bilgileri yedeklemek ve böylece paramızı her zaman korumak çok kolaydır.",
    security_kit_seed_c:
        "Tohum cümlesi veya tohum cümlesi, amacı bize kripto para birimi cüzdanımızı desteklemenin kolay ve basit bir yolunu sunmak olan 12 kelimelik bir diziyi ifade eder. Bu şekilde, herhangi bir nedenle kontrolü kaybedersek, bu kelimeleri bize verildikleri sırayla kullanarak onu geri alabiliriz.",
    security_kit_seed_d: "Bu ipuçlarını dikkate alın:",
    security_kit_seed_e: "Bunları size gösterdiğimiz sıraya göre bir kağıda yazmanız önemlidir.",
    security_kit_seed_f: "Bu sözleri asla başkalarıyla paylaşmayın.",
    security_kit_seed_g:
        "Bu cihazı değiştirirseniz veya kaybederseniz, paranızı geri almanın tek yolu bu sözler olabilir.",
    security_kit_seed_h: "Bir sonraki ekranda size bu kelimelerden bazılarını soracağız, o yüzden yazmayı unutmayın.",
    security_kit_confirm_a:
        "Bu kelimelerden ikisi tohum ifadenizin (Tohum) bir parçasıdır; doğrulama işlemini tamamlamak için bunları seçin.",
    security_kit_confirm_b:
        "Yalnızca iki kelime seçmeniz gerektiğini unutmayın. Birinin seçimini kaldırmak için yukarıda ona dokunmanız yeterlidir.",
    security_kit_confirm_error: "Seçilen kelimeler doğru değil",
    finalize: "Sona ermek",
    beexo_wallet_is: "Wallet, yeni nesil için oluşturulmuş, çok platformlu, çok para birimli bir kripto cüzdanıdır.",
    i_accept: "Kabul ediyorum",
    seed_phrase: "Tohum İfadesi",
    beexo_is_a_deterministic_wallet:
        "Çekirdek cümleyi oluşturmak için BIP39 standardını kullanıyoruz. İfadeyi güvenli bir şekilde kaydetme sorumluluğu tamamen kullanıcıya aittir. İfadenizi kaybetmenin veya birine açıklamanın tüm paranızı kaybetmeniz anlamına gelebileceğini unutmayın.",
    terms: "Şartlar",
    before_continuing: "Devam etmeden önce okuyup kabul etmelisiniz",
    terms_and_conditions: "Şartlar ve Koşullar, UELA (Son Kullanıcı Lisans Sözleşmesi) ve Gizlilik Politikaları.",
    privacy_terms: "Devam etmeden önce, şartlarımızı okuyup kabul etmelisiniz.",
    privacy_terms_link: "gizlilik politikaları",
    if_you_do_not: "Tüm şartlarımızı kabul etmiyorsanız lütfen bu uygulamayı kullanmayın.",
    crypto: "Kripto",
    draft: "Taslak",
    not_edit: "(DÜZENLENEBİLİR DEĞİLDİR)",
    bot_draft: "Bu bir taslaktır ve yalnızca basıldığında bir NFT olacaktır.",
    bot_draft_minted: "Bu NFT'yi basıyoruz, birazdan bunun NFT olarak yansıdığını göreceksiniz.",
    discover: "Keşfetmek",
    chat: "Sohbet",
    total_balance: "Toplam bakiye",
    balance: "Bakiye",
    send: "Gönder",
    wallet_connect: "Cüzdan Bağlantısı",
    dapps: "Dapps Gezgini",
    processing: "İŞLEME",
    processing_image: "GÖRÜNTÜ İŞLENİYOR",
    your_dapps: "Dapp'leriniz",
    connected: "Contecado",
    collections: "Koleksiyon",
    marketplace: "Market",
    disconnected: "Bağlanti Kaybedildi",
    explore_dapps: "Dapps'i Keşfedir",
    dapps_connect_warning: "Bu Dapp için aktif bir WalletConnect bağlantınız yok",
    dapps_connect_copy: "Dapp'te Wallet Connect'i arayın ve bağlanmak için QR Kodunu KOPYALAYIN",
    wallet_connect_paste_error: "Girilen kodun Wallet Connect'e karşılık gelmediği görülüyor",
    wallet_connect_question: "Gerçekten %{name} ile bağlantı kurmak istiyor musun?",
    wallet_connect_remove_question: "Gerçekten %{name}'in bağlantısını kesip kaldırmak istiyor musunuz?",
    wallet_connect_select_currency: "Lütfen %{name} üzerinde Wallet Connect'i başlatmak istediğiniz ağı (Zincir) seçin",
    wallet_connect_sign: "İmza",
    wallet_connect_welcome: "Merhaba! WalletConnect V2 kullanıyorsunuz 🚀",
    wallet_connect_welcome_message:
        "Artık bağlantıların çok zincirli olabileceğini unutmayın. Yeni bir Cüzdan Bağlantısı eklemek için ScannerQR'yi kullanın",
    wallet_connect_request_question: "Bu eylemi gerçekten gerçekleştirmek istiyor musunuz?",
    wallet_connect_disconnected: "Wallet Connect oturumu kapatıldı",
    wallet_connect_empty: "Aktif WalletConnect bağlantınız yok, yeni bir bağlantı eklemek için düğmeye tıklayın.",
    wallet_connect_connection_error: "Bağlanamadık, lütfen tekrar deneyin.",
    personal_sign_subtitle: "Kişisel bir mesaj imzalayın",
    personal_sign_description:
        "Bu, belirli bir imza olarak tanınabilir ve kötü niyetli bir DApp'in rastgele verileri imzalayabileceği durumlarda kötüye kullanımı önler.",
    no_proccessed_request: "Cüzdanımız şu anda DApp isteğiyle uyumlu değil.",
    eth_Transaction_subtitle: "Bir işlemi imzalamanızı istiyor",
    eth_Transaction_question: "Ayrıntıları görmek ve işlemi onaylamak için Devam'a basın",
    eth_sendTransaction_subtitle: "İşlemi Onayla",
    eth_sendTransaction: "From \n%{from} \n\nTo \n%{to}",
    eth_signTransaction: "From \n%{from} \n\nTo \n%{to} \n\nAmount \n%{value}",
    remove: "Sil",
    message_remove: "Bu öğe bir taslak olduğundan ve henüz basılmadığından silebilirsiniz.",
    question_remove: "Bu içeriği kaldırmak istiyor musunuz?",
    connect: "Bağlan",
    disconnect: "Baglantıyı kes",
    explore: "Keşif",
    copy_message: "KOPYALAYIN",
    report_message: "Raporla",
    cancel_message: "İptal",
    save: "Kaydet",
    save_restart: "Kaydet Ve Tekrar Et",
    no_network_connection: "İnternet bağlantınız yok gibi görünüyor; cüzdanın birçok özelliği kullanılamayabilir.",
    delete: "Sil",
    receive: "Almak",
    received: "Kabul Edilmiş",
    total_received: "Toplu Alım",
    total_payed: "Alınan Toplam Ödenen",
    sent: "Gönderilmiş",
    network: "Ağ",
    exchange: "Değişme",
    address: "Adres",
    your_address: "Adresiniz",
    copied: "Panoya kopyalandı",
    valid_address: "Lütfen geçerli bir adres girin",
    amount_small: "Tutar çok küçük",
    next: "Devam",
    back: "Geri",
    confirm_transaction: "İşlemi onayla",
    transaction_id: "İşlem Kimliği",
    amount: "Miktar",
    sending_to: "Gönderiliyor",
    sending_from: "Gönderen:",
    fee: "Ücret",
    service_fee: "Hizmet Bedeli (Sağlayıcı)",
    status: "Statü",
    date: "Gün",
    from: "Ülke",
    to: "To",
    confirmed: "Onaylanmış",
    slide_to_confirm: "Onaylamak için kaydırın",
    confirm: "Onaylamak",
    continue: "Devam",
    slide: "Aktarmak için kaydırın",
    transfer: "Transfer",
    transfer_bot: "Aktarımı yapmak istediğiniz kullanıcıyı arayın ve seçin",
    settings: "Ayarlar",
    preferences: "Tercihler",
    block_user: "Kullanıcıyı engelle",
    unblock_user: "Kullanıcının engelini kaldır",
    blocked_user: "Engellenmiş kullanıcılar",
    private_account: "Özel hesap",
    block_user_modal:
        "Bu kullanıcıyı gerçekten engellemek istiyor musunuz? Engellendikten sonra onların gönderilerini göremezsiniz ve onlar da sizin gönderilerinizi göremez.",
    empty_blocked_users:
        "Engellenen kullanıcınız yok. Bir kullanıcıyı engellemek için bunu onun profilinden yapmanız gerektiğini unutmayın.",
    security: "Güvenlik",
    import: "İçe aktarmak",
    support: "Destek",
    version: "Sürüm",
    language: "Dil",
    select_language: "Dil Seçin",
    backup: "Destek olmak",
    warning: "Uyarı",
    important: "Önemli",
    new_seed: "Yeni bir tohum oluştur",
    active: "Aktif",
    report_message_text: "Bu mesajı bildirebilirsiniz; değerlendirme için kontrol merkezimize iletilecektir.",
    report_message_question: "Bu mesajı bildirmek istiyor musunuz?",
    report_message_description: "Kişiye bu konuda bilgi verilmeyecektir. %{name}'i de engelleyebileceğinizi unutmayın",
    warning_new_seed_question: "Gerçekten yeni bir tohum üretmek istiyor musunuz?",
    warning_new_seed: "Doğru şekilde desteklemediyseniz mevcut tohumdaki paranızı kaybedeceksiniz",
    warning_import: "Yenisini içe aktarmadan önce tohum ifadenizi yedeklediğinizden emin olun.",
    type_words: "On iki kelimenizi yazın",
    crypto_address: "Kripto Address",
    pay_order: "Ödeme iste",
    select_network: "Ağ seçin",
    ethCompatibility: "Uyumlu ETH türetme",
    ethCompatibility_warning_title: "Yakın zamanda bu coinin PATH'ini ETH Ağı ile uyumlu olacak şekilde güncelledik.",
    ethCompatibility_warning_message:
        "Paranız varsa ve artık göremiyorsanız bu para biriminin ayarlarına erişerek önceki adresinize geri dönebilirsiniz.",
    mainnet_testnet:
        "Mainnet, kripto para birimleri ile olağan işlemlerinizi gerçekleştirebileceğiniz ana ağdır. Testnet'i kullanarak kaynaklarınızı tüketmeden farklı işlevleri test edebilirsiniz.",
    send_crypto: "Kripto Gönder",
    share_NFT: "NFT'yi paylaş",
    send_NFT: "NFT Gönder",
    poap_claimed: "Bu POAP zaten başka biri tarafından sahiplenildi.",
    poap_inactive: "Bu POAP talep edilemez. Lütfen başka bir tane deneyin.",
    poap_question: "Bu POAP'ı ETH adresinize atayalım",
    poap_testnet:
        "Bu bölüm TESTNET'te mevcut değildir, POAP'larınızı görüntülemek veya basmak için lütfen MAINNET'e geçin",
    internal_server_error:
        "Üzgünüz, bir hata oluştu. Lütfen internet bağlantınızı kontrol edin, uygulamayı yeniden başlatın, ağ meşgul olabilir ve birkaç dakika sonra tekrar deneyin.",
    info_NFT: "Bu mesaja dokunarak NFT ile ilgili daha fazla ayrıntıyı görebilirsiniz.",
    info_receive_NFT:
        "Bir NFT aldınız, artık koleksiyonunuzun bir parçası. Mesaja dokunarak daha fazla ayrıntı görebilir veya profilinizde görebilirsiniz.",
    info_send_NFT:
        "Bu NFT'yi gönderdiniz ve bu nedenle artık koleksiyonunuzun bir parçası değil. Bu mesaja dokunarak daha fazla ayrıntı görebilirsiniz.",
    warning_send_crypto:
        "Bu kullanıcıya adresleri güncel olmadığından anlık olarak kripto para gönderilmesi mümkün değildir. Onlardan size adreslerini göndermelerini veya ödeme talebinde bulunmalarını isteyebilirsiniz.",
    add_contact: "Kişi ekle",
    edit_contact: "Kişiyi düzenle",
    edit_profile: "Profili Düzenle",
    name: "İsim",
    alias: "Takma ad",
    set_alias: "Takma ad ayarla",
    name_error: "Ad boş olamaz",
    alias_error: "Takma ad boş olamaz",
    field_error: "Bu alan boş olamaz",
    birthday_error:
        "Doğum tarihiniz, eğik çizgi veya kısa çizgi ile ayrılmış olarak Yıl (Örn. 1995), Ay (Örn. 05) ve Gün (Örn. 22) ile tamamlanmalıdır. (Örn. 1995/05/22).",
    img_error: "Resim boş olamaz",
    contacts_list: "Kişi Listesi",
    search: "Aramak",
    no_foll_warning:
        "Görünüşe göre henüz hiçbir kullanıcıyı takip etmiyorsunuz, bu yüzden size önerilen gönderileri göstereceğiz. Gönderilerini görmek için kullanıcıları takip edebilir veya daha fazla gönderi görmek için Keşfet sekmesini kullanabilirsiniz.",
    follow: "Follow",
    unfollow: "Takibi Bırak",
    posts: "Gönderi",
    followers: "Takipçiler",
    following: "takip",
    contact: "İletişim",
    now: "Şimdi",
    minutes_ago: "dakika önce",
    hours_ago: "saat önce",
    days_ago: "gün önce",
    liked_by: "beğeni",
    and: "ve",
    others: "diğerleri",
    see_more: "daha fazla",
    users: "kullanıcılar",
    hashtags: "taglar",
    global_search: "Global arama",
    exchange_amount: "Tutar, minimum döviz kurundan daha düşük",
    exchange_funds: "Bu değişimi başlatmak için yeterli paranız yok. Geçerli bakiyeniz",
    new_post: "Yeni posta",
    gallery: "Galeri",
    camera: "Kamera",
    image_warning: "Devam etmeden önce lütfen bir resim seçin",
    image_hight: "Resim bir gönderi için çok uzun, yatay formatta fotoğraf çekmeyi deneyin",
    description_warning: "Devam etmeden önce lütfen bir açıklama seçin",
    camera_permission: "Kamera rulosuna erişim izni gerekli",
    saved_succesfuly: "Başarıyla kaydedildi",
    set_profile: "Profili Ayarla",
    enter_your_alias: "Takma adınızı girin",
    alias_exist: "Takma ad zaten mevcut",
    language_warning: "Uygulama yeni dili yüklemek için yeniden başlatılacak",
    buy: "Satın almak",
    bot_image_resize_title: "Görüntünüzün boyutunu optimize etmenize yardımcı olmamı ister misiniz?",
    bot_image_resize_message: "Resminizi optimize etmek için bu mesaja dokunun.",
    purchases: "Satın almalar",
    credit_card: "Kredi kartı",
    wire_transfer: "Elektronik transfer",
    provider_error: "Sağlayıcı isteği işleyemiyor",
    input_amount_message: "Giriş miktarı şu miktardan küçük olmalıdır:",
    message: "Mesaj",
    not_view_nft_msg: "Bu mesaj bir NFT içeriyor ve bu cüzdan tarafından desteklenmiyor.",
    and_bigger_than: "ve daha büyük",
    you_will_pay: "Ödeyeceksin",
    you_will_buy: "Alacaksın",
    to_address: "Adres",
    payment_method: "Ödeme yöntemi",
    select_currency: "Para birimini seçin",
    select_nft: "NFT'yi seçin",
    bot_select_nft: "Sohbette paylaşmak için NFT'lerinizden birini seçin",
    bot_select_send_nft:
        "Göndermek için NFT'lerinizden birini seçin; NFT'yi aktardığınızı ve gönderdiğiniz kullanıcının malı olacağını dikkate almalısınız. Ayrıca MarketPlace'te satışa sunulan NFT'leri gönderemezsiniz.",
    bot_select_nft_empty: "Görünüşe göre profilinizde paylaşabileceğiniz NFT yok.",
    bot_select_send_nft_empty:
        "Sohbet yoluyla gönderebileceğiniz NFT'niz yok. Bunları göndermek için satılık olmalarına gerek olmadığını unutmayın.",
    enable_currencies: "Para birimleri",
    select_country: "Ülke Seç",
    you_will_receive: "Alacaksın",
    you_have_to_pay: "Ödemeniz gerek",
    an_error_has_occurred: "Bir hata oluştu. Lütfen tekrar deneyin",
    an_error_has_occurred_open_NFT: "Bu NFT bilgisi alınmaya çalışılırken bir hata oluştu",
    enter_email: "E-postanızı giriniz",
    identity_verification: "kimlik doğrulama",
    personal_information_optional: "Kişisel Bilgiler (İsteğe bağlı)",
    first_name: "İlk adı",
    last_name: "Soy isim",
    birthday: "Doğum Günü (YYYY/AA/GG)",
    gender: "Cinsiyet",
    marital_status: "Medeni hal",
    nationality: "Milliyet",
    politically_exposed: "Siyasi Açıdan Açık",
    document_type: "Belge Türü",
    document_number: "Belge Numarası",
    single: "Bekar",
    married: "Evli",
    divorcee: "Boşanmış",
    widower: "Dul",
    male: "Erkek",
    female: "Kadın",
    yes: "Evet",
    no: "Hayır",
    kyc_status: "KYC (Müşterinizi Tanıyın) Durumu",
    domicile: "Adres",
    bank: "Banka",
    postal_code: "Posta Kodu",
    street_address: "Sokak",
    street_number: "sokak numarası",
    telephone_number: "Telefon numarası",
    name_bank: "Banka Adı",
    cbu: "CBU/CVU",
    tax_identification: "VERGİ kimliği",
    front_document: "Belgenin ön yüzü",
    front_document_description:
        "Belgenizin önünde bir fotoğrafını çekin. Resimde belgenin ön yüzünün tamamını görmeniz ve verileri net bir şekilde okuyabilmeniz gerekir.",
    back_document: "Belgenin arkası",
    back_document_description:
        "Belgenizin arka yüzünün fotoğrafını çekin. Resimde belgenin arka yüzünün tamamını görmeniz gerekir ve veriler net bir şekilde okunabilir.",
    service_image: "Adınıza ait hizmet veya vergi fotoğrafı",
    billing_image: "Ödeme biletinin fotoğrafı",
    billing_image_description: "Banka havalesi biletinin fotoğrafını eklemelisiniz",
    service_description: "Adınıza bir hizmetin veya verginin fotoğrafını çekin. Açıkça görülmeli ve okunmalıdır.",
    selfie_document: "Belgenizi tutarak fotoğraf çekin",
    selfie_description: "Belge önden ve yüzünüzden görülmelidir.",
    history: "Tarih",
    history_seed: "Tarihçe (Tohumlar)",
    kyc_warning:
        "Satın alma işlemi gerçekleştirmek için aktif bir KYC'nizin olması gerekir. E-posta adresinizi girin; daha önce bir KYC'niz olup olmadığını doğrulayalım veya yeni bir KYC oluşturmanız için size rehberlik edelim.",
    city: "Şehir",
    you_need_transfer: "Transfer makbuzunun fotoğrafını eklemeniz gerekmektedir",
    amount_transfer: "Aktarılacak tutar",
    amount_receive: "Alınacak tutar",
    request: "Talep edilen",
    wait: "Ödeme bileti bekleniyor",
    complete: "Tamamlamak",
    cancel: "İptal edildi",
    pending_by_validate: "Onay bekliyor",
    kyc_validation_pending:
        "Verileriniz doğrulanıyor. KYC'niz onaylandığında satın alma işlemi gerçekleştirebilirsiniz.",
    kyc_country_warning: "Kimliğinizi doğrulamak için tüm alanları doldurmanız gerekmektedir.",
    wire_transfer_info_card:
        "Satın alma işlemine devam etmek için belirtilen hesap adresine aktarmanız ve ardından bileti eklemeniz gerekir.",
    country: "Ülke",
    type_a_message: "Bir mesaj yazın...",
    bigger_than: "Tutarın daha büyük olması gerekir",
    select_provider: "Sağlayıcı Seçin",
    report: "Raporlar",
    reported: "Raporlanmış",
    message_report:
        "Bu içeriğin kötüye kullanıldığını düşünüyorsanız, bunu bildirebilirsiniz; bu durumda içerik diğer kullanıcılardan gizlenecektir.",
    question_report: "Bu içeriği bildirmek istiyor musunuz?",
    bot_report:
        "Görünüşe göre bu NFT diğer kullanıcılar tarafından rapor edilmiş, riski size ait olmak üzere satın alabilirsiniz.",
    bot_no_buy:
        "Şu anda Uygulamadan NFT satın almak mümkün değil, sizi bu NFT için web Uygulamamızdan teklif vermeye davet ediyoruz",
    bot_report_owner:
        "Diğer kullanıcıların NFT'nizi kötüye kullanım amaçlı olarak bildirdiği görülüyor. Satmaya devam edebilirsiniz ancak kurallarımızı ihlal edip etmediği değerlendirilene kadar ürün rapor edilmiş olarak görünecektir.",
    apply_loan: "Krediniz için ASCAV'a başvurun",
    auth_warning:
        "Görünüşe göre cihazınızda etkinleştirilmiş herhangi bir güvenlik yöntemi yok. Birisi cihazınıza eriştiğinde bilgilerinizi görebilmesi mümkündür. Cihazınızda bazı güvenlik yöntemlerini etkinleştirmenizi öneririz (Örn: Desen, PIN, Parmak İzi, Yüz Tanıma)",
    auth_warning_web:
        "Bu uygulamayı web tarayıcısından kullanırken koruyamadığımız bilgiler vardır. Uygulamayı Play Store'dan (Android) veya App Store'dan (iOS) cihazınıza indirmenizi öneririz.",
    local_auth: "Uygulamaya erişmek için parmak izinizi, kilit açma deseninizi veya PIN'inizi kullanın",
    auth_error:
        "Bu eylemi gerçekleştirmek için cihazınızda kimliğinizi doğru bir şekilde doğrulamanız gerekir. Tekrar denemek için buraya dokunun",
    authentication: "Parmak izi",
    add_more_currencies: "Daha ekle",
    new_post_not_available:
        "Bu işlev web sürümünde mevcut değildir; yalnızca uygulamadan (Android veya iOS) Gönderi oluşturabilirsiniz",
    approve_tokens: "Belirteçleri onayla",
    approve_tokens_message:
        "Değişim yapmadan önce tokenlarınızın kullanımını onaylamanız gerekir. Bunu ayarlardan tersine çevirebilirsiniz",
    approving: "Onaylanıyor",
    approving_message: "Lütfen jetonunuz onaylanana kadar bir dakika bekleyin",
    no_avatar_alias: "Bir Gönderiyi beğenebilmek için profilinizi tamamlayın.",
    transaction_detail: "İşlem detayı",
    your_network_is: "Ağınız şurada yapılandırılmıştır",
    and_the_operation: "ve bu operasyon",
    tries_to_follow: "takip etmeye çalışır. Ağınızı değiştirmek ve işleme devam etmek için devam tuşuna basın.",
    path_warning:
        "Bu para biriminin Yolunu değiştirmek, yeni bir adresiniz olacağı ve eski adresinizin bakiyesini yalnızca önceki yolunuza dönerek kontrol edebileceğiniz anlamına gelir. Benzer şekilde, cüzdanın bazı işlevleri (örneğin, CryptoChat aracılığıyla kripto alma) farklı davranabilir. Adresinizin Gaz ödemesi (Swap işlemleri) için kullanılacak adresten farklı olması durumunda ücreti tahsil edemeyeceğimizi ve işlemin gerçekleşemeyeceğini unutmayınız. Bu yolu ancak bu konuda bilginiz varsa değiştirmeniz gerektiği konusunda sizi uyarmanın önemli olduğuna inanıyoruz.",
    rewarded: "Ödüllendirildi",
    new_post_message: "Bir fotoğraf seçin veya kameranızı kullanın ve içerik oluşturmaya başlayın.",
    confirm_swap: "Değişimi Onayla",
    activity_transactions:
        "Görünüşe göre henüz %{currency} etkinliğiniz yok. Bir işlem yaptığınızda bu bölümde göreceksiniz.",
    daily: "Günlük",
    weekly: "Haftalık",
    close: "Kapat",
    show_seed: "Kelimeleri Göster",
    hide_seed: "Kelimeleri Gizle",
    words: "Kelimeler",
    qr: "QR (Seed)",
    type_a_password: "Bir şifre girin",
    QR_password: "QR şifresini yazın",
    confirm_password: "Şifreyi Onayla",
    warning_encrypted:
        "QR kodunu yalnızca sizin kullanabilmeniz için şifreleyeceğiz. Lütfen 4 rakam veya harf seçin. Bu kodu unutmamanız önemlidir çünkü gelecekte ifadenizi QR ile içe aktarmak için bu koda ihtiyacınız olacaktır.",
    warning_seed:
        "Kelimelerinizi asla paylaşmayın, ekran görüntüsünü almayın veya bir metin dosyasına kopyalamayın. Bunları bir kağıda yazın ve güvenli bir yerde saklayın",
    warning_QR:
        "Kelimelerinizi içeren bu QR kodu şifrelenmiş olsa da ekran görüntüsü veya resim dosyası olarak saklamak güvenli değildir. Kelimelerinizi yeni bir cihaza aktarmak için bunu kullanın ve ardından QR kodunu geri alın. Riski size ait olmak üzere saklayabilirsiniz.",
    info_confirm_password:
        "Lütfen şifreyi onaylayın. Oluşturacağınız QR kodunu taramak istediğinizde buna ihtiyacınız olacağını unutmayın.",
    password_error: "Girilen şifreler aynı değil",
    update: "Güncelleme",
    web_updates:
        "Web uygulamasının sürümünüze yönelik güncellemeler mevcuttur; bunlar, sayfayı yeniden başlattığınızda otomatik olarak etkilenecektir; Aksi halde tarayıcınızın önbelleğini ve çerezlerini temizlemeniz gerekebilir.",
    app_updates:
        "Sürümünüz için güncellemeler mevcuttur, bu nedenle bazı özellikler çalışmayı durdurabileceğinden uygulamayı güncellemeniz gerekir. Uygulamayı yeniden başlatmayı deneyebilirsiniz; otomatik olarak güncellenecektir veya %{store}'a gidip güncelle tuşuna basabilirsiniz.",
    swap_warning: "Bu işlemi gerçekleştirerek girilen tutarın %{percentage}%'sini kaybedeceksiniz",
    owned_by: "Sahip",
    created_by: "Tarafından yaratıldı",
    owner: "Kurucu",
    create_new_item: "Yeni Öğe Oluştur (NFT)",
    edit_item: "Taslağı Düzenle",
    create: "Yaratmak",
    item_name: "Örn: Bahar Çiçekleri",
    image: "Resim",
    archive: "Arşiv",
    modal_reddemable_question: "Bu %{type}'ı kullanmak istiyor musunuz?",
    redeemables_error_message: "Bu kapıda kullanabileceğiniz biletiniz yok",
    poster_image: "Kapak resmi",
    select_poster_image: "Videonuz için kapak resmini seçin",
    image_video: "Resim veya Video",
    sold_to: "Şuna satıldı",
    error_transfer_nft: "Satılıksa NFT gönderemezsiniz, MarketPlace'ten kaldırıp tekrar deneyin.",
    send_to: "Ttransfer edildi",
    transfer_to: "ArşivlendiAktar",
    archived: "Arşiv",
    gate_message: "Bu %{type} arşivlendiğinde onu artık listenizde görmeyeceksiniz ve artık kullanamayacaksınız",
    received_from: "Transfer eden",
    buyed_to: "Şuradan satın aldı",
    image_size_error: "Resim çok büyük, 3 MB'ı geçemez.",
    video_size_error: "Video çok büyük, 50 MB'ı geçemez.",
    video_width_error: "Video için desteklenen maksimum genişlik 720 pikseldir.",
    video_width_min_error: "Video için desteklenen minimum genişlik 360 pikseldir.",
    video_duration_error: "Video 90 saniyeden uzun olamaz.",
    video_ratio_error: "Videonun en boy oranı doğru değil, bu 9/16 veya 16/9 olabilir.",
    video_size_per_minute_error: "Video 60 saniyede 40 MB'tan büyük olamaz.",
    video_type_error: "Video formatı .MP4 olmalıdır",
    processing_video: "Video işleniyor, lütfen bekleyin...",
    required: "Gerekli",
    file_type_supported:
        "Desteklenen dosya türleri: Resim JPG, PNG. Maksimum boyut: 3 MB // Video MP4. Maksimum boyut 50 MB.",
    external_link: "Dış bağlantı",
    external_link_description:
        "Bu öğenin ayrıntı sayfasına bu URL'ye bir bağlantı ekleyeceğiz, böylece kullanıcılar bu öğe hakkında daha fazla bilgi edinmek için tıklayabilirler. Daha fazla ayrıntı içeren kendi web sayfanıza bağlantı verebilirsiniz.",
    external_link_placeholder: "Ex: https://yoursite.com/nft/123",
    description: "Tanım",
    description_description: "Açıklama, ürünün detay sayfasında görselinin altında yer alacaktır.",
    description_placeholder: "Örn: Bir eserin benzersiz elle çizilmiş çalışması...",
    blockchain_description: "NFT'nizin temel alacağı blok zincirini seçin.",
    propieties: "Özellikler",
    propieties_description: "Dikdörtgenler olarak görünen metin özellikleri",
    type_props: "Type: Ex: 'BODY'",
    name_props: "Name: Ex: 'Blue'",
    return_percentage: "Geri dönüş yüzdesi",
    offer_for_sale: "Satmak",
    cancel_sale: "Satışı İptal Et",
    confirm_buy: "Satın Almayı Onayla",
    on_sale: "Satılık",
    cancel_sale_question: "Bu NFT'yi satışa sunmayı durdurmak istiyor musunuz?",
    cancel_sale_description: "Bu işlemi gerçekleştirmek için bir işlemin maliyetini ödemeniz gerekecek.",
    nft_temporal_message:
        "Uygulamayı yeniden yüklemenize veya profilinizden çıkıp tekrar giriş yapmanıza gerek yoktur. İşlem tamamlandıktan sonra otomatik olarak bu ekranda görünecektir",
    nft_temporal_subtitle: "NFT'nizi işliyoruz, işlendikten sonra profil NFT'lerinizde göreceksiniz.",
    no_proccesed_request: "Cüzdanımız şu anda DApp gereksinimiyle uyumlu değil",
    return_percentage_description: "Öğenizin gelecekteki işlemlerinden kazanacağınız yüzdeyi belirleyin",
    return_percentage_error: "Oran %20'yi geçemez",
    address_description: "Ödeme alacağınız adres, bu alan düzenlenemez",
    info_card_confirm_sale:
        "NFT'nizin MarketPlace'imizde yer alması için her şey hazır, devam etmek için işlemi onaylayın. NFT'nizin Pazaryerinde listelenmesi birkaç dakika sürebilir.",
    info_card_confirm:
        "NFT'nizi oluşturmanın ilk adımını zaten tamamlıyorsunuz. İşlendikten sonra, onu fiyatlandırabilecek ve NFT Pazarında sunabileceksiniz.",
    approve_NFT: "NFT'yi onayla",
    almost_done: "Neredeyse tamamız!",
    approve_pending: "NFT onayınız alır almaz bu ürünü satmayı onaylayabilirsiniz",
    info_card_confirm_transfer:
        "NFT'nizi aktarmak için her şey hazır, işlemin devam etmesini onaylayın, bu işlem birkaç dakika sürebilir.",
    bot_marketplace_message:
        "Talebinizi işleme alıyoruz. Birkaç dakika içinde NFT'nizin Pazaryerinde listelendiğini göreceksiniz.",
    bot_marketplace_message_cancel:
        "Talebinizi işleme alıyoruz. Birkaç dakika içinde NFT'nizi artık Marketplace'te listelenmeyeceksiniz.",
    info_card_approve:
        "NFT'nizi satışa çıkarmadan önce onaylayacağız, bunun için aşağıda detayları verilen masrafları ödeyeceksiniz. Endişelenmeyin, NFT'nizi satabilmeniz için gereken adımları neredeyse tamamladık.",
    info_card_buy: "Aşağıda NFT'nin satın alınmasına ilişkin maliyetleri ayrıntılı olarak açıklıyoruz",
    price: "Fiyat",
    price_description: "NFT'nizin alıcılara sunulacağı fiyatı belirleyin",
    dapp_web_message: "Dapps'in bazı özellikleri web sürümünde mevcut olmayabilir.",
    dapp_web_info:
        "Uygulamamızı resmi mağazalardan yükleyebilir, daha iyi bir kullanıcı deneyimine sahip olacak ve işlemlerinizde daha fazla güvenliğe sahip olacaksınız.",
    services: "Servisler",
    email: "Email",
    lastname: "Soy isim",
    phone: "Telefon",
    error_email: "E-posta güncellenirken hata oluştu",
    error_name: "Ad güncellenirken hata oluştu",
    error_lastname: "Soyadı güncellenirken hata oluştu",
    error_phone: "Telefon güncellenirken hata oluştu",
    notifications: "Bildirimler",
    empty_list: "Sonuç bulunamadı. Lütfen aramanızı kontrol edip tekrar deneyin.",
    empty_message: "Henüz bildiriminiz yok",
    empty_follower:
        "Henüz takip isteğiniz yok. İsteklerin yalnızca profiliniz gizli olduğunda burada göründüğünü unutmayın",
    empty_post_own: "Profilinizde şu anda herhangi bir gönderi yok!",
    empty_post: "Mevcut POST yok.",
    empty_nft_own: "Profilinizde şu anda herhangi bir NFT yok!",
    empty_nft: "Kullanılabilir NFT yok.",
    empty_nft_history: "NFT geçmişiniz yok.",
    empty_poap_own: "Profilinizde şu anda herhangi bir POAP yok!",
    empty_poap: "Kullanılabilir POAP yok.",
    empty_create_poaps: "Aldığınızda burada görünecekler. POAP'ları talep etmek için QR tarayıcıyı kullanın.",
    empty_create_nft: "Bunları Pazar Yeri'nden edindiğinizde veya oluşturduğunuzda burada görünecekler.",
    empty_create_posts: "Yeni Gönderiler oluşturduğunuzda burada görünecekler",
    personal_verification: "Kişisel Doğrulama",
    previous: "Öncesi",
    zip_code: "Posta kodu",
    birthdate: "Doğum günü",
    step: "Adım",
    verification_completed: "Doğrulama Tamamlandı",
    personal_information: "Kişisel bilgi",
    facial_verification: "Yüz Doğrulama",
    front_idcard: "Kimlik Kartının Ön Yüzü",
    back_idcard: "Kimlik Kartının Arka Yüzü",
    take_selfie: "Bir Selfie Çekin",
    idcard_title: "Kimlik kartınızı yüklemek üzeresiniz. Lütfen şunlardan emin olun:",
    idcard_description_one: "Bu sizin devlet tarafından verilmiş, süresi dolmamış belgenizdir.",
    idcard_description_two: "Bu orijinal bir belgedir, tarama veya kopya değildir.",
    idcard_description_three: "Yansımaları veya bulanıklığı önlemek için kart tutucularını veya kapaklarını çıkarın.",
    idcard_description_four: "Belgeleri düz renkli bir arka plana yerleştirin.",
    selfie_title: "Bir selfie yüklemek üzeresiniz. Lütfen şunlardan emin olun:",
    selfie_description_one: "Tarafsız bir ifadeyle kendi selfienizi çekin",
    selfie_description_two:
        "Yüzünüzün tamamının göründüğünden, ortalandığından ve gözlerinizin açık olduğundan emin olun",
    video_title:
        "Son adım! Video başladıktan sonra ekranda görüntülenecek hareketleri gerçekleştirerek 8 saniyelik bir video kaydedin.",
    video_description: "Bu videoyla, belgenizde gösterilen kişiyle aynı kişi olduğunuzu doğrulayacağız.",
    record_video: "Video Kaydet",
    complete_field_warning: "Devam etmeden önce lütfen alanları doldurun.",
    complete_file_warning: "Devam etmeden önce lütfen resmi yükleyin.",
    photo_idcard_front: "Kimlik Kartının ön tarafını çerçeveye yerleştirin",
    photo_idcard_back: "Kimlik Kartının arkasını çerçeveye yerleştirin",
    kyc_mouth: "Ağzını aç ve kapat",
    kyc_eyes: "Göz kırpmak",
    kyc_wink: "göz kırp",
    share_post: "Gönderiyi paylaş",
    check_nft: "Bu muhteşem NFT'ye göz atın!",
    check_post: "Bu muhteşem gönderiye göz atın!",
    copy_link: "Linki Kopyala",
    link_copied: "Link Kopyalandı",

    welcome_to: "Hoş geldiniz",
    a_portal: "WEB 3.0 deneyiminize açılan bir portal 🚀",
    new_account: "Yeni Hesap Ekle",
    my_wallet: "CÜZDANIM",
    accounts: "Hesaplar ve Profiller",
    recovery_seed_error: "Hesap kurtarma yöntemi başarısız oldu, Seed Phrase (Tohum Cümlesi) ile giriş yapmayı deneyin",
    remove_seed_warning:
        "Hesabınızı SİLECEKSİNİZ, Seed Phrase (Tohum Cümlesi) bilgilerinizi not ettiyseniz yalnızca o zaman geri alabilirsiniz",
    restore_app_warning:
        "TÜM verilerinizi SİLECEKSİNİZ, Seed Phrase (Tohum Cümlesi) bilgilerinizi not ettiyseniz yalnızca o zaman geri alabilirsiniz",
    wallet: "Cüzdan",
    init: "Başlangıç",
    unarchive: "Arşivden Çıkar",
    assigned_to: "Şuna atanmış: ",
    redeemables_warning:
        "Sadece kullanılabilir %{type}lerinizi burada göreceksiniz. Kullanılan, süresi dolmuş veya arşivlenmiş %{type}lerinizi görmek için buraya dokunabilirsiniz 🚀",
    memberships_warning:
        "Sadece kullanılabilir üyeliklerinizi burada göreceksiniz. Arşivlenmiş üyeliklerinizi görmek için buraya dokunabilirsiniz 🌟",
    used_in: "Şurada kullandınız: ",
    transferred_to: "Şuna transfer ettiniz: ",
    received_from_redeemable: "Şuradan aldınız: ",
    expired: "Süresi doldu! 😭",
    use: "Kullan",
    signout: "Çıkış yap",
    advanced: "Gelişmiş",
    send_ticket: "Bilet Gönder",
    send_voucher: "Kupon Gönder",
    bot_message_select_redeemable: "Transfer etmek için bir %{type} seçin",
    bot_message_empty_redeemable: "Transfer etmek için kullanılabilir %{type}leriniz bulunmuyor",
    membership_claim_error: "Aynı Üyelikten iki tane alamazsınız",
    see_less: "Daha Az Gör",
    camera_permission_null: "Kamera kullanımı için uygulama dışı ayarlardan izin vermeniz gerekmektedir",
    seed_history_warning:
        "Ekleyemediğimiz bu Seed Phrase (Tohum Cümlesi) bilgilerini bulduk, isterseniz bunları atabilirsiniz",
    error_module_disabled: "Şu anda bu işlevi kullanamıyoruz",
    poaps_web_error: "Bu işlev WebApp sürümünde mevcut değil, Android veya iOS'te bulunan uygulamayı kullanabilirsiniz",
    remove_account: "Hesabı Sil",
    restore_app: "Uygulamayı Sıfırla",
    share: "Paylaş",
    check_experience: "Bu harika deneyime bir göz atın!",
    experiences: "Deneyimler",
    select_ticket: "Bilet Seç",
    select_quantity: "Miktar Seç",
    select: "Seç",
    use_in: "Şurada Kullan",
    congratulations: "Tebrikler!",
    enjoy_purchase: "Şimdi satın almanın tadını çıkarabilirsiniz.",
    confirm_go_back: "Başlangıca geri dönmek istediğinizden emin misiniz? Yapılan değişiklikler kaybolacak.",
    deleted_successfully: "Başarıyla Silindi",
    free: "Ücretsiz",
    order_details: "Sipariş Detayları",
    confirm_order: "Siparişi Onayla",
    my_cart: "Sepetim",
    add_cart: "Sepete Ekle",
    quantity: "Miktar",
    google_permission:
        "Google ile giriş yapmayı seçerseniz, Drive'a erişim iznini onaylamanız gerekecektir. Aksi takdirde, gizli bir şekilde giriş yapma seçeneğiniz bulunmaktadır.",
    continue_with: "İle Devam Et",
    or: "veya",
    new_user: "Yeni Hesap",
    use_account_associated: "Cihazınızda saklanan veya e-posta ile ilişkilendirilmiş hesaplar",
    incognito_with_seed_phrase: "Seed Phrase (Tohum Cümlesi) ile Gizli Mod",
    seed_phrase_detail: "Frase Semilla, kripto paralarınıza ve dijital varlıklarınıza erişim sağlar",
    welcome_sthorm: "Sthorm'a Hoş Geldiniz",
    sthorm_subtitle: "Sosyal mühendislik odaklı, amaç odaklı kapalı bir platform, refah için.",
    gob_subtitle:
        "Dijital varlıklar ve blockchain teknolojisinin heyecan verici dünyasının kapısını açmaya bir adım kaldınız.",
    username: "Kullanıcı Adı",
    password: "Parola",
    sign_in: "Giriş Yap",
    sign_up: "Kaydol",
    confirmPassword: "Parolayı Onayla",
    amount_to_buy: "Satın Alınacak Tutar",
    receives: "Alır",
    select_deposit_bank: "Yatırım yapacağınız bankayı seçin.",
    select_receive_bank: "Para alacağınız bankayı seçin.",
    payment_receipt: "Yatırım referans numarasını tamamlayın ve ödeme makbuzunu yükleyin.",
    transactions: "İşlemler",
    recent_transactions: "Son İşlemler",
    receipt: "Makbuz",
    empty_transactions: "Henüz bir işleminiz yok gibi görünüyor. Bir işlem yaptığınızda bu bölümde göreceksiniz.",
    kyc_alert: "COINCAEX ile işlem yapabilmek için KYC (Müşterini Tanı) bilgilerinizi tamamlamanız gerekiyor.",
    deposit_reference: "Yatırım Referans Numarası",
    sell: "Satış",
    total_to_deposit: "Yatırılacak Toplam Tutar",
    commission: "Komisyon",
    account_holder: "Hesap Sahibi",
    account_type: "Hesap Türü",
    account_name: "Hesap Adı",
    account_number: "Hesap Numarası",
    account_data: "Hesap Bilgileri",
    amount_to_sell: "Satılacak Tutar",
    total_deposit: "Size Yatırılacak Toplam",
    banks: "Bankalar",
    empty_banks: "Henüz bir banka eklememişsiniz.",
    add_bank: "Banka Ekle",
    select_bank: "Banka Seç",
    select_account: "Hesap Seç",
    discount_code: "İndirim Kodu (İsteğe Bağlı)",
    verify: "Doğrula",
    hash_receipt: "İşlem hash'ini tamamlayın ve ödeme makbuzunu yükleyin.",
    hash_transaction: "İşlem Hash'i (İsteğe Bağlı)",
    confirm_sell: "Satışı Onayla",
    buys: "Alış",
    sale: "Satış",
    bank_message: "Lütfen bir hesap ekleyin işlemi başlatmadan önce.",
    social: "SOCYAL",
    chats: "SOHBETLER",
    req: "Gerekmek",
    payment: "ödemek",
    transaction_message: "İşlem başarıyla tamamlandı! Durumunu son işlemlerde kontrol edebilirsin.",
    transaction_success: "Başarılı işlem.",
    error_coincaex: "Bir hata oluştu. Lütfen Coincaex destek ile iletişime geçin.",
    back_coincaex: "Coincaex'e Geri Dön",
    transaction_open_error: "Bu işlemi şu anda görüntülemek mümkün değil.",
    verified: "Doğrulandı",
    profile_contact_QR: "%{name} iletişim",
    signed_by: "Tarafından imzalandı ",
    wallet_connect_requesting_error: "%{name} cari hesabınıza ait olmayan bir işlem talep ediyor",
    wallet_connect_switch_account: "Bu adres isteğine yanıt vermek için hesap değiştirmeniz gerekebilir (%{address})`",
    error_message: "Üzgünüz, bir şeyler yanlış gitti. Lütfen daha sonra tekrar deneyin.",
    purchase_detail: "Satın Alma Detayı",
    buy_now: "Şimdi Satın Al",
    processing_payment: "Ödemeniz işleniyor",
    complete_purchase: "Satın Almayı Tamamla",
    add_item_warning: "Devam etmeden önce bir öğe ekleyin",
    calendar: "Takvim",
    fee_expenses: "Ücret masraflarını içermez",
    important_announcement: "Hizmet dışı",
    maintenance_mode:
        "%{name} hizmetlerinde bakım görevleri yürütüyoruz, bu nedenle uygulamaya erişim birkaç saatliğine etkilenecek. Umarız özür dileriz.",
};
