import Currency, { IConstructorCurrency } from '@core/currencies/Currency';
import { FiatCurrency } from '@core/fiat/FiatCurrencies';
import { Networks } from '@core/network/Networks';

import { ProviderType } from '@custom-types/ProviderType';
import Constants from "expo-constants"
import ETHImplementation from '../../ethereum/eth/ETHImplementation';
import { isCurrencyEnabled } from '@core/utils/CurrencyEnabled';

export default class DOC extends Currency {
    constructor(
        parameters: IConstructorCurrency
    ) {
        super({
            name: 'DOC',
            simbol: 'DOC',
            fullName: 'DoC',
            decimals: 18,
            implementation: ETHImplementation,
            networkID: parameters.testnet ? 37310 : 137,
            color: '#00a651',
            icon: 'doc',
            networkSymbol: 'RSK',
            underlyingCurrency: 'rbtc',
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "DOC", true),
            buyableBy: [ProviderType.KRIPTONMARKET]
        })
    }

}
