import Container from "@base/Container";
import { StyleSheet, View, Keyboard, ViewStyle } from "react-native";

import { colors, settings } from "@styles/globalStyles";
import RegularTextInput from "@base/RegularTextInput";
import React, { Component, useEffect } from "react";
import store from "@store/index";
import { setSearch } from "@store/actions/global";
import Icon from "@components/icons";
import i18n from "@i18n/i18n";

interface Props {
    onSearch: (value) => void;
    value: string;
    containerStyle?: ViewStyle;
}

interface State {
    value: string;
}

const { t } = i18n;

export default class SearchBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.cleanSearch = this.cleanSearch.bind(this);
        this.state = {
            value: "",
        };
    }

    onSearch(value: string) {
        this.setState({ value: value });
        this.props.onSearch(value);
    }

    cleanSearch() {
        this.onSearch("");
        Keyboard.dismiss();
    }

    render() {
        return (
            <View style={this.props?.containerStyle}>
                <View style={styles.searchBox}>
                    <Icon name={"search"} size={20} color={colors.text} align="left" style={styles.icon} />
                    <RegularTextInput
                        style={styles.input}
                        onChangeText={(value) => {
                            this.onSearch(value);
                        }}
                        value={this.props.value}
                        placeholder={t("search")}
                    />
                    {this.props.value?.length > 0 && (
                        <Icon
                            onPress={this.cleanSearch}
                            name={"x-lg"}
                            size={14}
                            color={colors.text}
                            align="left"
                            style={styles.iconRight}
                        />
                    )}
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    searchBox: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        flexDirection: "row",
        alignItems: "center",
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40,
    },
    icon: {
        position: "absolute",
        left: 10,
    },
    iconRight: {
        position: "absolute",
        right: 10,
        backgroundColor: colors.secondary,
        padding: 5,
        borderRadius: 15,
        overflow: "hidden",
    },
    button: {
        position: "absolute",
        width: 30,
        height: 30,
        right: 15,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        borderColor: "white",
        borderWidth: 1,
    },
});
