import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";
import { colors } from "@styles/globalStyles";
import Row from "@base/Row";
import { SimpleLineIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import RegularNumberInput from "@base/RegularNumberInput";
import Currency from "@core/currencies/Currency";
import Icon from "@components/icons";
import Wallet from "@core/wallet/Wallet";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import PressableBase from "@base/PressableBase";

interface Props {
    amount?: number;
    currency: Currency;
    onPress?: () => void;
    onAmountChange?: (amount: number) => void;
}

interface State {
    amount: string;
    formatAmount: string;
    fiatAmount: number;
    onPress: () => void;
    onAmountChange?: (amount: number) => void;
    prev: string;
}

export default class ExchangeCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const amount = this.props.amount ? this.props.amount : 0;
        this.state = {
            onPress: this.props.onPress ? this.props.onPress : () => {},
            onAmountChange: this.props.onAmountChange ? this.props.onAmountChange : () => {},
            amount: amount.toString(),
            formatAmount: "",
            fiatAmount: 0,
            prev: "",
        };
    }

    componentDidMount = () => {
        this.updateAmount(this.state.amount);
    };

    componentDidUpdate(prevProps: Props) {
        let amount = this.props.amount.toString();
        if (prevProps.amount !== this.props.amount) {
            if (!this.props.amount && this.state.prev.includes(".")) {
                //Fix backspace change
                amount = this.state.prev;
            }
            this.updateAmount(amount);
        }
    }

    updateAmount = (value: string) => {
        const amount = parseFloat(value) || 0;
        const fiat = amount * this.props.currency.getFiat() || 0;
        this.setState({
            amount: value,
            formatAmount: this.props.currency.format(this.props.currency.toDecimals(amount)),
            fiatAmount: fiat,
            prev: value.toString(),
        });
        this.state.onAmountChange(amount);
    };

    render() {
        return (
            <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                <View style={styles.left}>
                    <PressableBase onPress={this.state.onPress}>
                        <Row style={{ alignItems: "center" }}>
                            <CurrencyIcon currency={this.props.currency} styles={{ marginRight: 15 }}></CurrencyIcon>
                            <View>
                                <Row style={{ alignItems: "center" }}>
                                    <BoldText fontSize={20}>{this.props.currency.getPName()}</BoldText>
                                    <SimpleLineIcons
                                        name="arrow-down"
                                        size={16}
                                        align="center"
                                        style={{ marginLeft: 10 }}
                                        color={colors.text}
                                    />
                                </Row>
                                <RegularText color={colors.grey} fontSize={10}>
                                    {" "}
                                    {this.props.currency.getFormatBalance()}{" "}
                                </RegularText>
                            </View>
                        </Row>
                    </PressableBase>
                </View>
                <View style={styles.right}>
                    <PressableBase>
                        <RegularText align="right" fontSize={14} color="#bbb">
                            {" "}
                            $ {this.state.fiatAmount.toFixed(2)}
                        </RegularText>
                    </PressableBase>
                    {this.props.onAmountChange && (
                        <RegularNumberInput
                            fontSize={25}
                            onChangeText={(v: string) => {
                                this.updateAmount(v);
                            }}
                            value={this.state.amount}
                            align="right"
                            maxLength={8}
                            fontWeight="bold"
                        />
                    )}
                    {!this.props.onAmountChange && (
                        <BoldText
                            fontSize={25}
                            align="right"
                            style={{ display: "flex", flexWrap: "nowrap", justifyContent: "flex-end" }}
                        >
                            {this.state.formatAmount}
                        </BoldText>
                    )}
                </View>
            </Row>
        );
    }
}

const styles = StyleSheet.create({
    left: {
        flex: 0.5,
    },
    right: {
        flex: 0.5,
    },
    leftTitle: {
        fontSize: 20,
        lineHeight: 22,
        marginTop: 7,
    },
    leftDesc: {
        lineHeight: 20,
        paddingLeft: 2,
    },
    rightDesc: {
        textAlign: "right",
    },
});
