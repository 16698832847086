import React, { Component } from "react";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import { StyleSheet, View } from "react-native";
import BoldText from "@base/BoldText";
import { Image } from "react-native";
import { colors } from "@styles/globalStyles";
import Row from "@base/Row";

interface Props {}

interface State {}

const { t } = i18n;

export default class CustomWelcomeContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <Image
                    resizeMode="cover"
                    source={require("../../assets/splash/splash.png")}
                    style={{ width: 500, height: 350, marginTop: 105, marginBottom: -25 }}
                />

                <View style={{ alignItems: "center" }}>
                    <BoldText align="center" fontSize={20}>
                        Web3 Ecosystem for Professional Sports
                    </BoldText>
                    <RegularText style={{paddingTop: 10}} align="center" fontSize={16}>
                        Owned and Operated by Leagues, Teams and the Public
                    </RegularText>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});
