import { en } from "./languages/en";
import { es } from "./languages/es";
import { tr } from "./languages/tr";

export const compareLanguages = async () => {
    try {
        const esKeys = Object.keys(es);
        const enKeys = Object.keys(en);
        const trKeys = Object.keys(tr);

        const EN = esKeys.filter((key) => !enKeys.includes(key)) || [];
        if (EN?.length) {
            console.warn(`Faltan ${EN?.length} claves de traducción en INGLÉS`);

            const missingKeysAndValuesEN = EN.reduce((acc, key) => {
                acc[key] = es[key];
                return acc;
            }, {});
            console.warn(missingKeysAndValuesEN);
        }

        const TR = esKeys.filter((key) => !trKeys.includes(key)) || [];
        if (TR?.length) {
            console.warn(`Faltan ${TR?.length} claves de traducción en TURCO`);
            const missingKeysAndValuesTR = TR.reduce((acc, key) => {
                acc[key] = es[key];
                return acc;
            }, {});
            console.warn(missingKeysAndValuesTR);
        }

        const ES = enKeys.filter((key) => !esKeys.includes(key)) || [];
        if (ES?.length) {
            console.warn(`Faltan ${ES?.length} claves de traducción en ESPAÑOL`);
            const missingKeysAndValuesES = ES.reduce((acc, key) => {
                acc[key] = en[key];
                return acc;
            }, {});
            console.warn(missingKeysAndValuesES);
        }
    } catch (e) {}
};
