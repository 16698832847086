import BigNumber from "bignumber.js";

export default class AbstractCurrency {
    protected id: string;
    protected name: string;
    protected symbol: string;
    protected icon: string;
    protected decimals: number;
    protected fullName: string;
    protected formatPrecision = 8;

    constructor(id: string, name: string, symbol: string, icon: string, fullName: string, decimals: number) {
        this.id = id;
        this.name = name;
        this.symbol = symbol;
        this.icon = icon;
        this.fullName = fullName;
        this.decimals = decimals;
    }

    getId(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getFullName(): string {
        return this.fullName;
    }

    getIcon() {
        return this.icon;
    }

        
    getSymbol(): string {
        return this.symbol;
    }

    getDecimals(): number {
        return this.decimals;
    }

    fromDecimals(value: number) {
        const v = new BigNumber(value);
        const base = new BigNumber(10);
        return v.multipliedBy(base.exponentiatedBy(-1 * this.getDecimals())).toNumber();
    }

    fromDecimalsToString(value: number) {
        BigNumber.config({ DECIMAL_PLACES: this.getDecimals(), EXPONENTIAL_AT: 30 });
        const res = this.fromDecimals(value);
        return new BigNumber(res).toString();
    }

    toDecimals(value: number) {
        BigNumber.config({ EXPONENTIAL_AT: 30 });
        const v = new BigNumber(value);
        return v.multipliedBy(Math.pow(10, this.getDecimals())).toNumber();
    }

    format(value: number): string {
        const res = this.fromDecimals(value).toFixed(Math.min(this.getDecimals(), this.formatPrecision));
        return res.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1");
    }

    fiatFormat(value: number): string {
        return this.fromDecimals(value).toFixed(value < 0.01 && value > 0 ? 3 : 2);
    }

    public static fromJSON(obj: AbstractCurrency) {
        try {
            return new AbstractCurrency(
                obj.id ? obj.id : obj.name,
                obj.name,
                obj.symbol,
                obj.icon,
                obj.fullName,
                obj.decimals,
            );
        } catch (err) {
            //console.warn(err);
        }
    }
}
