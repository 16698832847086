import React, {Component} from "react"
import {View, Text, StyleSheet} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import RegularText from "@base/RegularText"
import {HeaderType} from "@custom-types/HeaderType"
import SimpleCard from "@components/cards/SimpleCard"
import {colors} from "@styles/globalStyles"

import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import SwitchCard from "@components/cards/SwitchCard"
import AppStorage from "@utils/storage"

interface Props {
    navigation: any
}

interface State {
    authentication: boolean
}

const {t} = i18n
export default class SecurityScreen extends Component<Props, State> {
    focusListener: any
    constructor(props: Props) {
        super(props)
        this.switchSecurity = this.switchSecurity.bind(this)
   
        this.state = {
            authentication: null,
        }
    }

    async componentDidMount () {
        
        const authentication = await AppStorage.getItem("authentication")
        if(authentication == true){
            this.setState({authentication: true})
        } else {    
            this.setState({authentication: false})
        }
        
    }

   

    async switchSecurity() {
        await AppStorage.setItem("authentication", !this.state.authentication)
        this.setState({authentication: !this.state.authentication})
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("security")} type={HeaderType.Light} {...this.props} />
                <Container style={{flex: 1}}>
                    {this.state.authentication !== null &&
                    <SwitchCard
                        title={t("authentication")}
                        onToggle={this.switchSecurity}
                        isOn={this.state.authentication}
                    />
                }
                </Container>
            </ScreenWrapper>
        )
    }
}
