export const en = {
    en: "English",
    es: "Spanish",
    tr: "Turkish",
    welcome: "Welcome",
    welcome_to: "Welcome to",
    a_portal: "A portal to your WEB 3.0 experience 🚀",
    hi: "Hi!",
    pending: "Pending",
    accounts: "Accounts and profiles",
    processed: "Processed",
    new_account: "Add Account",
    error: "Error",
    preminted: "Pre Minted",

    minted: "Minting",
    membership: "MEMBERSHIPs",
    my_wallet: "MY WALLET",
    remove_account: "Remove account",
    restore_app: "Restore app",
    membership_claim_error: "You cannot get two of the same Memberships",
    poaps_web_error:
        "This function is not available from the WebApp version, you can use the app available on Android or iOS",
    remove_seed_warning:
        "You are about to DELETE your account, you can only recover it if you have written down your Seed Phrase",
    restore_app_warning:
        "You are about to DELETE ALL your data, you can only recover it if you have written down yours Seed Phrases",
    bot_marketplace_warning:
        "This NFT is published in another Marketplace (%{name}), you can cancel that publication from here",
    ticket: "TICKETs",
    voucher: "VOUCHERs",
    download: "Download",
    bot_seed_warnign:
        "When creating a new seed it will be necessary to restart the app, you will be able to check your old seed in the history.",
    bot_continue: "Do you want to continue?",
    bot_seed_change: "Once the new seed phrase (Seed) is loaded, the app will restart.",

    bot_save_warning: "Remember to press the save button for the changes to take effect",
    security_kit_a:
        "It is very important that you have written down your seed phrase (Seed) or memorize the words that compose it",
    security_kit_b: "I have already written my Seed",
    security_kit_c: "Would you like to see your seed phrase now and write down the words?",
    security_kit_seed_a: "What is a seed phrase?",
    security_kit_seed_b:
        "One of the most interesting proposals to facilitate the management of our cryptocurrencies and wallets is the creation of the seed phrase or seed phrase. And thanks to this it is very easy to back up the information in our wallet and thus protect our money at all times. ",
    security_kit_seed_c:
        "The seed phrase or seed phrase, refers to a set of 12 words, whose purpose is to offer us an easy and simple way to support our cryptocurrency wallet. In this way, if for any reason control over it is lost , we could retrieve it just by using these words in the order they were originally given to us.",
    security_kit_seed_d: "Take these tips into consideration:",
    security_kit_seed_e:
        "It is important that you write them down on a piece of paper in the order that we show them to you.",
    security_kit_seed_f: "Never share these words with other people.",
    security_kit_seed_g:
        "If you ever change or lose this device, these words may be the only way to get your funds back.",
    security_kit_seed_h: "On the next screen we will ask you some of these words, so don't forget to write them down.",
    security_kit_confirm_a:
        "Two of these words are part of your seed phrase (Seed), select them to finish the verification process.",
    security_kit_confirm_b: "Remember that you only have to select two words. To deselect one, just touch it above.",
    security_kit_confirm_error: "The selected words are not correct",
    finalize: "Finish",
    beexo_wallet_is: "wallet is a multi-platform, multi-currency crypto wallet created for the new generation.",
    i_accept: "I accept",
    seed_phrase: "Seed Phrase",
    beexo_is_a_deterministic_wallet:
        "We use the BIP39 standard to generate the seed phrase. The responsibility to safely save the phrase rests entirely with the user. Keep in mind that losing or revealing your phrase to someone can mean losing all your funds.",
    terms: "Terms",
    before_continuing: "Before continuing, you must read and accept",
    terms_and_conditions: "Terms and Conditions, EULA (End User License Agreement) and Privacy Policies.",
    privacy_terms: "Before continuing, you must read and accept our ",
    privacy_terms_link: "privacy policies",
    if_you_do_not: "If you do not agree with all our terms, please do not use this application.",
    crypto: "Crypto",
    wallet: "Wallet",
    init: "Home",
    draft: "Draft",
    not_edit: "(NOT EDITABLE)",
    bot_draft: "This is a draft, and will only be an NFT when it has been minted.",
    bot_draft_minted: "We are minting this NFT, in a moment you will see it reflected as an NFT.",
    discover: "Discover",
    chat: "Chat",
    total_balance: "Total balance",
    balance: "Balance",
    send: "Send",
    wallet_connect: "Wallet Connect",
    dapps: "Dapps Explorer",
    processing: "PROCESSING",
    processing_image: "PROCESSING IMAGE",
    your_dapps: "Your Dapps",
    connected: "Contecado",
    collections: "Collections",
    marketplace: "Marketplace",
    disconnected: "Disconnected",
    explore_dapps: "Explore Dapps",
    dapps_connect_warning: "You do not have an active WalletConnect connection for this Dapp",
    dapps_connect_copy: "Search for Wallet Connect on the Dapp and COPY the QR Code to connect",
    wallet_connect_paste_error: "It seems that the code entered does not correspond to Wallet Connect",
    wallet_connect_question: "Do you really want to connect with %{name}?",
    wallet_connect_remove_question: "Do you really want to disconnect and remove %{name}?",
    wallet_connect_select_currency:
        "Please select the network (Chain) with which you want to start Wallet Connect on %{name}",
    wallet_connect_sign: "Sign",
    wallet_connect_welcome: "Hello! You are using WalletConnect V2 🚀",
    wallet_connect_welcome_message:
        "Remember that now connections can be multi-chain. To add a new Wallet Connect use ScannerQR",
    wallet_connect_request_question: "Do you really want to perform this action?",
    wallet_connect_disconnected: "Wallet Connect session has been closed",
    wallet_connect_empty: "You have no active WalletConnect connections, click the button to add a new connection.",
    wallet_connect_connection_error: "We couldn't connect, please try again.",
    personal_sign_subtitle: "Sign a personal message",
    personal_sign_description:
        "This can be recognized as a specific signature and prevents misuse where a malicious DApp can sign arbitrary data.",
    no_proccessed_request: "Our wallet is currently not compatible with the DApp request.",
    eth_Transaction_subtitle: "Requests you to sign a transaction",
    eth_Transaction_question: "Press Continue to see the details and confirm the transaction",
    eth_sendTransaction_subtitle: "Confirm Transaction",
    eth_sendTransaction: "From \n%{from} \n\nTo \n%{to}",
    eth_signTransaction: "From \n%{from} \n\nTo \n%{to} \n\nAmount \n%{value}",
    remove: "Remove",
    message_remove: "You can delete this item, since it is a draft and has not been minted yet.",
    question_remove: "Do you want to remove this content?",
    connect: "Connect",
    disconnect: "Disconnect",
    explore: "Explore",
    copy_message: "Copy",
    report_message: "Report",
    cancel_message: "Cancel",
    save: "Save",
    save_restart: "Save and Restart",
    no_network_connection:
        "It looks like you don't have an internet connection, many of the wallet's features may not be available.",
    delete: "Delete",
    receive: "Receive",
    received: "Received",
    total_received: "Total Received",
    total_payed: "Total Payed",
    sent: "Sent",
    network: "Network",
    exchange: "Exchange",
    address: "Address",
    your_address: "Your address",
    copied: "Copied to Clipboard",
    valid_address: "Please, enter a valid address",
    amount_small: "The amount is too small",
    next: "Next",
    back: "Back",
    confirm_transaction: "Confirm transaction",
    transaction_id: "Transaction ID",
    amount: "Amount",
    sending_to: "Sending to",
    sending_from: "Sending from",
    fee: "Fee",
    service_fee: "Service Fee (Provider)",
    status: "Status",
    date: "Date",
    from: "From",
    to: "To",
    confirmed: "Confirmed",
    slide_to_confirm: "Slide to confirm",
    confirm: "Confirm",
    continue: "Continue",
    slide: "Slide to transfer",
    transfer: "Transfer",
    transfer_bot: "Search and select the user you want to make the transfer to",
    settings: "Settings",
    signout: "Sign out",
    preferences: "Preferences",
    block_user: "Block User",
    unblock_user: "Unblock User",
    blocked_user: "Blocked Users",
    private_account: "Private Account",
    block_user_modal:
        "Do you really want to block this user? Once blocked you won't be able to see their posts and they won't be able to see yours either.",
    empty_blocked_users: "You have no blocked users. Remember that to block a user you must do it from their profile.",
    security: "Security",
    advanced: "Advanced",
    import: "Import",
    support: "Support",
    version: "Version",
    language: "Language",
    select_language: "Select Language",
    backup: "Backup",
    warning: "Warning",
    important: "Important",
    new_seed: "Create a new seed",
    active: "Active",
    report_message_text: "You can report this message and it will be forwarded to our control center for evaluation.",
    report_message_question: "Do you want to report this message?",
    report_message_description: "The contact will not be notified about this. Remember that you can also block %{name}",
    warning_new_seed_question: "Do you really want to generate a new seed?",
    warning_new_seed: "You will lose your funds from the current seed if you have not backed it correctly",
    warning_import: "Make sure to backup your seed phrase before importing a new one.",
    type_words: "Type your twelve words",
    crypto_address: "Crypto Address",
    pay_order: "Request Payment",
    select_network: "Select network",
    ethCompatibility: "Compatible ETH derivation",

    ethCompatibility_warning_title:
        "We have recently updated the PATH of this coin to be compatible with the ETH Network.",
    ethCompatibility_warning_message:
        "If you had funds and now you don't see them, you can go back to your previous address by accessing the settings for this currency.",
    mainnet_testnet:
        "Mainnet is the main network on which you can carry out your usual operations with cryptocurrencies. By using Testnet you can test different functionalities without consuming your resources.",
    send_crypto: "Send Crypto",
    share_NFT: "Share NFT",
    share: "Share",
    send_NFT: "Send NFT",
    send_ticket: "Send Ticket",
    send_voucher: "Send Voucher",
    poap_claimed: "This POAP has already been claimed by someone else.",
    poap_inactive: "This POAP cannot be claimed, please try another.",
    poap_question: "Let's assign this POAP to your ETH address",
    bot_message_select_redeemable: "Select a %{type} to transfer",
    bot_message_empty_redeemable: "You have no %{type}s available to transfer",
    poap_testnet: "This section is not available on TESTNET, please switch to MAINNET to view or mint your POAPs",
    internal_server_error:
        "Sorry, an error has occurred. Please check your internet connection, restart the application, the network may be busy, and try again in a few minutes",
    info_NFT: "You can see more details of the NFT by tapping on this message",
    info_receive_NFT:
        "You have received an NFT, now it is part of your collection. You can see more details by tapping on the message or you can see it on your profile",
    info_send_NFT:
        "You have sent this NFT and therefore it is no longer part of your collection. You can see more details by tapping on this message.",
    warning_send_crypto:
        "It is not possible to send cryptocurrencies to this user momentarily, as their addresses are out of date. You can ask them to send you their address or request a payment.",
    add_contact: "Add contact",
    edit_contact: "Edit contact",
    edit_profile: "Editar perfil",
    name: "Name",
    alias: "Alias",
    set_alias: "Set alias",
    name_error: "The name cannot be empty",
    alias_error: "The alias cannot be empty",
    field_error: "This field can not be empty",
    birthday_error:
        "Your date of birth must be completed with Year (Ex. 1995), Month (Ex. 05) and Day (Ex. 22), separated by slashes or dashes. (Ex. 1995/05/22).",
    img_error: "The image cannot be empty",
    contacts_list: "Contacts List",
    search: "Search",
    no_foll_warning:
        "It looks like you don't follow any users yet, so we'll show you suggested posts. You can follow users to see their posts or use the Discover tab to see more posts.",
    follow: "Follow",
    unfollow: "Unfollow",
    posts: "Posts",
    followers: "Followers",
    following: "Following",
    contact: "Contact",
    now: "now",
    minutes_ago: "minutes ago",
    hours_ago: "hours ago",
    days_ago: "days ago",
    liked_by: "Liked by",
    and: "and",
    others: "others",
    see_more: "See more",
    see_less: "See less",
    users: "Users",
    hashtags: "Hashtags",
    global_search: "Global search",
    exchange_amount: "The amount is lower than the exchange minimum of",
    exchange_funds: "You don't have enough funds to start this exchange. Your current balance is",
    new_post: "New post",
    gallery: "Gallery",
    camera: "Camera",
    image_warning: "Please select an image before continuing",
    image_hight: "The image is too tall for a Post, try taking a photo in landscape format",
    description_warning: "Please select an description before continuing",
    camera_permission: "Permission to access camera roll is required",
    camera_permission_null: "To use the camera you need to grant permission from the app's external settings",
    saved_succesfuly: "Saved succesfuly",
    recovery_seed_error: "Your account recovery method has failed, try logging in with your Seed Phrase",
    seed_history_warning:
        "We have found these Seed Phrases that we could not add to your history, you can discard them if you wish",
    set_profile: "Set Profile",
    enter_your_alias: "Enter your alias",
    alias_exist: "Alias alredy exists",
    language_warning: "The application will restart to load the new language",
    buy: "Buy",
    bot_image_resize_title: "Do you want me to help you optimize the size of your image?",
    bot_image_resize_message: "Tap on this message to optimize your image.",
    purchases: "Purchases",
    credit_card: "Credit Card",
    wire_transfer: "Wire Transfer",
    provider_error: "The provider is not able to process the request",
    input_amount_message: "The input amount must be smaller than",
    message: "Message",
    not_view_nft_msg: "This message contains an NFT and is not supported by this wallet.",
    and_bigger_than: "and bigger than",
    you_will_pay: "You will pay",
    you_will_buy: "You will buy",
    to_address: "To address",
    payment_method: "Payment method",
    select_currency: "Select Currency",
    select_nft: "Select NFT",
    bot_select_nft: "Select one of your NFTs to share in chat",
    bot_select_send_nft:
        "Select one of your NFTs to send it, you must take into account that you are transferring the NFT and it will be the property of the user to whom you send it. Also, you will not be able to send NFTs that are for sale on the MarketPlace.",
    bot_select_nft_empty: "It looks like you don't have any NFTs on your profile to share.",
    bot_select_send_nft_empty:
        "You don't have any NFTs to send via chat. Remember that they don't have to be for sale in order to send them.",
    enable_currencies: "Currencies",
    select_country: "Select Country",
    you_will_receive: "You will receive",
    you_have_to_pay: "You have to pay",
    an_error_has_occurred: "An error has occurred, please try again",
    an_error_has_occurred_open_NFT: "An error has occurred while trying to get this NFT information",
    enter_email: "Enter your email",
    identity_verification: "Identity Verification",
    personal_information_optional: "Personal Information (Optional)",
    first_name: "First Name",
    last_name: "Last Name",
    birthday: "Birthday (YYYY/MM/DD)",
    gender: "Gender",
    marital_status: "Marital Status",
    nationality: "Nationality",
    politically_exposed: "Politically Exposed",
    document_type: "Document Type",
    document_number: "Document Number",
    single: "Single",
    married: "Married",
    divorcee: "Divorcee",
    widower: "Widower",
    male: "Male",
    female: "Female",
    yes: "Yes",
    no: "No",
    kyc_status: "KYC (Know Your Customer) Status",
    domicile: "Address",
    bank: "Bank",
    postal_code: "Postal Code",
    street_address: "Street",
    street_number: "Street number",
    telephone_number: "Telephone number",
    name_bank: "Name of the Bank",
    cbu: "CBU/CVU",
    tax_identification: "TAX identification",
    front_document: "Front of document",
    front_document_description:
        "Take a picture of it in front of your document. In the image you have to see the entire front of the document and be able to read the data clearly.",
    back_document: "Back of document",
    back_document_description:
        "Take a photo of the back of your document. In the image you must see the entire back of the document and the data can be clearly read.",
    service_image: "Photo of service or tax in your name",
    billing_image: "Photo of the payment ticket",
    billing_image_description: "You must attach a photo of the bank transfer ticket",
    service_description: "Take a photo of a service or tax in your name. It must be seen and read clearly.",
    selfie_document: "Take a picture holding your document",
    selfie_description: "The document should be seen from the front and your face.",
    history: "History",
    history_seed: "History (Seeds)",
    kyc_warning:
        "To make a purchase it is necessary that you have an active KYC. Enter your email and we will verify if you already have a previous KYC or we will guide you to create a new one.",
    city: "City",
    you_need_transfer: "You need to add a photo of the transfer receipt",
    amount_transfer: "Amount to transfer",
    amount_receive: "Amount to receive",
    request: "Requested",
    wait: "Waiting for payment ticket",
    complete: "Complete",
    cancel: "Cancelled",
    pending_by_validate: "Pending validation",
    kyc_validation_pending: "KriptonMarket is verifying your data. When your KYC is approved, you can make a purchase.",
    kyc_country_warning: "You have to fill in the details for KriptonMarket to verify your identity.",
    wire_transfer_info_card:
        "You must transfer to the indicated account address and then attach the tiket to continue with the purchase process.",
    country: "Country",
    type_a_message: "Type a message...",
    bigger_than: "The amount must be bigger than ",
    select_provider: "Select Provider",
    report: "Report",
    reported: "REPORTED",
    message_report: "If you find this content abusive, you can report it and it will be hidden from other users.",
    question_report: "Do you want to report this content?",
    bot_report: "It seems that this NFT has been reported by other users, you can buy it at your own risk.",
    bot_no_buy:
        "At the moment it is not possible to purchase an NFT from the App, we invite you to bid for this NFT from our webApp",
    bot_report_owner:
        "It appears that other users have reported your NFT as abusive. You can continue to sell it, but it will appear as reported until we assess whether it violates our guidelines.",
    apply_loan: "Apply for your loan at ASCAV",
    auth_warning:
        "It seems that you do not have any security method activated on your device. It is possible that if someone accesses your device they can see your information. We recommend that you activate some security method on your device (Ex: Pattern, PIN, Fingerprint, Facial Recognition)",
    auth_warning_web:
        "When using this application from the web browser there is information that we cannot protect. We recommend downloading the application on your device from the Play Store (Android) or the App Store (iOS).",
    local_auth: "Use your fingerprint, unlock pattern or PIN to access the app",
    auth_error:
        "To execute this action it is necessary that you authenticate yourself on your device correctly. Touch here to try again",
    authentication: "Fingerprint",
    add_more_currencies: "Add more",
    new_post_not_available:
        "This function is not available for the web version, you can only create a Post from the application (Android or iOS)",
    approve_tokens: "Approve tokens",
    approve_tokens_message:
        "Before making an exchange, you must approve the use of your tokens. You can reverse this from settings",
    approving: "Approving",
    approving_message: "Please wait a minute while your token is approved",
    no_avatar_alias: "Complete your profile to be able to like a Post.",
    transaction_detail: "Transaction detail",
    your_network_is: "Your network is configured in",
    and_the_operation: "and the operation that this",
    tries_to_follow: "tries to follow. Press continue to change your network and continue with the operation.",
    path_warning:
        "Changing the Path of this currency implies that you will have a new address and that you will only be able to check the balance of your old address by reverting to your previous path. Likewise, some functionalities of the wallet (for example receiving crypto through CryptoChat) may behave differently. You must bear in mind that if your address differs from the one that will be used to pay for the Gas (Swap operations), we will not be able to collect the fee and the operation will not materialize. We believe it is important to warn you that you should only change this path if you have knowledge about it.",
    rewarded: "Rewarded",
    new_post_message: "Choose a photo or use your camera and start creating content.",
    confirm_swap: "Confirm Swap",
    activity_transactions:
        "It looks like you don't have any %{currency} activity yet. When you do an operation you will see it in this section.",
    daily: "Daily",
    weekly: "Weekly",
    close: "Close",
    show_seed: "Show words",
    hide_seed: "Hide words",
    words: "Words",
    qr: "QR (Seed)",
    type_a_password: "Type a password",
    QR_password: "Type QR password",
    confirm_password: "Confirm password",
    warning_encrypted:
        "We are going to encrypt the QR code so that only you can use it. Please choose 4 numbers or letters. It is important that you do not forget this code, because you will need it to import your phrase with QR in the future.",
    warning_seed:
        "Never share your words, do not take a screenshot of them, or copy them into a text file. Write them down on a piece of paper and keep it in a safe place",
    warning_QR:
        "Although this QR code that contains your words is encrypted, it is not safe to keep it as a screenshot or image file. Use it to import your words to a new device and then undo the QR code. You can keep it at your own risk.",
    info_confirm_password:
        "Please confirm the password. And remember that you will need it when you want to scan the QR code that you are going to generate.",
    password_error: "The passwords entered are not the same",
    update: "Update",
    web_updates:
        "There are updates available for your version of the web application, they should automatically impact when you restart the page; If not, it may be necessary to clear your browser's cache and cookies.",
    app_updates:
        "There are updates available for your version, therefore it is necessary to update the application, since certain features may stop working. You can try to restart the application and it will update automatically or go to the %{store} and press update.",
    swap_warning: "By performing this operation you will lose %{percentage}% of the amount entered",
    owned_by: "Owned  by",
    created_by: "Created by",
    assigned_to: "Has been assigned to ",
    used_in: "You have used it in ",
    transferred_to: "You have transferred it to",
    received_from_redeemable: "Received from ",
    expired: "It has expired! 😭",
    owner: "Owner",
    redeemables_warning:
        "You will only see your %{type}s available to use here. You can see your used, expired or archived %{type}s by tapping here 🚀",
    memberships_warning:
        "You will only see here your memberships available to use. To check your archived memberships, tap here 🌟",
    create_new_item: "Create new Item (NFT)",
    edit_item: "Edit Draft",
    create: "Create",
    item_name: "Ex: Spring Flowers",
    image: "Image",
    use: "Use",
    archive: "Archive",
    unarchive: "Unarchive",
    modal_reddemable_question: "Do you want to use this %{type}?",
    redeemables_error_message: "You have no tickets to use at this gate",
    poster_image: "Cover Image",
    select_poster_image: "Select the cover image for your video",
    image_video: "Image or Video",
    sold_to: "Sold to",
    error_transfer_nft: "You can't send an NFT if it's for sale, remove it from the MarketPlace and try again.",
    send_to: "Transferred to",
    transfer_to: "Transfer to",
    archived: "Archived",
    gate_message: "You are archiving this %{type}, you can revert this action whenever you want",
    received_from: "Transferred by",
    buyed_to: "Bought from",
    image_size_error: "The image is too big, it cannot exceed 3 MB.",
    video_size_error: "The video is too big, it cannot exceed 50 MB.",
    video_width_error: "The maximum supported width for video is 720 px.",
    video_width_min_error: "The minimum supported width for video is 360 px.",
    video_duration_error: "Video cannot be longer than 90 seconds.",
    video_ratio_error: "The aspect ratio of the video is not correct, this can be 9/16 or 16/9.",
    video_size_per_minute_error: "Video cannot be larger than 40 MB per 60 seconds.",
    video_type_error: "Video format must be .MP4",
    processing_video: "Processing video, please wait...",
    required: "Required",
    file_type_supported: "Supported file types: Image JPG, PNG. Maximum size: 3 MB // Video MP4. Maximum size 50 MB.",
    external_link: "External link",
    external_link_description:
        "We will include a link to this URL on this item's detail page, so users can click to learn more about it. You are welcome to link to your own web page with more details.",
    external_link_placeholder: "Ex: https://yoursite.com/nft/123",
    description: "Description",
    description_description: "The description will be included on the item's detail page underneath its image.",
    description_placeholder: "Ex: Unique hand-drawn work of a...",
    blockchain_description: "Select the blockchain your NFT will be based on.",
    propieties: "Properties",
    propieties_description: "Textual traits that show up as rectangles",
    type_props: "Type: Ex: 'BODY'",
    name_props: "Name: Ex: 'Blue'",
    return_percentage: "Return percentage",
    offer_for_sale: "Sell",
    cancel_sale: "Cancel Sale",
    confirm_buy: "Confirm Buy",
    on_sale: "On sale",
    cancel_sale_question: "Do you want to stop offering this NFT for sale?",
    cancel_sale_description: "To perform this operation you will have to pay the cost of a transaction.",
    nft_temporal_message:
        "You don't need to reload the app, or log out of your profile and log back in. Once processed, it will automatically show up on this screen",
    nft_temporal_subtitle: "We are processing your NFT, once it is processed you will see it in your profile NFTs.",
    no_proccesed_request: "Our wallet is currently not compatible with the DApp requirement",
    return_percentage_description: "Set the percentage you will earn on future transactions of your item",
    return_percentage_error: "The percentage cannot exceed 20%",
    address_description: "Address where you will receive payments, this field is not editable",
    info_card_confirm_sale:
        "We have everything ready for your NFT to be in our MarketPlace, confirm the transaction to continue. Your NFT may take a few minutes to appear listed in the Marketplace.",
    info_card_confirm:
        "You are already finishing the first step to create your NFT. Once processed, you will be able to price it and offer it on the NFTs Marketplace.",
    approve_NFT: "Approve NFT",
    almost_done: "We're almost done!",
    approve_pending: "As soon as your NFT approval has hit you can confirm to sell this item",
    info_card_confirm_transfer:
        "We have everything ready to transfer your NFT, confirm the transaction to continue, this process may take a few minutes.",
    bot_marketplace_message:
        "We are processing your request, in a few minutes you will see your NFT listed in the Marketplace.",
    bot_marketplace_message_cancel:
        "We are processing your request, in a few minutes you will no longer see your NFT listed on the Marketplace.",
    info_card_approve:
        "We will approve your NFT before putting it up for sale, for this you will pay the expenses detailed below. Don't worry, we are almost done with the steps so you can sell your NFT.",

    info_card_buy: "Below we detail the costs for the purchase of the NFT",
    price: "Price",
    price_description: "Set the price at which your NFT will be offered to buyers",
    dapp_web_message: "Some features for Dapps may not be available in the web version.",
    dapp_web_info:
        "You can install our app from the official stores, you will have a better user experience and greater security in your operations.",
    services: "Services",
    email: "Email",
    lastname: "Lastname",
    phone: "Phone",
    error_email: "Error updating email",
    error_name: "Error updating name",
    error_lastname: "Error updatinglastnamel",
    error_phone: "Error updating phone",
    notifications: "Notifications",
    empty_list: "No results found. Please check your search and try again.",
    empty_message: "You don't have notifications yet",
    empty_follower:
        "You don't have follow requests yet. Remember that requests only appear here when your profile is private",
    empty_post_own: "Your profile doesn't have any posts at the moment! ",
    empty_post: "No POSTs available.",
    empty_nft_own: "Your profile doesn't have any NFTs at the moment! ",
    empty_nft: "No NFTs available.",
    empty_nft_history: "You don't have any NFT history.",
    empty_poap_own: "Your profile doesn't have any POAPs at the moment! ",
    empty_poap: "No POAPs available.",
    empty_create_poaps: "They will appear here as you get them, use the QR scanner to claim POAPs.",
    empty_create_nft: "They will appear here as you obtain or create them from the MarketPlace.",
    empty_create_posts: "They will appear here as you create new Posts",
    personal_verification: "Personal Verification",
    previous: "Previous",
    zip_code: "Zip Code",
    birthdate: "Birthdate",
    step: "Step",
    verification_completed: "Verification Completed",
    personal_information: "Personal Information",
    facial_verification: "Facial Verification",
    front_idcard: "Front side of ID Card",
    back_idcard: "Back side of ID Card",
    take_selfie: "Take a Selfie",
    idcard_title: "You are about to upload your ID card. Pleaseensure that:",
    idcard_description_one: "This is your own government-issued document that is not expired.",
    idcard_description_two: "This is an original document, not a scan or copy.",
    idcard_description_three: "Remove any card holders or covers to avoid reflections or blur.",
    idcard_description_four: "Place documents against a solid-colored background.",
    selfie_title: "You are about to upload a selfie. Please ensure that:",
    selfie_description_one: "Take a selfie of yourself with a neutral expression",
    selfie_description_two: "Make sure your whole face is visible, centred, and your eyes are open",
    video_title:
        "Final step! Record an 8-second video performing the gestures that will be displayed on the screen once the video starts.",
    video_description: "With this video, we will validate that you are the same person as shown in your document.",
    record_video: "Record the Video",
    complete_field_warning: "Please complete the fields before continuing.",
    complete_file_warning: "Please upload the image before continuing.",
    photo_idcard_front: "Place the front of ID Card in the frame",
    photo_idcard_back: "Place the back of ID Card in the frame",
    kyc_mouth: "Open and close your mouth",
    kyc_eyes: "Blink",
    kyc_wink: "Wink",
    share_post: "Share post",
    check_nft: "Check out this amazing NFT!",
    check_post: "Check out this amazing post!",
    check_experience: "Check out this amazing experience!",
    copy_link: "Copy link",
    link_copied: "Link copied",
    experiences: "Experiences",
    select_ticket: "Select ticket",
    select_quantity: "Select quantity",
    select: "Select",
    use_in: "Use in",
    congratulations: "Congratulations!",
    enjoy_purchase: "You can now enjoy your purchase.",
    confirm_go_back: "Are you sure you want to go back? Your changes will be lost.",
    deleted_successfully: "Deleted successfully",
    free: "Free",
    order_details: "Order Details",
    confirm_order: "Confirm Order",
    my_cart: "My Cart",
    add_cart: "Add to Cart",
    quantity: "Quantity",
    google_permission:
        "If you choose to log in using Google, you will need to accept the access permissions for Drive. Alternatively, you can log in anonymously if you decide not to grant these permissions.",
    continue_with: "Continue with",
    or: "or",
    new_user: "new Account",
    use_account_associated: "Accounts stored on your device or associated with your email",
    incognito_with_seed_phrase: "Incognito with Seed Phrase",
    seed_phrase_detail: "The seed phrase will give you access to your cryptocurrencies and digital assets",
    welcome_sthorm: "Welcome to the Sthorm App",
    sthorm_subtitle: "A gated, purpose-driven, social engineering platform for prosperity.",
    gob_subtitle:
        "You are one step away from unlocking the gateway to the exciting world of digital assets and blockchain technology.",
    username: "Username",
    password: "Password",
    sign_in: "Sign In",
    sign_up: "Sign Up",
    confirmPassword: "Confirm Password",
    amount_to_buy: "Amount to buy",
    receives: "Receives",
    select_deposit_bank: "Select the bank where you will make the deposit.",
    select_receive_bank: "Select the bank where you will receive the deposit.",
    payment_receipt: "Please provide the deposit reference number and upload the payment receipt.",
    transactions: "Transactions",
    recent_transactions: "Recent transactions",
    receipt: "Receipt",
    empty_transactions:
        "It looks like you don't have any transaction. When you do an operation you will see it in this section.",
    kyc_alert: "You need to complete your KYC (Know Your Customer) to be able to operate with COINCAEX.",
    deposit_reference: "Deposit reference number",
    sell: "Sell",
    total_to_deposit: "Total to Deposit",
    commission: "Commission",
    account_holder: "Account holder",
    account_type: "Account type",
    account_name: "Account name",
    account_number: "Account number",
    account_data: "Account data",
    amount_to_sell: "Amount to sell",
    total_deposit: "Total that will be deposited to you",
    banks: "Banks",
    empty_banks: "You haven't added any bank yet.",
    add_bank: "Add Bank",
    select_bank: "Select Bank",
    select_account: "Select Account",
    discount_code: "Discount code (Optional)",
    verify: "Verify",
    hash_receipt: "Please provide the transaction hash and upload the payment receipt.",
    hash_transaction: "Transaction Hash (Optional)",
    confirm_sell: "Confirm Sell",
    buys: "Buys",
    sale: "Sale",
    bank_message: "Before starting the transaction, please add a bank account.",
    error_module_disabled: "This function cannot be used at this time",
    social: "SOCIAL",
    chats: "CHATs",
    req: "Request",
    payment: "Payment",
    transaction_message: "Transaction successfully completed! You can check its status in recent transactions.",
    transaction_success: "Successful transaction.",
    error_coincaex: "An error has occurred. Please contact Coincaex support.",
    back_coincaex: "Back to Coincaex",
    transaction_open_error: "It is not possible to view this transaction at this time.",
    verified: "Verified",
    profile_contact_QR: "%{name} Contact",
    signed_by: "Signed by ",
    wallet_connect_requesting_error: "%{name} requests an action that does not belong to your current account",
    wallet_connect_switch_account: "You may have to switch accounts to respond to this address request (%{address})`",
    error_message: "Sorry, something went wrong. Please try again later.",
    purchase_detail: "Purchase detail",
    buy_now: "Buy now",
    processing_payment: "We are processing your payment",
    complete_purchase: "Complete purchase",
    add_item_warning: "Add an item before continuing",
    calendar: "Calendar",
    fee_expenses: "Does not include fee expenses",
    important_announcement: "OUT OF SERVICE",
    maintenance_mode:
        "We are carrying out maintenance tasks on %{name} services, so access to the app will be affected for a few hours. We hope you know, we apologize.",
};
