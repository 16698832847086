import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { StyleSheet, View, ViewStyle } from "react-native";
import { colors } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import { AreaChart } from "react-native-svg-charts";
import Row from "@base/Row";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import Card from "@base/Card";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import Wallet from "@core/wallet/Wallet";
import * as shape from "d3-shape";
import { SectionTitle } from "../components/SectionTitle";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    navigation: NavigationType;
    client: Client;
    currencies?: Array<Currency>;
    totalBalance: number;
    fiatCurrency?: FiatCurrency;
    containerStyle?: ViewStyle;
}

interface State { }

const { t } = i18n;

export class _WalletSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.redirectToWallet = this.redirectToWallet.bind(this);
        this.state = {};
    }

    redirectToWallet() {
        this.props.navigation.navigate(TabsNavigatorScreens.Wallets.routeName);
    }

    render() {
        return (
            <View style={this.props.containerStyle}>
                {ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule) && (
                    <View style={{}}>
                        <SectionTitle
                            onPress={this.redirectToWallet}
                            title={t("wallet").toUpperCase()}
                            icon={"💵"}
                            counter={t("crypto").toUpperCase()}
                            seeMore={false}
                            {...this.props}
                        ></SectionTitle>

                        <Card
                            style={{ flex: 1, paddingTop: 10, minHeight: 50, marginTop: 10, marginRight: 0 }}
                            onPress={this.redirectToWallet}
                            background={
                                <AreaChart
                                    style={{ height: 85, marginTop: -5, marginHorizontal: -5 }}
                                    data={Wallet.getInstance()
                                        .findCurrencyById("BTC")
                                        ?.getPriceHistoryDaily()
                                        .map((p) => p.price)}
                                    contentInset={{ top: 10, bottom: 35 }}
                                    curve={shape.curveNatural}
                                    svg={{
                                        stroke: colors.grey,
                                        strokeOpacity: 0,
                                        strokeWidth: 1,
                                        fill: colors.tertiaryShadow,
                                        fillOpacity: 1,
                                    }}
                                ></AreaChart>
                            }
                        >
                            <Row style={styles.row}>
                                <View style={{ paddingLeft: 10, flex: 1, overflow: "hidden" }}>
                                    <View style={{ position: "relative" }}>
                                        <Row>
                                            <BoldText style={{ marginRight: 2, marginTop: 1 }} fontSize={20}>
                                                {this.props.fiatCurrency.getSymbol()}
                                            </BoldText>
                                            <BoldText align="center" fontSize={22}>
                                                {
                                                    this.props.fiatCurrency
                                                        .fiatFormat(this.props.totalBalance)
                                                        .split(".")[0]
                                                }
                                            </BoldText>

                                            <BoldText align="left" fontSize={12} style={{}}>
                                                {
                                                    this.props.fiatCurrency
                                                        .fiatFormat(this.props.totalBalance)
                                                        .split(".")[1]
                                                }
                                            </BoldText>

                                            <Row style={{ paddingLeft: 10, overflow: "hidden" }}>
                                                {Wallet.getInstance()
                                                    .getBalancedCurrencies()
                                                    .map((c) => {
                                                        return (
                                                            <CurrencyIcon
                                                                key={c.getId()}
                                                                size={16}
                                                                iconSize={8}
                                                                currency={c}
                                                                styles={{ marginRight: 4 }}
                                                            />
                                                        );
                                                    })}
                                            </Row>
                                        </Row>
                                    </View>
                                </View>

                                {/* <View style={{ flex: 0.1 }}>
                                <CircleButton
                                    containerStyles={{ felx: 1, paddingTop: 10 }}
                                    style={{ height: 30, width: 30 }}
                                    iconSize={15}
                                    icon={"right"}
                                    onPress={this.redirectToWallet}
                                ></CircleButton>
                            </View> */}
                            </Row>
                        </Card>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },
    row: {
        width: "100%",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.gradientFrom,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        totalBalance: state.wallet.totalBalance,
        fiatCurrency: state.wallet.fiatCurrency,
        currencies: state.wallet.currencies,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const WalletSection = connect(mapStateToProps, mapDispatchToProps)(_WalletSection);

export default WalletSection;
