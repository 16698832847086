import {
  SELECT_CURRENCY,
  ON_CHANGE,
  RELOAD,
  PREPARE_TRANSACTION,
  ON_TRANSACTION_READY,
  SELECT_TRANSACTION,
  PRE_PREPARE_TRANSACTION, CLEAN_OPERATION, SET_CURRENCIES, SYNC, SYNCED, SELECT_EXCHANGE_FROM, SELECT_EXCHANGE_TO, PREPARE_EXCHANGE, SYNC_TRANSACTIONS, PREPARE_BUY, SYNC_PURCHASE_ORDERS, SELECT_PURCHASE_ORDER, SELECT_BUTTON_ACTION_TYPE
} from '@store/actions/wallet';
import Currency from '@core/currencies/Currency';
import FiatCurrencies, { FiatCurrency } from '@core/fiat/FiatCurrencies';
import Wallet from '@core/wallet/Wallet';
import { ButtonActionsType } from '@components/wallet/WalletsButtons';

interface Transaction {

}

interface walletState {
  enabled: boolean,
  currencies: Array<Currency>,
  fiatCurrency: FiatCurrency,
  selectedCurrency: string,
  totalBalance: number,
  unconfirmedTotalBalance: number,
  currentOperation: any,
  selectedTransaction: number,
  syncing: boolean,
  selectedExchangeFrom: Currency,
  selectedExchangeTo: Currency,
  selectedPurchaseOrder: number,
  buttonActionsType: string
}

const initialState: walletState = {
  enabled: false, 
  currencies: [],
  fiatCurrency: FiatCurrencies.USD, 
  selectedCurrency: null,
  totalBalance: 0,
  unconfirmedTotalBalance: 0,
  currentOperation: null,
  selectedTransaction: null,
  syncing: true,
  selectedExchangeFrom: null,
  selectedExchangeTo: null,
  selectedPurchaseOrder: null,
  buttonActionsType: ButtonActionsType.RECEIVE
};

const walletReducer = (state = initialState, action) => {
  switch(action.type) {
    
    case SET_CURRENCIES:
      return {
        ...state, 
        currencies: action.currencies
      }

    case ON_CHANGE:
      return {
        ...state, 
        currencies: action.wallet.getEnabledCurrencies(),
        fiatCurrency: action.wallet.getFiatCurrency(),
        totalBalance: action.wallet.getTotalBalance(),
        unconfirmedTotalBalance: action.wallet.getUnconfirmedBalance()
      }

    case RELOAD:
      return {
        ...state,
        enabled: true,
      }

    case SYNC:
      return {
        ...state,
        syncing: true,
      }

    case SYNC_TRANSACTIONS:
      return {
        ...state,
        syncing: true,
      }

    case SYNC_PURCHASE_ORDERS:
      return {
        ...state,
        syncing: true,
      }

    case SYNCED:
      return {
        ...state,
        syncing: false,
      }

    case SELECT_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.currency
      }
    
      case SELECT_BUTTON_ACTION_TYPE:
      return {
        ...state,
        buttonActionsType: action.buttonActionsType
      }

    case CLEAN_OPERATION:
      return {
        ...state,
        currentOperation: null
      }

    case PRE_PREPARE_TRANSACTION:
      return {
        ...state,
        currentOperation: {
          type: PRE_PREPARE_TRANSACTION,
          address: action.parameters.address,
          amount: action.parameters.amount,
        }
      }

    case PREPARE_TRANSACTION:
      return {
        ...state,
        currentOperation: {
          type: 'TRANSACTION',
          data: action.parameters.transaction,
          skeleton: null
        }
      }

    case ON_TRANSACTION_READY:
      return {
        ...state,
        currentOperation: {
          ...state.currentOperation,
          skeleton: action.skeleton
        }
      }

    case SELECT_TRANSACTION:
      return {
        ...state,
        selectedTransaction: action.transaction
      }

    case SELECT_EXCHANGE_FROM:
      return {
        ...state,
        selectedExchangeFrom: action.parameters.currency
      }

    case SELECT_EXCHANGE_TO:
      return {
        ...state,
        selectedExchangeTo: action.parameters.currency
      }

    case PREPARE_EXCHANGE:
      return {
        ...state,
        currentOperation: {
          type: 'EXCHANGE',
          data: action.parameters.swap,
          skeleton: null
        }
      }

    case PREPARE_BUY:
      return {
        ...state,
        currentOperation: {
          type: 'BUY',
          data: action.parameters.data,
          skeleton: null
        }
      }

    case SELECT_PURCHASE_ORDER:
      return {
        ...state,
        selectedPurchaseOrder: action.order
      }
    
    default:
        return state;
  }
};

export default walletReducer;
