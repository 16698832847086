import React, { Component } from 'react';
import SendScreen from '@screens/wallet/send/SendScreen';
import ConfirmSendScreen from '@screens/wallet/send/ConfirmSendScreen';
import NavigationInstantiator from './helpers/NavigationInstantiator';
import ScannerScreen from '@screens/scanner/ScannerAddressScreen';
import ScannerBase from '@screens/scanner/ScannerBase';
import ScannerAddressScreen from '@screens/scanner/ScannerAddressScreen';


const SendNavigatorScreens = {
    SendMain: {
        routeName: "SendMain",
        title: "Send",
        component: SendScreen,
    },
    ConfirmSend: {
        routeName: "ConfirmSend",
        title: "Confirm Send",
        component: ConfirmSendScreen,
    },
    Scanner: {
        routeName: "Scanner",
        title: "Scanner",
        component: ScannerAddressScreen,
    },
   
}

const SendNavigator = NavigationInstantiator(SendNavigatorScreens)

export {SendNavigatorScreens, SendNavigator}