import { ModalBottomOptions, ModalOptions } from "@components/popup/ModalOptions";

export const LOADING = "LOADING";
export const SET_PRIORITY_LOADING = "SET_PRIORITY_LOADING";
export const RELOAD = "RELOAD";
export const PROGRESIVE_LOADER = "PROGRESIVE_LOADER";
export const MESSAGE_LOADER = "MESSAGE_LOADER";
export const READY = "READY";
export const SHOW_POPUP = "SHOW_POPUP";
export const HIDE_POPUP = "HIDE_POPUP";
export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const SHOW_MODAL_BOTTOM = "SHOW_MODAL_BOTTOM";
export const HIDE_MODAL_BOTTOM = "HIDE_MODAL_BOTTOM";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const SEARCH = "SEARCH";
export const REDIRECTION = "REDIRECTION";
export const SET_CURRENT_TASK = "SET_CURRENT_TASK";
export const RESET = "RESET";
export const READY_TO_RENDER = "READY_TO_RENDER";
export const WEB_SPLASH = "WEB_SPLASH";
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const SHOW_SCANNER = "SHOW_SCANNER";
export const HIDE_SCANNER = "HIDE_SCANNER";
export const SHOW_POPUP_MESSAGE = "SHOW_POPUP_MESSAGE";
export const HIDE_POPUP_MESSAGE = "HIDE_POPUP_MESSAGE";
export const MAINTENACE_MODE = "MAINTENACE_MODE";

export const setSearch = (search) => {
    return { type: SEARCH, search };
};

export const reloadWebView = (reload: boolean) => {
    return { type: RELOAD, reload };
};

export const setAuthentication = (activeAuthentication: boolean) => {
    return { type: SET_AUTHENTICATION, activeAuthentication };
};

export const setRedirection = (redirection: string) => {
    return { type: REDIRECTION, redirection };
};

export const loading = () => {
    return { type: LOADING };
};

export const setPriorityLoading = (loading: boolean) => {
    return { type: SET_PRIORITY_LOADING, loading };
};

export const readyToRender = () => {
    return { type: READY_TO_RENDER };
};

export const webSplash = () => {
    return { type: WEB_SPLASH };
};

export const maintenanceMode = () => {
    return { type: MAINTENACE_MODE };
};

export const setProgresiveLoader = (progresive_loader_value) => {
    return { type: PROGRESIVE_LOADER, progresive_loader_value: progresive_loader_value };
};

export const setMessageLoader = (message) => {
    return { type: MESSAGE_LOADER, message: message };
};

export const ready = () => {
    return { type: READY };
};

export const showPopup = (options: PopupOptions) => {
    return { type: SHOW_POPUP, options: options };
};

export const hidePopup = () => {
    return { type: HIDE_POPUP };
};

export const showModal = (options: ModalOptions) => {
    return { type: SHOW_MODAL, options: options };
};

export const hideModal = () => {
    return { type: HIDE_MODAL };
};

export const showSidebar = () => {
    return { type: SHOW_SIDEBAR };
};

export const hideSidebar = () => {
    return { type: HIDE_SIDEBAR };
};

export const showScanner = () => {
    return { type: SHOW_SCANNER };
};

export const hideScanner = () => {
    return { type: HIDE_SCANNER };
};

export const showSnackbar = (options: SnackbarOptions) => {
    return { type: SHOW_SNACKBAR, options: options };
};

export const hideSnackbar = () => {
    return { type: HIDE_SNACKBAR };
};

export const showModalBottom = (options: ModalBottomOptions) => {
    return { type: SHOW_MODAL_BOTTOM, options: options };
};

export const hideModalBottom = () => {
    return { type: HIDE_MODAL_BOTTOM };
};

export const acceptTerms = (parameters: { onSuccess?: any }) => {
    parameters.onSuccess ? parameters.onSuccess() : null;
    return { type: ACCEPT_TERMS, parameters: parameters };
};

export const setCurrentTask = (task) => {
    return { type: SET_CURRENT_TASK, task };
};

export const reset = () => {
    return { type: RESET };
};

export const showPopupMessage = (options: PopupMessageOptions) => {
    return { type: SHOW_POPUP_MESSAGE, options: options };
};

export const hidePopupMessage = () => {
    return { type: HIDE_POPUP_MESSAGE };
};
