import React, { Component } from "react";
import { View, StyleSheet,  Platform } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { WebView } from "react-native-webview";
import Constants from "expo-constants";

interface Props {
    navigation: NavigationType;
    selectedCurrency: any;
}

interface State {}

const { t } = i18n;

export class _OnrapmerScreen extends Component<Props, State> {
    protected currency: Currency;
    constructor(props: Props) {
        super(props);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.state = {};
    }

    render() {
        const apiKey = Constants.expoConfig?.extra?.keys?.onramper;
        const wallet = `${this.currency.getName()}:${this.currency.getAddress()}`;
        const client = Constants.expoConfig?.extra?.client;
        //@TODO: Ask a new api key for lockertoken and update this.
        const source = `https://${client == 'lockertoken' ? 'widget' : 'buy'}.onramper.com/?apiKey=${apiKey}&onlyCryptos=${this.currency.getName()}&wallets=${wallet}&defaultPaymentMethod=${
            Platform.OS == "ios" ? "applePay" : "creditCard"
        }`;

        return (
            <ScreenWrapper>
                <Header title={"Onramper"} type={HeaderType.Light} {...this.props} />

                {Platform.OS === "web" && (
                    <View style={{ flex: 1 }}>
                        <iframe src={source} style={{ border: "none" }} height={680} width={"100%"} />
                    </View>
                )}
                {Platform.OS !== "web" && (
                    <View style={{ flex: 1 }}>
                        <WebView style={{ flex: 1 }} source={{ uri: source }} />
                    </View>
                )}
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});
const OnrapmerScreen = connect(mapStateToProps, mapDispatchToProps)(_OnrapmerScreen);
export default OnrapmerScreen;

const styles = StyleSheet.create({});
