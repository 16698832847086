import React, { Component } from "react";
import { View, StyleSheet, Platform, ViewStyle, TextStyle } from "react-native";
import { BoldText } from "@base/BoldText";
import { getColorOpacity } from "./../../utils/helpers/global/global";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";

interface Props {
    currency: Currency;
    styles?: ViewStyle | TextStyle;
    containerStyles?: ViewStyle;
    fontSize?: number;
}

const { t } = i18n;

export default class NetworkCurrencySymbol extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <View style={[this.props.containerStyles, { paddingBottom: 2 }]}>
                {this.props.currency && this.props.currency.getNetworkSymbol() && (
                    <View
                        style={[
                            styles.implementationSign,
                            this.props.styles,
                            {
                                backgroundColor: getColorOpacity(
                                    this.props.currency.getUnderlyingCurrency().getColor(),
                                    0.75,
                                ),
                            },
                        ]}
                    >
                        <BoldText color={colors.networkSymbol} fontSize={this.props.fontSize || 8}>
                            {this.props.currency.getNetworkSymbol()}
                        </BoldText>
                    </View>
                )}
                {/* {this.props.currency.getBlockchain() == "centralized" && (
                    <View
                        style={[
                            styles.implementationSign,
                            this.props.styles,
                            {
                                backgroundColor: colors.complementary || colors.secondary,
                            },
                        ]}
                    >
                        <BoldText color={colors.labelText} fontSize={this.props.fontSize || 8}>
                            POINTS
                        </BoldText>
                    </View>
                )} */}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    implementationSign: {
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textAlignVertical: "center",
        overflow: "hidden",
        marginLeft: 5,
        paddingHorizontal: 6,
        paddingVertical: 2,
        borderRadius: 8,
        marginTop: Platform.OS == "android" ? 2 : Platform.OS == "ios" ? 4 : 0,
    },
});
