import store from "@store/index";
import i18n from "@i18n/i18n";
import { Platform, Share } from "react-native";
import * as Linking from "expo-linking";
import { showPopup, showSnackbar } from "@store/actions/global";
import { setSelectedNFT } from "@store/actions/nfts.action";
import { setSelectedPost } from "@store/actions/social.actions";
import NFTService from "./NFTService";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { NavigationType } from "@custom-types/NavigationType";
import ExperienceService from "./ExperienceService";

const { t } = i18n;

export default class ShareService {
    private static instance: ShareService;
    isLinkingInit: boolean = false;

    constructor() {}

    static getInstance(): ShareService {
        if (!ShareService.instance) {
            ShareService.instance = new ShareService();
        }
        return ShareService.instance;
    }

    handleLinking = (navigation: NavigationType) => {
        if (!this.isLinkingInit) {
            this.isLinkingInit = true;

            // App closed
            if (Platform.OS == "android") {
                const NativeIntent = require("react-native/Libraries/Linking/NativeIntentAndroid").default;

                NativeIntent.getInitialURL().then((url) => {
                    const { queryParams } = Linking.parse(url);
                    this.redirectLinking(queryParams, navigation);
                });
            } else {
                Linking.parseInitialURLAsync().then((url) => {
                    const { queryParams } = url;
                    this.redirectLinking(queryParams, navigation);
                });
            }

            // App in the background
            if (Platform.OS != "web")
                Linking.addEventListener("url", ({ url }) => {
                    const { queryParams } = Linking.parse(url);
                    this.redirectLinking(queryParams, navigation);
                });
        }
    };

    redirectLinking = async (queryParams: any, navigation) => {
        
        if (queryParams?.type == "nft" && queryParams?.id) {
            const updatedNft = await NFTService.getInstance().getNFT(queryParams?.id, queryParams?.blockchain, true);
            store.dispatch(setSelectedNFT(updatedNft));
            navigation.navigate("NFTs");
            navigation.navigate("NFTs", {
                screen: "NFT",
            });
        }

        if (queryParams?.type == "post" && queryParams?.id) {
            const updatedPost = await SocialNetworkService.getInstance().getPostById(queryParams?.id);
            store.dispatch(setSelectedPost(updatedPost));
            navigation.navigate("SocialNetwork");
            navigation.navigate("SocialNetwork", {
                screen: "PostDetail",
            });
        }

        if (queryParams?.type == "experience" && queryParams?.id) {
            const experience = await ExperienceService.getInstance().getExperience(queryParams?.id);
            navigation.navigate("Experience", {
                screen: "ExperienceDetail",
                params: { experience },
            });
        }
        
        if (queryParams?.type == "profile" && queryParams?.id) {
            navigation.navigate("Profile", {
                screen: "ProfileMain",
                params: { clientID: queryParams?.id },
            });
        }
    };

    handleShare = async (message: string, redirectLink: string) => {
        if (Platform.OS == "web") {
            navigator.clipboard.writeText(`${message} ${redirectLink}`);
            store.dispatch(showSnackbar({ type: "SUCCESS", message: t("link_copied") }));
        } else {
            try {
                await Share.share({
                    message: `${message} ${redirectLink} `,
                    url: redirectLink,
                });
            } catch (error: any) {
                store.dispatch(
                    showPopup({
                        type: "ERROR",
                        message: error.message,
                    })
                );
            }
        }
    };
}
