import ChatListScreen from "@screens/messages/ChatListScreen"
import ChatScreen from "@screens/messages/ChatScreen"
import ContactsListScreen from "@screens/messages/ContactsListScreen"
import ContactFormScreen from "@screens/messages/ContactFormScreen"
import SelectCurrencyActionsScreen from "@screens/messages/actions/SelectCurrencyActionsScreen"
import AddressScreen from "@screens/messages/actions/AddressScreen"
import OrderScreen from "@screens/messages/actions/OrderScreen"
import NetworkOptionScreen from "@screens/messages/actions/NetworkOptionScreen"
import SendCryptoScreen from "@screens/messages/actions/SendCryptoScreen"
import ConfirmSendCryptoScreen from "@screens/messages/actions/ConfirmSendCryptoScreen"
import ProfileImageScreen from "@screens/messages/ProfileImage"
import WarningSendCryptoScreen from "@screens/messages/actions/WarningSendCryptoScreen"
import NavigationInstantiator from "./helpers/NavigationInstantiator"
import SelectNftActionsScreen from "@screens/messages/actions/SelectNftActionsScreen"
import { ProfileNavigator } from "./ProfileNavigator"
import ConfirmTransferScreen from "@screens/nfts/ConfirmTransferScreen"
import SelectRedeemableActionsScreen from "@screens/messages/actions/SelectRedeemableActionsScreen"
import ConfirmTransferRedeemableScreen from "@screens/experiences/ConfirmTransferRedeemableScreen"
import ConfirmTtransferRedeemableExtendedScreen from "@screens/messages/actions/ConfirmTtransferRedeemableExpendedScreen"
import { ExperienceNavigator } from "./ExperienceNavigator"
import SelectAssetActionsScreen from "@screens/messages/actions/SelectAssetActionsScreen"
import ConfirmTtransferAssetExpendedScreen from "@screens/messages/actions/ConfirmTtransferAssetExpendedScreen"
import { NTFNavigator } from "./NFTNavigator"


const MessagesNavigatorScreens = {
    MessagesList: {
        routeName: "MessagesList",
        title: "Crypto Chat",
        component: ChatListScreen,
    },
    Chat: {
        routeName: "Chat",
        title: "Chat",
        component: ChatScreen,
    },
  
    Contacts: {
        routeName: "Contacts",
        title: "Contacts",
        component: ContactsListScreen,
    },
    ContactForm: {
        routeName: "ContactForm",
        title: "Edit contact",
        component: ContactFormScreen,
    },
    SelectCurrencyActions: {
        routeName: "SelectCurrencyActions",
        title: "Select Currency",
        component: SelectCurrencyActionsScreen,
    },
    SelectNftActions: {
        routeName: "SelectNftActions",
        title: "Select NFT",
        component: SelectNftActionsScreen,
    },
    SelectRedeemableActions: {
        routeName: "SelectRedeemableActions",
        title: "Select Redeemable",
        component: SelectRedeemableActionsScreen,
    },
    SelectAssetActions: {
        routeName: "SelectAssetActions",
        title: "Select Asset",
        component: SelectAssetActionsScreen,
    },
    AddressScreen: {
        routeName: "AddressScreen",
        title: "Address",
        component: AddressScreen,
    },
    OrderScreen: {
        routeName: "OrderScreen",
        title: "Order Screen",
        component: OrderScreen,
    },
    SendCrypto: {
        routeName: "SendCrypto",
        title: "Send Crypto",
        component: SendCryptoScreen,
    },
    ConfirmSendCryptoScreen: {
        routeName: "ConfirmSendCryptoScreen",
        title: "Confirm Send",
        component: ConfirmSendCryptoScreen,
    },
    ConfirmTransferNFTScreen: {
        routeName: "ConfirmTransferNFTScreen",
        title: "Confirm Transfer",
        component: ConfirmTransferScreen,
    },
    NetworkOption: {
        routeName: "NetworkOption",
        title: "Network Option",
        component: NetworkOptionScreen,
    },
    WarningSendCrypto: {
        routeName: "WarningSendCrypto",
        title: "Warning",
        component: WarningSendCryptoScreen,
    },
    Profile: {
        routeName: "Profile",
        title: "Profile",
        component: ProfileNavigator,
    },
    ConfirmTransferRedeemable: {
        routeName: "ConfirmTransferRedeemable",
        title: "ConfirmTransferRedeemable",
        component: ConfirmTtransferRedeemableExtendedScreen,
    },

    ConfirmTransferAsset: {
        routeName: "ConfirmTransferAsset",
        title: "ConfirmTransferAsset",
        component: ConfirmTtransferAssetExpendedScreen,
    },
    Experience: {
        routeName: "Experience",
        title: "Experience",
        component: ExperienceNavigator,
    },
    MessagesNFT: {
        routeName: "MessagesNFT",
        title: "NFT",
        component: NTFNavigator,
    },
}

const MessagesNavigator = NavigationInstantiator(MessagesNavigatorScreens)

export {MessagesNavigator, MessagesNavigatorScreens}
