import React, { Component } from "react";
import { View, Text, StyleSheet, Button, Dimensions, Platform } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import Row from "@base/Row";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import RNSwipeVerify from "react-native-swipe-verify";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import Card from "@base/Card";
import store from "@store/index";
import { confirmTransaction, getTransactions } from "@store/actions/wallet";

import { showModal, showPopup } from "@store/actions/global";
import Wallet from "@core/wallet/Wallet";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import InlineButton from "@base/InlineButton";
import WarningCard from "@base/WarningCard";
import Label from "@base/Label";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { getColorOpacity } from "@utils/helpers/global/global";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    fiatCurrency: FiatCurrency;
    currentOperation: any;
}

interface State {
    amount: number;
    sendingTo: string;
    swapAmount: string;
    sendingFrom: string;
    fee: number;
    isVerified: boolean;
    diff: number;
}

const { t } = i18n;

export class _ConfirmExchangeScreen extends Component<Props, State> {
    private from: Currency;
    private to: Currency;
    private fiatCurrency: FiatCurrency;
    private swipeVerify: any;

    constructor(props: Props) {
        super(props);
        this.from = props.currentOperation.data.from;
        this.to = props.currentOperation.data.to;
        this.fiatCurrency = this.props.fiatCurrency;
        const skeleton = this.from.getImplementation().parseSkeleton(this.props.currentOperation.skeleton);
        this.state = {
            amount: skeleton.amount,
            sendingTo: skeleton.sendingTo,
            swapAmount: skeleton.swapAmount,
            sendingFrom: skeleton.sendingFrom,
            fee: skeleton.fee,
            isVerified: false,
            diff: this.calculateDiff(skeleton.amount, this.from, Number(skeleton.swapAmount), this.to),
        };

        this.onConfirm = this.onConfirm.bind(this);
    }

    calculateDiff(amount: number, from: Currency, swapAmount: number, to: Currency) {
        const o = to.getFiat() * swapAmount;
        const i = from.getFiat() * amount;
        const diff = (o - i) / i;
        if (diff < -0.05) {
            store.dispatch(
                showModal({
                    title: t("warning"),
                    message: t("swap_warning", { percentage: (diff * -100).toFixed(2) }),
                }),
            );
        }
        return diff;
    }

    onConfirm() {
        this.setState({ isVerified: true });
        store.dispatch(
            confirmTransaction({
                currency: this.from.getUnderlyingCurrency(),
                skeleton: this.props.currentOperation.skeleton,
                onSuccess: () => {
                    store.dispatch(getTransactions(this.from));
                    store.dispatch(showPopup({ type: "SUCCESS" }));
                    this.props.navigation.navigate(WalletNavigatorScreens.Wallet.routeName);
                },
                onError: (error) => {
                    console.warn(error);
                    store.dispatch(showPopup({ type: "ERROR", message: t("an_error_has_occurred") }));
                    this.setState({ isVerified: false });
                    try {
                        this.swipeVerify.reset();
                    } catch (e) {
                        console.warn(e);
                    }
                },
            }),
        );
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("confirm_swap")} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    <View>
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("amount")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.state.amount} {this.from.getPName()}
                            </BoldText>
                            <Label
                                text={`$ ${this.from.toFiat(Number(this.state.amount))}`}
                                color={colors.labelText}
                                align="center"
                                fontSize={12}
                            />
                        </Card>

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("sending_to")}
                            </RegularText>
                            <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                {this.state.sendingTo}
                            </BoldText>
                        </Card>

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("you_will_receive")}
                            </RegularText>
                            <BoldText fixedWidth={250} color={colors.text} fontSize={14} align="center">
                                + {this.state.swapAmount} {this.to.getPName()}
                                <NetworkCurrencySymbol
                                    styles={{
                                        minWidth: 40,
                                        paddingHorizontal: 8,
                                    }}
                                    currency={this.to}
                                ></NetworkCurrencySymbol>
                            </BoldText>
                            <Label
                                text={`$ ${this.to.toFiat(Number(this.state.swapAmount))}`}
                                color={colors.labelText}
                                align="center"
                                fontSize={12}
                            />
                        </Card>

                        <Card style={styles.card}>
                            <RegularText color={colors.text} align="center">
                                {t("fee")}
                            </RegularText>
                            <BoldText color={colors.text} align="center">
                                {this.state.fee}{" "}
                                {this.from.isSmart()
                                    ? this.from.getPName()
                                    : this.from.getUnderlyingCurrency().getPName()}
                            </BoldText>
                            <Label
                                text={`$ ${this.from.getUnderlyingCurrency().toFiat(Number(this.state.fee))}`}
                                color={colors.labelText}
                                align="center"
                                fontSize={12}
                            />
                        </Card>
                    </View>

                    <Row>
                        <View style={styles.swipContainer}>
                            {Platform.OS !== "web" ? (
                                <RNSwipeVerify
                                    ref={(ref) => (this.swipeVerify = ref)}
                                    width="100%"
                                    buttonSize={50}
                                    borderColor={colors.secondary}
                                    buttonColor={this.state.isVerified ? colors.secondary : colors.secondary}
                                    backgroundColor={colors.background}
                                    textColor={colors.white}
                                    borderRadius={50}
                                    okButton={{ visible: true, duration: 400 }}
                                    onVerified={this.onConfirm}
                                    icon={
                                        <Icon
                                            name={this.state.isVerified ? "check" : "right"}
                                            size={28}
                                            color={colors.white}
                                            align="center"
                                        />
                                    }
                                >
                                    <RegularText color={colors.text}>
                                        {" "}
                                        {this.state.isVerified ? t("confirmed") : t("slide_to_confirm")}
                                    </RegularText>
                                </RNSwipeVerify>
                            ) : (
                                <InlineButton onPress={this.onConfirm} title={t("confirm")} />
                            )}
                        </View>
                    </Row>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    card: {},
    swipContainer: {
        flex: 1,
        height: 58,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmExchangeScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmExchangeScreen);

export default ConfirmExchangeScreen;
