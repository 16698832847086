import React, { Component } from "react";
import {
  BackHandler,
  Platform,
  RefreshControl,
  StyleSheet,
  View,
  FlatList,
} from "react-native";
import { colors } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import store from "@store/index";
import { selectChat, setUnreadMessages } from "@store/actions/chat.actions";
import { Chat } from "@custom-types/Chat";
import { ChatService } from "@core/services/ChatService";
import { Contact } from "@custom-types/Contact";
import SearchAllBar from "@components/chat/SearchAllBar";
import { Client } from "@custom-types/Client";
import { ContactService } from "@core/services/ContactService";
import { hideActions, showActions } from "@store/actions/header";
import { loading, ready, setSearch } from "@store/actions/global";
import {
  selectMessagesFromChat,
  selectOrderedAndFilteredChats,
} from "@store/reducers/chat.reducer";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { ChatMessageCard } from "@components/cards/ChatMessageCard";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";

interface Props {
  status: string;
  navigation: NavigationType;
  selectedChat: Chat;
  chats: Array<Chat>;
  client: Client;
  search: string;
}

interface State {
  aliasClients: Array<any>;
  contacts: Array<Contact>;
  chats: Array<Chat>;
  selectedChat: Chat;
  syncing: boolean;
  list: Array<any>;
}

const { t } = i18n;

export class _ChatListScreen extends Component<Props, State> {
  socket: any;
  alias: any;
  focusListener: any;
  chatService: ChatService;
  contactService: ContactService;
  backHandler: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      chats: this.props.chats,
      contacts: [],
      aliasClients: [],
      selectedChat: null,
      syncing: true,
      list: this.props.chats,
    };

    this.deselectChat = this.deselectChat.bind(this);
    this.onEndReached = this.onEndReached.bind(this);
    this.onPressChat = this.onPressChat.bind(this);
    this.onLongPressChat = this.onLongPressChat.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    if (Platform.OS != 'web'){
      this.backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
        this.setState({ selectedChat: null });
        store.dispatch(hideActions());
        return false;
      });
    }
  }

  async componentDidMount() {
    //this.props.status == "disconnected" ? store.dispatch(loading()) : store.dispatch(ready())
    this.chatService = await ChatService.getInstance();
    this.contactService = await ContactService.getInstance();
    await this.contactService.getContactsList();
    store.dispatch(setSearch(""));
    this.setState({
      chats: this.props.chats,
      list: this.props.chats,
      syncing: false,
    });

    this.focusListener = this.props.navigation.addListener("focus", () => {
      store.dispatch(setSearch(""));

      this.forceUpdate();
    });

    this.chatService.updateAllMessagesToReceived();
  }

  componentWillUnmount() {
    try {
      this.backHandler.remove();
      this.focusListener.remove();
    } catch (e) { }
    store.dispatch(setSearch(""));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      //this.props.status == "disconnected" ? store.dispatch(loading()) : store.dispatch(ready())
    }

    if (prevProps.chats != this.props.chats) {
      this.setList({ chats: this.props.chats });
    }
  }

  onRefresh = async () => {
    this.setState({ syncing: true });
    //this.props.status == "disconnected" ? store.dispatch(loading()) : store.dispatch(ready())
    this.chatService = await ChatService.getInstance();
    await this.chatService.refresh();
    this.contactService = await ContactService.getInstance();
    await this.contactService.getContactsList();
    store.dispatch(setSearch(""));
    this.setState({
      chats: this.props.chats,
      list: this.props.chats,
      syncing: false,
    });
    this.chatService.updateAllMessagesToReceived();
  };

  onPressChat(id) {
    store.dispatch(setSearch(""));
    const chat = this.state.chats.find((item) => item.id === id);
    if (chat) {
      this.deselectChat();
      store.dispatch(selectChat(Object.assign({}, chat)));
      this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName);
    }
  }

  deselectChat() {
    this.setState({ selectedChat: null });
    store.dispatch(hideActions());
  }

  selectChat(chat: Chat) {
    this.setState({ selectedChat: chat });
    store.dispatch(showActions());
  }

  onLongPressChat(chat: Chat) {
    if (this.state.selectedChat && this.state.selectedChat.id === chat.id) {
      this.deselectChat();
    } else {
      this.selectChat(chat);
    }
  }

  onEndReached = (info: { distanceFromEnd: number }) => {
    if (!this.state.aliasClients.length && this.state.chats.length > 9) {
      this.chatService.getAll().then();
    }
  };

  renderItem = ({ item, index }) => (
    <ChatMessageCard
      item={item}
      navigation={this.props.navigation}
      onLongPressChat={this.onLongPressChat}
      onPressChat={this.onPressChat}
      selectedChat={this.state.selectedChat}
    />
  );

  setList(items) {
    let chats = items.chats || this.state.chats;
    let contacts = items.contacts || this.state.contacts;
    let aliasClients = items.aliasClients || this.state.aliasClients;

    let list: any = chats;
    if (contacts.length) {
      list = list
        .concat([
          { type: "separator", title: "Contacts", id: "contact_separator" },
        ])
        .concat(contacts);
    }
    if (aliasClients.length) {
      list = list
        .concat([
          {
            type: "separator",
            title: t("global_search"),
            id: "alias_separator",
          },
        ])
        .concat(aliasClients);
    }

    this.setState({
      chats,
      contacts,
      aliasClients,
      list,
    });
  }

  render() {
    return (
      <ScreenWrapper>
        <Header
          title={t("chat")}
          type={HeaderType.Light}
          {...this.props}
          leftAvatar={{
            onPress: () => {
              null
              {
                this.props.navigation.navigate(
                  MessagesNavigatorScreens.Profile.routeName
                );
              }
            },
            uri:
              this.props.client &&
                this.props.client?.profileImagePath &&
                this.props.client?.profileImagePath !== null
                ? this.props.client?.profileImagePath.thumbnail
                : "",
            alias:
              this.props.client &&
                this.props.client?.alias !== null &&
                this.props.client?.alias
                ? this.props.client?.alias
                : "",
            size: 35,
          }}
          rightBtn={{
            onPress: () => {
              this.props.navigation.navigate(
                MessagesNavigatorScreens.Contacts.routeName
              );
            },
            icon: "person-list",
            iconType: "custom",
            size: 24,
          }}
          actions={[
            {
              onPress: async () => {
                await this.chatService.remove(this.state.selectedChat.id);
                this.deselectChat();
              },
              icon: "trash",
              iconType: "custom",
            },
          ]}
          hideActionsCallback={this.deselectChat}
        />

        <SearchAllBar
          setChats={(chats) => this.setList({ chats })}
          setContacts={(contacts) => this.setList({ contacts })}
          setAliasClient={(aliasClients) => this.setList({ aliasClients })}
        />

        <Container style={styles.container}>
          <FlatList
          showsHorizontalScrollIndicator={false}
            data={this.state.list}
            renderItem={this.renderItem}
            keyExtractor={(item) => item.id || item._id}
            contentContainerStyle={{ paddingBottom: 20 }}
            refreshControl={
              <RefreshControl
                tintColor={colors.text}
                onRefresh={this.onRefresh}
                refreshing={this.state.syncing}
              />
            }
            initialNumToRender={10}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={1}
          />
        </Container>
      </ScreenWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.chat.status,
    chats: selectOrderedAndFilteredChats(state),
    messages: state.message.items,
    client: state.auth.client,
    search: state.global.search,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const ChatListScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ChatListScreen);

export default ChatListScreen;

const styles = StyleSheet.create({
  hidden: {
    display: "none",
  },
  wrapper: {
    flex: 1,
    zIndex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  background: {
    position: "absolute",
    backgroundColor: colors.primary,
    opacity: 0.8,
    height: "100%",
    width: "100%",
  },
  input: {
    flex: 1,
    paddingVertical: 10,
    paddingHorizontal: 40,
  },
  icon: {
    position: "absolute",
    left: 10,
  },
  button: {
    position: "absolute",
    width: 30,
    height: 30,
    right: 15,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    borderColor: "white",
    borderWidth: 1,
  },
  container: {
    flex: 1,
    marginTop: 5,
  },
});
