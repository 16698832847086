import React, {Component} from "react"
import {View, Text, StyleSheet, Button, Dimensions} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import Row from "@base/Row"
import {colors} from "@styles/globalStyles"
import RegularText from "@base/RegularText"
import {connect} from "react-redux"
import Currency from "@core/currencies/Currency"
import {FiatCurrency} from "@core/fiat/FiatCurrencies"
import RNSwipeVerify from "react-native-swipe-verify"
import Icon from "@components/icons"
import BoldText from "@base/BoldText"
import Card from "@base/Card"
import store from "@store/index"
import {confirmTransaction, getTransactions} from "@store/actions/wallet"

import {loading, ready, showPopup} from "@store/actions/global"
import Wallet from "@core/wallet/Wallet"
import {ChatService} from "@core/services/ChatService"
import {CustomMessageModel} from "@custom-types/CustomMessageModel"
import {CustomMessageType} from "@custom-types/CustomMessageType"
import {setCustomMessage} from "@store/actions/chat.actions"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import InlineButton from "@base/InlineButton"
import * as WebBrowser from "expo-web-browser"
import {SimplexService} from "@core/services/SimplexService"
import i18n from "@i18n/i18n"
import {KriptonService} from "@core/services/KriptonService"
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator"
import { WalletNavigatorScreens } from "@navigation/WalletNavigator"

interface Props {
    navigation: NavigationType
    currencies: Array<Currency>
    selectedCurrency: string
    fiatCurrency: FiatCurrency
    currentOperation: any
}

interface State {}
const {t} = i18n
export class _ConfirmBuyScreen extends Component<Props, State> {
    private currency: Currency
    private fiatCurrency: FiatCurrency
    private kriptonService: KriptonService
    focusListener: any

    constructor(props: Props) {
        super(props)
        this.currency = Wallet.getInstance().findCurrencyById(props.selectedCurrency)
        this.fiatCurrency = props.fiatCurrency
        this.kriptonService = KriptonService.getInstance()
        this.onPressNext = this.onPressNext.bind(this)
        this.checkCurrentOperation = this.checkCurrentOperation.bind(this)
    }

    async componentDidMount() {
     
        this.focusListener = this.props.navigation.addListener(
            "focus",
                this.checkCurrentOperation
        );

    }

    checkCurrentOperation () {
        if (!this.props.currentOperation){
            this.props.navigation.navigate(WalletNavigatorScreens.Wallet.routeName)
        }
    }

    
    componentWillUnmount() {
        try {
            this.focusListener.remove()
        } catch(e){}
    }

    onPressNext = async () => {
        store.dispatch(loading())
        const result = await this.kriptonService.getPayment(this.props.currentOperation.data.data)
        store.dispatch(ready())
        this.props.navigation.navigate(KriptonMarketNavigatorScreens.KriptonMarket.routeName)
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={"Checkout"} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>
                    {this.props.currentOperation && this.props.currentOperation.type == "BUY" && (
                        <View style={{flex: 1, justifyContent: "space-between"}}>
                            <View>
                                <Card style={styles.card}>
                                    <RegularText color={colors.text} align='center'>
                                        {t("you_will_pay")}
                                    </RegularText>
                                    <BoldText color={colors.text} align='center'>
                                        {this.props.currentOperation.data.fiatMoney.totalAmount}{" "}
                                        {this.props.currentOperation.data.fiatMoney.currency}
                                    </BoldText>
                                </Card>
                                <Card style={styles.card}>
                                    <RegularText color={colors.text} align='center'>
                                        {t("you_will_buy")}
                                    </RegularText>
                                    <BoldText color={colors.text} align='center'>
                                        {this.props.currentOperation.data.digitalMoney.amount}{" "}
                                        {this.props.currentOperation.data.digitalMoney.currency}
                                    </BoldText>
                                </Card>
                                <Card style={styles.card}>
                                    <RegularText color={colors.text} align='center'>
                                        {t("to_address")}
                                    </RegularText>
                                    <BoldText color={colors.text} align='center' fontSize={12}>
                                        {this.currency.getAddress()}
                                    </BoldText>
                                </Card>
                                <Card style={styles.card}>
                                    <RegularText color={colors.text} align='center'>
                                        {t("payment_method")}
                                    </RegularText>
                                    <BoldText color={colors.text} align='center'>
                                        {t("wire_transfer")}
                                    </BoldText>
                                </Card>
                            </View>
                        </View>
                    )}
                </Container>
                <Container style={{flex: 1, flexDirection: "column-reverse"}}>
                    <View style={{marginTop: 20}}>
                        <InlineButton
                            title={t("next")}
                            onPress={this.onPressNext}
                            style={{marginHorizontal: 0, marginBottom: 20}}
                        />
                    </View>
                </Container>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    card: {},
})

const mapStateToProps = (state) => {
    return {
        currencies: state.wallet.currencies,
        selectedCurrency: state.wallet.selectedCurrency,
        fiatCurrency: state.wallet.fiatCurrency,
        currentOperation: state.wallet.currentOperation,
    }
}

const mapDispatchToProps = (dispatch) => ({})

const ConfirmBuyScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmBuyScreen)

export default ConfirmBuyScreen
