import {
    READY,
    LOADING,
    PROGRESIVE_LOADER,
    SHOW_POPUP,
    HIDE_POPUP,
    SHOW_MODAL,
    HIDE_MODAL,
    SHOW_SNACKBAR,
    HIDE_SNACKBAR,
    SHOW_MODAL_BOTTOM,
    HIDE_MODAL_BOTTOM,
    ACCEPT_TERMS,
    SEARCH,
    REDIRECTION,
    SET_CURRENT_TASK,
    RESET,
    MESSAGE_LOADER,
    RELOAD,
    READY_TO_RENDER,
    WEB_SPLASH,
    SET_AUTHENTICATION,
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    SHOW_SCANNER,
    HIDE_SCANNER,
    SET_PRIORITY_LOADING,
    SHOW_POPUP_MESSAGE,
    HIDE_POPUP_MESSAGE,
    MAINTENACE_MODE,
} from "@store/actions/global";

const initalSatate = {
    readyToRender: false,
    webSplash: true,
    maintenanceMode: false,
    loading: false,
    priorityLoading: true,
    popup: null,
    snackbar: null,
    search: "",
    redirection: null,
    currentTask: "",
    progresive_loader: false,
    progresive_loader_value: 0,
    messageLoader: "",
    reload: false,
    activeAuthentication: false,
    showSidebar: false,
    showScanner: false,
    showModalBottom: false,
    modalBottomOptions: null,
    popupMessage: null,
};

const globalReducer = (state = initalSatate, action) => {
    switch (action.type) {
        case READY:
            return {
                ...state,
                loading: false,
                progresive_loader: false,
                progresive_loader_value: 0,
                currentTask: "",
                messageLoader: "",
            };

        case LOADING:
            return {
                ...state,
                loading: true,
            };
        case SET_PRIORITY_LOADING:
            return {
                ...state,
                priorityLoading: action.loading,
            };

        case READY_TO_RENDER:
            return {
                ...state,
                readyToRender: true,
            };

        case SET_AUTHENTICATION:
            return {
                ...state,
                activeAuthentication: action.activeAuthentication,
            };

        case WEB_SPLASH:
            return {
                ...state,
                webSplash: false,
            };

        case MAINTENACE_MODE:
            return {
                ...state,
                maintenanceMode: true,
            };

        case PROGRESIVE_LOADER:
            return {
                ...state,
                loading: true,
                progresive_loader: true,
                progresive_loader_value: action.progresive_loader_value,
            };

        case MESSAGE_LOADER:
            return {
                ...state,
                loading: true,
                messageLoader: action.message,
            };

        case SHOW_POPUP:
            return {
                ...state,
                popup: action.options,
            };

        case HIDE_POPUP:
            return {
                ...state,
                popup: null,
            };

        case SHOW_MODAL:
            return {
                ...state,
                showModal: true,
                modalOptions: action.options,
            };

        case HIDE_MODAL:
            return {
                ...state,
                showModal: false,
            };

        case SHOW_SIDEBAR:
            return {
                ...state,
                showSidebar: true,
            };

        case HIDE_SIDEBAR:
            return {
                ...state,
                showSidebar: false,
            };

        case SHOW_SCANNER:
            return {
                ...state,
                showScanner: true,
            };

        case HIDE_SCANNER:
            return {
                ...state,
                showScanner: false,
            };

        case SHOW_SNACKBAR:
            return {
                ...state,
                snackbar: action.options,
            };

        case HIDE_SNACKBAR:
            return {
                ...state,
                snackbar: null,
            };

        case SHOW_MODAL_BOTTOM:
            return {
                ...state,
                showModalBottom: true,
                modalBottomOptions: action.options,
            };

        case HIDE_MODAL_BOTTOM:
            return {
                ...state,
                showModalBottom: false,
            };

        case SEARCH:
            return {
                ...state,
                search: action.search,
            };

        case SET_CURRENT_TASK:
            return {
                ...state,
                currentTask: action.task,
            };

        case REDIRECTION:
            return {
                ...state,
                redirection: action.redirection,
            };
        case RELOAD:
            return {
                ...state,
                reload: action.reload,
            };

        case SHOW_POPUP_MESSAGE:
            return {
                ...state,
                popupMessage: action.options,
            };

        case HIDE_POPUP_MESSAGE:
            return {
                ...state,
                popupMessage: null,
            };

        default:
            return state;
    }
};

export default globalReducer;
