import Currency, { IConstructorCurrency } from '@core/currencies/Currency';
import { Networks } from '@core/network/Networks';
import Constants from "expo-constants"
import ETHImplementation from '@core/currencies/ethereum/eth/ETHImplementation';
import { ProviderType } from '@custom-types/ProviderType';
import { isCurrencyEnabled } from '@core/utils/CurrencyEnabled';

export default class ARB extends Currency {
    constructor(
        parameters: IConstructorCurrency
    ) {
        super ({
            name: 'ARB',
            simbol: 'ARB',
            fullName: 'ARB',
            decimals: 18,
            implementation: ETHImplementation,
            chainId: parameters.testnet ? 421613 : 42161,
            networkID: parameters.testnet ? 61 : 60,
            color: '#13a0f2',
            icon: 'arbitrum',
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet?Networks.ethereum.testnet:Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "ARB", true),
            blockchain: 'arbitrum',
            buyableBy: [ProviderType.ONRAMPER],
            walletConnect: false,
      
        })
    }

}
