import Currency, { IConstructorCurrency } from '@core/currencies/Currency';
import { Networks } from '@core/network/Networks';
import TRXImplementation from './TRXImplementation';
import Constants from "expo-constants"
import { isCurrencyEnabled } from '@core/utils/CurrencyEnabled';

export default class TRX extends Currency {
    constructor(
        parameters: IConstructorCurrency
    ) {
        super ({
            name: 'TRX',
            simbol: 'TRX',
            fullName: 'Tron',
            decimals: 6,
            implementation: TRXImplementation,
            networkID: parameters.testnet?195:195,
            color: '#f8433f',
            icon: 'trx',
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet?Networks.ethereum.testnet:Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "TRX", true),
            blockchain: 'tron'
        })
    }

}
