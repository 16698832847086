import {colors} from "@styles/globalStyles"


export function getPercentagePriceVariationDaily(data: any) {
    if (data.length > 1) {
        const percent: number = ((data[data.length - 1].price - data[0].price) / data[0].price) * 100
        if (!isNaN(percent)) {
            return `${percent > 0 ? "+" : ""}${percent.toFixed(2)}%`
        }
    }
}

export function getPercentagePriceVariationColor(data: any) {
    if (data.length > 1) {
        const percent: number = ((data[data.length - 1].price - data[0].price) / data[0].price) * 100
        if (!isNaN(percent)) {
            var number = Math.round(percent *100) / 100
            var color = colors.grey
      
            number > 0.02 ? color = colors.green : colors.grey
            number < -0.02 ? color = colors.red : colors.grey
            return color
        }
    }
  
}
