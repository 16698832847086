import WebViewBase from "@components/webview/WebViewBase";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import i18n from "@i18n/i18n";
import { hideModal, loading, ready, showModal, showPopup } from "@store/actions/global";
import store from "@store/index";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import Web3 from "web3";

const { t } = i18n;

export enum METHODS {
    "available" = "available",
    "connect" = "connect",
    "personalSign" = "personalSign",
    "transactionSign" = "transactionSign",
}

export enum TYPES {
    "request" = "request",
    "response" = "response",
}

export default class XOConnectService {
    private webView: WebViewBase;
    private client: Client;
    private processedRequests = new Set<string>();

    constructor(ref: WebViewBase, client: Client) {
        this.webView = ref;
        this.client = client;
    }

    static xoConnectListener = (WebViewBaseRef: WebViewBase) => {
        return `(function() {
            window.XOConnect = true;
            window.addEventListener(
                "message",
                (event) => {
                    if (event.data?.length) {
                        window.ReactNativeWebView.postMessage(event.data);
                    }
                },
                false
            );   
    })();`;
    };

    send(id: string, data: any) {
        const response = JSON.stringify({
            id,
            type: "receive",
            data,
        });
        this.processedRequests.add(id);
        this.webView.sendPostMessage(response);
    }

    cancel(id: string) {
        const response = JSON.stringify({
            id,
            type: "cancel",
        });
        this.processedRequests.add(id);
        this.webView.sendPostMessage(response);
    }

    async onConnect(id) {
        let currencies = [];
        const client = this.client;

        Wallet.getInstance()
            .getCurrencies()
            .map((c: Currency) => {
                if (c.getUnderlyingCurrencyID() == c.getId()) {
                    currencies.push({
                        id: c.getId(),
                        address: c.getAddress(),
                    });
                }
            });

        const currency = Wallet.getInstance().findCurrencyById("eth");
        const signature = await currency.signMessage(`xoConnect-${id}`);

        this.send(id, {
            client: {
                _id: client._id,
                alias: client?.alias,
                profileImagePath: client?.profileImagePath,
                currencies: currencies,
                signature,
            },
        });
    }

    onCancel(id) {
        store.dispatch(hideModal());
        if (!this.processedRequests.has(id)) {
            this.cancel(id);
        }
    }

    onPersonalSign(id: string, data: any) {
        const currency = Wallet.getInstance().findCurrencyById("eth");
        const title = "";
        const message = data;
        store.dispatch(
            showModal({
                avatar: "",
                currency: {
                    backgroundColor: currency.getColor(),
                    icon: currency.getIcon(),
                },
                title: trimHelper(title, 50),
                subtitle: t("personal_sign_subtitle"),
                message: t("personal_sign_description"),
                description: `${t("message")}: ${message}`,
                btnTitle: t("wallet_connect_sign"),
                onPress: async () => {
                    store.dispatch(hideModal());
                    const signedMessage = await currency.signMessage(message);
                    const data = { txs: signedMessage };
                    this.send(id, data);
                },
                onPressClose: () => {
                    store.dispatch(hideModal());
                    this.cancel(id);
                },
            })
        );
    }

    async onTransactionSign(id: string, data: any, currencyId: string) {
        store.dispatch(loading());
        const currency = Wallet.getInstance().findCurrencyById(currencyId);

        try {
            const transaction = await currency.newTransaction({
                currency: currency,
                amount: currency.fromDecimals(data.value || 0),
                addressFrom: data.from,
                addressTo: data.to,
                data: data.data,
            });

            const skeleton = await currency.getImplementation().parseSkeleton(transaction.data);
            store.dispatch(
                showModal({
                    currency: {
                        backgroundColor: currency.getColor(),
                        icon: currency.getIcon(),
                    },
                    title: "",
                    subtitle: t("eth_sendTransaction_subtitle"),
                    description: t("eth_sendTransaction", {
                        from: trimHelper(skeleton.sendingFrom, 30, true) || "",
                        to: trimHelper(skeleton.sendingTo, 30, true) || "",
                        gas: skeleton.fee || "",
                    }),
                    question: `${t("amount")} \n${skeleton.amount} ${currency.getPName()}`,
                    btnTitle: t("confirm"),
                    onPress: () => this.ethSendTransaction(id, currency, transaction),
                    onPressClose: () => this.onCancel(id),
                })
            );
        } catch (e) {
            console.warn(e);
            this.onCancel(id);
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
        }
        store.dispatch(ready());
    }

    async ethSendTransaction(id: string, currency: Currency, transaction: any) {
        store.dispatch(hideModal());
        store.dispatch(loading());
        try {
            const res = await currency.sendTransaction(transaction.data);
            const data = { id, result: res?.data?.hash, jsonrpc: "2.0" };
            this.send(id, data);
            store.dispatch(showPopup({ type: "SUCCESS" }));
        } catch (e: any) {
            this.onCancel(id);
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: e.response.data.message || t("an_error_has_occurred"),
                })
            );
        }
        store.dispatch(ready());
    }

    handleRequests(payload) {
        if (payload?.nativeEvent?.data) {
            try {
                const resp = JSON.parse(payload?.nativeEvent?.data);
                if (resp?.type == TYPES.response) return;
                if (resp?.type == "cancel") {
                    this.onCancel(resp.id);
                }
                switch (resp?.method) {
                    case METHODS.connect:
                        this.onConnect(resp.id);
                        break;
                    case METHODS.personalSign:
                        this.onPersonalSign(resp.id, resp.data);
                        break;
                    case METHODS.transactionSign:
                        this.onTransactionSign(resp.id, resp.data, resp.currency);
                        break;
                }
            } catch (e) {
                console.warn(e);
            }
        }
    }
}
