import Currency, { IConstructorCurrency } from "@core/currencies/Currency"
import ETHImplementation from "@core/currencies/ethereum/eth/ETHImplementation"
import { FiatCurrency } from "@core/fiat/FiatCurrencies"
import { Networks } from "@core/network/Networks"
import { ProviderType } from "@custom-types/ProviderType"
import Constants from "expo-constants"
import { isCurrencyEnabled, isNFTEnabled } from "@core/utils/CurrencyEnabled"

export default class ETH extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            name: "ETH",
            simbol: "ETH",
            fullName: "Eth",
            decimals: 18,
            implementation: ETHImplementation,
            chainId: parameters.testnet ? 3 : 1,
            networkID: parameters.testnet ? 61 : 60,
            blockchain: "ethereum",
            color: "#627eea",
            icon: require("../../icons/ethereum.png"),
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "ETH", true),
            buyableBy: [ProviderType.KRIPTONMARKET, ProviderType.SIMPLEX, ProviderType.ONRAMPER, ProviderType.COINCAEX],
            sellableBy: [ProviderType.COINCAEX],
            walletConnect: true,
            nftSupport: isNFTEnabled("ETH", false),

        })
    }
}
