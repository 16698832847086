import axios from "axios"
import { AuthService } from "@core/services/AuthService"
import store from "@store/index"
import { showPopup } from "@store/actions/global"
import { BaseModel } from "@custom-types/BaseModel";
import { getEnv } from "@utils/helpers/global/global";
import { ApiService } from "./ApiService";

interface Options {
    showPopup: boolean
}

export abstract class BaseResourceService {
    abstract resource: string;

    abstract saveEntity(entity);

    abstract setEntitiesStore(entity);

    abstract createModel(values);

    abstract getAllEntitiesStore(): Array<BaseModel>;

    protected defaultOptions: Options = {
        showPopup: true,
    }

    getResourceUrl(): string {
        return `${this.getBaseUrl()}${this.resource}`
    }

    getBaseUrl(): string {
        return `${getEnv('API_URL')}`
    }

    async all() {
        try {
            const resp = await axios.get(`${getEnv('API_URL')}${this.resource}`, {
                headers: await ApiService.getAuthHeaders(),
            })

            this.setEntitiesStore(resp.data);
        } catch (error) {
            return error
        }
    }

    async create(body: object, opts: Options = this.defaultOptions) {
        try {
            const resp = await axios.post(`${getEnv('API_URL')}${this.resource}`, body, {
                headers: await ApiService.getAuthHeaders(),
            })


            const model = this.createModel(resp.data);
            this.saveEntity(model);

            if (opts.showPopup) {
                store.dispatch(showPopup({type: "SUCCESS"}))
            }

            return model
        } catch (error) {
            throw error.response.data.message
        }
    }

    public static async getAuthHeaders(tokenString?) {
        const token = (tokenString) ? tokenString : store.getState().auth.token.token;

        return {"Authorization": token}
    }

    async update(body: object, opts: Options = this.defaultOptions) {
        try {
            const resp = await axios.patch(`${getEnv('API_URL')}${this.resource}`, body, {
                headers: await ApiService.getAuthHeaders(),
            })

            const entity = this.createModel(resp.data)
            const entities = this.getAllEntitiesStore()

            const idx = entities.findIndex((c) => c._id === entity.id)
            if (idx > -1) {
                entities[idx] = entity
            }

            this.setEntitiesStore([...entities])

            if (opts.showPopup) {
                store.dispatch(showPopup({type: "SUCCESS"}))
            }

            return entities
        } catch (error) {
            throw error.response.data.message
        }
    }

    async delete(id, opts: Options = this.defaultOptions) {
        try {
            const resp = await axios.delete(`${getEnv('API_URL')}${this.resource}/${id}`, {
                headers: await ApiService.getAuthHeaders(),
            })

            const entities = [...this.getAllEntitiesStore()]
            const idx = entities.findIndex((c) => c._id === id)

            if (idx > -1) {
                entities.splice(idx, 1)
            }

            this.setEntitiesStore([...entities])

            if (opts.showPopup) {
                store.dispatch(showPopup({type: "SUCCESS"}))
            }

            return entities
        } catch (error) {
            throw error.response.data.message
        }
    }
}
