import React, { Component } from "react";
import { ScrollView, StyleSheet,  View } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Row from "@base/Row";
import Icon from "@components/icons";
import { colors, settings } from "@styles/globalStyles";
import ImageBase from "@components/image/ImageBase";
import { Camera } from "expo-camera";
import CircleButton from "@base/CircleButton";
import * as ImagePicker from "expo-image-picker";
import BotCard from "@base/BotCard";
import InlineButton from "@base/InlineButton";
import store from "@store/index";
import { showSnackbar } from "@store/actions/global";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import FloatingTextInput from "@base/FloatingTextInput";
import { CoincaexBuy } from "../../helper/coincaex.helper";
import { Route } from "@react-navigation/native";
import { CoincaexService } from "@core/services/CoincaexService";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { sourceIsTooBig } from "@utils/helpers/nfts/nft.helper";
import { MediaTypes } from "@screens/nfts/newNFTScreen";
import * as Clipboard from "expo-clipboard";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    route: Route<string, { data: CoincaexBuy }>;
    currencies: Array<Currency>;
    selectedCurrency: string;
}

interface State {
    image: any;
    referencePayment: string;
}

const { t } = i18n;

export class _CoincaexBuyPaymentScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            image: null,
            referencePayment: "",
        };
    }

    componentDidMount() { }

    pickImage = async (camera: boolean) => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
        let permissionCamera = await Camera.requestCameraPermissionsAsync();
        let result = null;

        if (permissionResult.granted === false || permissionCamera.granted === false) {
            alert("Permission to access camera roll is required!");
            return;
        }

        if (!camera) {
            result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                base64: true,
                quality: 0.2,
            });
        } else {
            result = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                base64: false,
                quality: 0.2,
            });
        }

        const imageWeight = await sourceIsTooBig(result.assets[0].uri, MediaTypes.IMAGE)

        if (imageWeight) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("image_size_error") }));
        }

        if (result.assets && !imageWeight) {
            this.setState({ image: result.assets[0].uri });
        }
    };

    handleSubmit = async () => {
        if (this.state.image && this.state.referencePayment) {
            const resp = await CoincaexService.getInstance().uploadPaymentBase64(this.state.image);

            if (resp && !resp.error) {
                let data: CoincaexBuy = this.props.route?.params?.data;
                data.reference = this.state.referencePayment
                data.voucher = resp.url;
                this.props.navigation.navigate(CoincaexNavigatorScreens.BuyDetail.routeName, { data: data, confirmPurchase: true });
            }
        } else {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("complete_field_warning") }));
        }
    };

    handleInputChange = (value: string, field: string) => {
        this.setState({
            ...this.state,
            [field]: value,
        });
    };

    copyToClipboard = async (value) => {
        await Clipboard.setStringAsync(value);
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("receipt")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <ScrollView>
                        {!this.state.image &&
                            <View>
                                <BotCard message={t("payment_receipt")} />
                                <Card
                                    style={{
                                        alignItems: "flex-start",
                                        marginBottom: 10
                                    }}
                                >
                                    <BoldText fontSize={14} style={[styles.text, { marginBottom: 10 }]}>{t("transaction_detail")}</BoldText>
                                    <RegularText style={styles.text} fontSize={14}>{t("bank")}: {this.props.route?.params?.data.bankName}</RegularText>
                                    <PressableBase
                                        onPress={() => this.copyToClipboard(this.props.route?.params?.data.transactionDepositTotal)}
                                        style={{ paddingVertical: 2 }}
                                    >
                                        <Row style={{ alignItems: "baseline" }}>
                                            <RegularText style={styles.text} fontSize={14}>{t("account_number")}: {this.props.route?.params?.data.bankAccountNumber}</RegularText>
                                            <Icon
                                                size={14}
                                                name="content-copy"
                                                color={colors.white}
                                                style={{ marginLeft: 10 }}
                                            />
                                        </Row>
                                    </PressableBase>
                                    <RegularText style={styles.text} fontSize={14}>{t("total_to_deposit")}: {this.props.route?.params?.data.transactionDepositTotal} {this.props.route?.params?.data.bankAccountCurrency}</RegularText>
                                    <RegularText style={[styles.text, { marginTop: 10 }]} fontSize={14}>{t("fee_expenses")}.</RegularText>
                                </Card>
                            </View>
                        }
                        <FloatingTextInput
                            onChangeText={(text) => this.handleInputChange(text, "referencePayment")}
                            value={this.state.referencePayment}
                            label={t("deposit_reference")}
                            multiline={false}
                        />
                        {this.state.image ? (
                            <View
                                style={{
                                    maxWidth: 600,
                                    width: "100%",
                                    marginHorizontal: "auto",
                                }}
                            >
                                <View style={{ marginVertical: 20 }}>
                                    <ImageBase
                                        uri={this.state.image}
                                        autoSize={true}
                                        style={{
                                            borderRadius: settings.cardRadius,
                                            resizeMode: "cover",
                                        }}
                                    />

                                    <View
                                        style={{
                                            position: "absolute",
                                            alignSelf: "center",
                                            bottom: -25,
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: 300 / 4,
                                            height: 50,
                                        }}
                                    >
                                        <PressableBase
                                            onPress={() => this.pickImage(false)}
                                            style={{ marginRight: 10 }}
                                        >
                                            <Icon name="images" size={20} color={colors.white} style={styles.icon} />
                                        </PressableBase>
                                        <PressableBase onPress={() => this.pickImage(true)}>
                                            <Icon name="camera" size={20} color={colors.white} style={styles.icon} />
                                        </PressableBase>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View style={styles.container}>
                                <Row style={{ justifyContent: "center", width: "100%" }}>
                                    <CircleButton
                                        title={t("gallery")}
                                        icon="images"
                                        iconSize={26}
                                        onPress={() => this.pickImage(false)}
                                        style={{ marginHorizontal: 10 }}
                                    />
                                    <CircleButton
                                        title={t("camera")}
                                        icon="camera"
                                        iconSize={26}
                                        onPress={() => this.pickImage(true)}
                                        style={{ marginHorizontal: 10 }}
                                    />
                                </Row>
                            </View>
                        )}
                    </ScrollView>
                    <InlineButton
                        style={{ marginVertical: 20 }}
                        title={t("continue")}
                        onPress={() => this.handleSubmit()}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        height: 40,
        width: 40,
        borderRadius: 20,
        padding: 10,
        backgroundColor: colors.secondary,
        overflow: "hidden",
    },
    imagePlaceholderContainer: {
        marginVertical: 15,
        width: "100%",
        height: 150,
        borderColor: colors.grey,
        backgroundColor: colors.shadow,
        borderWidth: 1,
        borderRadius: settings.cardRadius,
        borderStyle: "dashed",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    container: {
        marginVertical: 20,
        padding: 20,
        borderRadius: settings.cardRadius,
        minWidth: 80,
        justifyContent: "center",
        borderWidth: 1,
        borderColor: colors.grey,
        borderStyle: "dashed",
    },
    text: {
        marginBottom: 5
    }
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexBuyPaymentScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexBuyPaymentScreen);

export default CoincaexBuyPaymentScreen;
