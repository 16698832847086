import React, { Component } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import PostCard from "./components/PostCard";
import SocialHeader from "./components/SocialHeader";
import Post from "@custom-types/Post";
import { NavigationType } from "@custom-types/NavigationType";
import SocialNetworkService from "./service/SocialNetworkService";
import Container from "@base/Container";
import store from "@store/index";
import {
    setDiscoverPosts,
    setMainPosts,
} from "@store/actions/social.actions";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";


interface Props {
    client: Client;
    navigation: NavigationType;
    mainPosts: Array<Post>;
    discoverPosts: Array<Post>;
    reloadPosts: boolean;
    selectedPost: Post
}

interface State { }

const { t } = i18n;

export class _PostDetailScreen extends Component<Props, State> {
    socialNetworkService: SocialNetworkService;

    constructor(props: Props) {
        super(props);
        this.socialNetworkService = SocialNetworkService.getInstance()
        this.state = {};
    }

    onFollow = async (clientID) => {
        const posts = this.props.mainPosts.map((item) => {
            if (item.author.clientID == clientID) {
                item.author.follow = !item.author.follow;
            }
            return item;
        });

        store.dispatch(setMainPosts(posts));

        this.setState({ warning: false });

        const discoveryPosts = this.props.discoverPosts.map((item) => {
            if (item.author.clientID == clientID) {
                item.author.follow = !item.author.follow;
            }
            return item;
        });
        store.dispatch(setDiscoverPosts(discoveryPosts));

        const follow = await this.socialNetworkService.followUser(clientID);
    };

    render() {
        return (
            <ScreenWrapper>
                <SocialHeader navigation={this.props.navigation} />
                <Container style={{ paddingHorizontal: 0, flex: 1 }}>
                    <ScrollView>
                        {this.props.selectedPost && <PostCard
                            isLikeable={true}
                            onFollow={this.onFollow}
                            post={this.props.selectedPost}
                            navigation={this.props.navigation}
                        />}
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        mainPosts: state.social.mainPosts,
        discoverPosts: state.social.discoverPosts,
        reloadPosts: state.social.reloadPosts,
        selectedPost: state.social.selectedPost
    };
};

const mapDispatchToProps = (dispatch) => ({});

const PostDetailScreen = connect(mapStateToProps, mapDispatchToProps)(_PostDetailScreen);

export default PostDetailScreen;

const styles = StyleSheet.create({});