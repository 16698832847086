import React, { Component } from "react";
import { FlatList, StyleSheet } from "react-native";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import Card from "@base/Card";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import CardButton from "@base/CardButton";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import { NavigationType } from "@custom-types/NavigationType";
import { CoincaexBankAccount, CoincaexFiatCurrencies } from "../helper/coincaex.helper";
import { Avatar } from "react-native-elements";
import { Route } from "@react-navigation/native";

interface Props {
    bank: CoincaexBankAccount;
    navigation?: NavigationType;
}

interface State {}

const { t } = i18n;

export class _BankCardComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        const bank = this.props.bank;

        return (
            <>
                <Card>
                    <Row style={styles.title}>
                        <BoldText fontSize={16}>{bank.bankName}</BoldText>
                        <Icon name="bank" color={colors.white} size={26}></Icon>
                    </Row>
                    <Row style={styles.container}>
                        <Avatar size={18} rounded source={CoincaexFiatCurrencies[bank.bankAccountCurrency]?.icon} />
                        <BoldText style={{ paddingLeft: 5 }} color={colors.text} fontSize={12}>
                            {CoincaexFiatCurrencies[bank.bankAccountCurrency]?.name}
                            <RegularText color={colors.text} fontSize={12}>{` (${
                                CoincaexFiatCurrencies[bank.bankAccountCurrency]?.fullName
                            })`}</RegularText>
                        </BoldText>
                    </Row>
                    <Row style={styles.container}>
                        <RegularText style={{ paddingRight: 5 }} color={colors.text} fontSize={12}>
                            {t("account_name")}:
                        </RegularText>
                        <BoldText color={colors.text} fontSize={12}>
                            {bank.bankAccountName}
                        </BoldText>
                    </Row>
                    <Row style={styles.container}>
                        <RegularText style={{ paddingRight: 5 }} color={colors.text} fontSize={12}>
                            {t("account_number")}:
                        </RegularText>
                        <BoldText color={colors.text} fontSize={12}>
                            {bank.bankAccountNumber}
                        </BoldText>
                    </Row>
                    <Row style={styles.container}>
                        <RegularText style={{ paddingRight: 5 }} color={colors.text} fontSize={12}>
                            {t("account_type")}:
                        </RegularText>
                        <BoldText color={colors.text} fontSize={12}>
                            {bank.bankAccountType}
                        </BoldText>
                    </Row>
                </Card>
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        marginBottom: 5,
    },
    title: {
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        marginBottom: 10,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const BankCardComponent = connect(mapStateToProps, mapDispatchToProps)(_BankCardComponent);

export default BankCardComponent;
