import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import RegularText from "@base/RegularText";

import i18n from "@i18n/i18n";
import CircleButton from "@base/CircleButton";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import Card from "@base/Card";
import { AuthType, Client } from "@custom-types/Client";
import SocialNetworkService from "../../screens/social/service/SocialNetworkService";
import AvatarBase from "@components/avatar/AvatarBase";
import Row from "@base/Row";
import BoldText from "@base/BoldText";
import Label from "@base/Label";
import { ProfileData } from "@screens/profile2.0/components/ProfileHeader";
import { IOAuthHistoryStorage } from "@utils/storage/storages/OAuthHistoryStorage";
import { colors } from "@styles/globalStyles";
import Icon from "@components/icons";

interface Props {
    data: IOAuthHistoryStorage;
    selected: string;
    isActive: boolean;
    recoverSeed?: (client: Client) => void;
    importSeed?: (seed: string, client: Client) => void;
    removeItem?: (seed: string) => void;
    selectItem?: (seed: string) => void;
    deselectItem?: () => void;
}

interface State {
    onCloud: boolean;
    client: Client;
}

const { t } = i18n;

export default class SeedCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
        this.state = {
            onCloud: !this.props.data.mnemonic,
            client: this.props.data.client,
        };
    }

    async componentDidMount() {
        if (!this.state.client && this.props.data?.clientId) {
            var clientData = await SocialNetworkService.getInstance().getUserProfile(this.props.data?.clientId);
            if (clientData) {
                this.setState({ client: clientData });
            }
        }
    }

    onPress() {
        if (this.state.onCloud) {
            this.props.recoverSeed(this.state.client);
        } else {
            this.props.importSeed(this.props.data.mnemonic, this.state.client);
        }
    }

    //@TODO: Hay un tema de compatibilidad, ahora se usa el mnemonic como clave, el tema que si es onCloud no existe el mnemonic
    //Se debería usarl el clientId como clave, para todas las wallet nuevas va a existir si o si un clientID
    render() {
        return (
            <>
                <Card
                    onPress={this.onPress}
                    left={
                        <Row style={{ justifyContent: "flex-start", alignItems: "center", alignContent: "center" }}>
                            {this.state.client != null && (
                                <View style={{ marginRight: 5 }}>
                                    <AvatarBase
                                        size={40}
                                        alias={this.state.client.alias}
                                        uri={this.state.client.profileImagePath?.thumbnail}
                                    ></AvatarBase>
                                </View>
                            )}
                            <View style={{ justifyContent: "center" }}>
                                {this.state.client != null ? (
                                    <View>
                                        <BoldText fontSize={12}>
                                            {"@"}
                                            {trimHelper(this.state.client.alias, 24)}
                                        </BoldText>
                                    </View>
                                ) : (
                                    <View>
                                        <RegularText align="left">
                                            {trimHelper(this.props.data.mnemonic, 17)}
                                        </RegularText>
                                    </View>
                                )}
                                {this.props?.data?.authType && (
                                    <Row style={{ alignItems: "center", paddingTop: 2 }}>
                                        <View
                                            style={{
                                                height: 14,
                                                width: 14,
                                                backgroundColor: colors.complementary || colors.secondary,
                                                borderRadius: 7,
                                                overflow: "hidden",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Icon
                                                size={9}
                                                name={
                                                    (this.props?.data?.authType == AuthType.ANON && "incognito") ||
                                                    (this.props?.data?.authType == AuthType.GOOGLE && "google") ||
                                                    ""
                                                }
                                                color={colors.mainButtonsColor}
                                            ></Icon>
                                        </View>
                                        <RegularText fontSize={10} style={{ paddingLeft: 4 }}>
                                            {(this.props?.data?.authType == AuthType.ANON && "Incognito") ||
                                                (this.props?.data?.authType == AuthType.GOOGLE &&
                                                    this.props?.data?.email) ||
                                                ""}
                                        </RegularText>
                                    </Row>
                                )}
                            </View>
                        </Row>
                    }
                    right={
                        <View style={{ zIndex: 99999 }}>
                            {this.props.isActive ? (
                                <Label text={t("active")} fontSize={12} />
                            ) : (
                                <View>
                                    {this.props.selected == this.props.data.mnemonic ? (
                                        <CircleButton
                                            style={{ width: 30, height: 30, borderRadius: 15 }}
                                            iconSize={15}
                                            icon="trash"
                                            onPress={() => this.props.removeItem(this.props.data.mnemonic)}
                                        ></CircleButton>
                                    ) : (
                                        <CircleButton
                                            style={{ width: 30, height: 30, borderRadius: 15, paddingLeft: 1 }}
                                            iconSize={15}
                                            icon="key"
                                            onPress={this.onPress}
                                        ></CircleButton>
                                    )}
                                </View>
                            )}
                        </View>
                    }
                />
            </>
        );
    }
}
