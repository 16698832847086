import CurrencyImplementation from "../../CurrencyImplementation";
import { PathNode } from "@core/utils/PathNode";
import * as ethUtil from "ethereumjs-util";
import HDNode from "hdkey";
import Web3 from "web3";
import rsk3 from "@rsksmart/rsk3";
import { format } from "date-fns";
import Wallet from "@core/wallet/Wallet";
import Common from "ethereumjs-common";
const EthereumTx = require("ethereumjs-tx").Transaction;
const ethers = require("ethers");

export default class ETHImplementation extends CurrencyImplementation {
    async signMessage(addressNode: PathNode, hexMessage: string) {
        //@TODO assert >> this.currency.getAddress() == account
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let wallet = new ethers.Wallet(privateKey);
        return await wallet.signMessage(hexMessage);
    }

    async signTypedData(addressNode: PathNode, data) {
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let wallet = new ethers.Wallet(privateKey);

        try {
            if (!data?.types && !data?.message) {
                data = await JSON.parse(data);
            }
            if (data?.types && data.types["EIP712Domain"]) {
                delete data?.types["EIP712Domain"];
            }
            return await wallet._signTypedData(data?.domain, data?.types, data?.message);
        } catch (e) {}
    }

    generateAddress(addressNode: PathNode, options?: { chainId?: number }) {
        const chainId = options.chainId;
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;
        let address = "0x" + ethUtil.privateToAddress(privateKey).toString("hex");
        //if(this.currency.getId()=='RSK'){
        return Web3.utils.toChecksumAddress(address);
        //}
        //return Web3.utils.toChecksumAddress(address);
        //return rsk3.utils.toChecksumAddress(address, chainId);
    }

    async signTransaction(addressNode: PathNode, rawTx: any) {
        const privateKey = HDNode.fromExtendedKey(addressNode.node.xpriv, this.currency.getNetwork().bip32).privateKey;

        rawTx.gasLimit = rawTx.estimateGas;
        rawTx.data = rawTx.data ? rawTx.data : "0x";

        if (rawTx.chainId) {
            const customCommon = Common.forCustomChain(
                this.currency.isTestnet() ? "ropsten" : "mainnet",
                {
                    chainId: rawTx.chainId,
                },
                "petersburg",
            );

            const tx = new EthereumTx(rawTx, { common: customCommon });
            tx.sign(privateKey);
            const serializedTx = tx.serialize();
            return "0x" + serializedTx.toString("hex");
        } else {
            const tx = new EthereumTx(rawTx, { chain: this.currency.isTestnet() ? "ropsten" : "mainnet" });
            tx.sign(privateKey);
            const serializedTx = tx.serialize();
            return "0x" + serializedTx.toString("hex");
        }
    }

    parseTransaction(tx) {
        const contract_value = tx.contract_value ? parseInt("0x" + tx.contract_value.replace(/^0+/, "")) : 0;
        const contract_to = tx.contract_to ? "0x" + tx.contract_to.replace(/^0+/, "") : "";
        const wallet = Wallet.getInstance();
        const eth = wallet.findCurrencyByName("eth");
        return {
            type: tx.txfrom == this.currency.getAddress() ? 1 : 0,
            to: tx.contract_to ? contract_to : tx.txto,
            amount: this.currency.fromDecimals(contract_value ? contract_value : tx.value),
            from: tx.txfrom,
            id: tx.txhash,
            confirmations: tx.confirmations ? tx.confirmations : 0,
            fee: eth.format(tx.gas * tx.gasprice || 0),
            date: this.parseDate(tx.time),
        };
    }

    parseDate(time) {
        return format(new Date(time * 1000), "MMM dd, yyyy H:mma");
    }

    parseSkeleton(skeleton) {
        const value = skeleton.extra ? skeleton.extra.value : skeleton.value;
        const to = skeleton.extra ? skeleton.extra.to : skeleton.to;
        const wallet = Wallet.getInstance();
        const swapAmount = skeleton.extra ? skeleton.extra.swapAmount : 0;
        const eth = wallet.findCurrencyByName("eth");
        return {
            amount: this.currency.fromDecimals(parseInt(value)),
            sendingTo: to,
            sendingFrom: skeleton.from,
            fee: eth.fromDecimals(skeleton.fee),
            swapAmount: swapAmount,
        };
    }

    isValidAddress(address: string) {
        return Web3.utils.isAddress(address.toLowerCase());
    }
}
