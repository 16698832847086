import React, { Component } from "react";
import { View, StyleSheet, Text, InteractionManager, Platform } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";
import Currency from "@core/currencies/Currency";
import Card from "@base/Card";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import { AreaChart } from "react-native-svg-charts";
import * as shape from "d3-shape";
import Row from "@base/Row";
import {
    getPercentagePriceVariationColor,
    getPercentagePriceVariationDaily,
} from "@utils/helpers/currency/currency.helper";
import { getColorOpacity } from "@utils/helpers/global/global";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import CurrencyIcon from "@components/accessories/CurrencyIcon";

interface Props {
    onPress?: () => void;
    currency: Currency;
    fiatCurrency: FiatCurrency;
    priceHistory?: boolean;
}

interface State {
    prices: Array<any>;
    porcent: string;
    porcentColor: string;
    onPress: () => void;
}

export default class CurrencyCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            prices: [],
            porcent: "",
            porcentColor: colors.grey,
            onPress: this.props.onPress ? this.props.onPress : () => { },
        };
    }

    render() {
        const currency = this.props.currency;

        return (
            <Card
                onPress={this.state.onPress}
                style={{ alignItems: "center" }}
                icon={
                   <CurrencyIcon styles={{marginRight: 15}} currency={this.props.currency}/>
                }
                left={
                    <View>
                        <View style={styles.leftTitle}>
                            {currency.isSmart() && (
                                <View
                                    style={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: colors.secondary,
                                        borderRadius: 20,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: -27,
                                        marginTop: -10,
                                        marginRight: 9,
                                    }}
                                >
                                    <Icon name="favorites" size={15} color={colors.white} align="center" />
                                </View>
                            )}
                            <Row
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                <BoldText style={{ height: 24 }} fontSize={18} color={colors.highlightedText}>
                                    {currency.getPName()}
                                </BoldText>
                                <NetworkCurrencySymbol currency={currency}></NetworkCurrencySymbol>
                            </Row>
                        </View>

                        {this.props.currency.getFiat() !== 0 && (
                            <Row style={{ textAlignVertical: "center" }}>
                                <RegularText style={styles.leftDesc} fontSize={12}>
                                    {" "}
                                    {this.props.fiatCurrency.getSymbol() +
                                        " " +
                                        this.props.fiatCurrency.fiatFormat(this.props.currency.getFiat())}{" "}
                                </RegularText>

                                {getPercentagePriceVariationColor(currency.getPriceHistoryDaily()) &&
                                    this.props.priceHistory && (
                                        <BoldText
                                            color={getPercentagePriceVariationColor(currency.getPriceHistoryDaily())}
                                            fontSize={12}
                                            style={{ lineHeight: 18, marginLeft: 5 }}
                                        >
                                            {getPercentagePriceVariationDaily(currency.getPriceHistoryDaily())}
                                        </BoldText>
                                    )}
                            </Row>
                        )}
                    </View>
                }
                right={
                    <View>
                        <BoldText
                            fixedWidth={150}
                            style={styles.rightDesc}
                            fontSize={18}
                            color={colors.highlightedText}
                        >
                            {" "}
                            {currency.getFormatTotalBalance()}{" "}
                        </BoldText>
                        {this.props.currency.getFiat() !== 0 && (
                            <RegularText fixedWidth={150} style={styles.rightDesc} fontSize={12}>
                                {" "}
                                {this.props.fiatCurrency.getSymbol() +
                                    " " +
                                    this.props.fiatCurrency.fiatFormat(this.props.currency.getFiatTotalBalance())}{" "}
                            </RegularText>
                        )}
                    </View>
                }
                background={
                    this.props.priceHistory && (
                        <AreaChart
                            style={{ height: 85, marginTop: -5, marginHorizontal: -5 }}
                            data={currency.getPriceHistoryDaily().map((p) => p.price)}
                            contentInset={{ top: 10, bottom: 35 }}
                            curve={shape.curveNatural}
                            svg={{
                                stroke: colors.grey,
                                strokeOpacity: 0,
                                strokeWidth: 1,
                                fill: colors.tertiaryShadow,
                                fillOpacity: 1,
                            }}
                        ></AreaChart>
                    )
                }
                accessibilityLabel={this.props.currency.getFullName()}
            />
        );
    }
}

const styles = StyleSheet.create({
    
    left: {
        flex: 0.5,
    },
    right: {
        flex: 0.5,
    },
    leftTitle: {
        marginTop: 2,
        flexDirection: "row",
        alignItems: "center",
        height: 18,
    },
    leftDesc: {
        lineHeight: 18,
    },
    rightDesc: {
        textAlign: "right",
    },
});
