import React, { Component } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import Row from "@base/Row";
import { NavigationType } from "@custom-types/NavigationType";
import RegularText from "@base/RegularText";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import SwitchSelector from "react-native-switch-selector";
import * as Clipboard from "expo-clipboard";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import BoldText from "@base/BoldText";
import Icon from "@components/icons";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import QRBase from "@base/QRBase";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
}

interface State {
    showMessage: boolean;
    address: string;
    receiveAddress: string;
    format: number;
    switchQR: number;
}

const { t } = i18n;

export class _ReceiveScreen extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.onPress = this.onPress.bind(this);
        this.switchQR = this.switchQR.bind(this);
        this.switchFormatAddress = this.switchFormatAddress.bind(this);
        const format = 0;
        this.state = {
            showMessage: false,
            address: this.currency.getAddress(
                this.currency.isMultiFormat() ? { format: this.currency.getFormats()[format] } : {},
            ),
            receiveAddress: this.currency.getReceiveAddress(
                this.currency.isMultiFormat() ? { format: this.currency.getFormats()[format] } : {},
            ),
            format: format,
            switchQR: 1,
        };
    }

    onPress = async () => {
        Clipboard.setString(this.state.address);
        this.setState({ showMessage: true });
        setTimeout(() => {
            this.setState({ showMessage: false });
        }, 3000);
    };

    switchFormatAddress = () => {
        var format = this.state.format == 0 ? 1 : 0;
        this.setState({
            format: format,
            address: this.currency.getAddress({ format: this.currency.getFormats()[format] }),
            receiveAddress: this.currency.getReceiveAddress({ format: this.currency.getFormats()[format] }),
        });
    };

    switchQR() {
        this.setState({ switchQR: this.state.switchQR == 0 ? 1 : 0 });
    }

    render() {
        return (
            <ScreenWrapper type="scroll">
                <Header title={t("receive") + " " + this.currency.getPName()} type={HeaderType.Light} {...this.props} />
                <Container>
                    {this.currency.isMultiFormat() && (
                        <View style={{ height: Platform.OS == "web" ? 75 : 70 }}>
                            <Row
                                style={{
                                    justifyContent: "center",
                                    position: "absolute",
                                    zIndex: 999,
                                    width: "100%",
                                }}
                            >
                                <View style={{ position: "relative" }}>
                                    <SwitchSelector
                                        options={[
                                            { label: this.currency.getFormats()[0], value: 0 },
                                            {
                                                label: this.currency.getFormats()[1],
                                                value: 1,
                                            },
                                        ]}
                                        initial={this.state.format}
                                        value={this.state.format}
                                        style={{
                                            width: 250,
                                            marginHorizontal: "auto",
                                            alignSelf: "center",
                                            marginVertical: 20,
                                        }}
                                        onPress={() => {}}
                                        textColor={colors.text}
                                        selectedColor={colors.white}
                                        buttonColor={colors.secondary}
                                        borderColor={colors.secondary}
                                        animationDuration={250}
                                        backgroundColor={colors.shadow}
                                    />
                                    <PressableBase
                                        onPress={this.switchFormatAddress}
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            zIndex: 99999,
                                        }}
                                    ></PressableBase>
                                </View>
                            </Row>
                        </View>
                    )}
                    <View style={styles.qr}>
                        {this.state.switchQR == 0 ? (
                            <QRBase
                                value={`smartqr:${this.currency.getId()}:${this.state.address}`}
                                size={200}
                            />
                        ) : (
                            <QRBase
                                value={this.state.address}
                                size={200}
                            />
                        )}
                    </View>
                    <View style={{ position: "relative" }}>
                        <SwitchSelector
                            options={[
                                { label: "Smart QR", value: 0 },
                                {
                                    label: "QR",
                                    value: 1,
                                },
                            ]}
                            initial={this.state.switchQR}
                            value={this.state.switchQR}
                            fontSize={12}
                            style={{
                                width: 180,
                                marginHorizontal: "auto",
                                alignSelf: "center",
                                marginTop: 5,
                                marginBottom: 25,
                            }}
                            onPress={() => {}}
                            textColor={colors.text}
                            selectedColor={colors.white}
                            buttonColor={colors.secondary}
                            borderColor={colors.secondary}
                            animationDuration={250}
                            backgroundColor={colors.shadow}
                        />
                        <PressableBase
                            onPress={this.switchQR}
                            style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                zIndex: 99999,
                            }}
                        ></PressableBase>
                    </View>
                    <Row style={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                        <RegularText align="center">{t("your_address")}</RegularText>
                        <BoldText align="center"> {this.currency.getPFullName()}</BoldText>
                        <NetworkCurrencySymbol
                            currency={this.currency}
                            styles={{ minWidth: 45, paddingHorizontal: 8, paddingVertical: 3 }}
                            containerStyles={{ alignItems: "flex-start", marginLeft: 5 }}
                            fontSize={10}
                        ></NetworkCurrencySymbol>
                    </Row>
                    <View style={styles.addressWrapper}>
                        <View style={styles.addressContainer}>
                            <RegularText fixedWidth={250} align="center" selectable={true} fontSize={14}>
                                {this.state.address}
                            </RegularText>
                        </View>
                        <PressableBase onPress={this.onPress} style={styles.button}>
                            <Icon size={20} name={"content-copy"} color={colors.text} />
                        </PressableBase>
                    </View>
                    {this.state.showMessage && (
                        <RegularText align="center" color={colors.secondary} fontSize={14} style={{ marginTop: 10 }}>
                            {t("copied")}
                        </RegularText>
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const ReceiveScreen = connect(mapStateToProps, mapDispatchToProps)(_ReceiveScreen);

export default ReceiveScreen;

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    addressContainer: {
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
    },
    addressWrapper: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    qr: {
        flexDirection: "column",
        alignItems: "center",
        marginVertical: 20,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        justifyContent: "center",
        padding: 10,
    },
});
