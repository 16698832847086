import Container from "@base/Container";
import { Platform, StyleSheet, View } from "react-native";
import { SimpleLineIcons } from "@expo/vector-icons";
import { colors, settings } from "@styles/globalStyles";
import RegularTextInput from "@base/RegularTextInput";
import React, { useEffect } from "react";
import store from "@store/index";
import { setSearch } from "@store/actions/global";
import Icon from "@components/icons";
import i18n from "@i18n/i18n"

const styles = StyleSheet.create({
    searchBox: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        flexDirection: 'row',
        alignItems: 'center',
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40
    },
    icon: {
        position: 'absolute',
        left: 10
    },
    button: {
        position: 'absolute',
        width: 30,
        height: 30,
        right: 15,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        borderColor: 'white',
        borderWidth: 1
    }
});

const { t } = i18n

export function SearchBar() {
    const value = store.getState().global.search;
    return (
        <Container>
            <View style={styles.searchBox}>
                <Icon
                    name={'search'}
                    size={20}
                    color={colors.text}
                    align='left'
                    style={styles.icon}
                />
                <RegularTextInput
                    style={styles.input}
                    onChangeText={(value) => {
                        store.dispatch(setSearch(value))
                    }}
                    value={value}
                    placeholder={t ('search')}
                />
            </View>
        </Container>
    )
}
