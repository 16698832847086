import React, {Component} from "react"
import BoldText from "@base/BoldText";
import { Chat } from "@custom-types/Chat";
import { Message } from "@custom-types/Message";
import { NavigationType } from "@custom-types/NavigationType";
import store from "@store/index";
import { selectMessagesFromChat } from "@store/reducers/chat.reducer";
import { getInitial, getNameAndIfExistsContact, getUnreadMessagesCount, messageLengthHelper, setTimeVariables } from "@utils/helpers/chat/chat.helper";
import { View } from "react-native";
import ContactClientCard from "./ContactClientCard";
import { MessageCard } from "./MessageCard";


interface Props {
    item: Chat | any;
    navigation: NavigationType;
    onLongPressChat: (chat: Chat)  => void
    onPressChat: (chatId: string)  => void
    selectedChat: Chat
}

interface State {
    unread: number
}

export class ChatMessageCard extends Component<Props, any> {
    constructor(props: Props) {
        super(props)
        this.renderChat = this.renderChat.bind(this);
        this.state = {
            unread: 0
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        return true;
        /*return (nextProps.item != this.props.item) || 
               (nextProps.selectedChat != this.props.selectedChat) || */
               
      }

    renderChat = (chat: Chat) => {
        
        const {name} = getNameAndIfExistsContact(chat)
        const messages: Array<Message> = selectMessagesFromChat(store.getState(), chat.id)
        const time = setTimeVariables(messages)
        const unread = getUnreadMessagesCount(messages)
        const initial = getInitial(chat)

        return (
            <MessageCard
                profileImagePath={chat.to.profileImagePath?.thumbnail}
                initial={initial}
                title={name}
                subtitle={messages[0] ? messageLengthHelper(messages[0].text) : ""}
                info={time}
                onLongPress={() => this.props.onLongPressChat(chat)}
                onPress={() => this.props.onPressChat(chat.id)}
                unread={unread}
                key={chat.id}
                navigation={this.props.navigation}
                selected={this.props.selectedChat && this.props.selectedChat.id == chat.id}
            />
        )
    }

    render() {
        const {item} = this.props;
        
        if (item.type == "separator") {
            return (
                <View style={{marginTop: 20}}>
                    <BoldText>{this.props.item.title}:</BoldText>
                </View>
            )
        }

        if (this.props.item.messages) {
            return this.renderChat(this.props.item)
        }

        return <ContactClientCard item={this.props.item} navigation={this.props.navigation} />
    }
}