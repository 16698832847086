import SelectCurrencyScreen from "../../shared/SelectCurrencyScreen";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";

import store from "@store/index";
import { cleanOperation, prePrepareTransaction, selectCurrency } from "@store/actions/wallet";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { CustomMessageType } from "@custom-types/CustomMessageType";
import { setCustomMessage } from "@store/actions/chat.actions";
import { Chat } from "@custom-types/Chat";
import Wallet from "@core/wallet/Wallet";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import { NavigationType } from "@custom-types/NavigationType";

interface Props {
    navigation: NavigationType;
    selectedCurrency: string;
    customMessage: CustomMessageModel;
    selectedChat: Chat;
}

class _SelectCurrencyActionsScreen extends SelectCurrencyScreen {
    protected currency: Currency;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // if (!this.props.selectedChat.to.version && this.props.customMessage.type == CustomMessageType.SendCrypto) {
        //     this.props.navigation.navigate(MessagesNavigatorScreens.WarningSendCrypto.routeName);
        // }
    }

    onPress = (item) => {
        const customMessage = this.props.customMessage;
        this.currency = item;
        customMessage.address =
            customMessage.type == CustomMessageType.SendCrypto
                ? this.getAddress()
                : this.currency.isMultiFormat()
                ? this.currency.getAddress({ format: this.currency.getFormats()[0] })
                : this.currency.getAddress();
        customMessage.currencyId = this.currency.getId();
        customMessage.currencyName = this.currency.getPName();
        customMessage.network = this.currency.isTestnet() ? "testnet" : "mainnet";
        store.dispatch(setCustomMessage(customMessage));

        switch (customMessage.type) {
            case CustomMessageType.CryptoAddress:
                this.props.navigation.navigate(MessagesNavigatorScreens.AddressScreen.routeName);
                break;
            case CustomMessageType.PayOrder:
                store.dispatch(cleanOperation());
                store.dispatch(selectCurrency(this.currency.getId()));
                this.props.navigation.navigate(MessagesNavigatorScreens.OrderScreen.routeName);
                break;

            case CustomMessageType.SendCrypto:
                store.dispatch(cleanOperation());
                store.dispatch(selectCurrency(this.currency.getId()));
                customMessage.address = this.getAddress();
                store.dispatch(
                    prePrepareTransaction({
                        address: this.getAddress(),
                        amount: 0,
                    }),
                );
                this.props.navigation.navigate(MessagesNavigatorScreens.SendCrypto.routeName);
                break;
        }
    };

    getAddress() {
       
        if (!this.props.selectedChat.to.addresses && this.props.customMessage.type == CustomMessageType.SendCrypto) {
            this.props.navigation.navigate(MessagesNavigatorScreens.WarningSendCrypto.routeName);
            return;
        }

        if (
            this.props.selectedChat?.to?.ethCompatibility &&
            this.currency.getUnderlyingCurrency().hasEthCompatibilitySupport()
        ) {
            return this.props.selectedChat.to.addresses[this.currency.isTestnet() ? "testnet" : "mainnet"]["eth"];
        }

        if (this.currency.getBlockchain() == "centralized") {
            return this.props.selectedChat.to._id;
        }
       
     
        if (this.currency.getUnderlyingCurrencyID() == "RON") {
            return `ronin:${this.props.selectedChat.to.addresses["mainnet"]["eth"].slice(2).toLowerCase()}`;
        }

        if (this.currency.getStaticNetworkID() == Wallet.getInstance().findCurrencyById("ETH").getStaticNetworkID()) {
            return this.props.selectedChat.to.addresses[this.currency.isTestnet() ? "testnet" : "mainnet"]["eth"];
        }
       
        return this.props.selectedChat.to.addresses[this.currency.isTestnet() ? "testnet" : "mainnet"][
            this.currency.getUnderlyingCurrencyID().toLowerCase()
        ];
    }
}

const mapStateToProps = (state) => {
    let currencies = Wallet.getInstance().getCurrencies();

    return {
        enabledCurrencies: state.wallet.currencies,
        currencies: currencies,
        fiatCurrency: state.wallet.fiatCurrency,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SelectCurrencyActionsScreen = connect(mapStateToProps, mapDispatchToProps)(_SelectCurrencyActionsScreen);

export default SelectCurrencyActionsScreen;
