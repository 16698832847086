import React, { Component } from "react";
import { StyleSheet,  } from "react-native";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import Row from "@base/Row";
import Card from "@base/Card";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import PressableBase from "@base/PressableBase";

interface Props {
    icon: string;
    name: string;
    onPress: () => void
}

interface State { }

const { t } = i18n;

export class _PaymentCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }


    render() {
        return (
            <PressableBase onPress={() => this.props.onPress()}>
                <Card style={{
                    flexDirection: "row",
                    flex: 1,
                }}>
                    <Row style={{ flex: 1, alignItems: "center", justifyContent: "space-between", paddingVertical: 5 }}>
                        <Row style={{ backgroundColor: "transparent", alignItems: "center", justifyContent: "flex-start", flex: 1 }}>
                            <Icon size={22} name={this.props.icon} color={colors.text} />
                            <RegularText style={{ marginLeft: 15 }}>{this.props.name}</RegularText>
                        </Row>
                    </Row>
                </Card >
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    externalCircle: {
        width: 24,
        height: 24,
        borderRadius: 12,
        borderColor: colors.complementary || colors.secondary,
        borderWidth: 2,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent"
    },
    innerCircle: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: colors.complementary || colors.secondary,
    },
    row: {
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
    },
});

const mapStateToProps = (state) => { return {} };

const mapDispatchToProps = (dispatch) => ({});

const PaymentCard = connect(mapStateToProps, mapDispatchToProps)(_PaymentCard);

export default PaymentCard;
