import React, { Component } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import BotCard from "@base/BotCard";
import { CoincaexService } from "@core/services/CoincaexService";
import { Bank, CoincaexBuy, findCountryByName } from "../../helper/coincaex.helper";
import { Route } from "@react-navigation/native";
import DropdownComponent from "../../components/DropdownComponent";


interface Props {
    navigation: NavigationType;
    route: Route<string, { data: CoincaexBuy, fiatCurrency: string }>;
    currencies: Array<Currency>;
    selectedCurrency: string;
}

interface State {
    banks: any;
    selectedBank: Bank;
}

const { t } = i18n;

export class _CoincaexBuyBanksScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.state = {
            banks: [],
            selectedBank: null,
        };
    }

    async componentDidMount() {
        const data = await CoincaexService.getInstance().getBankListCoincaex();

        if (data) {
            const countries = Object.keys(data.bankList);
            const banksByCountry = countries.map((country) => {
                const banks = data.bankList[country][this.props.route?.params?.fiatCurrency];
                return {
                    country,
                    banks,
                };
            });

            this.setState({ banks: banksByCountry });
        }
    }

    renderItem = ({ item }) => {
        const country = findCountryByName(item.country);

        return (
            item.banks?.length &&
            <DropdownComponent
                country={country}
                item={item}
                selectedBank={this.state.selectedBank}
                setBank={this.setBank}
                submit={this.handleSubmit}
            />
        );
    };

    handleSubmit = () => {
        let data: CoincaexBuy = this.props.route.params?.data;

        data.bankAccountCurrency = this.state.selectedBank.Code;
        data.bankAccountNumber = this.state.selectedBank.Number;
        data.bankName = this.state.selectedBank.Bank;

        this.props.navigation.navigate(CoincaexNavigatorScreens.BuyPayment.routeName, { data: data });
    };


    setBank = (value: any) => {
        this.setState({ selectedBank: value })
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={"Deposito"} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <View style={{ flex: 1, marginBottom: 10 }}>
                        <BotCard message={t("select_deposit_bank")}></BotCard>
                        <FlatList
                            data={this.state.banks}
                            keyExtractor={(item) => item.country}
                            renderItem={this.renderItem}
                        />
                    </View>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexBuyBanksScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexBuyBanksScreen);

export default CoincaexBuyBanksScreen;
