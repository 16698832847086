import React, { Component } from "react";
import { FlatList, ScrollView, StyleSheet,  View } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import store from "@store/index";
import { hideModalBottom, loading, ready, showModalBottom, showSnackbar } from "@store/actions/global";
import FloatingTextInput from "@base/FloatingTextInput";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import OptionCard from "@components/cards/OptionCard";
import BoldText from "@base/BoldText";
import { CoincaexService } from "@core/services/CoincaexService";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import { CoincaexBankAccount, findCountryByName } from "../helper/coincaex.helper";
import Card from "@base/Card";
import Row from "@base/Row";
import Icon from "@components/icons";
import { colors } from "@styles/globalStyles";
import { Avatar } from "react-native-elements";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
    route: any;
}

interface State {
    registerForm: CoincaexBankAccount;
    availableBanks: any;
    countries: Array<any>;
    selectedCountry: string;
    avatar: string;
}

const { t } = i18n;

export class _CoincaexAddBankScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            registerForm: {
                bankName: "",
                bankAccountCurrency: "",
                bankAccountType: "",
                bankAccountName: "",
                bankAccountNumber: "",
            },
            availableBanks: [],
            countries: [],
            selectedCountry: null,
            avatar: null,
        };
    }

    async componentDidMount() {
        const resp = await CoincaexService.getInstance().getAvailableCountries();

        this.setState({
            ...this.state,
            countries: [...resp],
        });
    }

    handleInputChange = (value: string, field: string) => {
        this.setState({
            ...this.state,
            registerForm: { ...this.state.registerForm, [field]: value },
        });
    };

    handleSubmit = async () => {
        if (this.validate()) {
            const resp = await CoincaexService.getInstance().addBankAccount(this.state.registerForm);

            if (resp) {
                this.onSuccess();
            }
        }
    };

    onSuccess = () => {
        this.props.navigation.navigate(CoincaexNavigatorScreens.CoincaexHome.routeName, { reload: true });
        store.dispatch(showSnackbar({ type: "SUCCESS", message: t("saved_succesfuly") }));
    };

    validate = (): boolean => {
        const { bankName, bankAccountCurrency, bankAccountName, bankAccountNumber, bankAccountType } =
            this.state.registerForm;

        if (!bankName || !bankAccountCurrency || !bankAccountName || !bankAccountNumber || !bankAccountType) {
            store.dispatch(showSnackbar({ type: "ERROR", message: t("complete_field_warning") }));
            return false;
        }

        return true;
    };

    selectCountry = async (item) => {
        store.dispatch(hideModalBottom());
        store.dispatch(loading());
        const resp = await CoincaexService.getInstance().getAvailableBanksByCountry(item);

        this.setState({
            ...this.state,
            selectedCountry: item,
            availableBanks: resp,
            registerForm: {
                bankName: resp.banks[0].id || "",
                bankAccountCurrency: resp.currencies[0].id || "",
                bankAccountType: resp.types[0].id || "",
                bankAccountName: "",
                bankAccountNumber: "",
            },
        });
        store.dispatch(ready());
    };

    renderCountries = ({ item }) => {
        const country = findCountryByName(item);

        return (
            <PressableBase onPress={() => this.selectCountry(country.name)}>
                <Card>
                    <Row style={{ justifyContent: "flex-start", width: "100%", alignItems: "center" }}>
                        <Avatar size={28} rounded source={country.icon} />
                        <View style={{ marginLeft: 15 }}>
                            <RegularText>{country.name}</RegularText>
                        </View>
                    </Row>
                </Card>
            </PressableBase>
        );
    };

    renderItem = ({ item }, field: string) => {
        return (
            <OptionCard
                selected={this.state.registerForm[field] == item.id}
                title={item.value}
                onPress={() => this.handleInputChange(item.id, field)}
            />
        );
    };

    countriesModal = () => {
        return (
            <View>
                <FlatList
                    data={this.state.countries}
                    keyExtractor={(item) => item}
                    renderItem={(item: any) => this.renderCountries(item)}
                    scrollEnabled={false}
                />
            </View>
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("add_bank")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <ScrollView>
                        <View style={styles.marginContainer}>
                            <BoldText style={styles.marginTitle}>{t("country")}</BoldText>
                            <PressableBase
                                onPress={() =>
                                    store.dispatch(
                                        showModalBottom({
                                            modalContent: this.countriesModal(),
                                        }),
                                    )
                                }
                            >
                                <Card>
                                    <Row
                                        style={{ justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                    >
                                        {this.state.selectedCountry ? (
                                            <RegularText>{this.state.selectedCountry}</RegularText>
                                        ) : (
                                            <RegularText>{t("select_country")}</RegularText>
                                        )}
                                        <Icon name="right" size={16} color={colors.text} />
                                    </Row>
                                </Card>
                            </PressableBase>
                        </View>
                        {this.state.selectedCountry && this.state.availableBanks && (
                            <View>
                                <View style={styles.marginContainer}>
                                    <BoldText style={styles.marginTitle}>{t("select_bank")}</BoldText>
                                    <FlatList
                                        data={this.state.availableBanks?.banks}
                                        keyExtractor={(item) => item.id}
                                        renderItem={(item: any) => this.renderItem(item, "bankName")}
                                        scrollEnabled={false}
                                    />
                                </View>
                                <View style={styles.marginContainer}>
                                    <BoldText style={styles.marginTitle}>{t("select_currency")}</BoldText>
                                    <FlatList
                                        data={this.state.availableBanks?.currencies}
                                        keyExtractor={(item) => item.id}
                                        renderItem={(item: any) => this.renderItem(item, "bankAccountCurrency")}
                                        scrollEnabled={false}
                                    />
                                </View>
                                <View style={styles.marginContainer}>
                                    <BoldText style={styles.marginTitle}>{t("account_type")}</BoldText>
                                    <FlatList
                                        data={this.state.availableBanks?.types}
                                        keyExtractor={(item) => item.id}
                                        renderItem={(item: any) => this.renderItem(item, "bankAccountType")}
                                        scrollEnabled={false}
                                    />
                                </View>
                                <View>
                                    <BoldText style={styles.marginTitle}>{t("account_data")}</BoldText>
                                    <FloatingTextInput
                                        onChangeText={(text) => this.handleInputChange(text, "bankAccountName")}
                                        value={this.state.registerForm.bankAccountName}
                                        label={t("account_name")}
                                        multiline={false}
                                    />
                                    <FloatingTextInput
                                        onChangeText={(text) => this.handleInputChange(text, "bankAccountNumber")}
                                        value={this.state.registerForm.bankAccountNumber}
                                        label={t("account_number")}
                                        multiline={false}
                                    />
                                </View>
                                <InlineButton
                                    style={{ marginVertical: 20 }}
                                    title={t("add_bank")}
                                    onPress={this.handleSubmit}
                                />
                            </View>
                        )}
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    marginTitle: {
        marginBottom: 10,
    },
    marginContainer: {
        marginBottom: 20,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexAddBankScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexAddBankScreen);

export default CoincaexAddBankScreen;
