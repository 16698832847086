import AppStorage from "@utils/storage";
import store from "@store/index";
import { setClient, setToken } from "@store/actions/auth";
import Wallet from "@core/wallet/Wallet";
import { Platform } from "react-native";
import * as Notifications from "expo-notifications";
import BTC from "@core/currencies/btc/BTC";
import Constants from "expo-constants";
import * as Device from "expo-device";
import AuthStorage from "@utils/storage/storages/AuthStorage";
import { loading, ready, showModal } from "@store/actions/global";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { getEnv } from "@utils/helpers/global/global";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { OAuthHistoryService } from "@core/services/oauth/OAuthHistoryService";

const EC = require("elliptic").ec;
const ec = new EC("secp256k1");
const CryptoJS = require("crypto-js");

export interface AuthLocalStorage {
    client: any;
    version?: string;
}

export const getAuthFromLocalStorage = async (): Promise<AuthLocalStorage> => {
    return await AuthStorage.get();
};

export const setAuthLocalStorage = async (data: AuthLocalStorage) => {
    return await AuthStorage.set({
        client: data.client,
        version: Constants.expoConfig.version,
    });
};

export const setClientTokenReduxStorage = (client, token) => {
    if (client && token) {
        store.dispatch(setClient(client));
        store.dispatch(setToken(token));

        return;
    }

    throw new Error("client token error");
};

export const getWalletPublicAndPrivateKey = () => {
    const wallet = Wallet.getInstance();

    const keys = new BTC({
        seed: wallet.getSeed(),
        fiatCurrency: wallet.getFiatCurrency(),
        testnet: false,
    }).getKeys();

    return { walletPublicKey: keys.publicKey, walletPrivateKey: keys.privateKey };
};

/* Begin Diffie-Hellman */

export const generateSharedKeyFromOwnPrivateKeyAndExternalPubKey = (ownPrivateKey, externalPublicKey): any => {
    const elliptic = getEcKeyFromSeed(ownPrivateKey);
    return generateSharedKeyFromEcKeyAndExternalPubKey(elliptic, externalPublicKey);
};

export const generateSharedKeyFromEcKeyAndExternalPubKey = (elliptic, publicKey): any => {
    return elliptic.derive(ec.keyFromPublic(publicKey, "hex").getPublic()).toString(16);
};

export const decryptStringFromServer = (walletPrivateKey, serverPublicKey, encryptedString) => {
    const sharedKey = generateSharedKeyFromOwnPrivateKeyAndExternalPubKey(walletPrivateKey, serverPublicKey);
    return CryptoJS.AES.decrypt(encryptedString, sharedKey).toString(CryptoJS.enc.Utf8);
};

export const getChatSeed = () => {
    const wallet = Wallet.getInstance();
    const mainAddress = wallet.getMainAddress();
    const mnemonic = wallet.getMnemonic();
    return mnemonic.join(" ").substring(15, 5).replace(/\s/g, "").concat(mainAddress);
};

export const getEcKeyFromSeed = (seed) => ec.keyFromPrivate(seed);
export const getEcPubKeyFromKey = (key: any) => key.getPublic().encode("hex").toString();

/* End Diffie-Hellman */

export const isValidTokenDate = (token) => {
    const expiredAt = token?.expiredAt;
    return expiredAt ? Date.parse(expiredAt) > Date.now() : false;
};

export const isValidVersion = (version) => {
    return version == Constants.expoConfig.version;
};

export const getNotificationToken = async () => {
    if (Platform.OS != "web" && Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();

        let finalStatus = existingStatus;
        if (existingStatus !== "granted") {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== "granted") {
            //@todo: "AGREGAR UN MENSAJE CUANDO NO TENGAS PERMISOS PARA ENVIARTE NOTIFICACIONES"
            return;
        }

        const token = (
            await Notifications.getExpoPushTokenAsync({ projectId: Constants.expoConfig?.extra?.eas?.projectId })
        ).data;

        if (Platform.OS === "android") {
            await Notifications.setNotificationChannelAsync("default", {
                name: "beexo",
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: "#FF231F7C",
            });
        }

        return token;
    }
};


