import React, {Component} from "react"
import {
    View,
    Text,
    StyleSheet,
    Button,
    BackHandler,
    Keyboard,
    Platform,
    EmitterSubscription,
    TouchableWithoutFeedback,
} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import RegularTextInput from "@base/RegularTextInput"
import RegularNumberInput from "@base/RegularNumberInput"
import Row from "@base/Row"
import SwitchSelector from "react-native-switch-selector"
import { colors, settings } from "@styles/globalStyles"
import RegularText from "@base/RegularText"
import InlineButton from "@base/InlineButton"
import {connect} from "react-redux"
import Currency from "@core/currencies/Currency"
import {FiatCurrency} from "@core/fiat/FiatCurrencies"

import store from "@store/index"
import {CLEAN_OPERATION, cleanOperation, PRE_PREPARE_TRANSACTION, prepareTransaction, prepareBuy} from "@store/actions/wallet"
import Wallet from "@core/wallet/Wallet"
import BigNumber from "bignumber.js"
import {Dimensions} from "react-native"
import {loading, ready, showPopup} from "@store/actions/global"
import debounce from "lodash.debounce"
import Icon from "@components/icons"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import OptionCard from "@components/cards/OptionCard";
import { SimpleEffect } from "redux-saga/effects"
import { SimplexService } from "@core/services/SimplexService"
import i18n from "@i18n/i18n"
import { WalletNavigatorScreens } from "@navigation/WalletNavigator"
import { SendNavigatorScreens } from "@navigation/SendNavigator"
import { BuyNavigatorScreens } from "@navigation/BuyNavigator"
import { SimplexNavigatorScreens } from "@navigation/SimplexNavigator"

interface Props {
    navigation: NavigationType
    route: any
    currencies: Array<Currency>
    selectedCurrency: string
    fiatCurrency: FiatCurrency
    currentOperation: any
    customMessage?: any
    next?: (transaction: any) => void
    onSuccessNavigate?: string
}

interface State {
    amount: any
    convertedAmount: BigNumber
    switch: boolean
    fiat: number
    currency: Currency
    showError: boolean
    errorMessage: string
    screenHeight: any
    next?: (transaction: any) => void
    loading: boolean
}

const { t } = i18n

export class _BuyMethodScreen extends Component<Props, State> {
    private currency: Currency
    private fiatCurrency: FiatCurrency
    private keyboardWillShowSub: EmitterSubscription
    private keyboardWillHideSub: EmitterSubscription
    private title = t ('buy')
    private simplexService: SimplexService;

    constructor(props: Props) {
        super(props);
        this.simplexService = SimplexService.getInstance();
        this.init = this.init.bind(this)
        this.state = this.init()
        this.onPressNext = this.onPressNext.bind(this)
        this.props.navigation.addListener("focus", (payload) => {
            this.init()
        })
        if (Platform.OS != 'web')
            BackHandler.addEventListener("hardwareBackPress", () => {
                store.dispatch(cleanOperation())
                return false
            })
        this.keyboardWillShow = this.keyboardWillShow.bind(this)
        this.keyboardWillHide = this.keyboardWillHide.bind(this)
    }

    setTitle(title: string) {
        this.title = title
    }

    componentDidMount() {
        this.keyboardWillShowSub = Keyboard.addListener("keyboardWillShow", this.keyboardWillShow)
        this.keyboardWillHideSub = Keyboard.addListener("keyboardWillHide", this.keyboardWillHide)
    }

    init() {
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency)
        this.fiatCurrency = this.props.fiatCurrency
        const op = this.props.currentOperation

        const fiat = this.fiatCurrency.fromDecimals(this.currency.getFiat())
        let amount = 0
        let showAddressError = false

        let s = this.state ? this.state.switch : false
        const state = {
            amount: amount,
            convertedAmount: this.getConvertedAmount(amount, fiat, s),
            switch: s,
            fiat: fiat,
            currency: Wallet.getInstance().findCurrencyById(this.props.selectedCurrency),
            showError: false,
            errorMessage: '',
            screenHeight: Platform.OS == "web" ? Math.round(Dimensions.get("window").height) - 80 : "100%",
            next:
                this.props.next ||
                ((transaction: any) => {
                    store.dispatch(
                        prepareTransaction({
                            transaction,
                            onSuccess: () => {
                                this.props.onSuccessNavigate
                                    ? this.props.navigation.navigate(this.props.onSuccessNavigate)
                                    : this.props.navigation.navigate(WalletNavigatorScreens.Send.routeName, {screen: SendNavigatorScreens.ConfirmSend.routeName})
                            },
                            onError: (error) => {
                                store.dispatch(showPopup({type: "ERROR", message: error}))
                            },
                        })
                    )
                }),
            loading: false,
        }
        if (this.state) this.setState(state)
        return state
    }

    componentWillUnmount() {
        this.keyboardWillShowSub.remove()
        this.keyboardWillHideSub.remove()
    }

    keyboardWillShow = (event) => {
        if (Platform.OS == "ios") {
            this.setState({
                screenHeight: Math.round(Dimensions.get("window").height) - event.endCoordinates.height,
            })
        }
    }

    keyboardWillHide = (event) => {
        if (Platform.OS == "ios") {
            this.setState({
                screenHeight: "100%",
            })
        }
    }

    validateAmount(): boolean {
        const amount = (this.state.switch) ? this.state.amount : this.state.convertedAmount
        const minAmount = this.simplexService.getMin();
        const maxAmount = this.simplexService.getMax();
        const isValid = amount > minAmount && amount < maxAmount;
        if(!isValid){
            this.setState({
                showError: true,
                errorMessage: `${t ('input_amount_message')} $${maxAmount} ${t ('and_bigger_than')} $${minAmount}`
            })
        }
        return isValid
    }

    validate() {
        return this.validateAmount()
    }

    onPressNext = async () => {
        if(this.validate()) {
            store.dispatch(loading());
            try {
                const res = await this.simplexService.getQuote(
                    this.currency.getName(), 
                    this.fiatCurrency.getName(),
                    this.state.switch?this.fiatCurrency.getName():this.currency.getName(),
                    Number(this.state.amount)
                );
                store.dispatch(ready());
                store.dispatch(prepareBuy({data: this.simplexService.processData(res)}));
                this.props.navigation.navigate(SimplexNavigatorScreens.ConfirmBuySimplex.routeName)
            } catch(e) {
                store.dispatch(ready());
                this.setState({
                    showError: true,
                    errorMessage: t ('provider_error')
                })

            }
            
        }
    }

    getConvertedAmount = (value, fiat, s) => {
        this.fiatCurrency.getDecimals()
        BigNumber.config({EXPONENTIAL_AT: this.currency.getDecimals() + 1})
        const v = new BigNumber(value)
        const f = new BigNumber(fiat)
        return v.multipliedBy(f.exponentiatedBy(s ? -1 : 1)).decimalPlaces(s ? 8 : 2)
    }

    onChangeAmount = (value: number) => {
        let convertedAmount = this.getConvertedAmount(value ? value : 0, this.state.fiat, this.state.switch)
        this.setState({
            amount: value,
            convertedAmount: convertedAmount,
        })
    }

    switchCurrency = () => {
        this.setState({
            amount: this.state.convertedAmount,
            convertedAmount: this.state.amount,
            switch: !this.state.switch,
        })
    }

    showConvertedAmount() {
        return (
            this.state.convertedAmount +
            " " +
            (!this.state.switch ? this.fiatCurrency.getName() : this.currency.getPName())
        )
    }

    onPress() {
        if (Platform.OS == "ios") Keyboard.dismiss()
    }

    render() {
        return (
            <ScreenWrapper>
                <Row
                    style={{
                        justifyContent: "center",
                        position: "absolute",
                        marginTop: 65,
                        zIndex: 999,
                        width: "100%",
                    }}>
                    <SwitchSelector
                        options={[
                            {label: this.currency.getPName(), value: 1},
                            {
                                label: this.fiatCurrency.getName(),
                                value: this.currency.getFiat(),
                            },
                        ]}
                        initial={this.state.switch ? 1 : 0}
                        style={{
                            width: 250,
                            marginHorizontal: "auto",
                            alignSelf: "center",
                            marginVertical: 20,
                        }}
                        onPress={this.switchCurrency}
                        textColor={colors.text}
                        selectedColor={colors.white}
                        buttonColor={colors.secondary}
                        borderColor={colors.secondary}
                        animationDuration={250}
                        backgroundColor={colors.shadow}
                    />
                </Row>
                <TouchableWithoutFeedback onPress={this.onPress} style={{zIndex: -1, height: "100%"}}>
                    <View style={{height: this.state.screenHeight}}>
                        <Header
                            title={`${this.title} ${this.currency.getPName()}`}
                            type={HeaderType.Light}
                            backTo={this.props.route?.params ? this.props.route.params.backTo : SimplexNavigatorScreens.SimplexMain.routeName}
                            {...this.props}
                           
                        />
                        <Container style={{marginTop: 60}}>
                            <RegularNumberInput
                                style={{height: 45}}
                                onChangeText={this.onChangeAmount}
                                value={this.state.amount ? this.state.amount : ""}
                                align='center'
                                fontSize={35}
                                placeholder='0'
                                editable
                            />

                            <RegularText align='center' fontSize={12} color={colors.text}>
                                {this.showConvertedAmount()}
                            </RegularText>

                        </Container>
                        <Container style={{flex: 1, flexDirection: "column-reverse"}}>
                            <View style={{height: 60}}>
                                <InlineButton
                                    title={ t('next')}
                                    onPress={this.onPressNext}
                                    style={{marginHorizontal: 0, marginBottom: 20}}
                                />
                            </View>

                            {this.state.showError && (
                                <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                    {this.state.errorMessage}
                                </RegularText>
                            )}

                            <View style={{flex: 1}}></View>
                        </Container>
                    </View>
                </TouchableWithoutFeedback>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    button: {
        width: 40,
        height: 40,
        alignItems:"center",
        alignContent: "center",
        backgroundColor: colors.shadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 0,
        marginLeft: 10
    },
    addressWrapper: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: 15,
        width: "100%",
    },
    addressContainer: {
        flex: 1,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
    },
})

const mapStateToProps = (state) => {
    return state.wallet
}

const mapDispatchToProps = (dispatch) => ({})

const BuyMethodScreen = connect(mapStateToProps, mapDispatchToProps)(_BuyMethodScreen)

export default BuyMethodScreen
