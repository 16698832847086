import React, {Component} from "react"
import {View, StyleSheet, RefreshControl, FlatList} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import Row from "@base/Row"
import {colors} from "@styles/globalStyles"
import RegularText from "@base/RegularText"
import {connect} from "react-redux"
import Currency from "@core/currencies/Currency"
import BoldText from "@base/BoldText"
import Card from "@base/Card"
import store from "@store/index"
import {
    confirmTransaction,
    getTransactions,
    selectPurchaseOrder,
    sync,
    synced,
    syncPurchaseOrders,
} from "@store/actions/wallet"

import {loading, ready, showPopup} from "@store/actions/global"
import Wallet from "@core/wallet/Wallet"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"

import {SimpleLineIcons} from "@expo/vector-icons"
import i18n from "@i18n/i18n"
import {FloatButton} from "@base/FloatButton"
import InfoCard from "@base/InfoCard"
import {SimplexService} from "@core/services/SimplexService"
import { BuyNavigatorScreens } from "@navigation/BuyNavigator"
import { SimplexNavigatorScreens } from "@navigation/SimplexNavigator"
interface Props {
    navigation: NavigationType
    currencies: Array<Currency>
    selectedCurrency: string
}

interface State {
    purchaseOrders: Array<any>
    syncing: boolean
}

const {t} = i18n

export class _SimplexMainScreen extends Component<Props, State> {
    private currency: Currency
    private simplexService: SimplexService
    constructor(props: Props) {
        super(props)
        this.simplexService = SimplexService.getInstance()
        this.currency = Wallet.getInstance().findCurrencyById(props.selectedCurrency)

        this.state = {
            purchaseOrders: [],
            syncing: true,
        }
        this.onPress = this.onPress.bind(this)
        this.sync = this.sync.bind(this)
        this.redirect = this.redirect.bind(this)
    }

    
    componentDidMount() {
        this.props.navigation.addListener("focus", this.sync)
    }

    async sync() {
        const purchaseOrders = await this.simplexService.getPurchaseOrders(this.currency.getName())
        if (purchaseOrders) {
            this.setState({syncing: false})
            store.dispatch(syncPurchaseOrders(this.currency))
            this.setState({purchaseOrders: purchaseOrders}, () => this.redirect())
        }
    }

    redirect() {
        if (this.state.purchaseOrders.length < 1) {
            this.props.navigation.navigate(SimplexNavigatorScreens.BuyMethodSimplex.routeName, {backTo: BuyNavigatorScreens.SelectProviderBuy.routeName})
        }
    }

    onPress = (i) => {
        store.dispatch(selectPurchaseOrder(i))
        this.props.navigation.navigate(SimplexNavigatorScreens.PurchaseOrderSimplex.routeName)
    }

    getStatusColor(state) {
        let color = colors.grey
        switch (state) {
            case "PENDING":
                color = colors.yellow
                break
            case "DONE":
                color = colors.green
                break
        }

        return color
    }

    renderItem = ({item, index}) => (
        <Card
            borderLeft={this.getStatusColor(item.state)}
            key={item.id}
            onPress={() => this.onPress(index)}
          
            left={
                <View>
                    <RegularText style={{lineHeight: 18}}> {item.digitalMoney?.currency} </RegularText>
                    <RegularText fontSize={10}> {item.date} </RegularText>
                </View>
            }
            right={
                <View>
                    <BoldText style={{lineHeight: 20}} fixedWidth={150} fontSize={18} align='right'>
                        {item.digitalMoney.amount}
                    </BoldText>
                    <RegularText style={{lineHeight: 14}} fixedWidth={150} fontSize={12} align='right'>
                        {" "}
                        $ {item.fiatMoney.totalAmount}{" "}
                    </RegularText>
                </View>
            }
        />
    )

    onRefresh = () => {
        this.sync()
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={"Simplex"}
                    type={HeaderType.Light}
                    backTo={BuyNavigatorScreens.SelectProviderBuy.routeName}
                    {...this.props}
                />
                <Container style={styles.container}>
                    <Row style={{flex: 1}}>
                        <FlatList
                            style={{position: "absolute", paddingRight: 5, left: 0, right: -5, top: 0, bottom: 0}}
                            data={this.state.purchaseOrders}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item.id + (Math.random() * 100).toString()}
                            initialNumToRender={10}
                            maxToRenderPerBatch={20}
                            refreshControl={
                                <RefreshControl
                                    tintColor={colors.text}
                                    refreshing={this.state.syncing}
                                    onRefresh={this.onRefresh}
                                />
                            }
                        />
                    </Row>
                    {!this.state.syncing && (
                        <FloatButton
                            onPress={() => this.props.navigation.navigate(SimplexNavigatorScreens.BuyMethodSimplex.routeName)}
                            iconName={"cart"}
                        />
                    )}
                </Container>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    card: {},
    icon: {
        width: 30,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: 5,
    },
})

const mapStateToProps = (state) => {
    return state.wallet
}

const mapDispatchToProps = (dispatch) => ({})

const SimplexMainScreen = connect(mapStateToProps, mapDispatchToProps)(_SimplexMainScreen)

export default SimplexMainScreen
