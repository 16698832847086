import React, { Component } from "react";
import { FlatList, Platform, ScrollView, Share, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { Route } from "@react-navigation/native";
import { CartRedeemable, EXPERIENCE, REDEEMABLE } from "@custom-types/ExpercienceModel";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import ExperienceService from "@core/services/ExperienceService";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import store from "@store/index";
import {
    hideModal,
    hidePopupMessage,
    showModal,
    showModalBottom,
    showPopup,
    showPopupMessage,
    showSnackbar,
} from "@store/actions/global";
import RedeemableOptionCard from "./components/RedeemableOptionCard";
import Constants from "expo-constants";
import { colors, settings } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import { emptyRedeemableCart, setReedemableCart } from "@store/actions/experiences.actions";
import FlatListBase from "@base/FlatListBase";
import RedeemableDetailModal from "./components/RedeemableDetailModal";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import ImageBase from "@components/image/ImageBase";
import Row from "@base/Row";
import RegularText from "@base/RegularText";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import moment from "moment";
import LeafletComponent from "@components/maps/Leaflet";
import AvatarBase from "@components/avatar/AvatarBase";
import ShareService from "@core/services/ShareService";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<string, { experience: EXPERIENCE }>;
    checkoutRedeemables: Array<CartRedeemable>;
    fiatCurrency: FiatCurrency;
}

interface State {
    experience: EXPERIENCE;
    showDescription: boolean;
    selectedRedeemable: EXPERIENCE;
    redirectUrl: string;
    redeemablesData: Array<CartRedeemable>;
    showBuyButton: boolean;
}

const { t } = i18n;

export class _ExperienceScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            experience: this.props.route?.params?.experience || null,
            showDescription: false,
            selectedRedeemable: null,
            redeemablesData: this.props.checkoutRedeemables || [],
            showBuyButton: false,
            redirectUrl:
                `https://share.${Constants.expoConfig?.extra?.url}?type=experience&id=${this.props.route?.params?.experience._id}` ||
                "",
        };
    }

    async componentDidMount() {
        const experience = await ExperienceService.getInstance().getMembership(
            this.props.route.params?.experience?._id
        );

        if (experience) {
            this.setState({ experience: experience });
        }

        const setRedeemable = this.state.experience.redeemableOptions.map((item: any) => {
            return { redeemable: item, quantity: 0 };
        });
        store.dispatch(setReedemableCart(setRedeemable));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.checkoutRedeemables !== this.props.checkoutRedeemables) {
            this.setState({ redeemablesData: this.props.checkoutRedeemables });

            const quantity = this.props.checkoutRedeemables?.filter((r: CartRedeemable) => {
                return r.quantity > 0;
            });
            this.setState({ showBuyButton: quantity?.length > 0 });
        }
    }

    renderRedeemable = ({ item }) => {
        return item.redeemable?.buyable ? (
            <RedeemableOptionCard
                quantity={item.quantity}
                redeemable={item.redeemable}
                onPress={() => this.redeemableDetail(item.redeemable)}
                fromExperience={true}
            />
        ) : null;
    };

    redeemableDetail = (redeemable: REDEEMABLE) => {
        store.dispatch(
            showModalBottom({
                modalContent: <RedeemableDetailModal navigation={this.props.navigation} redeemable={redeemable} />,
            })
        );
    };

    handleShare = async () => {
        ShareService.getInstance().handleShare(t("check_experience"), this.state.redirectUrl);
    };

    checkQuantity = (redeemables: Array<CartRedeemable>) => {
        const findItem = redeemables.find((item) => item.quantity > 0);

        return findItem ? true : false;
    };

    handleBackButton = async () => {
        if (this.checkQuantity(this.props.checkoutRedeemables)) {
            store.dispatch(
                showPopupMessage({
                    type: "MESSAGE",
                    message: t("confirm_go_back"),
                    onPressAccept: () => {
                        store.dispatch(emptyRedeemableCart());
                        store.dispatch(hidePopupMessage());
                        this.props.navigation.goBack();
                    },
                })
            );
        } else {
            this.props.navigation.goBack();
        }
    };

    buyNow = () => {
        if (this.checkQuantity(this.props.checkoutRedeemables)) {
            this.props.navigation.navigate(ExperienceNavigatorScreens.Checkout.routeName);
        } else {
            store.dispatch(showSnackbar({ type: "MESSAGE", message: t("add_item_warning") }));
        }
    };

    getDate = (start: Date | string, end: Date | string) => {
        const startDate = moment(start).locale("en");
        const endDate = moment(end).locale("en");

        if (startDate.isSame(endDate, "day")) {
            return startDate.format("LL");
        }
        if (startDate.isSame(endDate, "month")) {
            return `${startDate.format("MMMM D")} to ${endDate.format("D")}, ${startDate.format("YYYY")}`;
        }
        if (startDate.isSame(endDate, "year")) {
            return `${startDate.format("MMMM D")} to ${endDate.format("MMMM D")}, ${startDate.format("YYYY")}`;
        }
        return `${startDate.format("LL")} to ${endDate.format("LL")}`;
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.experience.name, 15)}
                    type={HeaderType.Light}
                    leftBtn={{
                        onPress: () => {
                            this.handleBackButton();
                        },
                        icon: "arrow-left",
                        size: 24,
                    }}
                    rightBtn={{
                        onPress: () => {
                            this.handleShare();
                        },
                        icon: "share",
                        size: 22,
                    }}
                    {...this.props}
                />
                <ScrollView>
                    <Container style={{ flex: 1 }}>
                        <View style={styles.card}>
                            <View style={styles.wrapper}>
                                <View style={styles.container}>
                                    <ImageBase
                                        autoSize={true}
                                        uri={
                                            this.state.experience.image?.carousel ||
                                            this.state.experience.image?.cover ||
                                            ""
                                        }
                                        style={styles.image}
                                    ></ImageBase>
                                </View>
                                <View style={styles.circleWrapper}>
                                    <View style={styles.circle}>
                                        <RegularText style={{ marginTop: Platform.OS == "web" ? -2 : 2 }}>
                                            {"🔥"}
                                        </RegularText>
                                    </View>
                                </View>
                                <View style={styles.details}>
                                    <BoldText style={{ position: "relative", marginBottom: 10 }} fontSize={20}>
                                        {this.state.experience.name}
                                    </BoldText>
                                    <View style={styles.avatarMerchant}>
                                        <AvatarBase
                                            uri={this.state.experience?.merchant?.image?.thumbnail}
                                            size={18}
                                        ></AvatarBase>
                                        <RegularText
                                            style={{ paddingHorizontal: 5 }}
                                            color={colors.labelText || colors.white}
                                            fontSize={12}
                                        >
                                            {t("created_by")}{" "}
                                            <BoldText fontSize={12} color={colors.labelText || colors.white}>
                                                {this.state.experience?.merchant?.name}
                                            </BoldText>
                                        </RegularText>
                                    </View>

                                    {this.state.experience.description.length && (
                                        <View style={{ marginVertical: 10, paddingTop: 10 }}>
                                            {!this.state.showDescription &&
                                                this.state.experience.description?.length > 149 ? (
                                                <RegularText
                                                    align={"justify"}
                                                    fontSize={16}
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    {trimHelper(this.state.experience.description, 150)}
                                                    <PressableBase
                                                        style={{ height: 16, marginLeft: 10 }}
                                                        onPress={() => this.setState({ showDescription: true })}
                                                    >
                                                        <RegularText
                                                            align="left"
                                                            fontSize={16}
                                                            style={{ color: colors.complementary }}
                                                        >
                                                            {t("see_more")}
                                                        </RegularText>
                                                    </PressableBase>
                                                </RegularText>
                                            ) : (
                                                <RegularText
                                                    align={"justify"}
                                                    fontSize={16}
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    {this.state.experience.description}
                                                </RegularText>
                                            )}
                                        </View>
                                    )}

                                    {this.state?.experience?.startDate && (
                                        <Row style={{ paddingTop: 10, alignItems: "center", }}>
                                            <View style={styles.icon}>
                                                <Icon color={colors.text} size={15} name="calendar-minus"></Icon>
                                            </View>
                                            <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                                {this.getDate(
                                                    this.state.experience?.startDate,
                                                    this.state.experience?.endDate
                                                )}
                                            </RegularText>
                                        </Row>
                                    )}

                                    {this.state.experience.location.description && (
                                        <Row style={{ paddingTop: 10, alignItems: "center", }}>
                                            <View style={styles.icon}>
                                                <Icon color={colors.text} size={16} name="address"></Icon>
                                            </View>
                                            <RegularText fontSize={14} style={{ paddingLeft: 5, flex: 1 }}>
                                                {this.state.experience.location.description}
                                            </RegularText>
                                        </Row>
                                    )}

                                    {this.state.experience.location.lng && this.state.experience.location.lat && (
                                        <LeafletComponent
                                            marker={[
                                                this.state.experience.location.lat,
                                                this.state.experience.location.lng,
                                            ]}
                                            markerImage={
                                                this.state.experience.merchant.image.thumbnail ||
                                                this.state.experience.merchant.image.cover
                                            }
                                        />
                                    )}
                                </View>
                            </View>
                            {this.state.experience && this.state.experience.redeemableOptions.length > 0 && (
                                <View style={{ padding: 10 }}>
                                    <FlatListBase
                                        data={this.state.redeemablesData}
                                        renderItem={this.renderRedeemable}
                                        keyExtractor={(item) => item.redeemable._id}
                                    />
                                </View>
                            )}
                        </View>
                        {this.state.showBuyButton && (
                            <InlineButton style={{ marginBottom: 10 }} onPress={this.buyNow} title={t("buy_now")} />
                        )}
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 10,
    },
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 10,
    },
    details: {
        width: "100%",
        paddingVertical: 10,
        paddingHorizontal: 15,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
    },
    circleWrapper: {
        position: "absolute",
        top: 15,
        left: 15,
    },
    circle: {
        height: 32,
        width: 32,
        borderRadius: 16,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    icon: {
        backgroundColor: colors.tertiaryShadow,
        height: 30,
        width: 30,
        borderRadius: 15,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end",
    },
    image: {
        width: "100%",
        height: "100%",
        borderTopLeftRadius: settings.cardRadius,
        borderTopRightRadius: settings.cardRadius,
    },
    avatarMerchant: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 20,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        checkoutRedeemables: state.experiences.checkoutRedeemables,
        experiences: state.experiences,
        fiatCurrency: state.experiences.selectedFiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExperienceScreen = connect(mapStateToProps, mapDispatchToProps)(_ExperienceScreen);

export default ExperienceScreen;
