import React, { Component } from "react";
import { View, StyleSheet, Platform } from "react-native";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";

interface Props {
    amount?: string;
    title?: string;
    color?: string;
    symbol?: string;
    subtitle?: string;
    testnet?: boolean
    style?: StyleSheet;
}

interface State {
    title: string;
    color: string;
}

export default class Balance extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            title: props.title,
            color: props.color ? props.color : colors.balanceColor,
        };
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            this.setState({
                title: this.props.title
            });
        }
    }

    render() {
        return (
            <View style={styles.container}>
                {this.props.testnet && (
                    <View
                        style={{
                            marginTop: -5,
                            alignSelf: "center",
                            paddingVertical: 2,
                            paddingHorizontal: 5,
                            backgroundColor: colors.secondary,
                            borderRadius: 5,
                        }}
                    >
                        <BoldText color={colors.white} align="center" fontSize={10}>
                            TESTNET
                        </BoldText>
                    </View>
                )}

                <View style={[styles.center, { borderColor: this.state.color }]}>
                    {this.props.symbol && (
                        <View style={styles.currencySymbol}>
                            <BoldText fontSize={30} color={this.state.color} align={"right"}>
                                {" "}
                                {this.props.symbol}{" "}
                            </BoldText>
                        </View>
                    )}
                    <BoldText fixedWidth={300} align="center" fontSize={40} color={this.state.color}>
                        {" "}
                        {this.props.amount}{" "}
                    </BoldText>
                </View>
                <RegularText align="center" fontSize={16} color={colors.text}>
                    {" "}
                    {this.state.title}{" "}
                </RegularText>
                {this.props.subtitle && (
                    <RegularText align="center" fontSize={12} color={colors.text}>
                        {" "}
                        {this.props.subtitle}{" "}
                    </RegularText>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        marginTop: 5,
        marginBottom: 20,
        alignItems: "center",
    },
    center: {
        flexDirection: "row",
        borderRadius: 15,
        width: 250,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    currencySymbol: {
        marginLeft: -20,
    },
});
