import React, { Component, useEffect, useState } from "react";
import { View, Text, StyleSheet, BackHandler, Platform } from "react-native";
import { colors } from "@styles/globalStyles";

import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import { prePrepareTransaction, prepareTransaction, selectCurrency } from "@store/actions/wallet";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import store from "@store/index";
import Wallet from "@core/wallet/Wallet";
import InlineButton from "@base/InlineButton";
import { Camera } from "expo-camera";
import i18n from "@i18n/i18n";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { MainNavigatorScreens } from "@navigation/MainNavigator";
import { SendNavigatorScreens } from "@navigation/SendNavigator";
import jsQR from "jsqr";
import ScannerBase from "./ScannerBase";

interface Props {
    navigation: NavigationType;
    route: any;
    currencies: Array<Currency>;
    totalBalance: number;
    fiatCurrency: FiatCurrency;
    selectCurrency: (id: any) => void;
}

interface State {
    previousAmount: number | string;
}

const { t } = i18n;

export class _ScannerAddressScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.executeBarCodeScanned = this.executeBarCodeScanned.bind(this);
        this.state = {
            previousAmount: this.props.route?.params ? this.props.route.params.amount : 0,
        };

        if (Platform.OS != 'web')
            BackHandler.addEventListener("hardwareBackPress", () => this.props.navigation.isFocused());
    }

    executeBarCodeScanned(qrCode) {
        const actionData = qrCode?.split(":") || "";

        const data = qrCode.split("?");
        const address = actionData[0] == "smartqr" ? actionData[2] : data[0];

        const params = {
            address: address,
            amount: this.state.previousAmount,
        };
        this.props.navigation.navigate({
            name: SendNavigatorScreens.SendMain.routeName,
            params,
            merge: true,
        });
    }

    render() {
        return (
            <ScannerBase
                returnBarCodeScanned={this.executeBarCodeScanned}
                navigation={this.props.navigation}
            ></ScannerBase>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    selectCurrency: (id) => dispatch(selectCurrency(id)),
});

const ScannerAddressScreen = connect(mapStateToProps, mapDispatchToProps)(_ScannerAddressScreen);

export default ScannerAddressScreen;

const styles = StyleSheet.create({});
