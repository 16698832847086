import React, {Component} from "react"
import {
    View,
    StyleSheet,
    BackHandler,
    Keyboard,
    Platform,
    EmitterSubscription,
    TouchableWithoutFeedback,
} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import RegularNumberInput from "@base/RegularNumberInput"
import Row from "@base/Row"
import SwitchSelector from "react-native-switch-selector"
import { colors, settings } from "@styles/globalStyles"
import RegularText from "@base/RegularText"
import InlineButton from "@base/InlineButton"
import {connect} from "react-redux"
import Currency from "@core/currencies/Currency"
import {FiatCurrency} from "@core/fiat/FiatCurrencies"
import store from "@store/index"
import {cleanOperation, prepareTransaction, prepareBuy} from "@store/actions/wallet"
import Wallet from "@core/wallet/Wallet"
import BigNumber from "bignumber.js"
import {Dimensions} from "react-native"
import {loading, ready, showPopup} from "@store/actions/global"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import OptionCard from "@components/cards/OptionCard"
import i18n from "@i18n/i18n"
import {KriptonService} from "@core/services/KriptonService"
import {setFiatCurrency} from "@store/actions/kriptonmarket.actions"
import {KriptonMarketNavigatorScreens} from "@navigation/KriptonMarketNavigator"
import {WalletNavigatorScreens} from "@navigation/WalletNavigator"
import {SendNavigatorScreens} from "@navigation/SendNavigator"

interface Props {
    navigation: NavigationType
    currencies: Array<Currency>
    selectedCurrency: string
    fiatCurrency: string
    currentOperation: any
    customMessage?: any
    next?: (transaction: any) => void
    onSuccessNavigate?: string
    country: string
}

interface State {
    amount: any
    payment_method: PaymentMethod
    convertedAmount: BigNumber
    switch: boolean
    fiat: number
    currency: Currency
    showError: boolean
    errorMessage: string
    screenHeight: any
    next?: (transaction: any) => void
    loading: boolean
    fiatCurrency: FiatCurrency
}

export enum PaymentMethod {
    CREDIT_CARD = "CREDIT_CARD",
    WIRE_TRANSFER = "WIRE_TRANSFER",
}

const {t} = i18n

export class _BuyMethodScreen extends Component<Props, State> {
    private currency: Currency
    private keyboardWillShowSub: EmitterSubscription
    private keyboardWillHideSub: EmitterSubscription
    private title = t("buy")
    private kriptonService: KriptonService

    constructor(props: Props) {
        super(props)
        this.kriptonService = KriptonService.getInstance()
        this.init = this.init.bind(this)
        this.state = {
            amount: null,
            payment_method: null,
            convertedAmount: null,
            switch: null,
            fiat: null,
            currency: null,
            showError: null,
            errorMessage: null,
            screenHeight: null,
            loading: null,
            fiatCurrency: null,
        }
        this.init()
        this.onPressNext = this.onPressNext.bind(this)
        this.props.navigation.addListener("focus", (payload) => {
            this.init()
        })

        if (Platform.OS != 'web')
            BackHandler.addEventListener("hardwareBackPress", () => {
                store.dispatch(cleanOperation())
                return false
            })
        this.keyboardWillShow = this.keyboardWillShow.bind(this)
        this.keyboardWillHide = this.keyboardWillHide.bind(this)
        this.showConvertedAmount = this.showConvertedAmount.bind(this)
    }

    setTitle(title: string) {
        this.title = title
    }

    componentDidMount() {
        this.keyboardWillShowSub = Keyboard.addListener("keyboardWillShow", this.keyboardWillShow)
        this.keyboardWillHideSub = Keyboard.addListener("keyboardWillHide", this.keyboardWillHide)
    }

    async init() {
        store.dispatch(loading())
        const country = this.props.country

        let fiatCurrency = this.props.fiatCurrency

        if (!fiatCurrency) {
            fiatCurrency = await this.kriptonService.getDefaultCurrency(country)
            store.dispatch(setFiatCurrency(fiatCurrency))
        }
        let f = new FiatCurrency(fiatCurrency, fiatCurrency, "$", fiatCurrency, 0)
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency)
        const op = this.props.currentOperation

        const fiat = f.fromDecimals(
            1 /
                (await this.kriptonService.getQuote(this.currency.getName(), fiatCurrency, fiatCurrency, 1))["data"][
                    "amount_out"
                ]
        )
        let amount = 0
        let showAddressError = false

        let s = this.state ? this.state.switch : false

        const state = {
            fiatCurrency: f,
            amount: amount,
            payment_method: PaymentMethod.WIRE_TRANSFER,
            convertedAmount: new BigNumber(0),
            switch: s,
            fiat: fiat,
            currency: Wallet.getInstance().findCurrencyById(this.props.selectedCurrency),
            showError: false,
            errorMessage: "",
            screenHeight: Platform.OS == "web" ? Math.round(Dimensions.get("window").height) - 80 : "100%",
            next:
                this.props.next ||
                ((transaction: any) => {
                    store.dispatch(
                        prepareTransaction({
                            transaction,
                            onSuccess: () => {
                                this.props.onSuccessNavigate
                                    ? this.props.navigation.navigate(this.props.onSuccessNavigate)
                                    : this.props.navigation.navigate(WalletNavigatorScreens.Send.routeName, {
                                          screen: SendNavigatorScreens.ConfirmSend.routeName,
                                      })
                            },
                            onError: (error) => {
                                store.dispatch(showPopup({type: "ERROR", message: error}))
                            },
                        })
                    )
                }),
            loading: false,
        }
        if (this.state) this.setState(state)
        store.dispatch(ready())
        return state
    }

    componentWillUnmount() {
        this.keyboardWillShowSub.remove()
        this.keyboardWillHideSub.remove()
    }

    keyboardWillShow = (event) => {
        if (Platform.OS == "ios") {
            this.setState({
                screenHeight: Math.round(Dimensions.get("window").height) - event.endCoordinates.height,
            })
        }
    }

    keyboardWillHide = (event) => {
        if (Platform.OS == "ios") {
            this.setState({
                screenHeight: "100%",
            })
        }
    }

    validateAmount(): boolean {
        const amount = this.state.switch ? this.state.amount : this.state.convertedAmount
        const minAmount = this.kriptonService.getMin()
        //const maxAmount = this.kriptonService.getMax()
        const isValid = amount > minAmount
        if (!isValid) {
            this.setState({
                showError: true,
                errorMessage: `${t("bigger_than")} $${minAmount}`,
            })
        }
        return isValid
    }

    validate() {
        return this.validateAmount()
    }

    onPressNext = async () => {
        if (this.validate()) {
            store.dispatch(loading())
            try {
                const res = await this.kriptonService.getQuote(
                    this.currency.getName(),
                    this.state.fiatCurrency.getName(),
                    this.state.switch ? this.state.fiatCurrency.getName() : this.currency.getName(),
                    Number(this.state.amount),
                    this.currency.getAddress()
                )
                store.dispatch(ready())
                store.dispatch(prepareBuy({data: this.kriptonService.processData(res)}))
                this.props.navigation.navigate(KriptonMarketNavigatorScreens.ConfirmBuyKripton.routeName)
            } catch (e) {
                store.dispatch(ready())
                this.setState({
                    showError: true,
                    errorMessage: t("provider_error"),
                })
            }
        }
    }

    selectMethod = (method) => {
        this.setState({payment_method: method})
    }

    getConvertedAmount = (value, fiat, s) => {
        this.state.fiatCurrency.getDecimals()
        BigNumber.config({EXPONENTIAL_AT: this.currency.getDecimals() + 1})
        const v = new BigNumber(value)
        const f = new BigNumber(fiat)
        return v.multipliedBy(f.exponentiatedBy(s ? -1 : 1)).decimalPlaces(s ? 8 : 2)
    }

    onChangeAmount = (value: number) => {
        let convertedAmount = this.getConvertedAmount(value ? value : 0, this.state.fiat, this.state.switch)
        this.setState({
            amount: value,
            convertedAmount: convertedAmount,
        })
    }

    switchCurrency = () => {
        this.setState({
            amount: this.state.convertedAmount,
            convertedAmount: this.state.amount,
            switch: !this.state.switch,
        })
    }

    showConvertedAmount() {
        return (
            this.state.convertedAmount +
            " " +
            (!this.state.switch ? this.state.fiatCurrency.getName() : this.currency.getPName())
        )
    }

    onPress() {
        if (Platform.OS == "ios") Keyboard.dismiss()
    }

    render() {
        return (
            <ScreenWrapper>
                {this.currency && this.state?.fiatCurrency && (
                    <>
                        <Row
                            style={{
                                justifyContent: "center",
                                position: "absolute",
                                marginTop: 65,
                                zIndex: 999,
                                width: "100%",
                            }}>
                            <SwitchSelector
                                options={[
                                    {label: this.currency.getPName(), value: 1},
                                    {
                                        label: this.state.fiatCurrency.getName(),
                                        value: this.state.fiat,
                                    },
                                ]}
                                initial={this.state.switch ? 1 : 0}
                                style={{
                                    width: 250,
                                    marginHorizontal: "auto",
                                    alignSelf: "center",
                                    marginVertical: 20,
                                }}
                                onPress={this.switchCurrency}
                                textColor={colors.text}
                                selectedColor={colors.white}
                                buttonColor={colors.secondary}
                                borderColor={colors.secondary}
                                animationDuration={250}
                                backgroundColor={colors.shadow}
                            />
                        </Row>
                        <TouchableWithoutFeedback onPress={this.onPress} style={{zIndex: -1, height: "100%"}}>
                            <View style={{height: this.state.screenHeight}}>
                                <Header
                                    title={`${this.title} ${this.currency.getPName()}`}
                                    type={HeaderType.Light}
                                    {...this.props}
                                    rightBtn={{
                                        onPress: () => {
                                            this.props.navigation.navigate(
                                                KriptonMarketNavigatorScreens.SelectFiatCurrencyKripton.routeName
                                            )
                                        },
                                        icon: "settings",
                                        size: 26,
                                    }}
                                />
                                <Container style={{marginTop: 60}}>
                                    <RegularNumberInput
                                        style={{height: 45}}
                                        onChangeText={this.onChangeAmount}
                                        value={this.state.amount ? this.state.amount : ""}
                                        align='center'
                                        fontSize={35}
                                        placeholder='0'
                                        editable
                                    />

                                    <RegularText align='center' fontSize={12} color={colors.text}>
                                        {this.showConvertedAmount()}
                                    </RegularText>
                                </Container>
                                <Container style={{flex: 1, flexDirection: "column-reverse"}}>
                                    <InlineButton
                                        title={t("next")}
                                        onPress={this.onPressNext}
                                        style={{marginHorizontal: 0, marginBottom: 20}}
                                    />

                                    <View style={{marginBottom: 5}}>
                                        {/* <OptionCard
                                    onPress={() => this.selectMethod(PaymentMethod.CREDIT_CARD)}
                                    selected={this.state.payment_method == PaymentMethod.CREDIT_CARD}
                                    title={t("credit_card")}
                                    subtitle='Powered by Simplex'
                                /> */}
                                        <OptionCard
                                            onPress={() => this.selectMethod(PaymentMethod.WIRE_TRANSFER)}
                                            selected={this.state.payment_method == PaymentMethod.WIRE_TRANSFER}
                                            title={t("wire_transfer")}
                                            subtitle='Powered by KriptonMarket'
                                        />
                                    </View>

                                    {this.state.showError && (
                                        <RegularText align={"center"} fontSize={12} color={colors.secondary}>
                                            {this.state.errorMessage}
                                        </RegularText>
                                    )}

                                    <View style={{flex: 1}}></View>
                                </Container>
                            </View>
                        </TouchableWithoutFeedback>
                    </>
                )}
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    button: {
        width: 40,
        height: 40,
        alignItems: "center",
        alignContent: "center",
        backgroundColor: colors.shadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 0,
        marginLeft: 10,
    },
    addressWrapper: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: 15,
        width: "100%",
    },
    addressContainer: {
        flex: 1,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
    },
})

const mapStateToProps = (state) => {
    return {
        ...state.wallet,
        fiatCurrency: state.kriptonMarket.currency,
        country: state.kriptonMarket.country,
    }
}

const mapDispatchToProps = (dispatch) => ({})

const BuyMethodScreen = connect(mapStateToProps, mapDispatchToProps)(_BuyMethodScreen)

export default BuyMethodScreen
