import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import ImageBase from "@components/image/ImageBase";
import { colors } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Icon from "@components/icons";
import Row from "@base/Row";
import moment from "moment";
import Card from "@base/Card";

interface Props {
    navigation: NavigationType;
    redeemable: any;
}

interface State {
    redeemable: any;
}

const { t } = i18n;

export class _RedeemableDetailModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            redeemable: this.props.redeemable || {},
        };
    }

    getDate = () => {
        const startDate = moment(this.state.redeemable?.startDate);
        const endDate = moment(this.state.redeemable?.endDate);

        if (startDate.isSame(endDate, "day")) {
            return startDate.format("LL");
        }
        if (startDate.isSame(endDate, "month")) {
            return `${startDate.format("MMMM D")} to ${endDate.format("D")}, ${startDate.format("YYYY")}`;
        }
        if (startDate.isSame(endDate, "year")) {
            return `${startDate.format("MMMM D")} to ${endDate.format("MMMM D")}, ${startDate.format("YYYY")}`;
        }
        return `${startDate.format("LL")} to ${endDate.format("LL")}`;
    };

    render() {
        return (

            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.wrapper}>

                    <Card style={{ marginVertical: 0, paddingTop: 0 }}>
                        <ImageBase
                            autoSize={true}
                            uri={
                                this.state.redeemable?.image?.carousel || this.state.redeemable?.image?.cover || ""
                            }
                        ></ImageBase>
                        <View style={styles.detailsContainer}>
                            <BoldText style={{ position: "relative", marginVertical: 10 }} fontSize={20}>
                                {this.state.redeemable?.name}
                            </BoldText>

                            <View style={{ marginBottom: 10 }}>
                                <BoldText style={styles.label}>{t("description")}</BoldText>
                                {this.state.redeemable?.description.length && (
                                    <RegularText align={"justify"} fontSize={16} style={{ marginBottom: 10 }}>
                                        {this.state.redeemable?.description}
                                    </RegularText>
                                )}

                                {this.state.redeemable?.location?.length && (
                                    <Row style={{ marginBottom: 10, alignItems: "center" }}>
                                        <View style={styles.icon}>
                                            <Icon color={colors.text} size={16} name="address"></Icon>
                                        </View>
                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {this.state.redeemable?.location}
                                        </RegularText>
                                    </Row>
                                )}

                                {this.state?.redeemable?.startDate && (
                                    <Row style={{ marginBottom: 10, alignItems: "center" }}>
                                        <View style={styles.icon}>
                                            <Icon color={colors.text} size={16} name="calendar-minus"></Icon>
                                        </View>
                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {this.getDate()}
                                        </RegularText>
                                    </Row>
                                )}
                            </View>

                            <View>
                                <BoldText style={styles.label}>{t("price")}</BoldText>
                                {this.state.redeemable.price ?
                                    <BoldText align="center" fontSize={16} style={{ marginRight: 10 }}>
                                        {`$ ${this.state.redeemable.price.toFixed(2)}`}
                                    </BoldText>
                                    :
                                    <Row style={{ marginBottom: 10, alignItems: "center" }}>
                                        <BoldText align="center" fontSize={16} style={{ marginRight: 10 }}>$ 0.00</BoldText>
                                        <View style={styles.bubble}>
                                            <BoldText
                                                align="center"
                                                fontSize={10}
                                                color={colors.labelText || colors.white}
                                                style={{ textTransform: "uppercase" }}
                                            >
                                                {t("free")}
                                            </BoldText>
                                        </View>
                                    </Row>
                                }
                            </View>
                        </View>
                    </Card>
                </View>
            </ScrollView>

        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 10
    },
    detailsContainer: {
        width: "100%",
        paddingVertical: 10,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
        margin: 0
    },
    circleWrapper: {
        position: "absolute",
        top: 15,
        left: 15,
    },
    labelWrapper: {
        position: "absolute",
        top: 15,
        right: 15,
        height: 32,
    },
    circle: {
        height: 32,
        width: 32,
        borderRadius: 16,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    icon: {
        backgroundColor: colors.tertiaryShadow,
        height: 30,
        width: 30,
        borderRadius: 15,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    addItem: {
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        width: 30,
        backgroundColor: colors.secondary,
        borderRadius: 50,
        alignContent: "center",
        margin: "auto",
    },
    quantityContainer: {
        alignItems: "center",
        borderRadius: 6,
        height: 30,
    },
    label: {
        marginBottom: 10,
        color: colors.grey,
        fontSize: 14,
    },
    bubble: {
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 8,
        paddingVertical: 3,
        borderRadius: 4,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const RedeemableDetailModal = connect(mapStateToProps, mapDispatchToProps)(_RedeemableDetailModal);

export default RedeemableDetailModal;
