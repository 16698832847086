import React, { Component } from "react";
import PressableBase from "./PressableBase";

interface Props {
    delay?: number;
    singleTap?: any;
    doubleTap?: any;
    children?;
}

interface State {
    delayTime: number;
    firstPress: boolean;
    lastTime: any;
    timer: any;
}

export default class DoubleTap extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            delayTime: this.props.delay ? props.delay : 150,
            firstPress: true,
            lastTime: new Date(),
            timer: false,
        };
    }

    _onTap = () => {
        // get the instance of time when pressed
        let now = new Date().getTime();

        if (this.state.firstPress) {
            this.setState({
                firstPress: false,
                timer: setTimeout(() => {
                    this.props.singleTap ? this.props.singleTap() : null;
                    this.setState({
                        firstPress: true,
                        timer: false,
                    });
                }, this.state.delayTime),
            });

            this.setState({ lastTime: now });
        } else {
            if (now - this.state.lastTime < this.state.delayTime) {
                this.state.timer && clearTimeout(this.state.timer);

                this.props.doubleTap ? this.props.doubleTap() : null;

                this.setState({ firstPress: true });
            }
        }
    };

    render() {
        return (
            <PressableBase onPressPriority={"high"} onPress={this._onTap}>
                {this.props.children}
            </PressableBase>
        );
    }

    componentWillUnmount() {
        // make sure to clear the timer when unmounting
        this.state.timer && clearTimeout(this.state.timer);
    }
}
