import React, { Component } from "react";
import { StyleSheet} from "react-native";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import SocialNetworkService from "../../social/service/SocialNetworkService";
import Container from "@base/Container";
import Post from "@custom-types/Post";
import PostSmallCard from "../../social/components/PostSmallCard";
import i18n from "@i18n/i18n";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import store from "@store/index";
import { setPOSTs } from "@store/actions/profile.actions";
import FlatListBase from "@base/FlatListBase";

const { t } = i18n;

interface Props {
    navigation: NavigationType;
    getProfileHeader: () => any;
    cliendID: string;
    profile?: Client;
    posts: Array<Post>;
    postExtra: {
        page: number;
        totalDocs: number;
        totalPages: number;
        posts: Array<Post>;
    };
    isAvatarAndAliasSet: boolean;
    isOwner: boolean;
    loadingBackground?: boolean;

    openPost?: (post) => any;
}

interface State {}

export class PostsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.openPost = this.openPost.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    openPost(post) {
        this.props.navigation.navigate(ProfileNavigatorScreens.ProfilePosts.routeName, {
            clientID: this.props.cliendID,
            selectedPost: post._id,
        });
    }

    async loadMore() {
        if (this.props.postExtra?.page < this.props.postExtra?.totalPages) {
            const posts = await SocialNetworkService.getInstance().getUserPosts(
                this.props.cliendID,
                this.props.postExtra?.page + 1,
            );
            store.dispatch(
                setPOSTs({
                    id: this.props.cliendID,
                    dataPOSTs: {
                        page: posts?.page || 1,
                        totalDocs: posts?.totalDocs || 0,
                        totalPages: posts?.totalPages || 0,
                        posts: this.props.postExtra.posts.concat(posts?.docs) || [],
                    },
                }),
            );
        }
    }

    renderItem = ({ item }) => (
        <PostSmallCard
            isLikeable={false}
            isOwner={this.props.isOwner}
            openPost={(post) => this.openPost(post)}
            post={item}
            navigation={this.props.navigation}
        />
    );

    render() {
        return (
            <Container style={{ flex: 1, paddingHorizontal: 5 }}>
                <FlatListBase
                    showsHorizontalScrollIndicator={false}
                    data={this.props.posts}
                    renderItem={this.renderItem}
                    keyExtractor={(item) => item.id || item._id}
                    contentContainerStyle={{ paddingBottom: 20 }}
                    numColumns={2}
                    ListHeaderComponent={this.props.getProfileHeader()}
                    columnWrapperStyle={{ justifyContent: "space-between", flex: 1 }}
                    initialNumToRender={this.props.postExtra?.totalDocs}
                    onEndReached={this.loadMore}
                />
            </Container>
        );
    }
}

const styles = StyleSheet.create({});
