import React, { Component } from 'react';
import { View, Text, StyleSheet, Button, Dimensions, Platform } from 'react-native';
import { Header } from '@components/header/Header';
import Container from '@base/Container';
import { HeaderType } from '@custom-types/HeaderType';
import { NavigationType } from '@custom-types/NavigationType';
import Row from '@base/Row';
import { colors } from '@styles/globalStyles';
import RegularText from '@base/RegularText';
import { connect } from 'react-redux';
import Currency from '@core/currencies/Currency';
import { FiatCurrency } from '@core/fiat/FiatCurrencies';
import RNSwipeVerify from 'react-native-swipe-verify'
import Icon from '@components/icons'
import BoldText from '@base/BoldText';
import Card from '@base/Card';
import store from '@store/index';
import { confirmTransaction, getTransactions } from '@store/actions/wallet';

import { showPopup } from '@store/actions/global';
import Wallet from "@core/wallet/Wallet";
import WarningCard from '@base/WarningCard';
import ScreenWrapper from '@components/wrapper/ScreenWrapper';
import i18n from "@i18n/i18n"
import InlineButton from '@base/InlineButton';
import { ExchangeNavigatorScreens } from '@navigation/ExchangeNavigator';
import BotCard from '@base/BotCard';
import Label from '@base/Label';

interface Props {
    navigation: NavigationType,
    currencies: Array<Currency>,
    selectedCurrency: string,
    fiatCurrency: FiatCurrency,
    currentOperation: any
}

interface State {
    amount: number,
    sendingTo: string,
    sendingFrom: string,
    fee: number,
    isVerified: boolean
}

const { t } = i18n

export class _ApproveExchangeScreen extends Component<Props, State> {
    private currency: Currency;
    private fiatCurrency: FiatCurrency;
    private swipeVerify: any;

    constructor(props: Props){
        super(props);

        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.fiatCurrency = this.props.fiatCurrency;
        const skeleton = this.currency.getImplementation().parseSkeleton(this.props.currentOperation.skeleton);
        this.state = {
            amount: skeleton.amount,
            sendingTo: skeleton.sendingTo,
            sendingFrom: skeleton.sendingFrom,
            fee: skeleton.fee,
            isVerified: false
       }

       this.onConfirm = this.onConfirm.bind(this);
    }

    onConfirm() { 
        this.setState({ isVerified: true });
        store.dispatch(confirmTransaction({
            currency: this.currency.getUnderlyingCurrency(),
            skeleton: this.props.currentOperation.skeleton,
            onSuccess: () =>
            {
                this.currency.setApproved(true);
                store.dispatch(getTransactions(this.currency));
                store.dispatch(showPopup({type: 'SUCCESS'}));
                this.props.navigation.navigate(ExchangeNavigatorScreens.Exchange.routeName);
            },
            onError: (error) => {
                console.warn(error);
                store.dispatch(showPopup({type: 'ERROR', message: t ('an_error_has_occurred')}));
                this.setState({ isVerified: false });
                try { 
                    this.swipeVerify.reset()
                } catch (e) { console.warn(e) }
            }
        }));
    }

    render() {
        return ( 
            <ScreenWrapper>
                <Header title={t('approve_tokens')} type={HeaderType.Light} {...this.props} />
                <Container style={styles.container}>

                    <View>
                        <BotCard message={t('approve_tokens_message')} />
                        <Card style={styles.card}>
                            <RegularText color={colors.text} align='center'>
                                {t('fee')}
                            </RegularText>
                            <BoldText color={colors.text} align='center'>
                                { this.state.fee } {this.currency.isSmart()?this.currency.getPName():this.currency.getUnderlyingCurrency().getPName()}
                            </BoldText>
                            <Label
                                text={`$ ${this.currency.getUnderlyingCurrency().toFiat(this.state.fee)}`}
                                color={colors.labelText}
                                align="center"
                                fontSize={12}
                            />
                        </Card>
                    </View>

                    <Row>
                        <View style={styles.swipContainer}>
                        {Platform.OS !== "web" ? (
                        <RNSwipeVerify ref={ref => this.swipeVerify = ref}
                            width="100%"
                            buttonSize={50}
                            borderColor={colors.secondary}
                            buttonColor={this.state.isVerified?colors.secondary:colors.secondary}
                            backgroundColor={colors.background}
                            textColor={colors.white}
                            borderRadius={50}
                            okButton={{ visible: true, duration: 400 }}
                            onVerified={this.onConfirm}
                            icon={
                                <Icon name={this.state.isVerified?'check':'right'} size={28} color={colors.white} align='center' />
                            }
                        > 
                            <RegularText color={colors.text}> {this.state.isVerified ? t('confirmed') : t('slide_to_confirm')}</RegularText>
                        </RNSwipeVerify>
                        ) : (
                            <InlineButton onPress={this.onConfirm} title={t("confirm_swap")} />
                        )}
                    </View>
                    </Row>
                </Container>
            </ScreenWrapper>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-between',
        paddingVertical: 20
    },
    buttons: {
        marginVertical: 15,
    },
    card: {
        
    },
    swipContainer: {
        flex:1,
        height: 58,
    }
});


const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({
    
});

const ApproveExchangeScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(_ApproveExchangeScreen);
  
export default ApproveExchangeScreen;
