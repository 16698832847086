import React, { Component } from "react";
import { View, StyleSheet, Animated, Easing } from "react-native";
import RegularText from "@base/RegularText";
import { colors, settings } from "@styles/globalStyles";
import Card from "@base/Card";
import GradientWrapper from "@components/wrapper/GradientWrapper";
import PressableBase from "@base/PressableBase";

interface Props {
    title: string;
    left?: any;
    onToggle: () => void;
    isOn: boolean;
    disabled?: boolean;
}

interface State {
    isOn: boolean;
    disabled: boolean;
}

export default class SwitchCard extends Component<Props, State> {
    private offsetX;
    private on = 22;
    private off = 4;

    constructor(props: Props) {
        super(props);
        this.state = {
            isOn: this.props.isOn ? this.props.isOn : false,
            disabled: this.props.disabled ? this.props.disabled : false,
        };
        this.offsetX = new Animated.Value(this.props.isOn ? this.on : this.off);
        this.onToggle = this.onToggle.bind(this);
    }

    onToggle() {
        this.setState({ isOn: !this.state.isOn }, () => {
            const toValue = this.state.isOn ? this.on : this.off;
            this.animate([
                {
                    value: this.offsetX,
                    config: {
                        toValue: toValue,
                        duration: 350,
                        easing: Easing.elastic(2),
                    },
                },
            ]);
        });
        this.props.onToggle ? this.props.onToggle() : null;
    }

    animate(animations: { value: Animated.Value; config: Partial<Animated.TimingAnimationConfig> }[]) {
        animations.map((animation) => {
            Animated.timing(animation.value, {
                ...animation.config,
                toValue: animation.config.toValue,
                useNativeDriver: false,
            }).start();
        });
    }

    render() {
        return (
            <Card
                left={this.props.left ? this.props.left : <RegularText> {this.props.title} </RegularText>}
                right={
                    <View>
                        <PressableBase
                            style={{ paddingVertical: 5 }}
                         
                            onPress={() => (this.state.disabled ? null : this.onToggle())}
                            accessibilityLabel={this.props.title}
                        >
                            <GradientWrapper disable={!this.state.isOn} style={styles.toggleSwitch}>
                                <View style={styles.circleWrapper}>
                                    <Animated.View
                                        style={[styles.insideCircle, { transform: [{ translateX: this.offsetX }] }]}
                                    />
                                </View>
                            </GradientWrapper>
                        </PressableBase>
                    </View>
                }
            />
        );
    }
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "white",
        borderRadius: settings.cardRadius,
        marginVertical: 5,
    },
    left: {
        flex: 0.5,
    },
    right: {
        flex: 0.5,
        alignItems: "flex-end",
    },
    leftTitle: {
        fontSize: 20,
        lineHeight: 22,
        marginTop: 7,
    },
    leftDesc: {
        lineHeight: 20,
        paddingLeft: 2,
    },
    rightDesc: {
        textAlign: "right",
    },
    toggleSwitch: {
        justifyContent: "flex-start",
        width: 46,
        borderRadius: 20,
        height: 24,
        backgroundColor: colors.tertiaryShadow,
        flexDirection: "row",
        alignItems: "center",
    },
    circleWrapper: {
        padding: 2,
        alignItems: "center",
        justifyContent: "center",
        height: 20,
        width: 20,
    },
    insideCircle: {
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        backgroundColor: "white",
        width: 18,
        height: 18,
        borderRadius: 9,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 2.5,
        elevation: 1.5,
    },
});
