import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { Platform,  View } from "react-native";
import { Avatar } from "react-native-elements";
import Icon from "@components/icons";
import RegularText from "@base/RegularText";
import BoldText from "@base/BoldText";
import { getColorOpacity } from "@utils/helpers/global/global";
import PressableBase from "@base/PressableBase";

interface Props {
    uri?: string;
    alias?: string;
    size?: number;
    accesory?: boolean;
    accesoryIconSize?: number;
    accesoryIiconName?: string;
    isOwner?: boolean;
    selected?: boolean;
    overlayContainerStyle?: string;
    onPress?: () => void;
    onLongPress?: () => void;
    onBlur?: () => void;
}

interface State {}

export class AvatarBase extends Component<Props, State> {
    render() {
        return (
            <View style={{ alignSelf: "center" }}>
                <View>
                    {this.props.uri && this.props.uri !== undefined ? (
                        <View style={{}}>
                            <Avatar
                                onPress={this.props.onPress}
                                size={this.props.size}
                                rounded
                                // containerStyle={
                                //     this.props.selected && {
                                //         borderWidth: 2,
                                //         borderColor: colors.complementary || colors.secondary,
                                //     }
                                // }
                                titleStyle={{ fontFamily: "NunitoRegular", color: colors.text }}
                                title={this.props.alias?.substring(0, 1).toUpperCase()}
                                source={{
                                    uri: this.props.uri && this.props.uri !== undefined ? this.props.uri : "",
                                }}
                            />
                            {this.props.accesory && (
                                <View
                                    style={{
                                        position: "absolute",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignSelf: "center",
                                        alignContent: "center",
                                        zIndex: 9999,
                                        bottom: 0,
                                        right: 1,
                                        height: 36,
                                        width: 36,
                                        overflow: "hidden",
                                        borderRadius: 18,
                                        backgroundColor: getColorOpacity(colors.floatButtonBackground, 0.8),
                                    }}
                                >
                                    <PressableBase onPress={this.props.onPress || null}>
                                        <Icon
                                            name={this.props.accesoryIiconName}
                                            size={18}
                                            color={colors.floatButtonText}
                                        />
                                    </PressableBase>
                                </View>
                            )}
                        </View>
                    ) : (
                        <View style={{ position: "relative" }}>
                            <PressableBase
                                onPress={this.props.onPress}
                                style={{
                                    backgroundColor:
                                        this.props.alias?.substring(0, 1) == "1" && !this.props.isOwner
                                            ? "#d5d5d5"
                                            : colors.avatarBase,
                                    height: this.props.size,
                                    width: this.props.size,
                                    borderRadius: Math.round(this.props.size / 2),
                                    justifyContent: "center",
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    overflow: "hidden",
                                    zIndex: 9999,
                                }}
                                accessibilityLabel="profile-button"
                            >
                                {this.props.alias?.substring(0, 1) == "1" && (
                                    <View
                                        style={{
                                            justifyContent: "center",
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Icon
                                            style={{
                                                paddingTop: !this.props.isOwner
                                                    ? Platform.OS == "web"
                                                        ? this.props.size / 3
                                                        : Platform.OS == "ios"
                                                        ? this.props.size / 5
                                                        : this.props.size / 4
                                                    : 0,
                                            }}
                                            name={this.props.isOwner ? "person-profile" : "person-fill"}
                                            size={!this.props.isOwner ? this.props.size / 1.1 : this.props.size / 2}
                                            color={colors.avatarBase}
                                        />
                                    </View>
                                )}
                                <BoldText
                                    color={colors.floatButtonText}
                                    align="center"
                                    fontSize={this.props.size ? this.props.size / 2.2 : 16}
                                >
                                    {this.props.alias?.substring(0, 1) !== "1"
                                        ? this.props.alias?.substring(0, 1).toUpperCase()
                                        : ""}
                                </BoldText>
                            </PressableBase>
                            {this.props.accesory && (
                                <PressableBase
                                    onPress={this.props.onPress || null}
                                    style={{
                                        position: "absolute",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        zIndex: 9999,
                                        bottom: 0,
                                        right: 1,
                                        height: 36,
                                        width: 36,
                                        overflow: "hidden",
                                        borderRadius: 18,
                                        backgroundColor: getColorOpacity(colors.floatButtonBackground, 0.8),
                                    }}
                                    accessibilityLabel="profile-button"
                                >
                                    <Icon
                                        name={this.props.accesoryIiconName}
                                        size={15}
                                        color={colors.floatButtonText}
                                    />
                                </PressableBase>
                            )}
                        </View>
                    )}
                </View>
            </View>
        );
    }
}

export default AvatarBase;
