import React, { Component } from 'react';
import { View,  StyleSheet } from 'react-native';
import { colors } from '@styles/globalStyles';
import { Header } from '@components/header/Header';
import Container from '@base/Container';
import { HeaderType } from '@custom-types/HeaderType';
import Row from '@base/Row';
import InlineButton from '@base/InlineButton';
import { NavigationType } from '@custom-types/NavigationType';
import RegularText from '@base/RegularText';
import SwitchSelector from 'react-native-switch-selector';
import ExchangeCard from '@components/exchange/ExchangeCard';
import Icon from '@components/icons';
import Currency from '@core/currencies/Currency';
import Wallet from '@core/wallet/Wallet';
import { connect } from 'react-redux';
import store from '@store/index';
import { selectExchangeFrom, selectExchangeTo, prepareExchange } from '@store/actions/wallet';
import { showPopup } from '@store/actions/global';
import ScreenWrapper from '@components/wrapper/ScreenWrapper';
import i18n from "@i18n/i18n"
import { ExchangeNavigatorScreens } from '@navigation/ExchangeNavigator';
import { remove } from 'fs-extra';
import PressableBase from '@base/PressableBase';

interface Props {
    navigation: NavigationType;
    from: Currency,
    to: Currency
}

interface State {
    from: {
        currency: Currency,
        amount: number
    },
    to: {
        currency: Currency,
        amount: number
    },
    error: string
}

const { t } = i18n


class _ExchangeScreen extends Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.onPressNext = this.onPressNext.bind(this);
        this.state = this.initState(props)
    }

    isValidPair(currency: Currency, pairs: Array<any>) {
        if(!currency)
            return false;

        return pairs.find(p => p.name.toUpperCase() == currency.getId().toUpperCase());
    }

    initState(props) {
        const wallet = Wallet.getInstance();
        const from:Currency = props.from?props.from:wallet.getCurrency('ETH');
        const pairs = from.getPairs();
        if (!pairs || pairs.length == 0) {
            return {
                from: {
                    currency: from,
                    amount: 0
                },
                to: {
                    currency: from,
                    amount: 0
                },
                error: null
            }
        }

        const to = (this.isValidPair(props.to, pairs))?props.to:wallet.getCurrency(pairs[0].name);

        return {
            from: {
                currency: from,
                amount: 0
            },
            to: {
                currency: to,
                amount: 0
            },
            error: null
        }
    }

    componentWillReceiveProps(props) {
        this.setState(this.initState(props));
    }

    componentDidMount() {
        let props = this.props;
        if (props.to && props.to.isTestnet() != props.from.isTestnet()) {
            props = { ...props, to: null };
        }
        this.setState(this.initState(props));
    }

    onPress = () => {
        const from = this.state.from;
        const to = this.state.to;
        store.dispatch(selectExchangeFrom(to));
        store.dispatch(selectExchangeTo(from));
        this.setState({from: to, to: from});
    }

    getError = () => {
        const amount = this.state.from.amount;
        const symbol = this.state.from.currency.getSymbol();
        const balance = this.state.from.currency.getBalance() 
        const formatBalance = this.state.from.currency.getFormatBalance();
        const minimum = this.getMinAmount();
        const dAmount = this.state.from.currency.toDecimals(amount);
        
        if(amount < minimum) 
            return `${t('exchange_amount')} ${minimum} ${symbol}`
        
        if(dAmount > balance)
            return `${t('exchange_funds')} ${formatBalance} ${symbol}`

    }

    onAmountFromChange = (amount:number) => {
        const from = this.state.from.currency;
        const to = this.state.to.currency;
        const pair = this.state.from.currency.getPair(to.getId());
        this.setState({
            from: {currency: from, amount: amount}, 
            to: {currency: to, amount: amount*(pair?.min || 0)},
            error: this.getError()
        });
    }

    onPressNext() {
        const error = this.getError();
        if(error){
            this.setState({error:error });
            setTimeout(() => { this.setState({error: null }) }, 3000);
        }else {
            store.dispatch(prepareExchange({
                swap: {
                    from: this.state.from.currency,
                    to: this.state.to.currency,
                    amount: this.state.from.amount
                },
                onSuccess: (isApproved) => {
                    if(isApproved){
                        this.props.navigation.navigate(ExchangeNavigatorScreens.ConfirmExchange.routeName);
                    }else {
                        if(this.state.from.currency.isApproving()) {
                            this.props.navigation.navigate(ExchangeNavigatorScreens.ApprovingExchange.routeName);
                        } else {
                            this.props.navigation.navigate(ExchangeNavigatorScreens.ApproveExchange.routeName);
                        }
                    }
                },
                onError: (error) => {
                    store.dispatch(showPopup({type: 'ERROR', message:error}));
                }
            }));
        }
    }

    onSelectFrom = () => {
        this.props.navigation.navigate(ExchangeNavigatorScreens.SelectCurrencyFrom.routeName)
    }

    onSelectTo = () => {
        this.props.navigation.navigate(ExchangeNavigatorScreens.SelectCurrencyTo.routeName)
    }

    switchAmount = (value: number) => {
        this.onAmountFromChange(value);
    }

    getMinAmount = () => {
        return 0.00001;
    }

    getHalfAmount = () => {
        return this.getMaxAmount()/2;
    }

    getMaxAmount = () => {
        const currency = this.state.from.currency;
        return currency.fromDecimals(currency.getBalance());
    }

    render() {
        return ( 
            <ScreenWrapper>
                <Header {...this.props} 
                    title={t('exchange')}
                    type={HeaderType.Light}
                    rightBtn={{
                        onPress: () => {
                            this.props.navigation.navigate(ExchangeNavigatorScreens.ExchangeHistory.routeName)
                        },
                        icon: "clock-history",
                        iconType: "custom"
                    }}
                />
                <Container>
                    <View style={styles.container}>
                        <ExchangeCard 
                            currency={this.state.from.currency}
                            amount={this.state.from.amount}
                            onAmountChange={this.onAmountFromChange}
                            onPress={this.onSelectFrom}
                        />

                        <Row style={{alignItems:"center", marginVertical: 15}}>
                            <View style={styles.divisor}></View>
                            <View style={styles.divisorIcon}>
                                <PressableBase onPress={this.onPress}>
                                    <Icon name='exchange' size={25} color={colors.white} align='center' style={{lineHeight: 39}} />
                                </PressableBase>
                            </View>
                        </Row>

                        <ExchangeCard 
                            currency={this.state.to.currency}
                            amount={this.state.to.amount}
                            onPress={this.onSelectTo}
                        />
                    </View>


                    <Row style={{justifyContent:'center'}}>
                        <SwitchSelector options={[
                                { label: 'MIN', value: this.getMinAmount() },
                                { label: 'HALF', value: this.getHalfAmount() },
                                { label: 'MAX', value: this.getMaxAmount() }
                            ]} 
                            initial={0} 
                            style={{width:250, marginHorizontal: 'auto', alignSelf:'center', marginVertical: 20}}
                            onPress={this.switchAmount}
                               textColor={colors.text} 
                            selectedColor={colors.white}
                            buttonColor={colors.secondary}
                            borderColor={colors.secondary}
                            animationDuration={250}
                            backgroundColor={colors.shadow} />
                    </Row>
                </Container>
                <Container style={{flex: 1, flexDirection: 'column-reverse'}}>
                    <View style={{marginTop: 20}}>
                        <InlineButton title={t('next')} onPress={this.onPressNext} style={{marginHorizontal:0, marginBottom: 20}} />
                    </View>
                    
                    {this.state.error &&
                    <RegularText
                            align={'center'}
                            fontSize={12} 
                            color={colors.secondary}> 
                        {this.state.error}
                    </RegularText>
                    }
                    
                    <View style={{flex:1}}></View>
                </Container>
            </ScreenWrapper>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.shadow,
        borderRadius: 20,
        padding: 20
    },
    divisor: {
        height: 1,
        backgroundColor: colors.background,
        flex: 1
    },
    divisorIcon: {
        width: 40,
        height: 40,
        marginLeft: 10,
        backgroundColor: colors.secondary,
        borderRadius: 40,
        alignItems: 'center'
    }
});

const mapStateToProps = (state) => {
    return {
        from: state.wallet.selectedExchangeFrom,
        to: state.wallet.selectedExchangeTo,
    };
};

const mapDispatchToProps = (dispatch) => ({
    
});

const ExchangeScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_ExchangeScreen);
  
export default ExchangeScreen;

