import { getEnv } from "@utils/helpers/global/global";
import store from "@store/index";
import { loading, maintenanceMode } from "@store/actions/global";
import Constants from "expo-constants";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import Wallet from "@core/wallet/Wallet";

export enum Modules {
    "walletModule" = "walletModule",
    "socialNetworkModule" = "socialNetworkModule",
    "dappsModule" = "dappsModule",
    "nftsModule" = "nftsModule",
    "poapsModule" = "poapsModule",
    "experiencesModule" = "experiencesModule",
    "blogModule" = "blogModule",
    "bannersModule" = "bannersModule",
}

export enum Services {
    "walletConnectService" = "walletConnectService",
    "exchangeService" = "exchangeService",
    "buyService" = "buyService",
    "sellService" = "sellService",
    "oAuthService" = "oAuthService",
    "testnetService" = "testnetService",
}

export enum SocialNetworkType {
    "close" = "close",
    "open" = "open",
    "exclusive" = "exclusive",
}

export enum ExperienceModuleType {
    "open" = "open",
    "exclusive" = "exclusive",
}

export enum NFTsModules {
    "mint" = "mint",
    "transfer" = "transfer",
    "list" = "list",
    "marketplace" = "marketplace",
}

export enum SkinType {
    "base" = "base",
    "events" = "events",
    "crypto" = "crypto",
    "memberships" = "memberships",
    "sports" = "sports",
    "assets" = "assets",
    "nfts" = "nfts",
    "news" = "news",
}

export class ModuleControlService {
    private static instance: ModuleControlService;

    private Modules = {
        walletModule: false,
        socialNetworkModule: false,
        dappsModule: false,
        nftsModule: false,
        poapsModule: false,
        experiencesModule: false,
        blogModule: false,
        bannersModule: false,
    };

    private Services = {
        walletConnectService: false,
        exchangeService: false,
        testnetService: false,
        buyService: false,
        sellService: false,
        oAuthService: false,
    };

    private App = {
        _id: "",
        name: "",
        enabled: false,
        socialNetwork: "",
        maintenanceMode: false,
        experienceModuleType: ExperienceModuleType.open,
        skins: SkinType.base,
        nftProps: { mint: false, transfer: false, list: false, marketplace: false },
    };

    static getInstance(): ModuleControlService {
        if (!ModuleControlService.instance) {
            ModuleControlService.instance = new ModuleControlService();
        }
        return ModuleControlService.instance;
    }

    async init() {
        try {
            const headers = ApiService.headers();
            const version = Constants.expoConfig.version;
            const url = `${getEnv("API_URL")}app/versions/${version}`;
            const { data } = await axios.get(url, { headers });

            this.Modules = data.modules;
        
            this.Services = {
                walletConnectService: data.services.walletConnectService,
                exchangeService: data.services.exchangeService,
                buyService: data.services?.buyService && Constants.expoConfig?.extra?.buyProviders?.length > 0,
                sellService: data.services?.buyService && Constants.expoConfig?.extra?.sellProviders?.length > 0,
                oAuthService: data.services.oAuthService,
                testnetService: data.services.testnetService,
            };
            
            this.App = data.wallet;

            this.App.skins = data.wallet?.skinType || SkinType.base;
        } catch (e) {}
    }

    appInMaintenance() {
        //let maintenance = true;
       let maintenance = this.App.maintenanceMode;
        if (maintenance) {
            store.dispatch(maintenanceMode());
        }

        return maintenance;
    }

    isModuleEnabled(module: Modules) {
        try {
            switch (module) {
                case Modules.poapsModule:
                    return Wallet.getInstance().isTestnet() ? false : this.Modules[module];

                default:
                    return this.Modules[module];
            }
        } catch (e) {}
        return false;
    }
    isServiceEnabled(service: Services) {
        try {
            return this.Services[service];
        } catch (e) {}
        return false;
    }

    getActiveExperienceModuleType(experienceModuleType) {
        try {
            return this.App.experienceModuleType == experienceModuleType;
        } catch (e) {}
    }

    getSocialNetworkType() {
        try {
            return this.App.socialNetwork;
        } catch (e) {}
    }

    isNecessaryUserVerified(verified) {
        try {
            if (!this.Modules.socialNetworkModule) {
                return false;
            }
            return this.App.socialNetwork == SocialNetworkType.exclusive ? verified : true;
        } catch (e) {}
    }

    isNFTsModuleEnabled(nftsModule: NFTsModules) {
        if (!this.Modules.nftsModule) return false;

        return this.App.nftProps[nftsModule] || false;
    }

    getDefaultScreenCompatibility() {
        const defaultScreen = Constants.expoConfig?.extra?.defaultScreen || "";
        switch (defaultScreen) {
            case "NFTs":
                return this.isModuleEnabled(Modules.nftsModule) ? defaultScreen : "";

            case "Dapps":
                return this.isModuleEnabled(Modules.dappsModule) ? defaultScreen : "";

            case "SocialNetwork":
                return this.isModuleEnabled(Modules.socialNetworkModule) ? defaultScreen : "";

            case "Experiences":
                return this.isModuleEnabled(Modules.experiencesModule) ? defaultScreen : "";
            default:
                return "";
        }
    }

    getSkin = (skins: SkinType) => {
        try {
            return this.App.skins == skins;
        } catch (e) {}
    };
}
