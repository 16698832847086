import React, { Component, useRef } from "react";
import { View, StyleSheet, FlatList, RefreshControl } from "react-native";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import Post from "@custom-types/Post";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Container from "@base/Container";
import { colors } from "@styles/globalStyles";
import i18n from "@i18n/i18n";
import PostCard from "@screens/social/components/PostCard";
import { connect } from "react-redux";
import { Route } from "@react-navigation/native";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import store from "@store/index";
import { setPOSTs } from "@store/actions/profile.actions";

interface Props {
    navigation: NavigationType;
    route: Route<string, { clientID: string; selectedPost: string }>;
    profilePosts?: Array<Post>;
    selectedPost?: Post;
    client?: Client;
    profiles: any;
    onFollow?: (clientID) => any;
    loadMore?: () => any;
}

interface State {
    index: number;
    // posts: Array<Post>;
    // syncing: boolean;
    // page: number;
    // clientID: string;
}

const { t } = i18n;

export class _ProfilePostsScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const index = this.props.profiles[this.props.route.params?.clientID]?.POSTs?.posts
            .map((post: Post) => post.id)
            .indexOf(this.props.route.params?.selectedPost);
        this.state = {
            index: index ? index : 0,
        };
        this.loadMore = this.loadMore.bind(this);
    }

   

    renderItem = ({ item }) => {
        return <PostCard isLikeable={true} post={item} navigation={this.props.navigation} />;
    };

    async loadMore() {
     
        if (
            this.props.profiles[this.props.route.params?.clientID]?.POSTs?.page <
            this.props.profiles[this.props.route.params?.clientID]?.POSTs?.totalPages
        ) {
            const posts = await SocialNetworkService.getInstance().getUserPosts(
                this.props.route.params?.clientID,
                this.props.profiles[this.props.route.params?.clientID]?.POSTs?.page + 1,
            );
       
            store.dispatch(
                setPOSTs({
                    id: this.props.route.params?.clientID,
                    dataPOSTs: {
                        page: posts?.page || 1,
                        totalDocs: posts?.totalDocs || 0,
                        totalPages: posts?.totalPages || 0,
                        posts:
                            this.props.profiles[this.props.route.params?.clientID]?.POSTs?.posts.concat(posts?.docs) ||
                            [],
                    },
                }),
            );
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header {...this.props} title={t("posts")} type={HeaderType.Light} />
                <Container style={{ paddingHorizontal: 0, flex: 1 }}>
                    <FlatList
                        data={this.props.profiles[this.props.route.params?.clientID]?.POSTs?.posts}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.id}
                        contentContainerStyle={{
                            paddingBottom: 20,
                        }}
                        initialNumToRender={4}
                        maxToRenderPerBatch={10}
                        initialScrollIndex={this.state.index}
                        onScrollToIndexFailed={(info) => {
                            const wait = new Promise((resolve) => setTimeout(resolve, 500));
                            wait.then(() => {
                                return;
                            });
                        }}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                //onRefresh={this.onRefresh}
                                refreshing={false}
                            />
                        }
                        onEndReachedThreshold={1}
                        onEndReached={this.loadMore}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        profiles: state.profile.profiles,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ProfilePostsScreen = connect(mapStateToProps, mapDispatchToProps)(_ProfilePostsScreen);

export default ProfilePostsScreen;
