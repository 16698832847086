import {StyleSheet, TouchableHighlight, View} from "react-native"
import Card from "@base/Card"
import React, {Component} from "react"
import {colors} from "@styles/globalStyles"
import {Chat} from "@custom-types/Chat"
import store from "@store/index"
import {selectChat, selectContact} from "@store/actions/chat.actions"
import {ChatService} from "@core/services/ChatService"
import {connect} from "react-redux"
import Icon from "@components/icons"
import {SearchCardInterface} from "@custom-types/SearchCardInterface"
import {setSearch, showPopup} from "@store/actions/global"
import {selectChatsAsArray} from "@store/reducers/chat.reducer"
import AvatarBase from "@components/avatar/AvatarBase"
import MessageCard from "./MessageCard"
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator"

interface Props {
    item: SearchCardInterface
    chats: Array<Chat>
    navigation: any
    onLongPress?: any
    selected?: boolean
}

interface State {
    initial: string
}

export class _ContactClientCard extends Component<Props, State> {
    chatService: ChatService

    constructor(props) {
        super(props)

        this.redirectToChat = this.redirectToChat.bind(this)
    }

    async componentDidMount() {
        this.chatService = await ChatService.getInstance()
    }

    shouldComponentUpdate(nextProps, nextState){
        return nextProps != this.props;
    }

    async redirectToChat() {
        try {
            const client = this.props.item.getClient()
            let chat = await this.chatService.getById(client._id);

            if (!chat) {
                store.dispatch(showPopup({type: "ERROR", message: chat.toString()}))
                return;
            }

            store.dispatch(selectChat(chat))
            this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName)
            store.dispatch(setSearch(""));
           
        } catch (e) {
            console.warn(e)
        }
    }

    render() {
        return (
            <MessageCard
                onLongPress={this.props.onLongPress}
                selected={this.props.selected}
                onPress={this.redirectToChat}
                profileImagePath={this.props.item.getClient().profileImagePath?.square}
                initial={this.props.item.getClient().alias[0]}
                title={this.props.item.getTitle()}
                subtitle={this.props.item.getSubtitle()}
                navigation={this.props.navigation}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        chats: selectChatsAsArray(state),
    }
}

const mapDispatchToProps = (dispatch) => ({})

const ContactClientCard = connect(mapStateToProps, mapDispatchToProps)(_ContactClientCard)

export default ContactClientCard

const styles = StyleSheet.create({
    touchable: {
        marginHorizontal: 5,
    },
    viewContainer: {
        flex: 1,
        flexDirection: "row",
    },
    image: {
        width: 45,
        height: 45,
        borderRadius: 150 / 2,
        overflow: "hidden",
        borderWidth: 1,
    },
    textName: {
        color: colors.text,
        fontSize: 18,
    },
    textAlias: {
        fontSize: 11,
        marginTop: 3,
        color: colors.grey,
    },
    letter: {
        fontSize: 20,
        color: colors.text,
        width: 10,
    },
})
