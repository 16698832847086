import Currency, { IConstructorCurrency } from "@core/currencies/Currency"
import { FiatCurrency } from "@core/fiat/FiatCurrencies"
import { Networks } from "@core/network/Networks"
import { ProviderType } from "@custom-types/ProviderType"
import Constants from "expo-constants"
import { isCurrencyEnabled } from "@core/utils/CurrencyEnabled"
import ETHImplementation from "@core/currencies/ethereum/eth/ETHImplementation"

export default class ArbitrumLOCKER extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            id: "ARBITRUMLOCKER",
            name: "LOCKER",
            simbol: "LOCKER",
            fullName: "Locker",
            decimals: 18,
            implementation: ETHImplementation,
            chainId: parameters.testnet ? 421613 : 42161,
            networkID: parameters.testnet ? 61 : 60,
            color: "#6e5c28",
            icon: "locker",
            networkSymbol: "ARB",
            underlyingCurrency: "arb",
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "ARBITRUMLOCKER", false),
        })
    }
}
