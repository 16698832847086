import { eventChannel } from "redux-saga";
import { select, put, call, takeEvery, take, spawn, delay } from "redux-saga/effects";
import {
    READY,
    showPopup,
    SHOW_POPUP,
    hidePopup,
    ACCEPT_TERMS,
    RESET,
    hideSnackbar,
    SHOW_SNACKBAR,
} from "@store/actions/global";
import { Platform } from "react-native";
import * as Updates from "expo-updates";

function handleReset() {
    if (Platform.OS !== "web") {
        Updates.reloadAsync();
    } else {
        window.location.reload();
    }
}

function* handleShowPopup(action) {
    const options: PopupOptions = action.options;

    yield delay(options.type == "ERROR" ? 3000 : 2000);
    yield put(hidePopup());
}

function* handleShowSnackbar(action) {
    const options: SnackbarOptions = action.options;
    yield delay(options.type == "ERROR" || "MESSAGE" ? 3000 : 2000);
    yield put(hideSnackbar());
}

export default function* main() {
    while (true) {
        yield takeEvery(SHOW_SNACKBAR, handleShowSnackbar);
        yield takeEvery(RESET, handleReset);
        yield spawn(handleShowPopup, yield take(SHOW_POPUP));
    }
}
