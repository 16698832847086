import React, { Component } from "react";
import { View, StyleSheet, Text, TextStyle, Animated, Easing } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";
import { colors } from "@styles/globalStyles";
import Icon from "@components/icons";
import i18n from "@i18n/i18n";
import Row from "./Row";


interface Props {
    onPress?: () => void;
    style?: TextStyle;
    title?: string;
    message: string;
    
}

interface State {
    onPress: () => void;
    sizeBot: any;
}

const { t } = i18n;

export default class BotCard extends Component<Props, State> {
    protected sizeBot = 0.8;
    intervalID: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            onPress: props.onPress ? props.onPress : null,
            sizeBot: new Animated.Value(this.sizeBot),
        };
    }

    animate(animations: { value: Animated.Value; config: Partial<Animated.TimingAnimationConfig> }[]) {
        animations.map((animation) => {
            Animated.timing(animation.value, {
                ...animation.config,
                toValue: animation.config.toValue,
                useNativeDriver: false,
            }).start();
        });
    }

    componentDidMount() {
        this.animate([
            {
                value: this.state.sizeBot,
                config: {
                    toValue: 1,
                    duration: 700,
                    delay: 2000,
                    easing: Easing.elastic(3),
                },
            },
        ]);
        this.intervalID = setInterval(() => {
            this.animate([
                {
                    value: this.state.sizeBot,
                    config: {
                        toValue: 0.8,
                        duration: 500,
                    },
                },
            ]);

            setTimeout(() => {
                this.animate([
                    {
                        value: this.state.sizeBot,
                        config: {
                            toValue: 1,
                            duration: 700,
                            easing: Easing.elastic(3),
                        },
                    },
                ]);
            }, 600);
        }, 6000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        return (
            <View style={{ paddingTop: 0, paddingBottom: 20, width: "100%", overflow: "hidden" }}>
                <Row>
                    <View style={styles.bot}>
                        <Animated.View
                            style={{
                                transform: [{ scale: this.state.sizeBot }, { perspective: 1000 }],
                            }}
                        >
                            <Icon name={"robot"} size={30} color={colors.text}></Icon>
                        </Animated.View>
                    </View>
                    <View style={styles.card}>
                        <View style={styles.cardTop}></View>
                        <RegularText color={colors.text} align={"justify"}>
                            {this.props.title && (
                                <BoldText align={"justify"} color={colors.text}>
                                    {this.props.title}{" "}
                                </BoldText>
                            )}
                            {this.props.message}
                        </RegularText>
                    </View>
                </Row>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        marginTop: 15,
        marginLeft: 15,
        marginRight: 0,
        paddingHorizontal: 12,
        paddingTop: 5,
        paddingBottom: 8,
        backgroundColor: colors.tertiaryShadow,
        borderRadius: 8,
        borderTopLeftRadius: 1,
        zIndex: 9999,
    },
    cardTop: {
        position: "absolute",
        left: -8.1,
        top: 0,
        width: 0,
        height: 12,
        borderRightWidth: 8,
        borderTopWidth: 0,
        borderBottomWidth: 10,
        borderRightColor: colors.tertiaryShadow,
        borderTopColor: "transparent",
        borderBottomColor: "transparent",
        borderTopLeftRadius: 1,
    },

    bot: {
        backgroundColor: colors.tertiaryShadow,
        height: 50,
        width: 50,
        borderRadius: 25,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
});
