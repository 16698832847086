import React, {Component} from "react";
import {SearchBar} from "@components/chat/SearchBar";
import {connect} from "react-redux";
import {Contact} from "@custom-types/Contact";
import {Chat} from "@custom-types/Chat";
import debounce from "lodash.debounce";
import {ClientService} from "@core/services/ClientService";
import { selectChatsAsArray } from "@store/reducers/chat.reducer";

interface Props {
    contacts: Array<Contact>,
    chats: Array<Chat>,
    setChats: any,
    setContacts: any,
    setAliasClient: any,
    setSearch?: any;
    search: string;
}

interface State {}

export class _SearchAllBar extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.filter = this.filter.bind(this);
        this.filterChats = this.filterChats.bind(this);

        this.filterChats = debounce(this.filterChats, 300);
        this.filterContacts = debounce(this.filterContacts, 300);
        this.filterAlias = debounce(this.filterAlias, 300);
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(prevProps.search !== this.props.search) {
            await this.filter();
        }
    }

    async filter() {
        let value = this.props.search;
        this.props.setChats([]);
        this.props.setContacts([]);
        this.props.setAliasClient([]);

        await this.filterChats(value);
        this.filterContacts(value);
        await this.filterAlias(value);
    }

    async filterChats(value): Promise<unknown> {
        return new Promise(resolve => {
            let chatFilter = this.props.chats;

            if (value) {
                const contactsIds = this.props.contacts.map(c => c.client._id);
                const contactsIdFilter = this.props.contacts
                    .filter((c) => c.name.toLowerCase().includes(value.toLowerCase()))
                    .map((c) => c.client._id);

                const chatContactFilter = this.props.chats.filter((c) => contactsIdFilter.includes(c.to._id));
                const chatNotContactFilter = this.props.chats.filter((c) =>
                    c.to.alias.toLowerCase().includes(value.toLowerCase()) && !(contactsIds.includes(c.to._id)))

                chatFilter = [...chatContactFilter, ...chatNotContactFilter];
            }

            chatFilter = chatFilter.filter(c => (c.messages && c.messages.length > 0))
            this.props.setChats(chatFilter);

            resolve(chatFilter);
        })
    }

    filterContacts(value) {
        const chats = this.props.chats.filter((c: Chat) => (c.messages && c.messages.length > 0));
        let contactsFilter = [];

        if (value) {
            const chatsIdTo = chats.map(c => c.to._id);
            contactsFilter = this.props.contacts.filter((c) => {
                return (c.name.toLowerCase().includes(value.toLowerCase()) &&
                    !chatsIdTo.includes(c.client._id));
            });
        }

        this.props.setContacts(contactsFilter);
        return contactsFilter
    }

    async filterAlias(value) {
        let aliasClients = [];
        if (value) {
            aliasClients = await (ClientService.getInstance()).all(value);
        }

        this.props.setAliasClient(aliasClients);
    }


    render() {
        return (
            <SearchBar/>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        chats: selectChatsAsArray(state),
        contacts: state.chat.contacts,
        search: state.global.search
    }
};

const mapDispatchToProps = (dispatch) => ({});

const SearchAllBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_SearchAllBar);

export default SearchAllBar;