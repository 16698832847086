import Currency, { IConstructorCurrency } from '@core/currencies/Currency';
import { FiatCurrency } from '@core/fiat/FiatCurrencies';
import { Networks } from '@core/network/Networks';
import ETHImplementation from '../eth/ETHImplementation';
import { ProviderType } from '@custom-types/ProviderType';
import Constants from "expo-constants"
import { isCurrencyEnabled } from '@core/utils/CurrencyEnabled';

export default class USDC extends Currency {
    constructor(
        parameters: IConstructorCurrency
    ) {
        super({
            name: 'USDC',
            simbol: 'USDC',
            fullName: 'USDC',
            decimals: 6,
            implementation: ETHImplementation,
            networkID: parameters.testnet ? 61 : 60,
            color: '#2671c4',
            icon: 'usdc',
            networkSymbol: 'ETH',
            underlyingCurrency: 'eth',
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "USDC", true),
            buyableBy: [ProviderType.SIMPLEX, ProviderType.COINCAEX],
            sellableBy: [ProviderType.COINCAEX],
           
        })
    }

}
