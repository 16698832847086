import { StyleSheet, Appearance } from 'react-native';

const darkColors = {
    primary: '#1d1b2c',
    secondary: '#9B5AF2',
    complementary: '#9B5AF2',
    tertiary: '#0f0d19', 
    background: '#161425',
    gradientFrom: '#1a1936',
    gradientTo: '#11101d',
    grey: '#9091a4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: 'white',
    shadow: 'rgba(255,255,255,0.04)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)',
    tertiaryShadow: 'rgba(255,255,255,0.06)',
    avatarBase: '#9B5AF2',
    incognitoButton: "#363638"
};

const lightColors = {
    primary: '#1d1b2c',
    secondary: '#9B5AF2',
    complementary: '#9B5AF2',
    tertiary: '#fff', 
    background: '#d8d9db',
    gradientFrom: '#f3f5f9',
    gradientTo: '#d8d9db',
    grey: '#9091a4',
    white: 'white',
    black: '#333',
    blue: '#2696fb',
    green: '#1DBC60',
    yellow: '#FECA54',
    red: '#EE4540',
    text: '#454656',
    shadow: 'rgba(255,255,255,0.8)',
    secondaryShadow: 'rgba(0, 0, 0, 0.1)'
};

const settings = {}

export { lightColors, darkColors, settings }