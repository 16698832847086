import Currency, { IConstructorCurrency } from '@core/currencies/Currency';
import { Networks } from '@core/network/Networks';
import Constants from "expo-constants"
import ETHImplementation from '@core/currencies/ethereum/eth/ETHImplementation';
import { ProviderType } from '@custom-types/ProviderType';
import { isCurrencyEnabled, isNFTEnabled } from '@core/utils/CurrencyEnabled';

export default class MATIC extends Currency {
    constructor(
        parameters: IConstructorCurrency
    ) {
        super ({
            name: 'MATIC',
            simbol: 'MATIC',
            fullName: 'MATIC',
            decimals: 18,
            implementation: ETHImplementation,
            chainId: parameters.testnet ? 80001 : 137,
            networkID: parameters.testnet ? 61 : 60,
            color: '#8247e5',
            icon: 'polygon',
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet?Networks.ethereum.testnet:Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "MATIC", true),
            blockchain: 'polygon',
            buyableBy: [ProviderType.ONRAMPER, ProviderType.COINCAEX],
            sellableBy: [ProviderType.COINCAEX],
            walletConnect: true,
            nftSupport: isNFTEnabled("MATIC", true),
        
        })
    }

}
