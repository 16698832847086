import axios from "axios";
import store from "@store/index";
import { ApiService } from "@core/services/ApiService";
import { setChatStatus, updateChat } from "@store/actions/chat.actions";
import Wallet from "@core/wallet/Wallet";
import { Message, messageSchema } from "@custom-types/Message";
import { ContactService } from "@core/services/ContactService";
import { chatByIdSelector, chatSelectedSelector, selectMessagesFromSelectedChat } from "@store/reducers/chat.reducer";
import * as helper from "@utils/helpers/chat/chat-service.helper";
import { Sender } from "@utils/helpers/chat/message-state/sender";
import { MQTTClient } from "@core/services/MQTTClient";
import { Chat } from "@custom-types/Chat";
import { normalize, NormalizedSchema } from "normalizr";
import { addMessages } from "@store/actions/message.actions";
import { getLastMessageDateFromSelectedChat } from "@utils/helpers/chat/chat-service.helper";
import { getEnv } from "@utils/helpers/global/global";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { Receiver } from "@utils/helpers/chat/message-state/receiver";
import { SerializedMessage } from "@custom-types/SerializedMessage";
import { loading } from "@store/actions/global";
import { AuthService } from "./AuthService";

export class ChatService {
    resource = "chat";
    private static instance;

    private socket: any;
    private authService: AuthService;
    private contactService: ContactService;
    private mqttClient: MQTTClient;
    private wallet: Wallet;
    private page: number = 1;

    public static async getInstance(): Promise<ChatService> {
        if (!ChatService.instance) {
            ChatService.instance = new ChatService();
            await ChatService.instance.init();
        }

        return ChatService.instance;
    }

    async init() {
        this.wallet = Wallet.getInstance();
        this.authService = await AuthService.getInstance();
        this.contactService = ContactService.getInstance();
        this.mqttClient = MQTTClient.getInstance();

        await this.getAll();
        this.mqttInit();
    }

    refresh = async () => {
        this.page = 1;
        await this.getAll();
    };

    findInStorageById = (id: string) => {
        const chats = this.getAllEntitiesStore();
        return chats[id];
    };

    getById = async (id: string) => {
        let chat = this.findInStorageById(id);
        if (!chat) {
            let resp = await this.create({ clientId: id });
            chat = this.findInStorageById(resp._id);
        }
        return chat;
    };

    getAll = async () => {
        axios
            .get(`${getEnv("API_URL")}${this.resource}?page=${this.page}`, {
                headers: await ApiService.getAuthHeaders(),
            })
            .then((resp) => {
                this.saveChatsInStorage(resp.data.docs);
                this.updateAllMessagesToReceived();
                this.page = resp.data.nextPage || this.page;
                store.dispatch(setChatStatus("connected"));
            });
    };

    create = async (body) => {
        const resp: any = await axios.post(`${getEnv("API_URL")}${this.resource}`, body, {
            headers: await ApiService.getAuthHeaders(),
        });
        this.saveChatInStorage(resp.data);
        return resp.data;
    };

    remove = async (id) => {
        axios
            .delete(`${getEnv("API_URL")}${this.resource}/${id}`, { headers: await ApiService.getAuthHeaders() })
            .then(() => {
                let chat = Object.assign({}, chatByIdSelector(store.getState(), id));
                chat.messages = [];
                store.dispatch(updateChat(chat));
            })
            .catch((e) => e.response.data.message);
    };

    reportMessage = async (message: SerializedMessage) => {
        try {
            let url = `${getEnv("API_URL")}${this.resource}/report`;
            let body = {
                reportedMessageText: message.text,
                reportedMessageId: message._id,
                reportedUser: message.user._id,
            };

            const resp = await axios.post(url, body, {
                headers: { ...(await ApiService.getAuthHeaders()) },
            });

            return resp.data;
        } catch (e) {
            console.warn(e);
        }
    };

    getByToId = async (toId) => {
        axios
            .get(`${getEnv("API_URL")}${this.resource}/to/${toId}`, { headers: await ApiService.getAuthHeaders() })
            .then((resp) => this.saveChatInStorage(resp.data))
            .catch((e) => e.response.data.message);
    };

    getEarlierMessages = async () => {
        const chat: Chat = Object.assign(new Chat(), chatSelectedSelector(store.getState()));
        const lastDate = helper.getLastMessageDateFromSelectedChat();

        if (lastDate) {
            axios
                .get(`${getEnv("API_URL")}${this.resource}/earlier/${chat._id}/${lastDate}`, {
                    headers: await ApiService.getAuthHeaders(),
                })
                .then((resp) => helper.saveEarlierMessagesInStorage(resp.data, chat))
                .catch((e) => e.response.data.message);
        }
    };

    listenNewMessages = (msg) => {
        const message = Message.create(msg);
        if (!helper.existsChatIsStorage(message.chatId)) {
            this.getByToId(message.to._id).then();
            return;
        }
        if (!helper.existsMessageIsStorage(message.id)) {
            const state = message.state;
            if (message.text) state.processNewMessage();
        }
    };

    listenUpdateMessage = (msg) => {
        const messages = store.getState().message;
        if (!messages.items[msg.id] && !messages.items[msg.internalId]) {
            this.listenNewMessages(msg);
            return;
        }

        const message = Message.create(msg);
        message.decrypt();
        message.updateStorage();
    };

    sendMessage = (msg) => {
        const message = Message.create(msg);
        if (message.state instanceof Sender) message.state.saveAndSend();
    };

    sendCustomMessage = () => {
        const selectedChat = store.getState().chat.selected;
        const customMessage: CustomMessageModel = CustomMessageModel.create(store.getState().chat.customMessage);
        const message = customMessage.transformToRegularMessage(selectedChat);
        if (message.state instanceof Sender) message.state.saveAndSend();
    };

    updateMessagesToReadChatSelected = () => {
        const messages: Array<Message> = selectMessagesFromSelectedChat(store.getState());

        messages.forEach((m: Message) => {
            const state = m.state;
            if (state instanceof Receiver && state.getStateNumber() < Message.READ_STATE) {
                const participantState = m.state as Receiver;
                participantState.toRead();
                participantState.updateStorage();
                participantState.updateServer();
            }
        });
    };

    updateAllMessagesToReceived = () => {
        const messages = Object.values(store.getState().message.items);

        messages.forEach((m: Message) => {
            const state = m.state;
            if (state instanceof Receiver && state.getStateNumber() === Message.SENT_STATE) {
                state.toReceived();
                state.updateStorage();
                state.updateServer();
            }
        });
    };

    mqttInit = () => {
        const clientId = store.getState().auth.client?._id;

        this.mqttSubscribe();

        this.mqttClient.getClient().on("message", (topic, message: string) => {
            const msg = JSON.parse(message.toString());
            switch (topic.toLowerCase()) {
                case `to-client-chat-update-message-${clientId}`.toLowerCase():
                    this.listenUpdateMessage(msg);
                    break;
                case `to-client-chat-new-message-${clientId}`.toLowerCase():
                    this.listenNewMessages(msg);
                    break;
            }
        });
    };

    mqttSubscribe = () => {
        const clientId = store.getState().auth.client?._id;

        this.mqttClient.getClient().subscribe(`to-client-chat-new-message-${clientId}`, { qos: 2 });
        this.mqttClient.getClient().subscribe(`to-client-chat-update-message-${clientId}`, { qos: 2 });
    };

    getAllEntitiesStore = () => store.getState().chat.items;
    saveChatsInStorage = (entities: Array<any>) => helper.saveChatsInStorage(entities);
    saveChatInStorage = (entity) => helper.saveChatsInStorage([entity]);

    static async restartInstance() {
        ChatService.instance = null;
        await AuthService.restartInstance();
        ContactService.restartInstance();
        return ChatService.getInstance();
    }
}
