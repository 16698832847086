import RegularText from "@base/RegularText"
import { colors } from "@styles/globalStyles"
import React, { Component } from "react"
import { View, StyleSheet } from "react-native"
import Container from "@base/Container"
import { color } from "react-native-elements/dist/helpers"
import { SearchBar } from "@components/chat/SearchBar"
import { connect } from "react-redux"
import i18n from "@i18n/i18n"
import PressableBase from "@base/PressableBase"

interface Props {
    setSearch: (searchType) => any
    onSearch: (search) => any
    search: string
}


interface State {
    searchType: any
}

export enum SearchType {
    USER = "USER",
    POST = "POST",
    HASHTAG = "HASHTAG",
}

const { t } = i18n

class _SocialSearchBar extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            searchType: SearchType.USER,
        }
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            this.props.onSearch(this.props.search);
        }
    }

    componentDidMount = () => {
        this.props.setSearch(SearchType.USER)
    }

    setSearch = (SearchType) => {
        this.setState({ searchType: SearchType })
        this.props.setSearch(SearchType)
    }

    render() {
        return (
            <View>
                <SearchBar />
                <Container>
                    <View style={{ flexDirection: "row", justifyContent: "space-around", marginVertical: 10 }}>
                        <PressableBase onPress={() => this.setSearch(SearchType.USER)} accessibilityLabel="users-botton">
                            <RegularText
                                fontSize={16}
                                style={this.state.searchType == SearchType.USER ? styles.select : null}>
                                {t('users')}
                            </RegularText>
                        </PressableBase>
                        <PressableBase onPress={() => this.setSearch(SearchType.POST)} accessibilityLabel="posts-botton">
                            <RegularText style={this.state.searchType == SearchType.POST ? styles.select : null}>
                                {t('posts')}
                            </RegularText>
                        </PressableBase>
                        <PressableBase onPress={() => this.setSearch(SearchType.HASHTAG)} accessibilityLabel="hashtags-botton">
                            <RegularText style={this.state.searchType == SearchType.HASHTAG ? styles.select : null}>
                                {t('hashtags')}
                            </RegularText>
                        </PressableBase>
                    </View>
                </Container>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: "row",
        paddingHorizontal: 12,
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: colors.shadow,
        height: 40,
        borderRadius: 20,
        marginHorizontal: 5,
    },

    select: {
        color: colors.secondary,
        borderBottomColor: colors.secondary,
        fontFamily: "NunitoBold",
    },
    input: {
        zIndex: 999,
        width: '90%',

    },
});

const mapStateToProps = (state) => {
    return {
        search: state.global.search
    }
};

const mapDispatchToProps = (dispatch) => ({});

const SocialSearchBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_SocialSearchBar);

export default SocialSearchBar;
