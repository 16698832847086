import React, { Component } from 'react';
import { StyleSheet, View, TextStyle, ViewStyle } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

interface Props {
    value: string
    size: number
}

interface State {

}

export default class QRBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return (
            <View style={{borderWidth:6, borderColor:'white'}}>
                <QRCode
                    value={this.props.value}
                    size={this.props.size || 200}
                    backgroundColor={"white"}
                    color={"black"}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        flexDirection: 'row'
    }
});