import Wallet from "@core/wallet/Wallet";
import GoogleAuthService from "./providers/GoogleAuthService";
import { generateMnemonic as gM, mnemonicToSeedSync, entropyToMnemonic } from "bip39";
import { AuthType, Client } from "@custom-types/Client";
import { ClientService } from "../ClientService";
import axios from "axios";
import { AuthService } from "../AuthService";
import { getEnv } from "@utils/helpers/global/global";
import OAuthStorage from "@utils/storage/storages/OAuthStorage";
import store from "@store/index";
import { setClient } from "@store/actions/auth";
import { IOAuthHistoryStorage, OAuthHistoryStorage } from "@utils/storage/storages/OAuthHistoryStorage";
import { OAuthProvider } from "./providers/OAuthProvider";
import { OAuthHistoryService } from "./OAuthHistoryService";
import AnonAuthService from "./providers/AnonAuthService";
import { ApiService } from "../ApiService";
import { loading, ready, reset, showPopup } from "@store/actions/global";
import * as AuthAux from "@core/utils/AuthAux";
import { Shamir } from "./helpers/Shamir";
import i18n from "@i18n/i18n";
import AppStorage from "@utils/storage";
import { Storage } from "@utils/storage/Storage";
import { DeprecatedStorage } from "@utils/storage/DeprecatedStorage";
import WalletAsyncStorage from "@utils/storage/storages/WalletAsyncStorage";
import WalletSecureStorage from "@utils/storage/storages/WalletSecureStorage";


const { t } = i18n;

export type OAuthType = "google" | "anon";

export class OAuthService {
    private static instance: OAuthService;
    private type: OAuthType;
    private service: OAuthProvider;
    private client: Client;
    private secrets: Shamir;

    constructor(authType: OAuthType, data?: { tokens: any; userData: any; client: Client }) {
        this.type = authType;
        this.client = data?.client;
        this.secrets = Shamir.getInstance();

        switch (authType) {
            case AuthType.GOOGLE:
                this.service = new GoogleAuthService(data);
                break;
            default:
                this.service = new AnonAuthService(data);
        }
    }

    static async init(type: OAuthType) {
        OAuthStorage.remove();
        OAuthService.instance = new OAuthService(type);
        await Wallet.init();
        store.dispatch(setClient(OAuthService.instance.getClient()));
        return OAuthService.instance;
    }

    static async initFromStorage() {
        if (!OAuthService.instance) {
            const auth = await OAuthStorage.get();
            OAuthService.instance = auth
                ? new OAuthService(auth.authType || AuthType.ANON, {
                      tokens: auth.tokens,
                      userData: auth.userData,
                      client: auth.client,
                  })
                : new OAuthService(AuthType.ANON);
        }
        await Wallet.init();
        //@TODO: Acá a veces tira error porque dice q la instance esta en null, lo cual no tiene mucho sentido porque estaría creando la instancia antes
        store.dispatch(setClient(OAuthService.instance.getClient()));
    }

    static getInstance(): OAuthService {
        return OAuthService.instance;
    }

    async getNotificationToken() {}

    async updateClientFromSeed(client, mnemonic, data) {
        await OAuthHistoryService.addSeedHistory(data, mnemonic, client?.authType, data?.email);
        await this.restoreSeed(mnemonic, data);
    }

    async setNotificationToken() {
        try {
            const expoToken = await AuthAux.getNotificationToken();

            if (!expoToken) return;

            const url = `${getEnv("API_URL")}v3/client/notification-token`;
            const body = { token: expoToken };
            const res = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });
            return res.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async removeNotificationToken() {
        try {
            const expoToken = await AuthAux.getNotificationToken();

            if (!expoToken) return;

            const url = `${getEnv("API_URL")}v3/client/notification-token/${expoToken}`;
            const res = await axios.delete(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return res.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async isAuth() {
        const storage = await OAuthStorage.get();
        return storage?.isAuth || false;
    }

    getAuthHeaders() {
        return this.service.getAuthHeaders();
    }

    getAuthType() {
        return this.type;
    }

    async auth(data: any) {
        const userData = await this.service.auth(data);

        await OAuthStorage.set({
            isAuth: true,
            authType: this.type,
            tokens: { ...this.service.getTokens() },
            userData: { ...this.service.getClientData() },
        });

        return userData;
    }

    async signout() {
        await this.removeNotificationToken();
        await OAuthStorage.remove();
        await WalletSecureStorage.remove()
        await this.setClient(null);
        OAuthService.instance = null;
        return;
    }

    async removeAccount(mnemonic: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}v3/client`;
            const res = await axios.delete(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            await OAuthHistoryService.removeSeedHistory(mnemonic);
        } catch (e) {}
        store.dispatch(ready());
        return await OAuthService.getInstance().signout();
    }

    async newAlias(mnemonic?: string) {
        const words = await this.generateMnemonic(mnemonic);
        const userData = this.getClientData();

        await this.setClient(null);

        return { words, userData };
    }

    async getCloudClient() {
        const url = `${getEnv("API_URL")}account`;
        let clientData = null;
        try {
            const { data } = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            clientData = data;
        } catch (e) {}

        const providerClientData = this.service.getProcessedClientData();

        return Client.create({
            ...clientData,
            email: providerClientData.email,
            alias: clientData?.alias || providerClientData.alias,
            avatar: clientData?.profileImagePath?.thumbnail || providerClientData.avatar,
        });
    }

    getClient() {
        return this.client;
    }

    async setClient(client: Client) {
        this.client = client;
        const storage = await OAuthStorage.get();
        await OAuthStorage.set({
            ...storage,
            client: this.client,
        });
        store.dispatch(setClient(OAuthService.instance.getClient()));
        return this.client;
    }

    getClientData() {
        return this.service?.getProcessedClientData();
    }

    async generateMnemonic(mnemonic?: string) {
        await Wallet.init(mnemonic ? mnemonic : true);
        return this.getMnemonic();
    }

    getMnemonic() {
        return Wallet.getInstance().getMnemonic().join(" ");
    }

    generateShamir() {
        const seedHex = this.secrets.str2hex(this.getMnemonic());
        const [shareA, shareB] = this.secrets.share(seedHex, 2, 2);
        return { shareA, shareB };
    }

    async recoverSeed(client: Client) {
        try {
            const { shareA, shareB } = await this.service.recoverSeed(client);
            const combAB = this.secrets.combine([shareA, shareB]);
            const seed = this.secrets.hex2str(combAB);
            return seed;
        } catch (e: any) {
            store.dispatch(ready());
       
            if (e.message == "unauthorized") {
                await this.signout();
                return;
            }
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("recovery_seed_error"),
                }),
            );

            console.warn(e);
        }
    }

    async uploadKeys() {
        try {
            return await this.service.uploadKeys(this.generateShamir());
        } catch (e) {
            await this.signout();
        }
    }

    getRegisterEndpoint() {
        return `${getEnv("API_URL")}account/register/${this.service.getRegisterEndpoint()}`;
    }

    async register(data: { alias: string; avatar: string }) {
        const userData = this.getClientData();
        const tempClient = Client.create({ ...userData, ...data });
        ClientService.getInstance().setAddresses(tempClient, mnemonicToSeedSync(this.getMnemonic()));

        const keyChat = ClientService.getInstance().setChatPublicKey(tempClient);

        const res = await axios.post(
            this.getRegisterEndpoint(),
            {
                alias: tempClient.alias,
                addresses: tempClient.addresses,
                email: tempClient.email,
                keyChat: keyChat,
            },
            {
                headers: await this.service.getAuthHeaders(),
            },
        );
        const client = await this.setClient(Client.create({ ...res.data }));
        await OAuthHistoryService.addSeedHistory(client, this.getMnemonic(), "anon", "");
        await this.uploadKeys();
        await OAuthHistoryService.addSeedHistory(client, this.getMnemonic(), this.getAuthType(), client.email);
        return true;
    }

    async syncClientOnCloud() {
        const client = this.getClient();

        if (this.service.allowCloud()) {
            const url = `${getEnv("API_URL")}v3/client`;
            const { data } = await axios.patch(
                url,
                {
                    alias: client.alias,
                    email: client.email,
                    authType: this.getAuthType(),
                },
                {
                    headers: { ...(await ApiService.getAuthHeaders()) },
                },
            );

            if (data) {
                this.setClient(Client.create(data));
            }

            try {
                if (!data.hasRecoveryData) await this.uploadKeys();
            } catch (e) {}
        }

        try {
            await OAuthHistoryService.addSeedHistory(client, this.getMnemonic(), this.getAuthType(), client.email);
        } catch (e) {}
    }

    async restoreSeed(mnemonic: string, client: Client) {
        await this.removeNotificationToken();
        await this.generateMnemonic(mnemonic);
        client.email = client?.email || this.getClientData().email;
        client.authType = this.getAuthType();

        await this.setClient(client);
        await this.syncClientOnCloud();
    }

    async getCloudHistory(): Promise<Partial<IOAuthHistoryStorage>[]> {
        if (!this.service.allowCloud()) {
            return [];
        }
        const client = this.getClient();
        const email = client?.email || this.getClientData().email;
        const url = `${getEnv("API_URL")}account/email/${email}`;
        try {
            const { data } = await axios.get(url, {
                headers: await OAuthService.getInstance().getAuthHeaders(),
            });
            return data.map((i) => {
                return {
                    clientId: i.id,
                    authType: "google",
                    email: i.email,
                    client: Client.create(i),
                };
            });
        } catch (e) {
            console.warn(e);
        }
    }

    //@TODO: Check que solo traiga los correspondientes
    async getHistory() {
        store.dispatch(loading());
        let client = this.getClient() || this.getClientData();
        const storageHistory = await OAuthHistoryService.getSeedHistory(client);
        const cloudHistory = await this.getCloudHistory();
        store.dispatch(ready());
        //@TODO: Add isCurrent to the current client
        return mergeWithoutDuplicates(storageHistory, cloudHistory);
    }

    verifyClientFromSeed = async () => {
        try {
            const client = this.getClient();
            const mnemonic = this.getMnemonic();
            const url = `${getEnv("API_URL")}v3/client`;
            const { data } = await axios.patch(
                url,
                {},
                {
                    headers: { ...(await ApiService.getAuthHeaders()) },
                },
            );

            if (!data) {
                return true;
            }

            if (data && client?._id !== data?._id) {
                await this.updateClientFromSeed(client, mnemonic, data);
                return false;
            } else {
                //@TODO: Ya que estamos solicitando el client, es correcto setearlo?
                OAuthService.getInstance().setClient(Client.create(data));
            }
            return client?._id == data?._id;
        } catch (e) {}
    };

    async restoreApp(){
        const storage = DeprecatedStorage.getInstance();
        storage.removeItem('terms');
        storage.removeItem('terms_v2');
        storage.removeItem('app_state');
        storage.removeItem('app_state_v2');
        storage.removeItem('coincaex');
        storage.removeItem('initializer');
        storage.removeItem('language');
        storage.removeItem('language_v2');
        storage.removeItem('authentication');
        storage.removeItem('authentication_v2');
        storage.removeItem('wallet_secure', {isSecure:true});
        storage.removeItem('wallet_secure_v2', { isSecure: true });
        storage.removeItem('wallet');
        storage.removeItem('oAuth_v2');
        storage.removeItem('oAuthHistory_v2');
        storage.removeItem('clientHistory_v2');
        storage.removeItem('wallet_async');
        storage.removeItem('wallet_async_v2');
        storage.removeItem('clientHistory');
        storage.removeItem('auth');
        storage.removeItem('auth_v2');
        storage.removeItem('auth2.0');
        storage.removeItem('auth2.0_v2');
        storage.removeItem('auth3.0');
        storage.removeItem('auth3.0_v2');
        storage.removeItem('auth4.0_v2');
        storage.removeItem('seed_history');
        storage.removeItem('seed_history_v2');
        storage.removeItem('security');
    }
}

function mergeWithoutDuplicates(localHistory, cloudHistory) {
    const mergedHistory = [...localHistory];

    if (cloudHistory) {
        cloudHistory.forEach((cloudItem) => {
            const clientIdExists = mergedHistory.some((localItem) => localItem.clientId === cloudItem.clientId);
            if (!clientIdExists) {
                mergedHistory.push(cloudItem);
            }
        });
    }

    return mergedHistory;
}
