import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import { ASSET, CartRedeemable, MEMBERSHIP, REDEEMABLE, RedeemableType } from "@custom-types/ExpercienceModel";

export const SET_REDEEMABLES = "SET_REDEEMABLES";
export const SET_MEMBERSHIPS = "SET_MEMBERSHIPS";
export const SET_ASSETS = "SET_ASSETS";
export const UPDATE_REDEEMABLE = "UPDATE_REDEEMABLE";
export const UPDATE_MEMBERSHIP = "UPDATE_MEMBERSHIP";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const SET_SELECTED_REDEEMABLE = "SET_SELECTED_REDEEMABLE";
export const SET_EXPERIENCES = "SET_EXPERIENCES";
export const SET_MERCHANTS = "SET_MERCHANTS";
export const ADD_REDEEMABLE_CART = "ADD_REDEEMABLE_CART";
export const REMOVE_REDEEMABLE_CART = "REMOVE_REDEEMABLE_CART";
export const EMPTY_REDEEMABLE_CART = "EMPTY_REDEEMABLE_CART";
export const SET_EXPERIENCE_FIAT_CURRENCY = "SET_EXPERIENCE_FIAT_CURRENCY";
export const SET_REEDEMABLE_CART = "SET_REEDEMABLE_CART";
export const EMPTY_REDEEMABLE_CART_ITEM = "EMPTY_REDEEMABLE_CART_ITEM";

export const setRedeemables = (
    redeemableType: RedeemableType,
    redeemables: Array<REDEEMABLE>,
    paginator: { page: number; totalDocs: number; totalPages: number }
) => {
    return { type: SET_REDEEMABLES, redeemableType, redeemables, paginator };
};

export const setMemberships = (
    memberships: Array<MEMBERSHIP>,
    paginator: { page: number; totalDocs: number; totalPages: number }
) => {
    return { type: SET_MEMBERSHIPS, memberships, paginator };
};

export const setAssets = (assets: Array<ASSET>, paginator: { page: number; totalDocs: number; totalPages: number }) => {
    return { type: SET_ASSETS, assets, paginator };
};

export const updateAsset = (asset: ASSET) => {
    return { type: UPDATE_ASSET, asset };
};

export const updateMembership = (membership: MEMBERSHIP) => {
    return { type: UPDATE_MEMBERSHIP, membership };
};

export const updateRedeemable = (redeemable: REDEEMABLE) => {
    return { type: UPDATE_REDEEMABLE, redeemable };
};

export const setSelectedRedeemable = (redeemable: REDEEMABLE) => {
    return { type: SET_SELECTED_REDEEMABLE, redeemable };
};

export const setExperiences = (
    experiences: Array<any>,
    paginator: { page: number; totalDocs: number; totalPages: number }
) => {
    return { type: SET_EXPERIENCES, experiences, paginator };
};

export const setMerchants = (
    merchants: Array<any>,
    paginator: { page: number; totalDocs: number; totalPages: number }
) => {
    return { type: SET_MERCHANTS, merchants, paginator };
};

export const addRedeemableCart = (redeemable: REDEEMABLE) => ({
    type: ADD_REDEEMABLE_CART,
    payload: {
        redeemable,
    },
});

export const removeRedeemableCart = (redeemable: REDEEMABLE) => ({
    type: REMOVE_REDEEMABLE_CART,
    payload: {
        redeemable,
    },
});

export const emptyRedeemableCart = () => ({
    type: EMPTY_REDEEMABLE_CART,
});


export const setExperienceFiatCurrency = (fiatCurrency: FiatCurrency) => ({
    type: SET_EXPERIENCE_FIAT_CURRENCY, fiatCurrency
})

export const setReedemableCart = (redeemable: Array<CartRedeemable>) => ({
    type: SET_REEDEMABLE_CART,
    payload: {
        redeemable,
    },
});

export const emptyRedeemableCartItem = (id: string) => ({
    type: EMPTY_REDEEMABLE_CART_ITEM,
    payload: {
        id
    }
});