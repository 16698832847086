import Currency, { IConstructorCurrency } from '@core/currencies/Currency';
import ETHImplementation from '@core/currencies/ethereum/eth/ETHImplementation';
import { FiatCurrency } from '@core/fiat/FiatCurrencies';
import { Networks } from '@core/network/Networks';
import { ProviderType } from '@custom-types/ProviderType';
import Constants from "expo-constants"
import { isCurrencyEnabled } from '@core/utils/CurrencyEnabled';

export default class RBTC extends Currency {
    constructor(
        parameters: IConstructorCurrency
    ) {
        super({
            name: 'RBTC',
            simbol: 'RBTC',
            fullName: 'RBTC',
            decimals: 18,
            implementation: ETHImplementation,
            networkID: parameters.testnet ? 37310 : 137,
            color: '#00B520',
            icon: 'rbtc',
            chainId: parameters.testnet ? 31 : 30,
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "RBTC", true),
            ethCompatibilitySupport: true,
            buyableBy: [ProviderType.KRIPTONMARKET],
            walletConnect: true,
            blockchain: 'rsk'

        })
    }
}
