import AbstractCurrency from "@core/currencies/AbstractCurrency";

export class FiatCurrency extends AbstractCurrency {}

interface IFiatCurrency {
    [key: string]: FiatCurrency;
}
const FiatCurrencies: IFiatCurrency = {
    USD: new FiatCurrency("USD", "USD", "$", require("./icons/USD.png"), "Dollars", 0),
    EUR: new FiatCurrency("EUR", "EUR", "€", require("./icons/EUR.png"), "Euros", 0),
    ARS: new FiatCurrency("ARS", "ARS", "$", require("./icons/ARS.png"), "Pesos", 0),
};



export default FiatCurrencies;
