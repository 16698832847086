export const es = {
    en: "Inglés",
    es: "Español",
    tr: "Turco",
    welcome: "Bienvenido",
    welcome_to: "Bienvenido a",
    a_portal: "Un portal a tu experiencia WEB 3.0 🚀",
    hi: "¡Hola!",
    new_account: "Agregar Cuenta",
    my_wallet: "MI BILLETERA",
    membership: "MEMBERSHIPs",
    accounts: "Cuentas y perfiles",
    ticket: "TICKETs",
    voucher: "VOUCHERs",
    pending: "Pendiente",
    processed: "Procesado",
    error: "Error",
    preminted: "Pre Minteo",
    recovery_seed_error:
        "Ha fallado el método de recuperación de tu cuenta, intenta ingresar con tu Frase Semilla (Seed)",
    minted: "Minting",
    private_account: "Cuenta Privada",
    bot_marketplace_warning:
        "Este NFT se encuentra publicado en otro Marketplace (%{name}), puedes cancelar esa publicación desde aquí",
    bot_seed_warnign:
        "Al crear una nueva semilla será necesario reiniciar la app, podrás consultar tu antigua semilla en el historial.",
    bot_save_warning: "Recuerda presionar el boton de guardar para que surtan efecto los cambios",
    bot_continue: "¿Deseas continuar?",
    bot_seed_change: "Una vez cargada la nueva Frase Semilla (Seed) se reiniciará la app.",
    security_kit_a:
        "Es muy importante que hayas anotado tu Frase Semilla (Seed) o memorices las palabras que la componen",
    security_kit_b: "Ya he anotado mi Seed",
    security_kit_c: "¿Quieres ver tu Frase Semilla (Seed) ahora y anotar las palabras?",
    security_kit_seed_a: "¿Qué es una frase semilla (seed phrase)?",
    security_kit_seed_b:
        "Una de las más interesantes propuestas para facilitar el manejo de nuestras   criptomonedas y billetera es la creación de la frase semilla o seed phrase. Y es que gracias a esta resulta muy fácil respaldar la información de nuestro billetera y proteger así nuestro dinero en todo momento.",
    security_kit_seed_c:
        "La frase semilla o seed phrase, hace mención a un conjunto de 12 palabras, que tienen como finalidad ofrecernos una forma fácil y sencilla de respaldar nuestro billetera de criptomonedas. De esta forma, si por cualquier causa se llegase a perder el control sobre él, podríamos recuperarlo tan solo usando estas palabras en el orden que se nos dieron originalmente.",
    security_kit_seed_d: "Toma en consideración estos consejos:",
    security_kit_seed_e: "Es importante que las anotes en un papel en el orden que te las mostramos.",
    security_kit_seed_f: "Nunca compartas estas palabras con otras personas.",
    security_kit_seed_g:
        "Si algún día cambias o extravías este dispositivo es posible que estas palabras sean la única forma de recuperar tus fondos.",
    security_kit_seed_h:
        "En la pantalla siguiente te preguntaremos algunas de estas palabras, así que no olvides antotarlas.",
    security_kit_confirm_a:
        "Dos de estas palabras forman parte de tu Frase Semilla (Seed), selecciónalas para finalizar el proceso de verificación.",
    security_kit_confirm_b:
        "Recuerda que solo debes seleccionar dos palabras. Para deseleccionar una solo tócala arriba.",
    security_kit_confirm_error: "Las palabras seleccionadas no son correctas",
    finalize: "Finalizar",
    beexo_wallet_is: "es una billetera de criptomonedas multiplataforma y multidivisa creada para la nueva generación.",
    i_accept: "Acepto",
    seed_phrase: "Frase Semilla",
    remove_seed_warning: "Estas a punto de BORRAR tu cuenta, sólo podrás recuperarla si has anotado tu Frase Semilla",
    restore_app_warning:
        "Estas a punto de BORRAR TODOS tus datos, sólo podrás recuperarlos si has anotado tus Frases Semillas",
    beexo_is_a_deterministic_wallet:
        "Utilizamos el estándar BIP39 para generar la frase semilla (Seed). La responsabilidad de guardar la frase recae enteramente en el usuario. Tenga en cuenta que perder o revelar su frase a alguien puede significar perder sus fondos.",
    terms: "Términos",
    before_continuing: "Antes de continuar, debe leer y aceptar los",
    terms_and_conditions:
        "Términos y Condiciones, EULA (Acuerdo de Licencia del Usuario Final) y Politicas de Privacidad.",
    privacy_terms: "Antes de continuar, debe leer y aceptar nuestras ",
    privacy_terms_link: "políticas de privacidad",
    if_you_do_not: "Si no está de acuerdo con todos nuestros términos, no utilice esta aplicación.",
    crypto: "Crypto",
    wallet: "Billetera",
    init: "Inicio",
    draft: "Draft",
    not_edit: "(NO EDITABLE)",
    bot_draft: "Este es un borrador, y solo será un NFT cuando haya sido minteado (acuñado).",
    bot_draft_minted: "Estamos minteando este NFT, en un momento lo verás reflejado como un NFT.",
    discover: "Descubrir",
    explore: "Explorar",
    chat: "Mensajes",
    total_balance: "Balance total",
    balance: "Balance",
    dapps: "Explorador Dapps",
    processing: "PROCESANDO",
    processing_image: "PROCESANDO IMAGEN",
    your_dapps: "Tus Dapps",
    connected: "Contecado",
    collections: "Colecciones",
    marketplace: "Marketplace",
    disconnected: "Desconectado",
    explore_dapps: "Explorar Dapps",
    archived: "Archivado",
    archive: "Archivar",
    unarchive: "Desarchivar",
    created_by: "Creado por",
    assigned_to: "Se ha asignado a ",
    redeemables_warning:
        "Solo verás acá tus %{type}s disponibles para usar. Puedes ver tus %{type}s usados, expirados o archivados tocando acá 🚀",
    memberships_warning:
        "Solo verás acá tus memberships disponibles para usar. Para consultar tus memberships archivadas toca aca 🌟",

    used_in: "Lo has usado en ",
    transferred_to: "Lo has transferido a",
    received_from_redeemable: "Lo has recibido de ",
    expired: "Ha expirado! 😭",
    use: "Usar",
    modal_reddemable_question: "¿Quieres usar este %{type}?",
    redeemables_error_message: "No tienes tickets para usar en esta puerta",

    gate_message: "Estás archivando este %{type}, puedes revertir esta acción cuando quieras",
    wallet_connect: "Wallet Connect",
    dapps_connect_warning: "No tienes una conexión activa de WalletConnect para esta Dapp",
    dapps_connect_copy: "Busca Wallet Connect en la Dapp y COPIA el Código QR para conectarte",
    wallet_connect_paste_error: "Parece que el código ingresado no corresponde a Wallet Connect",
    wallet_connect_question: "¿Realmente quieres conectarte con %{name}?",
    wallet_connect_remove_question: "¿Realmente quieres desconectar y borrar %{name}?",
    wallet_connect_select_currency:
        "Por favor selecciona la red (Chain) con la cual quieres iniciar Wallet Connect en %{name}",
    wallet_connect_sign: "Firmar",
    wallet_connect_request_question: "¿Realmente quieres realizar esta acción?",
    wallet_connect_welcome: "Hola! Estas usando WalletConnect V2 🚀",
    wallet_connect_welcome_message:
        "Recuerda que ahora las conexiones pueden ser multi-chain. Para agregar un nuevo Wallet Connect usa el ScannerQR",
    wallet_connect_disconnected: "Se ha cerrado la sesión de Wallet Connect",
    wallet_connect_empty:
        "No tienes conexiones de WalletConnect activas, pulsa el boton para agregar una nueva conexión.",
    wallet_connect_connection_error: "No hemos podido establecer la conexión, intenta nuevamente.",
    personal_sign_subtitle: "Firma un mensaje personal",
    personal_sign_description:
        "Esta se puede reconocer como una firma específica y evita el uso indebido en el que una DApp maliciosa puede firmar datos arbitrarios.",
    no_proccesed_request: "Nuestra billetera momentaneamente no es compatible con el requerimiento de la DApp",
    eth_Transaction_subtitle: "Solicita que firmes una transacción",
    eth_Transaction_question: "Presiona Continuar para ver los detalles y confirmar la transacción",

    eth_sendTransaction_subtitle: "Confirma la transacción",
    eth_sendTransaction: "Desde \n%{from} \n\nA \n%{to} \n\nGas \n%{gas}",

    eth_signTransaction: "Desde \n%{from} \n\nA \n%{to}",
    copy_message: "Copiar",
    report_message: "Reportar",
    cancel_message: "Cancelar",
    remove: "Borrar",
    connect: "Conectar",
    disconnect: "Desconectar",
    send: "Enviar",
    save: "Guardar",
    save_restart: "Guardar y Reiniciar",
    no_network_connection:
        "Parece que no tienes conexión a internet, es posible que muchas de las funciones de la billetera no esten disponibles.",
    delete: "Borrar",
    network: "Red",
    receive: "Recibir",
    sent: "Enviado",
    received: "Recibido",
    total_received: "Total Recibido",
    total_payed: "Total Pagado",
    exchange: "Exchange",
    slide_to_confirm: "Desliza para confirmar",
    confirm: "Confirmar",
    address: "Dirección",
    your_address: "Tu dirección",
    copied: "Copiada al portapapeles",
    valid_address: "Por favor introduce una dirección válida",
    amount_small: "El monto es muy pequeño",
    next: "Siguiente",
    back: "Regresar",
    confirm_transaction: "Confirmar transacción",
    transaction_id: "Transacción ID",
    amount: "Monto",
    sending_to: "Enviado a",
    sending_from: "Enviados desde",
    fee: "Tarifa",
    service_fee: "Tarifa de Servicio (Proveedor)",
    status: "Estado",
    date: "Fecha",
    from: "De",
    to: "A",
    confirmed: "Confirmado",
    download: "Descargar",
    continue: "Continuar",
    slide: "Deslizar para transferir",
    transfer: "Transferir",
    transfer_bot: "Busca y selecciona al usuario al que le quieras realizar la transferencia",
    settings: "Configuración",
    signout: "Cerrar sesión",
    preferences: "Preferencias",
    block_user: "Bloquear Usuario",
    block_user_modal:
        "¿Realmente quieres bloquear a este usuario? Una vez bloqueado no podrás ver sus post y tampoco este podra ver los tuyos.",
    empty_blocked_users:
        "No tienes usuarios Bloqueados. Recuerda que para bloquear un usuario debes hacerlo desde su perfil.",
    unblock_user: "Desbloquear",
    blocked_user: "Usuarios Bloqueados",
    security: "Seguridad",
    advanced: "Avanzado",
    import: "Importar",
    support: "Soporte",
    version: "Versión",
    language: "Lenguaje",
    select_language: "Seleccionar Lenguaje",
    backup: "Respaldo",
    warning: "Advertencia",
    important: "Importante",
    report_message_text: "Puedes reportar este mensaje y se reenviará a nuestro centro de control para ser evaluado.",
    report_message_question: "¿Quieres reportar este mensaje?",
    report_message_description:
        "No se notificará al contacto sobre esto. Recuerda que también puedes bloquear a %{name}",
    new_seed: "Crear nueva semilla",
    active: "Activa",
    select_network: "Selecciona la Red",
    mainnet_testnet:
        "Mainnet es la red principal sobre la que podrás realizar tus operaciones habituales con criptomonedas. Al utilizar Testnet puedes probar diferentes funcionalidades sin consumir tus recursos.",
    warning_new_seed_question: "¿Realmente quieres generar una nueva semilla?",
    warning_new_seed: "Perderá sus fondos de la semilla actual si no la ha respaldado correctamente",
    warning_import: "Asegúrate de hacer una copia de seguridad de tu Frase Semilla (Seed) antes de importar una nueva.",
    type_words: "Escribe tus doce palabras",
    crypto_address: "Dirección (Crypto)",
    pay_order: "Solicitar Pago",
    poap_claimed: "Este POAP ya ha sido reclamado por otra persona.",
    poap_inactive: "Este POAP no puede ser reclamado, intenta con otro.",
    poap_question: "Vamos a asignar este POAP a tu dirección de ETH",
    poap_testnet:
        "Esta sección no se encuentra disponible en TESTNET, por favor cambie a MAINNET para visualizar o mintear POAPs",
    send_crypto: "Enviar Crypto",
    share_NFT: "Compartir NFT",
    send_NFT: "Enviar NFT",
    send_ticket: "Enviar Ticket",
    send_voucher: "Enviar Voucher",
    bot_message_select_redeemable: "Selecciona un %{type} para transferirlo",
    bot_message_empty_redeemable: "No tienes %{type}s disponibles para transferir",
    internal_server_error:
        "Lo sentimos, ha ocurrido un error. Verifique su conexión a Internet, reinicie la aplicación, la red puede estar ocupada, e intente nuevamente en unos minutos",
    info_NFT: "Puedes ver mas detalles del NFT tocando sobre este mensaje",
    info_receive_NFT:
        "Has recibido un NFT, ahora es parte de tu colección. Puedes ver mas detalles tocando sobre el mensaje o sino lo puedes ver en tu perfil",
    info_send_NFT:
        "Has enviado este NFT y por tanto ya no forma parte de tu colección. Puedes ver mas detalles tocando sobre este mensaje.",
    warning_send_crypto:
        "No es posible enviar criptomonedas a este usuario momentáneamente, ya que sus direcciones se encuentran desactualizadas. Puedes solicitarle que te envié su dirección o que te solicite un pago.",
    add_contact: "Agregar contacto",
    edit_contact: "Editar contacto",
    edit_profile: "Editar perfil",
    name: "Nombre",
    alias: "Alias",
    set_alias: "Escribe tu alias",
    name_error: "El nombre no puede estar vacío",
    alias_error: "El alias no puede estar vacío",
    field_error: "Este campo no puede estar vacío",
    birthday_error:
        "Tu fecha de nacimiento debe ser completada con Año (Ej. 1995), Mes (Ej. 05) y Día (Ej. 22), separados por barras o guiones. (Ej. 1995/05/22).",
    img_error: "La imagen no puede estar vacía",
    contacts_list: "Lista de Contactos",
    search: "Buscar",
    no_foll_warning:
        "Parece que todavía no sigues a ningún usuario, así que te mostraremos publicaciones sugeridas. Puedes seguir a usuarios para ver sus publicaciones o utilizar la pestaña Descubrir para ver más publicaciones.",
    follow: "Seguir",
    unfollow: "Dejar de seguir",
    posts: "Publicaciones",
    ethCompatibility: "Derivación ETH compatible",
    membership_claim_error: "No puedes obtener dos Memberships iguales",
    ethCompatibility_warning_title:
        "Se ha actualizado el PATH de esta red para que sea compatible con la dirección de ETH.",
    ethCompatibility_warning_message:
        "Si tenias fondos y ahora no los visualizas, puedes volver a tu dirección anterior accediendo a la configuración de esta moneda.",
    followers: "Seguidores",
    following: "Seguidos",
    contact: "Contactar",
    now: "ahora",
    minutes_ago: "minutos atrás",
    hours_ago: "horas atrás",
    days_ago: "días atrás",
    liked_by: "Le gusta a",
    and: "y",
    others: "personas más",
    see_more: "Ver más",
    see_less: "Ver menos",
    users: "Usuarios",
    hashtags: "Hashtags",
    global_search: "Búsqueda global",
    exchange_amount: "El monto es menor que el mínimo de intercambio de",
    exchange_funds: "No tienes fondos suficientes para iniciar este intercambio. Tu saldo actual es",
    new_post: "Nueva publicación",
    gallery: "Galería",
    camera: "Cámara",
    image_warning: "Selecciona una imagen antes de continuar",
    image_hight: "La imagen es demasiado alta para un Post, prueba de tomar una foto en formato horizontal",
    description_warning: "Agrega una descripción antes de continuar",
    camera_permission: "Es necesario que otorgues permiso para usar la cámara",
    camera_permission_null:
        "Para usar la cámara es necesario que otorgues permiso desde la configuración externa de la app",
    saved_succesfuly: "Guardado correctamente",
    set_profile: "Configurar Perfil",
    enter_your_alias: "Escribe tu alias",
    alias_exist: "El alias ya existe",
    seed_history_warning:
        "Hemos encontrado estas Frases de Semilla que no pudimos agregar a tu historial, puedes descartarlas si asi lo consideras",
    language_warning: "Se reiniciará la aplicación para cargar el nuevo idioma",
    buy: "Comprar",
    purchases: "Compras",
    credit_card: "Tarjeta de Crédito",
    wire_transfer: "Giro Bancario",
    provider_error: "El proveedor no puede procesar la solicitud",
    input_amount_message: "El importe debe ser menor que",
    message: "Mensaje",
    and_bigger_than: "y mayor que",
    you_will_pay: "Vas a pagar",
    you_will_buy: "Vas a comprar",
    to_address: "Tu dirección",
    payment_method: "Método de pago",
    select_currency: "Seleccionar Moneda",
    select_nft: "Seleccionar NFT",
    error_module_disabled: "No es posible utilizar esta función en este momento",
    bot_select_nft: "Selecciona uno de tus NFTs para compartirlo por el chat",
    bot_select_send_nft:
        "Selecciona uno de tus NFTs para enviarlo, debes tener en cuenta que estas transfiriendo el NFT y será propiedad del usuario al que se lo envíes. Además, no podrás enviar NFTs que esten a la venta en el MarketPlace.",
    bot_select_nft_empty: "Parece que no tienes ningún NFT en tu perfil para compartir.",
    bot_select_send_nft_empty:
        "No tienes ningún NFT para enviar por el chat. Recuerda que no deben estar a la venta para poder enviarlos.",
    enable_currencies: "Monedas",
    select_country: "Seleccionar País",
    you_will_receive: "Vas a recibir",
    you_have_to_pay: "Tienes que pagar",
    poaps_web_error:
        "Esta función no esta disponible desde la versión WebApp, puedes usar la app disponible en Android o iOS",
    an_error_has_occurred: "Ha ocurrido un error, por favor intenta nuevamente",
    an_error_has_occurred_open_NFT: "Ha ocurrido un error al intentar obtener la información de este NFT",
    enter_email: "Ingresa tu email",
    identity_verification: "Verificar Identidad",
    personal_information_optional: "Información Personal (Opcional)",
    first_name: "Nombres",
    last_name: "Apellido",
    birthday: "Fecha de Nacimiento (AAAA/MM/DD)",
    gender: "Sexo",
    marital_status: "Estado Civil",
    nationality: "Nacionalidad",
    politically_exposed: "Políticamente expuesto",
    document_type: "Tipo de Documento",
    document_number: "Número de Documento",
    single: "Soltero",
    married: "Casado",
    divorcee: "Divorciado",
    widower: "Viudo",
    male: "Masculino",
    female: "Femenino",
    yes: "Si",
    no: "No",
    kyc_status: "Estado de KYC (Know Your Customer)",
    domicile: "Domicilio",
    bank: "Banco",
    postal_code: "Código Postal",
    street_address: "Calle",
    street_number: "Número de la calle",
    telephone_number: "Teléfono",
    name_bank: "Nombre del Banco",
    cbu: "CBU/CVU",
    tax_identification: "Identificación Tributaria (CUIT-CUIL)",
    front_document: "Frente del documento",
    front_document_description:
        "Sácale una foto al frente de tu documento. En la imagen se tiene que ver todo el frente del documento y poder leerse claramente los datos.",
    back_document: "Reverso del documento",
    back_document_description:
        "Sácale una foto al reverso de tu documento. En la imagen se tiene que ver todo el reverso del documento y poder leerse claramente los datos.",
    service_image: "Foto de servicio o impuesto a tu nombre",
    service_description: "Toma una foto de un servicio o impuesto a tu nombre. Se debe ver y leer claramente.",
    billing_image: "Foto del ticket de pago",
    billing_image_description: "Debes adjuntar foto del ticket de transferencia bancaria",
    selfie_document: "Tomate una foto sosteniendo tu documento",
    selfie_description: "Debe verse el documento de frente y tu cara.",
    history: "Historial",
    history_seed: "Historial (Seeds)",
    remove_account: "Borrar cuenta",
    restore_app: "Reestablecer app",
    kyc_warning:
        "Para hacer una compra es necesario que tengas un KYC activo. Ingresa tu email y verificaremos si ya tienes un KYC previo o te guiaremos para crear uno nuevo.",
    city: "Ciudad",
    you_need_transfer: "Es necesario que agregues foto del comprobante de transferencia",
    amount_transfer: "Monto a transferir",
    amount_receive: "Monto recibido",
    request: "Iniciado",
    req: "Solicitar",
    wait: "Esperando ticket de pago",
    complete: "Completado",
    cancel: "Cancelado",
    pending_by_validate: "Pendiente de Validación",
    kyc_validation_pending:
        "KriptonMarket esta verificando tus datos. Cuando aprueben tu KYC podrás realizar una compra.",
    kyc_country_warning: "Tienes que completar los datos para que KriptonMarket verifique tu identidad.",
    wire_transfer_info_card:
        "Debes realizar la transferencia al CBU indicado y luego adjuntar el comprobante para continuar con el proceso de compra. A continuación tienes una lista de los Bancos habilitados.",
    country: "País",
    type_a_message: "Escribe un mensaje...",
    bigger_than: "El monto debe ser mayor a ",
    select_provider: "Seleccionar Proveedor",
    report: "Denunciar",
    reported: "REPORTADO",
    message_remove: "Puedes borrar este item, ya que es un borrador y aun no ha sido acuñado.",
    question_remove: "¿Quieres borrar este contenido?",
    message_report: "Si consideras abusivo este contenido, puedes reportarlo y aparecerá oculto para otros usuarios.",
    question_report: "¿Quieres denunciar este contenido?",
    bot_report: "Parece que este NFT ha sido denunciado por otros usuarios, puedes comprarlo bajo tu propio riesgo.",
    bot_report_owner:
        "Parece que otros usuarios han denunciado tu NFT como abusivo. Lo podrás seguir vendiendo, pero aparecerá como reportado hasta que evaluemos si infringe nuestras normas.",
    auth_warning:
        "Parece que no tienes activado ningún método de seguridad en tu dispositivo. Te recomendamos activar alguno en las configuraciones de tu dispositivo. (Ej: Patrón, PIN, Huella Dactilar).",
    auth_warning_web:
        "Al usar esta aplicación desde el navegador web hay información que no podemos proteger. Te recomendamos descargar la aplicación en tu dispositivo desde Play Store (Android) o App Store (iOS).",
    local_auth: "Usa tu huella, PIN o patrón para ingresar a la app.",
    auth_error: "Para ingresar a la app es necesario que te autentiques en tu dispositivo.",
    authentication: "Huella Dactilar",
    add_more_currencies: "Agregar más",
    new_post_not_available:
        "Esta función no se encuentra disponible para la versión web, solo puedes crear un Post desde la aplicacion (Android o iOS)",
    approve_tokens: "Aprobar tokens",
    approve_tokens_message:
        "Antes de realizar un intercambio, debes aprobar el uso de tus tokens. Puede revertir esto desde la configuración",
    approving: "Aprobando",
    approving_message: "Por favor espera un minuto mientras el uso del contrato es autorizado",
    no_avatar_alias: "Completa tu perfil para darle Like a una publicación.",
    transaction_detail: "Detalle de transacción",
    your_network_is: "Tu billetera está configurada en",
    and_the_operation: "y la operación que intentas realizar esta en",
    tries_to_follow: ". Presiona continuar para cambiar la red y realizar la operación.",
    path_warning:
        "Cambiar el Path de esta moneda implica que tendrás una nueva dirección y que solo podrás consultar el saldo de tu vieja dirección revirtiendo a tu path anterior. Asimismo puede que algunas funcionalidades de la billetera (por ejemplo recibir crypto a través del CryptoChat) se comporten de manera diferente. Debes tener en cuenta que si tu dirección difiere de la que se utilizará para pagar el Gas (operaciones Swap) no podremos cobrar la tarifa y la operación no se concretara.  Creemos importante avisarte que solo debes cambiar este path si tienes conocimiento al respecto.",
    rewarded: "Recompensado",
    new_post_message: "Elige una foto o usa tú cámara y comeinza a crear contenido.",
    confirm_swap: "Confirmar Swap",
    activity_transactions:
        "Parece que todavía no tienes ninguna actividad de %{currency}. Cuando hagas una operación la verás en esta sección.",
    daily: "Diario",
    weekly: "Semanal",
    close: "Cerrar",
    show_seed: "Mostrar palabras",
    hide_seed: "Ocultar palabras",
    words: "Palabras",
    qr: "QR (Seed)",
    type_a_password: "Escribe una contraseña",
    QR_password: "QR Password",
    confirm_password: "Confirma la contraseña",
    warning_encrypted:
        "Vamos a encriptar el código QR para que solo tu puedas usarlo. Por favor, elige 4 números o letras. Es importante que no olvides este código, porque lo necesitaras para importar tu frase con QR en el futuro.",
    warning_seed:
        "Nunca compartas tus palabras, no hagas captura de pantalla de las mismas, ni las copies en un archivo de texto. Escríbelas en un papel y guárdalo en un lugar seguro.",
    warning_QR:
        "Si bien este código QR que contiene tus palabras esta encriptado no es seguro conservarlo como captura de pantalla o archivo de imagen. Úsalo para importar tus palabras a un nuevo dispositivo y luego deshecha el código QR. Lo puedes conservar bajo tu propio riesgo.",
    info_confirm_password:
        "Por favor, confirma la contraseña. Y recuerda que la necesitarás cuando quieras escanear el código QR que vas a generar.",
    password_error: "Las contraseñas ingresadas no son iguales",
    update: "Actualizar",
    web_updates:
        "Hay actualizaciones disponibles para tu versión de la aplicación web, las mismas deberían impactar automáticamente al reiniciar la pagina; si no fuese así puede que sea necesario que limpies el cache y cookies de tu navegador.",
    app_updates:
        "Hay actualizaciones disponibles para tu versión, por lo tanto es necesario actualizar la aplicación, ya que ciertas características podrían dejar de funcionar. Puedes probar de reiniciar la aplicación y la misma se actualizara en forma automatica o dirigirte al %{store} y presionar actualizar.",
    swap_warning: "Realizando esta operación perderás el %{percentage}% del monto ingresado",
    owned_by: "Propiedad de",

    create: "Crear",
    owner: "Propietario",
    create_new_item: "Crear nuevo Item (NFT)",
    edit_item: "Editar Borrador",
    required: "Requerido",
    item_name: "Ej: Flores de Primavera",
    image: "Imagen",
    poster_image: "Imagen de portada",
    select_poster_image: "Selecciona la imagen de portada para tu video",
    image_video: "Imagen o Video",
    not_view_nft_msg: "Este mensaje contiene un NFT y no es soportado por esta billetera.",
    sold_to: "Vendido a",
    send_to: "Transferido a",
    transfer_to: "Transferir a",
    received_from: "Transferido por",
    error_transfer_nft: "No puedes enviar un NFT si esta a la venta, quitalo del MarketPlace e intenta nuevamente.",
    buyed_to: "Comprado a",
    bot_image_resize_title: "¿Quieres que te ayude a optimizar el tamaño de tu imagen?",
    bot_image_resize_message: "Toca sobre este mensaje para optimizar tu imagen.",
    image_size_error: "La imagen es demasiado grande, la misma no puede superar los 3 MB.",
    video_size_error: "El video es demasiado grande, el mismo no puede superar los 50 MB.",
    video_width_error: "El ancho máximo admitido para video es de 720 px.",
    video_type_error: "El formato del video debe ser .MP4",
    video_width_min_error: "El ancho mínimo admitido para video es de 360 px.",
    video_duration_error: "El video no puede ser mayor a 90 segundos.",
    video_ratio_error: "La relación de aspecto del video no es correcta, este puede ser 9/16 o 16/9.",
    video_size_per_minute_error: "El video no puede tener un tamaño mayor a 40 MB por cada 60 segundos.",

    file_type_supported:
        "Tipos de archivo admitidos: Imagen JPG, PNG. Tamaño máximo: 3 MB // Video MP4. Tamaño máximo 50 MB.",
    processing_video: "Procesando video, aguarde por favor...",
    external_link: "Enlace externo",
    external_link_description:
        "Incluiremos un enlace a esta URL en la página de detalles de este elemento, para que los usuarios puedan hacer clic para obtener más información al respecto. Le invitamos a vincular a su propia página web con más detalles.",
    external_link_placeholder: "Ej: https://yoursite.com/nft/123",
    description: "Descripción",
    description_description: "La descripción se incluirá en la página de detalles del artículo debajo de su imagen.",
    description_placeholder: "Ej: Obra única dibujada a mano de un ...",
    blockchain_description: "Seleccione la blockchain sobre la que se asentará su NFT",
    propieties: "Propiedades",
    propieties_description: "Rasgos textuales que se muestran como rectángulos",
    type_props: "Tipo: Ej: 'FONDO'",
    name_props: "Nombre: Ej: 'Azul'",
    return_percentage: "Porcentaje de retorno",
    offer_for_sale: "Vender",
    cancel_sale: "Cancelar venta",
    confirm_buy: "Confirmar Compra",
    cancel_sale_question: "¿Quieres dejar de ofrecer a la venta este NFT?",
    cancel_sale_description: "Para realizar esta operación deberás abonar el costo de una transacción.",
    nft_temporal_message:
        "No es necesario que recargues la aplicación, ni salgas de tu perfil y vuelvas entrar. Una vez procesado se mostrará automáticamente en esta pantalla",
    nft_temporal_subtitle: "Estamos procesando tu NFT, una vez que este procesado lo verás en los NFTs de tu perfil.",
    return_percentage_description: "Establece el porcentaje que obtendrás en futuras transacciones de tu artículo",
    return_percentage_error: "El porcentaje no puede superar el 20%",
    address_description: "Dirección en la que recibirás los pagos, este campo no es editable",
    on_sale: "En venta",
    info_card_confirm_sale:
        "Tenemos todo listo para que tu NFT este en nuestro MarketPlace, confirma la transacción para continuar. Tu NFT puede tardar unos minutos en aparecer listado en el Marketplace.",
    info_card_confirm_transfer:
        "Tenemos todo listo para transferir tu NFT, confirma la transacción para continuar, este proceso puede tardar algunos minutos.",
    almost_done: "Casi hemos terminado!",
    bot_marketplace_message: "Estamos procesando tu solicitud, en unos minutos verás tu NFT listado en el Marketplace.",
    bot_no_buy:
        "Momentáneamente no es posible realizar la compra de un NFT desde la App, te invitamos a ofertar por este NFT desde nuestra webApp",
    bot_marketplace_message_cancel:
        "Estamos procesando tu solicitud, en unos minutos dejarás de ver tu NFT listado en el Marketplace.",
    apply_loan: "Solicita tu préstamo en ASCAV",
    info_card_confirm:
        "Ya estas terminando el primer paso para crear tu NFT. Una vez procesado podrás ponerle precio y ofrecerlo en el NFTs Marketplace.",
    approve_NFT: "Aprobar NFT",
    approve_pending: "En cuanto haya impactado la aprobación de tu NFT podrás confirmar para vender este item",
    info_card_approve:
        "Vamos a aprobar tu NFT antes de ponerlo a la venta, para esto pagarás los gastos que abajo se detallan. No te preocupes, ya casi terminamos los pasos para que puedas vender tu NFT.",
    info_card_buy: "A continuación detallamos los costos por la compra del NFT",
    price: "Precio",
    price_description: "Establece el precio al que se ofrecera tu NFT a los compradores",
    dapp_web_message: "Es posible que algunas de las funciones para Dapps no estén disponibles en la versión web.",
    dapp_web_info:
        "Puedes instalar nuestra app desde las tiendas oficiales, tendrás una mejor experiencia de uso y mayor seguridad en tus operatorias.",
    services: "Servicios",
    email: "Email",
    lastname: "Apellido",
    phone: "Teléfono",
    error_email: "Error al actualizar email",
    error_name: "Error al actualizar nombre",
    error_lastname: "Error al actualizar apellido",
    error_phone: "Error al actualizar teléfono",
    notifications: "Notificaciones",
    empty_list: "No se encontraron resultados. Por favor, verifica tu búsqueda e inténtalo nuevamente.",
    empty_message: "Aún no tienes notificaciones",
    empty_follower:
        "Todavía no tienes solicitudes de seguimiento. Recuerda que solo aparecen aca solicitudes cuando tu perfil es privado",
    empty_post_own: "¡Tu perfil no tiene posts por el momento!",
    empty_post: "No hay POSTs disponibles.",
    empty_nft_own: "¡Tu perfil no tiene NFTs por el momento! ",
    empty_nft: "No hay NFTs disponibles.",
    empty_nft_history: "No tienes un histórico.",
    empty_poap_own: "¡Tu perfil no tiene POAPs por el momento! ",
    empty_poap: "No hay POAPs disponibles.",
    empty_create_poaps: "Aparecerán aquí a medida que los obtengas, usa el escaner QR para reclamar POAPs.",
    empty_create_nft: "Aparecerán aquí a medida que los obtengas o los crees desde el MarketPlace.",
    empty_create_posts: "Aparecerán aquí a medida que vayas creando nuevos Posts",
    personal_verification: "Verificación Personal",
    previous: "Anterior",
    zip_code: "Código Postal",
    birthdate: "Fecha de Nacimiento",
    step: "Paso",
    verification_completed: "Verificación Completada",
    personal_information: "Información Personal",
    facial_verification: "Reconocimiento Facial",
    front_idcard: "Frente del documento",
    back_idcard: "Dorso del documento",
    take_selfie: "Tómate una Selfie",
    idcard_title: "Estás a punto de cargar tu documento de identidad. Por favor, asegúrate de lo siguiente:",
    idcard_description_one: "Este es tu propio documento vigente, emitido por el gobierno.",
    idcard_description_two: "Este es un documento original, no una copia o escaneo.",
    idcard_description_three: "Retira cualquier soporte o funda del documento para evitar reflejos o borrosidades.",
    idcard_description_four: "Coloca el documento sobre una superficie de fondo sólido.",
    selfie_title: "Estás a punto de cargar una selfie. Por favor, asegúrate de lo siguiente:",
    selfie_description_one: "Tómate una selfie con una expresión neutral.",
    selfie_description_two: "Asegúrate de que se vea todo tu rostro, centrado, y que tus ojos estén abiertos.",
    video_title:
        "¡Último paso! Graba un video de 8 segundos realizando los gestos que se mostrarán en la pantalla una vez comenzado el video.",
    video_description: "Con este video validaremos que seas la misma persona que aparece en tu documento.",
    record_video: "Graba el Video",
    complete_field_warning: "Completa todos los campos antes de continuar.",
    complete_file_warning: "Carga la imagen antes de continuar",
    photo_idcard_front: "Posiciona el frente del documento dentro del marco.",
    photo_idcard_back: "Posiciona el dorso del documento dentro del marco.",
    kyc_mouth: "Abre y cierre la boca",
    kyc_eyes: "Parpadea",
    kyc_wink: "Guiña el ojo",
    share: "Compartir",
    share_post: "Compartir post",
    check_nft: "¡Mira este increíble NFT!",
    check_post: "¡Mira este increíble post!",
    check_experience: "¡Mira esta increíble experiencia!",
    copy_link: "Copiar enlace",
    link_copied: "Enlace copiado",
    experiences: "Experiencias",
    select_ticket: "Seleccionar ticket",
    select_quantity: "Seleccionar cantidad",
    select: "Seleccionar",
    use_in: "Usar en",
    congratulations: "¡Felicitaciones!",
    enjoy_purchase: "Ahora puedes disfrutar de tu compra.",
    confirm_go_back: "¿Estás seguro de que deseas volver al inicio? Se perderán los cambios realizados.",
    deleted_successfully: "Eliminado correctamente",
    free: "Gratis",
    order_details: "Detalle de Orden",
    confirm_order: "Confirmar Orden",
    my_cart: "Mi Carrito",
    add_cart: "Agregar al Carrito",
    quantity: "Cantidad",
    google_permission:
        "Si opta por iniciar sesión mediante Google, será necesario que apruebe los permisos para acceder a Drive. Por el contrario, tiene la opción de iniciar sesión de manera incógnita.",
    continue_with: "Continuar con",
    or: "o",
    new_user: "nueva Cuenta",
    use_account_associated: "Cuentas almacenadas en su dispositivo o asociadas a su correo electrónico",
    incognito_with_seed_phrase: "Incognito con Frase Semilla",
    seed_phrase_detail: "La frase semilla te dará acceso a tus criptomonedas y activos digitales",
    welcome_sthorm: "Bienvenido a Sthorm",
    sthorm_subtitle: "Una plataforma cerrada, orientada a un propósito, de ingeniería social para la prosperidad.",
    gob_subtitle:
        "Estás a un paso de desbloquear la puerta de entrada al emocionante mundo de los activos digitales y la tecnología blockchain.",
    username: "Usuario",
    password: "Contraseña",
    sign_in: "Iniciar Sesión",
    sign_up: "Registrate",
    confirmPassword: "Confirmar Contraseña",
    amount_to_buy: "Monto a comprar",
    receives: "Recibe",
    select_deposit_bank: "Selecciona el banco donde realizaras el deposito.",
    select_receive_bank: "Selecciona el banco donde recibiras el deposito.",
    payment_receipt: "Completa el número de referencia del depósito y carga el comprobante de pago.",
    transactions: "Transacciónes",
    recent_transactions: "Transacciónes recientes",
    receipt: "Comprobante",
    empty_transactions:
        "Parece que todavía no tienes ninguna transacción. Cuando hagas una operación la verás en esta sección.",
    kyc_alert: "Es necesario que completes tu KYC (Know Your Customer) para poder operar con COINCAEX.",
    deposit_reference: "Número de referencia del depósito",
    sell: "Vender",
    total_to_deposit: "Total a Despoitar",
    commission: "Comisión",
    account_holder: "Titular",
    account_type: "Tipo de cuenta",
    account_name: "Nombre de la cuenta",
    account_number: "Número de cuenta",
    account_data: "Datos de la cuenta",
    amount_to_sell: "Monto a vender",
    total_deposit: "Total que se te depositará",
    banks: "Bancos",
    empty_banks: "Aún no has agregado ningún banco.",
    add_bank: "Agregar Banco",
    select_bank: "Seleccionar Banco",
    select_account: "Seleccionar Cuenta",
    discount_code: "Código de descuento (Opcional)",
    verify: "Verificar",
    hash_receipt: "Completa el hash de la transacción y carga el comprobante de pago.",
    hash_transaction: "Hash de la Transacción (Opcional)",
    confirm_sell: "Confirmar Venta",
    buys: "Compra",
    sale: "Venta",
    bank_message: "Antes de iniciar la transacción, por favor agrega una cuenta bancaria.",
    no_proccessed_request: "La app no es compatible con el requerimiento de la Dapp.",
    social: "SOCIAL",
    chats: "CHATs",
    payment: "Pago",
    transaction_message: "¡Transacción realizada con éxito! Puedes verificar su estado en transacciones recientes.",
    transaction_success: "Transacción exitosa.",
    error_coincaex: "Ha ocurrido un error. Por favor, ponte en contacto con el soporte de Coincaex.",
    back_coincaex: "Regresar a Coincaex",
    transaction_open_error: "No es posible consultar esta transaccion en este momento.",
    verified: "Verificado",
    profile_contact_QR: "Contacto %{name}",
    signed_by: "Firmado por ",
    wallet_connect_requesting_error: "%{name} solicita una acción que no pertenece a tu cuenta actual",
    wallet_connect_switch_account:
        "Es posible que tengas que cambiar de cuenta para responder a esta solicitud de dirección (%{address})`",
    error_message: "Lo siento, algo salió mal. Por favor, inténtalo de nuevo más tarde.",
    purchase_detail: "Detalle de la compra",
    buy_now: "Comprar ahora",
    processing_payment: "Estamos procesando su pago",
    complete_purchase: "Finalizar compra",
    add_item_warning: "Agrega un item antes de continuar",
    calendar: "Calendario",
    fee_expenses: "No incluye gastos de fee",
    important_announcement: "FUERA DE SERVICIO",
    maintenance_mode:
        "Estamos relaizando tareas de mantenimiento en los servicios de %{name}, por lo que el ingreso a la app se verá afectado durante algunas horas. Esperamos sepa disculpanos.",
};
