import React, { Component } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import CurrencyCard from "@components/cards/CurrencyCard";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import store from "@store/index";
import { selectExchangeFrom, selectExchangeTo, updateCurrencyEnabled } from "@store/actions/wallet";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { Chat } from "@custom-types/Chat";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import SwitchCard from "@components/cards/SwitchCard";
import BoldText from "@base/BoldText";
import Icon from "@components/icons";
import { ExchangeNavigatorScreens } from "@navigation/ExchangeNavigator";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";
import { colors } from "@styles/globalStyles";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import { Route } from "@react-navigation/native";


interface Props {
    currencies: Array<Currency>;
    enabledCurrencies?: Array<Currency>;
    fiatCurrency: FiatCurrency; 
    onPress: (item) => void
  
}

interface State {
  
}

const { t } = i18n;

export default class SelectCurrencyComponent extends Component<Props, State> {
 
    constructor(props: Props) {
        super(props);

    
    }

    async switchSecurity(currency) {
        store.dispatch(
            updateCurrencyEnabled({ currency: currency, enabled: currency.enabled == null ? true : !currency.enabled }),
        );
    }

  

    renderItem = ({ item }) =>
        item.isEnabled() ? (
            <CurrencyCard onPress={() => this.props.onPress(item)} currency={item} fiatCurrency={this.props.fiatCurrency} />
        ) : (
            <SwitchCard
                title={item.getPName()}
                left={
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <CurrencyIcon styles={{ marginRight: 15 }} currency={item} />

                        <BoldText fontSize={20}>{item.getPName()}</BoldText>
                        <NetworkCurrencySymbol currency={item}></NetworkCurrencySymbol>
                    </View>
                }
                onToggle={() => this.switchSecurity(item)}
                isOn={item.isEnabled()}
            />
        );

    render() {
        return (
           
                    <FlatList
                        data={this.props.currencies}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.getId()}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        initialNumToRender={this.props.currencies.length}
                        maxToRenderPerBatch={10}
                        extraData={this.props.enabledCurrencies}
                    />
     
     
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

        overflow: "hidden",
    },
});
