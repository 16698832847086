// React
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";

// Types
import { NotificationModel } from "@custom-types/NotificationModel";
import { NavigationType } from "@custom-types/NavigationType";

// Base
import BoldText from "@base/BoldText";
import Card from "@base/Card";
import Row from "@base/Row";
import RegularText from "@base/RegularText";
import Badge from "@base/Badge";

import i18n from "@i18n/i18n";
import { NotificationsNavigatorScreens } from "@navigation/NotificationsNavigator";
import { format } from "date-fns";
import RadiusButton from "@base/RadiusButton";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { NotificationService } from "@core/services/NotificationService";

interface State {
    notificationsArray: Notification[];
}

interface Props {
    notification: NotificationModel;
    navigation: NavigationType;
    hideDate?: boolean;
}

const { t } = i18n;

export class NotificationCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.updateReadNotification = this.updateReadNotification.bind(this);
    }

    updateReadNotification() {
        NotificationService.getInstance().updateReadNotification(this.props.notification._id);
    }

    render() {
        return (
            <Card
                style={this.props.notification.isRead && styles.read}
                onPress={this.props.notification?.description?.length < 100 ? this.updateReadNotification : null}
            >
                {this.props.notification.title && (
                    <Row style={[styles.row]}>
                        <BoldText fontSize={15} style={styles.left} numberOfLines={2}>
                            {this.props.notification.title}
                        </BoldText>
                    </Row>
                )}
                {this.props.notification.description && (
                    <Row style={[styles.row, {paddingTop: 5}]}>
                        <RegularText numberOfLines={2} fontSize={12} style={styles.left}>
                            {trimHelper(this.props?.notification?.description, 100)}
                        </RegularText>
                    </Row>
                )}
                {this.props.notification.createdAt && (
                    <Row style={[styles.row, { justifyContent: "space-between", paddingTop: 10 }]}>
                        {this.props.hideDate ? (
                            <View></View>
                        ) : (
                            <RegularText fontSize={12} style={styles.left}>
                                {format(new Date(this.props.notification.createdAt), "MMMM dd")}
                            </RegularText>
                        )}
                        {this.props.notification?.description?.length > 99 && (
                            <RadiusButton
                                title={t("see_more")}
                                style={{ paddingVertical: 2 }}
                                fontSize={12}
                                onPress={() =>
                                    this.props.navigation.navigate("Notifications", {
                                        screen: NotificationsNavigatorScreens.NotificationDetail.routeName,
                                        params: {
                                            notification: this.props.notification,
                                        },
                                    })
                                }
                            />
                        )}
                    </Row>
                )}
            </Card>
        );
    }
}

const isUpdated = (prevProps, nextProps) => {
    return prevProps == nextProps;
};

export default React.memo(NotificationCard, isUpdated);

const styles = StyleSheet.create({
    row: {
        width: "100%",
    },

    left: {
        flex: 1,
    },
    right: {
        minWidth: 1,
    },
    read: {
        opacity: 0.5,
    },
});
