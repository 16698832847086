import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready, setMessageLoader, setProgresiveLoader, showPopup } from "@store/actions/global";

import i18n from "@i18n/i18n";
import Wallet from "@core/wallet/Wallet";

const { t } = i18n;

export default class POAPService {
    private static instance: POAPService;

    constructor() {}

    static getInstance(): POAPService {
        if (!POAPService.instance) {
            POAPService.instance = new POAPService();
        }
        return POAPService.instance;
    }

    async getProfilePOAPs(clientID: string) {
        if (Wallet.getInstance().isTestnet()) {
            return;
        }
        try {
            const url = `${getEnv("API_URL")}poaps/client/${clientID}/`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data;
        } catch (e) {
            console.warn(e);
        }
    }

    async getPOAP(poapID: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}poaps/token/${poapID}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());

            return response.data;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async getClaimData(qrHash: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}poaps/claim-qr/${qrHash}`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            store.dispatch(ready());

            return response.data;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }

    async claimPOAP(address: string, qrHash: string, secret: string) {
        store.dispatch(loading());
        try {
            const url = `${getEnv("API_URL")}poaps/claim-qr`;
            const body = {
                address: address,
                qrHash: qrHash,
                secret: secret,
            };
            const response = await axios.post(url, body, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(ready());

            return response.data;
        } catch (e) {
            store.dispatch(ready());
            console.warn(e);
        }
    }
}
