import axios from 'axios';
import Wallet from '@core/wallet/Wallet';
import { getEnv } from '@utils/helpers/global/global';
import { ApiService } from './ApiService';

export default class DataService {
    async syncData(wallet: Wallet){
        let currencies = wallet.getCurrencies();
        try {
            const url = `${getEnv('API_URL')}coins/data`;
            const result = (await axios.get(url, {headers: ApiService.headers()}))['data'];
            
            currencies.map(currency => {
                if(result[currency.getId()]){
                    currency.setPairs(result[currency.getId()].pairs)
                    currency.setFiat(result[currency.getId()].price)
                    currency.setPriceHistoryDaily(result[currency.getId()].priceHistory.daily)
                    currency.setPriceHistoryWeekly(result[currency.getId()].priceHistory.weekly)
                }
            });
        } catch(e){}
        return currencies;
      }
}

