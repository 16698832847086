import React, { Component } from "react";
import { View, StyleSheet, Text, TextStyle, Platform } from "react-native";

import { colors, settings } from "@styles/globalStyles";
import Row from "./Row";
import PressableBase from "./PressableBase";

interface Props {
    left?: any;
    right?: any;
    icon?: any;
    onPress?: () => void;
    onLongPress?: () => void;
    onBlur?: () => void;
    style?: TextStyle;
    selected?: boolean;
    borderLeft?: string
    background?: any
    accessibilityLabel?: string
    children?: any
}

interface State {
    onPress: () => void;
    onLongPress: () => void;
    onBlur: () => void;
}

export default class Card extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            onPress: props.onPress ? props.onPress : null,
            onLongPress: props.onLongPress ? props.onLongPress : null,
            onBlur: props.onBlur ? props.onBlur : null,
        };
    }

    getBase() {
        return (
            <View
                style={[
                    styles.defaultStyles,
                    this.props.style,
                    { maxWidth: "100%" },
                    this.props.selected ? styles.selected : {},
                    this.props.borderLeft ? { borderLeftColor: this.props.borderLeft, borderLeftWidth: 10 } : {}
                ]}
            >
                {!this.props.children && (
                    <Row style={styles.row}>
                        {this.props.icon && <View>{this.props.icon}</View>}
                        {this.props.left && (
                            <View style={styles.left}>{this.props.left}</View>
                        )}
                        {this.props.right && (
                            <View style={styles.right}>{this.props.right}</View>
                        )}
                    </Row>
                )}
                {this.props.children}
                {this.props.background &&
                    <View style={{ position: "absolute", width: "110%", height: 150, zIndex: -9999 }}>
                        {this.props.background}
                    </View>
                }
            </View>
        );
    }

    render() {
        return (
            <View style={{ maxWidth: "100%" }}>
                {this.props.onPress || this.props.onLongPress ? (
                    <PressableBase
                        onPress={this.state.onPress}
                        onLongPress={this.state.onLongPress}
                        onBlur={this.state.onBlur}
                        disableOpacity={!this.state.onPress}
                        
                        style={
                            Platform.OS == "web" && this.state.onPress
                                ? {}
                                : 
                                //@ts-ignore
                                { cursor: "default" }
                        }
                        accessibilityLabel={this.props.accessibilityLabel}
                    >
                        {this.getBase()}
                    </PressableBase>
                ) : (
                    this.getBase()
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        overflow: "hidden",
        paddingHorizontal: 15,
        paddingVertical: 14,
        alignItems: "center",
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        shadowColor: settings.shadow.shadowColor,
        shadowOpacity: settings.shadow.shadowOpacity,
        shadowRadius: settings.shadow.shadowRadius,
        marginVertical: 5,
    },
    row: {
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
    },
    left: {
        flex: 1,
    },
    right: {
        minWidth: 50,
        alignItems: "flex-end",
    },
    leftTitle: {
        fontSize: 20,
        lineHeight: 22,
        marginTop: 7,
    },
    leftDesc: {
        lineHeight: 20,
        paddingLeft: 2,
    },
    rightDesc: {
        textAlign: "right",
    },
    selected: {
        borderWidth: 1,
        borderColor: colors.secondary,
    },
});
