import React, { Component } from "react";
import { View, StyleSheet, Platform } from "react-native";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { colors, settings } from "@styles/globalStyles";
import Currency from "@core/currencies/Currency";
import Row from "@base/Row";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import {
    getPercentagePriceVariationColor,
    getPercentagePriceVariationDaily,
} from "@utils/helpers/currency/currency.helper";

import { AreaChart } from "react-native-svg-charts";

import { Defs, Stop, LinearGradient } from "react-native-svg";
import * as shape from "d3-shape";
import SwitchSelector from "react-native-switch-selector";
import i18n from "@i18n/i18n";
import { getColorOpacity } from "@utils/helpers/global/global";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import PressableBase from "@base/PressableBase";

interface Props {
    amount?: string;
    title?: string;
    color?: string;
    symbol?: string;
    subtitle?: string;
    style?: StyleSheet;
    currency?: Currency;
    fiatCurrency?: FiatCurrency;
}

interface State {
    title: string;
    color: string;
    switch: boolean;
    price: Array<any>;
}

const { t } = i18n;

export default class CurrencyBalance extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            title: props.title,
            color: props.color ? props.color : colors.text,
            switch: false,
            price: this.props.currency.getPriceHistoryDaily().map((p) => p.price),
        };
        this.switchCurrency = this.switchCurrency.bind(this);
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            this.setState({
                title: this.props.title,
                color: this.props.color ? props.color : colors.text,
            });
        }
    }

    switchCurrency = () => {
        this.setState(
            {
                switch: !this.state.switch,
                price: [],
            },
            () =>
                this.setState({
                    price: this.state.switch
                        ? this.props.currency.getPriceHistoryWeekly().map((p) => p.price)
                        : this.props.currency.getPriceHistoryDaily().map((p) => p.price),
                }),
        );
    };

    render() {
        return (
            <View style={styles.container}>
                <View
                    style={{
                        position: "absolute",
                        width: "110%",
                        marginBottom: -60,
                        bottom: 0,
                        overflow: "hidden",
                        zIndex: -9999,
                    }}
                >
                    <AreaChart
                        style={{ height: 130, marginTop: -5, marginHorizontal: -10 }}
                        data={this.state.price}
                        contentInset={{ top: 10, bottom: 35 }}
                        svg={{
                            stroke: this.props.currency.getColor(),
                            strokeOpacity: this.state.price?.every((value) => value === this.state.price[0]) ? 0 : 1,
                            strokeWidth: 1,
                            fill: "url(#gradient)",
                        }}
                    >
                        <Defs>
                            <LinearGradient
                                id={"gradient"}
                                x1={"0%"}
                                y1={"0%"}
                                x2={"0%"}
                                y2={"100%"}
                                gradientUnits="userSpaceOnUse"
                            >
                                <Stop
                                    offset={"0%"}
                                    stopColor={this.props.currency.getColor()}
                                    stopOpacity={Platform.OS == "android" ? 0.5 : 0.6}
                                />
                                <Stop
                                    offset={Platform.OS == "android" ? "80%" : "90%"}
                                    stopColor={colors.gradientTo}
                                    stopOpacity={0}
                                />
                            </LinearGradient>
                        </Defs>
                    </AreaChart>
                </View>

                {this.props.currency && (
                    <Row style={{ width: "100%", justifyContent: "center", height: 30 }}>
                        <View
                            style={{
                                position: "absolute",
                                left: 0,
                                justifyContent: "center",
                                top: Platform.OS == "web" ? 5 : 0,
                            }}
                        >
                            <View style={{ opacity: 0.9 }}>
                                <RegularText
                                    align="center"
                                    fontSize={12}
                                    style={{
                                        backgroundColor: colors.tertiaryShadow,
                                        paddingHorizontal: 8,
                                        paddingVertical: 4,
                                        borderRadius: settings.cardRadius,
                                        overflow: "hidden",
                                    }}
                                >
                                    {this.props.currency.getPFullName()}
                                </RegularText>
                            </View>

                            <NetworkCurrencySymbol
                                containerStyles={{ alignItems: "flex-start", marginTop: 5 }}
                                styles={{
                                    minWidth: 45,
                                    marginLeft: 2,
                                    paddingVertical: 4,
                                    paddingHorizontal: 8,
                                }}
                                fontSize={10}
                                currency={this.props.currency}
                            ></NetworkCurrencySymbol>
                        </View>

                        <View
                            style={{
                                position: "absolute",
                                right: 0,
                                top: Platform.OS == "web" ? 5 : 0,
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                            }}
                        >
                            {this.props.currency.getFiat() !== 0 && (
                                <View>
                                    <RegularText
                                        align="right"
                                        fontSize={12}
                                        style={{
                                            backgroundColor: colors.tertiaryShadow,
                                            paddingHorizontal: 8,
                                            paddingVertical: 4,
                                            borderRadius: settings.cardRadius,
                                            overflow: "hidden",
                                        }}
                                    >
                                        {this.props.fiatCurrency.getSymbol() +
                                            " " +
                                            this.props.fiatCurrency.fiatFormat(this.props.currency.getFiat())}
                                    </RegularText>
                                </View>
                            )}

                            {this.state.switch && (
                                <View>
                                    {getPercentagePriceVariationColor(this.props.currency.getPriceHistoryWeekly()) && (
                                        <BoldText
                                            color={getPercentagePriceVariationColor(
                                                this.props.currency.getPriceHistoryWeekly(),
                                            )}
                                            align="right"
                                            fontSize={12}
                                            style={{ lineHeight: 18, marginTop: 2, paddingHorizontal: 8 }}
                                        >
                                            {getPercentagePriceVariationDaily(
                                                this.props.currency.getPriceHistoryWeekly(),
                                            )}
                                        </BoldText>
                                    )}
                                </View>
                            )}

                            {!this.state.switch && (
                                <View>
                                    {getPercentagePriceVariationColor(this.props.currency.getPriceHistoryDaily()) && (
                                        <BoldText
                                            color={getPercentagePriceVariationColor(
                                                this.props.currency.getPriceHistoryDaily(),
                                            )}
                                            align="right"
                                            fontSize={12}
                                            style={{ lineHeight: 18, marginTop: 2, paddingHorizontal: 8 }}
                                        >
                                            {getPercentagePriceVariationDaily(
                                                this.props.currency.getPriceHistoryDaily(),
                                            )}
                                        </BoldText>
                                    )}
                                </View>
                            )}
                        </View>
                    </Row>
                )}

                <BoldText style={{ marginTop: 15 }} fixedWidth={300} align="center" fontSize={35} color={colors.text}>
                    {this.props.amount}
                </BoldText>
                {this.props.currency.getFiat() !== 0 && (
                    <View style={{ paddingHorizontal: 10 }}>
                        <BoldText
                            align="center"
                            fontSize={12}
                            fixedWidth={150}
                            style={{
                                backgroundColor: colors.tertiaryShadow,
                                paddingHorizontal: 8,
                                paddingTop: 2,
                                paddingBottom: 4,
                                borderRadius: settings.cardRadius,
                                overflow: "hidden",
                            }}
                        >
                            {this.props.fiatCurrency.getSymbol() +
                                " " +
                                this.props.fiatCurrency.fiatFormat(this.props.currency.getFiatTotalBalance())}{" "}
                        </BoldText>
                    </View>
                )}

                <Row
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        position: "absolute",
                        marginBottom: -75,
                        bottom: 0,
                        zIndex: 9999,
                        // width: "100%",
                    }}
                >
                    <View style={{ position: "relative" }}>
                        <SwitchSelector
                            options={[
                                { label: t("daily"), value: 1 },
                                {
                                    label: t("weekly"),
                                    value: 0,
                                },
                            ]}
                            initial={this.state.switch ? 1 : 0}
                            value={this.state.switch ? 1 : 0}
                            height={35}
                            style={{
                                zIndex: 9999,
                                width: 165,
                                marginHorizontal: "auto",
                                alignSelf: "center",
                            }}
                            onPress={() => null}
                            textColor={colors.text}
                            selectedColor={colors.white}
                            buttonColor={colors.secondary}
                            borderColor={colors.secondary}
                            animationDuration={250}
                            backgroundColor={colors.shadow}
                        />
                        <PressableBase
                            onPress={this.switchCurrency}
                            style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 99999 }}
                        ></PressableBase>
                    </View>
                </Row>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        marginTop: Platform.OS == "web" ? 22 : 0,
        marginBottom: 98,
        alignItems: "center",
    },
    center: {
        flexDirection: "row",
        borderRadius: 15,
        width: 250,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    currencySymbol: {
        marginLeft: -20,
    },
});
