import { _SendScreen } from "@screens/wallet/send/SendScreen";
import { connect } from "react-redux";
import { _ConfirmSendScreen } from "@screens/wallet/send/ConfirmSendScreen";
import { _ConfirmTransferRedeemableScreen } from "@screens/experiences/ConfirmTransferRedeemableScreen";
import ExperienceService from "@core/services/ExperienceService";
import store from "@store/index";
import { showPopup } from "@store/actions/global";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import { ChatService } from "@core/services/ChatService";
import { REDEEMABLE } from "@custom-types/ExpercienceModel";
import { setCustomMessage } from "@store/actions/chat.actions";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import Wallet from "@core/wallet/Wallet";
import i18n from "@i18n/i18n";

const { t } = i18n;

export class _ConfirmTtransferRedeemableExtendedScreen extends _ConfirmTransferRedeemableScreen {
    constructor(props: any) {
        super(props);
        this.sendMessage = this.sendMessage.bind(this);
        this.setRedeemableMessage = this.setRedeemableMessage.bind(this);
    }

    async onConfirm() {
        const resp = await ExperienceService.getInstance().confirmRedeemableTransfer(this.state.skeleton);
        if (resp) {
            await this.setRedeemableMessage(this.state.redeemable);
            await this.sendMessage();
            await ExperienceService.getInstance().getRedeemables(this.state.redeemable.redeemableOption.type);
            this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName);
            return;
        }
        store.dispatch(
            showPopup({
                type: "ERROR",
                message: t("an_error_has_occurred"),
            }),
        );
    }

    async setRedeemableMessage(redeemable: REDEEMABLE) {
        const customMessage: CustomMessageModel = store.getState().chat.customMessage;
        customMessage.network = Wallet.getInstance().getNetworkType();
        customMessage.currencyId = "...";
        customMessage.currencyName = "...";
        customMessage.address = "...";
        customMessage.redeemable = {
            _id: redeemable._id,
            experience: redeemable.experience,
            redeemableOption: redeemable.redeemableOption,
            status: redeemable.status,
        };

        store.dispatch(setCustomMessage(customMessage));
    }

    async sendMessage() {
        const service: ChatService = await ChatService.getInstance();
        await service.sendCustomMessage();
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        customMessage: state.chat.customMessage,
        selectedChat: state.chat.selected,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmTtransferRedeemableExtendedScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_ConfirmTtransferRedeemableExtendedScreen);

export default ConfirmTtransferRedeemableExtendedScreen;
