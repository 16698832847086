import React, { Component } from "react";
import { FlatList, Platform, ScrollView, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { Route } from "@react-navigation/native";
import { REDEEMABLE, ExperienceStatus, RedeemableType } from "@custom-types/ExpercienceModel";
import ExperienceService from "@core/services/ExperienceService";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import ImageBase from "@components/image/ImageBase";
import { colors, settings } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import QRCode from "react-native-qrcode-svg";
import Card from "@base/Card";
import Icon from "@components/icons";
import store from "@store/index";
import { setSelectedRedeemable, updateRedeemable } from "@store/actions/experiences.actions";
import Row from "@base/Row";
import RedeemableHistoryCard from "./components/RedeemableHistoryCard";
import CircleButton from "@base/CircleButton";
import { getRedeemableColorStatus, getRedeemableIcon } from "./components/helpers/redeemable.helper";
import { ExperiencesNavigator, ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import AvatarBase from "@components/avatar/AvatarBase";
import { hideModal, loading, ready, showModal, showScanner } from "@store/actions/global";
import { getColorOpacity } from "@utils/helpers/global/global";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import { HomeNavigator, HomeNavigatorScreens } from "@navigation/HomeNavigator";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import QRBase from "@base/QRBase";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<string, { redeemable: REDEEMABLE, backTo?: string }>;
}

interface State {
    redeemable: REDEEMABLE;
    showDescription: boolean;
    isOwner: boolean;
}

const { t } = i18n;

export class _RedeemableScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setFavorite = this.setFavorite.bind(this);
        this.getDate = this.getDate.bind(this);
        this.showArchiveModal = this.showArchiveModal.bind(this);
        this.archiveRedeemable = this.archiveRedeemable.bind(this);
        this.setUnarchive = this.setUnarchive.bind(this);
        this.updateRedeemable = this.updateRedeemable.bind(this);
        this.state = {
            redeemable: this.props.route?.params?.redeemable || null,
            showDescription: false,
            isOwner: this.props.route?.params?.redeemable?.owner?._id == this.props.client?._id,
        };
    }

    async componentDidMount() {
        this.updateRedeemable()
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.route.params?.redeemable !== this.props.route.params.redeemable) {
            this.updateRedeemable()
        }
    }

    componentWillUnmount() {
        store.dispatch(setSelectedRedeemable(null));
    }

    async updateRedeemable() {
   
        const redeemable: REDEEMABLE = await ExperienceService.getInstance().getRedeemable(
            this.props.route.params?.redeemable?._id,
        );

        if (redeemable) {
            store.dispatch(setSelectedRedeemable(redeemable));
            this.setState({ redeemable: redeemable, isOwner: redeemable?.owner?._id == this.props.client?._id });
        }
    }

    async setFavorite() {
        this.setState(
            (prevState) => ({
                redeemable: {
                    ...prevState.redeemable,
                    favorite: !this.state.redeemable.favorite,
                },
            }),
            () => store.dispatch(updateRedeemable(this.state.redeemable)),
        );
        const resp = await ExperienceService.getInstance().setFavorite(this.state.redeemable);
    }

    getDate() {
        const startDate = moment(
            this.state.redeemable.redeemableOption?.startDate || this.state.redeemable.experience?.startDate,
        );
        const endDate = moment(
            this.state.redeemable.redeemableOption?.endDate || this.state.redeemable.experience?.endDate,
        );

        if (startDate.isSame(endDate, "day")) {
            return startDate.format("LL");
        }
        if (startDate.isSame(endDate, "month")) {
            return `${startDate.format("MMMM D")} to ${endDate.format("D")}, ${startDate.format("YYYY")}`;
        }
        if (startDate.isSame(endDate, "year")) {
            return `${startDate.format("MMMM D")} to ${endDate.format("MMMM D")}, ${startDate.format("YYYY")}`;
        }
        return `${startDate.format("LL")} to ${endDate.format("LL")}`;
    }

    showArchiveModal() {
        store.dispatch(
            showModal({
                avatar: this.state.redeemable.redeemableOption.image.cover,
                title: trimHelper(this.state.redeemable.redeemableOption.name, 50),
                message: t("gate_message", { type: this.state.redeemable.redeemableOption.type }),
                question: t("wallet_connect_request_question"),
                btnTitle: t("archive"),
                onPress: () => this.archiveRedeemable(),
            }),
        );
    }

    async archiveRedeemable() {
        store.dispatch(hideModal());
        store.dispatch(loading());
        const resp = await ExperienceService.getInstance().archiveRedeemable(this.state.redeemable);

        if (resp) {
            ExperienceService.getInstance().getRedeemables(this.state.redeemable.redeemableOption.type);
            this.props.navigation.navigate("Home", {
                screen: "HomeMain",
            });
        }
        store.dispatch(ready());
    }

    async setUnarchive() {
        store.dispatch(loading());
        const resp = await ExperienceService.getInstance().unarchiveRedeemable(this.state.redeemable);

        if (resp) {
            ExperienceService.getInstance().getRedeemables(this.state.redeemable.redeemableOption.type);
            this.props.navigation.navigate("Home", {
                screen: "HomeMain",
            });
        }
        store.dispatch(ready());
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.redeemable.redeemableOption.name, 15)}
                    type={HeaderType.Light}
                    backTo={this.props.route.params?.backTo || null}
               
                    {...this.props}
                />

                <Container style={{ flex: 1 }}>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <Card style={styles.wrapper}>
                            <ImageBase
                                autoSize={true}
                                uri={
                                    this.state.redeemable.redeemableOption?.image?.carousel ||
                                    this.state.redeemable.redeemableOption?.image?.cover ||
                                    this.state.redeemable.experience.image.cover
                                }
                            ></ImageBase>

                            {this.state.isOwner && (
                                <PressableBase onPress={this.setFavorite} style={styles.favorite}>
                                    {this.state.redeemable.favorite ? (
                                        <Icon color={colors.yellow} size={20} name="star-fill" />
                                    ) : (
                                        <Icon color={colors.grey} size={20} name="star" />
                                    )}
                                </PressableBase>
                            )}

                            <View style={styles.circleWrapper}>
                                <View style={styles.circle}>
                                    <RegularText style={{ marginTop: Platform.OS == "web" ? -2 : 2 }}>
                                        {getRedeemableIcon(this.state.redeemable.redeemableOption.type)}
                                    </RegularText>
                                </View>
                            </View>
                            <View
                                style={{
                                    position: "relative",
                                    height: 200,
                                    marginTop: -75,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                <View style={styles.qrWrapper}>
                                    <QRBase value={`?redeemable=${this.state.redeemable._id}`} size={150} />
                                </View>
                            </View>
                            <View style={styles.details}>
                                <Row style={{ width: "100%", justifyContent: "space-between", marginBottom: 4 }}>
                                    <View>
                                        {this.state.redeemable.archivedAt && (
                                            <View
                                                style={{
                                                    borderRadius: 15,
                                                    paddingHorizontal: 12,
                                                    paddingVertical: 4,
                                                    backgroundColor: getColorOpacity(colors.grey, 0.8),
                                                }}
                                            >
                                                <Row style={{ alignItems: "center" }}>
                                                    <Icon
                                                        size={14}
                                                        style={{ paddingRight: 5 }}
                                                        color={colors.labelText}
                                                        name={"archive"}
                                                    ></Icon>

                                                    <RegularText color={colors.labelText} fontSize={12}>
                                                        {t("archived").toUpperCase()}
                                                    </RegularText>
                                                </Row>
                                            </View>
                                        )}
                                    </View>
                                    <View>
                                        <BoldText
                                            style={{
                                                paddingHorizontal: 12,
                                                paddingVertical: 4,
                                                borderRadius: 22,
                                                backgroundColor: getRedeemableColorStatus(this.state.redeemable.status),
                                            }}
                                            align="center"
                                            fontSize={12}
                                            color={colors.labelText || colors.white}
                                        >
                                            {this.state.redeemable?.status.toUpperCase()}
                                        </BoldText>
                                    </View>
                                </Row>
                                <BoldText style={{ position: "relative" }} fontSize={20}>
                                    {this.state.redeemable.redeemableOption.name}
                                </BoldText>

                                {!this.state.showDescription &&
                                this.state.redeemable.redeemableOption.description?.length > 149 ? (
                                    <View>
                                        <RegularText align={"justify"} style={{ paddingTop: 10 }}>
                                            {trimHelper(this.state.redeemable.redeemableOption.description, 150)}
                                            <PressableBase
                                                style={{ height: 18 }}
                                                onPress={() => this.setState({ showDescription: true })}
                                            >
                                                <RegularText align="left" style={{ color: "#4796fb" }}>
                                                    {" "}
                                                    {t("see_more")}
                                                </RegularText>
                                            </PressableBase>
                                        </RegularText>
                                    </View>
                                ) : (
                                    <View>
                                        <RegularText align={"justify"} style={{ paddingTop: 10 }}>
                                            {this.state.redeemable.redeemableOption.description}
                                        </RegularText>
                                    </View>
                                )}
                                {this.state.redeemable?.owner && (
                                    <Row style={{ alignItems: "center", paddingTop: 15 }}>
                                        <AvatarBase
                                            size={30}
                                            uri={
                                                this.state.redeemable?.owner?.profileImagePath?.thumbnail
                                                    ? this.state.redeemable?.owner?.profileImagePath.thumbnail
                                                    : ""
                                            }
                                            alias={this.state.redeemable?.owner?.alias}
                                            overlayContainerStyle={colors.secondaryShadow}
                                        ></AvatarBase>

                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {t("owned_by")}
                                            <BoldText fontSize={14}>
                                                {" "}
                                                {trimHelper(this.state.redeemable?.owner?.alias, 20)}
                                            </BoldText>
                                        </RegularText>
                                    </Row>
                                )}
                                {this.state.redeemable?.experience?.location?.length && (
                                    <Row
                                        style={{
                                            paddingTop: 15,
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <View style={styles.icon}>
                                            <Icon color={colors.text} size={16} name="address"></Icon>
                                        </View>
                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {this.state.redeemable.experience.location}
                                        </RegularText>
                                    </Row>
                                )}
                                {this.state.redeemable?.experience?.startDate && (
                                    <Row
                                        style={{
                                            paddingTop: 10,
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <View style={styles.icon}>
                                            <Icon color={colors.text} size={15} name="calendar-minus"></Icon>
                                        </View>
                                        <RegularText fontSize={14} style={{ paddingLeft: 5 }}>
                                            {this.getDate()}
                                        </RegularText>
                                    </Row>
                                )}
                            </View>
                        </Card>

                        {this.state.isOwner && (
                            <Card
                                style={{ flex: 1, width: "100%", flexDirection: "row", justifyContent: "space-around" }}
                            >
                                {this.state.redeemable.redeemableOption.transferable &&
                                    this.state.redeemable.status !== ExperienceStatus.EXPIRED &&
                                    this.state.redeemable.status !== ExperienceStatus.USED && (
                                        <CircleButton
                                            icon={"lightning-charge-1"}
                                            title={t("send")}
                                            onPress={() =>
                                                this.props.navigation.navigate(
                                                    ExperienceNavigatorScreens.TransferRedeemable.routeName,
                                                    { redeemable: this.state.redeemable },
                                                )
                                            }
                                        ></CircleButton>
                                    )}
                                {this.state.redeemable.status !== ExperienceStatus.EXPIRED &&
                                    this.state.redeemable.status !== ExperienceStatus.USED && (
                                        <CircleButton
                                            icon={"qr"}
                                            title={`${t("use")} (QR)`}
                                            onPress={() => store.dispatch(showScanner())}
                                        ></CircleButton>
                                    )}

                                {!this.state.redeemable.archivedAt && (
                                    <CircleButton
                                        icon={"archive"}
                                        title={t("archive")}
                                        iconSize={26}
                                        onPress={this.showArchiveModal}
                                    ></CircleButton>
                                )}

                                {this.state.redeemable.archivedAt && (
                                    <CircleButton
                                        icon={"archive"}
                                        title={t("unarchive")}
                                        iconSize={26}
                                        onPress={this.setUnarchive}
                                    ></CircleButton>
                                )}
                            </Card>
                        )}

                        {this.state.isOwner && (
                            <View style={{ paddingTop: 10 }}>
                                <View>
                                    <BoldText fontSize={18}>{t("history")}</BoldText>
                                </View>
                                {this.state.redeemable.history
                                    ?.map((h) => {
                                        return (
                                            <RedeemableHistoryCard
                                                redeemable={this.state.redeemable}
                                                client={this.props.client}
                                                key={h._id}
                                                history={h}
                                            ></RedeemableHistoryCard>
                                        );
                                    })
                                    .reverse()}
                            </View>
                        )}
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    details: {
        width: "100%",
        paddingTop: 5,
        paddingBottom: 25,
        paddingHorizontal: 15,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
    },
    qrWrapper: {
        position: "absolute",
        padding: 5,
        backgroundColor: colors.gradientFrom,
        borderRadius: 5,
    },
    circleWrapper: {
        position: "absolute",
        top: 15,
        left: 15,
    },
    circle: {
        height: 32,
        width: 32,
        borderRadius: 16,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    favorite: {
        position: "absolute",
        top: 15,
        right: 15,
    },
    icon: {
        backgroundColor: colors.tertiaryShadow,
        height: 30,
        width: 30,
        borderRadius: 15,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    bubble: {
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        borderRadius: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const RedeemableScreen = connect(mapStateToProps, mapDispatchToProps)(_RedeemableScreen);

export default RedeemableScreen;
