import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { FlatList, Platform, StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import NFTSmallCard from "@screens/nfts/components/NFTSmallCard";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ProfileSections } from "@custom-types/ProfileType";
import NFTService from "@core/services/NFTService";
import store from "@store/index";
import { setSelectedNFT } from "@store/actions/nfts.action";
import NFT from "@custom-types/NFTModel";
import FlatListBase from "@base/FlatListBase";
import { SectionTitle } from "../components/SectionTitle";

interface Props {
    navigation: NavigationType;
    client: Client;
    profiles: any;
    containerStyle?: ViewStyle;
}

interface State {}

const { t } = i18n;

export class _NFTsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToProfileNFTs = this.navigateToProfileNFTs.bind(this);
        this.navigateToNFT = this.navigateToNFT.bind(this);
        this.getNFTs = this.getNFTs.bind(this);
        this.state = {};
    }

    renderItem = ({ item }) => {
        return (
            <View style={{ width: 220 }}>
                <NFTSmallCard isOwner={true} nft={item} onPress={() => this.navigateToNFT(item)} />
            </View>
        );
    };

    navigateToProfileNFTs() {
        this.props.navigation.navigate(HomeNavigatorScreens.Profile.routeName, {
            screen: ProfileNavigatorScreens.ProfileMain.routeName,
            params: {
                clientID: this.props.client?._id,
                profileSection: ProfileSections.nftProfile,
            },
        });
    }

    async navigateToNFT(nft) {
        const updatedNft = await NFTService.getInstance().getNFT(nft?._id, nft?.blockchain, true);

        if (updatedNft) {
            store.dispatch(setSelectedNFT(updatedNft));
            this.props.navigation.navigate(HomeNavigatorScreens.Profile.routeName, {
                screen: ProfileNavigatorScreens.ProfileNFT.routeName,
            });
        }
    }

    getNFTs() {
        const nfts = this.props.profiles[this.props.client?._id]?.NFTs;
        const sorted_nfts: Array<NFT> = nfts.sort((a: any, b: any) =>
            new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1,
        );
        return sorted_nfts;
    }

    render() {
        //@TODO: Si el cliente no esta seteado no renderizo, revisar
        if (!this.props.client?._id) {
            return <></>;
        }
        return (
            <View>
                {this.props.profiles[this.props.client?._id]?.NFTs?.length > 0 && (
                    <View style={this.props.containerStyle}>
                        <SectionTitle
                            onPress={this.navigateToProfileNFTs}
                            title={"NFTs"}
                            icon={"✨"}
                            counter={this.props.profiles[this.props.client?._id]?.NFTs?.length}
                            {...this.props}
                        ></SectionTitle>

                        <FlatListBase
                            data={this.getNFTs() || []}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item.id || item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                            initialNumToRender={2}
                            horizontal={true}
                            maxToRenderPerBatch={10}
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        profiles: state.profile.profiles,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const NFTsSection = connect(mapStateToProps, mapDispatchToProps)(_NFTsSection);

export default NFTsSection;
