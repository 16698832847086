import Currency, { IConstructorCurrency } from "@core/currencies/Currency";
import BTCImplementation from "@core/currencies/btc/BTCImplementation";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import { Networks } from "@core/network/Networks";
import { ProviderType } from "@custom-types/ProviderType";
import Constants from "expo-constants";
import { isCurrencyEnabled } from "@core/utils/CurrencyEnabled";

export default class BTC extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            name: "BTC",
            simbol: "BTC",
            fullName: "Bitcoin",
            decimals: 8,
            implementation: BTCImplementation,
            networkID: parameters.testnet ? 1 : 0,
            color: "#fd9c3f",
            icon: require("../icons/bitcoin.png"),
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.bitcoin.testnet : Networks.bitcoin.mainnet,
            blockchain: "bitcoin",
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "BTC", true),
            buyableBy: [ProviderType.KRIPTONMARKET, ProviderType.SIMPLEX, ProviderType.ONRAMPER, ProviderType.COINCAEX],
            sellableBy: [ProviderType.COINCAEX],
        });
    }

    getAddress(options?: { path?: string; format?: string }) {
        if (options && options.format == "SEGWIT") {
            options.path = options.path || this.getCurrentPath("84");
        }
        return super.getAddress(options);
    }
}
