import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { colors, settings } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import moment from "moment";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import ImageBase from "@components/image/ImageBase";
import Card from "@base/Card";
import CircleButton from "@base/CircleButton";
import store from "@store/index";
import { addRedeemableCart, emptyRedeemableCartItem, removeRedeemableCart } from "@store/actions/experiences.actions";
import Icon from "@components/icons";
import PressableBase from "@base/PressableBase";

interface Props {
    redeemable: any;
    fromExperience?: boolean;
    quantity?: number;
    onPress: () => void;
}

interface State {
    date: DateRedeemable;
}

const { t } = i18n;

interface DateRedeemable {
    startMonth: string;
    startDay: string;
    endMonth: string;
    endDay: string;
}

export class _RedeemableOptionCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            date: {
                startMonth: null,
                startDay: null,
                endMonth: null,
                endDay: null,
            },
        };
    }

    componentDidMount(): void {
        this.getDate(this.props.redeemable.startDate, this.props.redeemable.endDate)
    }

    getDate = async (start: Date | string, end: Date | string) => {
        const startDate = moment(start);
        const endDate = moment(end);

        if (startDate.isSame(endDate, "day")) {
            this.setState({
                date: {
                    startMonth: startDate.format("MMM"),
                    startDay: startDate.format("D"),
                    endMonth: null,
                    endDay: null,
                },
            });
        } else {
            this.setState({
                date: {
                    startMonth: startDate.format("MMM"),
                    startDay: startDate.format("D"),
                    endMonth: endDate.format("MMM"),
                    endDay: endDate.format("D"),
                },
            });
        }
    };

    incrementCount = () => {
        store.dispatch(addRedeemableCart(this.props.redeemable));
    };

    decrementCount = () => {
        if (this.props.quantity > 0) {
            store.dispatch(removeRedeemableCart(this.props.redeemable))
        };
    };

    emptyItem = () => {
        store.dispatch(emptyRedeemableCartItem(this.props.redeemable._id))
    }

    render() {
        return (
            <View style={{ flex: 1, marginBottom: 10 }}>
                <PressableBase style={{ flex: 1 }} onPress={this.props.onPress}>
                    <Card style={styles.container}>
                        {this.props.redeemable.image && (
                            <ImageBase
                                style={{ height: 70, borderRadius: settings.cardRadius, marginRight: 15 }}
                                uri={
                                    this.props.redeemable?.image?.thumbnail || this.props.redeemable?.image?.cover || ""
                                }
                                autoSizeWidth={true}
                            ></ImageBase>
                        )}
                        <View style={styles.cardContainer}>
                            <Row style={{ justifyContent: "space-between", alignItems: "flex-start", marginBottom: 15 }}>
                                <RegularText style={{ width: "90%" }} fontSize={14}>
                                    {this.props.redeemable.name}
                                </RegularText>
                                {this.props.fromExperience ?
                                    <View style={{ marginLeft: 5, width: "10%", alignItems: "flex-end" }}>
                                        <Icon name="info-circle" size={16} color={colors.text} />
                                    </View> :
                                    <PressableBase style={{ margin: 5, width: "10%", alignItems: "flex-end" }} onPress={this.emptyItem}>
                                        <Icon name="x-circle" size={16} color={colors.text} />
                                    </PressableBase>
                                }
                            </Row>
                            <View style={styles.cardDetail}>
                                <BoldText fontSize={14}>
                                    {this.props.redeemable.price
                                        ? `$ ${this.props.redeemable.price.toFixed(2)}`
                                        : "$ 0.00"}
                                </BoldText>
                                <Row style={styles.quantityContainer}>
                                    <CircleButton
                                        style={{ width: 30, height: 30, marginBottom: 0 }}
                                        iconSize={12}
                                        icon="dash"
                                        onPress={this.decrementCount}
                                    />
                                    <RegularText fontSize={14} style={{ marginHorizontal: 5 }}>
                                        {this.props.quantity}
                                    </RegularText>
                                    <CircleButton
                                        style={{ width: 30, height: 30, marginBottom: 0 }}
                                        iconSize={12}
                                        icon="plus"
                                        onPress={this.incrementCount}
                                        containerStyles={{ marginRight: 0 }}
                                    />
                                </Row>
                            </View>
                        </View>
                    </Card>
                </PressableBase>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flex: 1,
    },
    addItem: {
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        width: 30,
        backgroundColor: colors.secondary,
        borderRadius: 50,
        alignContent: "center",
        margin: "auto",
    },
    quantityContainer: {
        alignItems: "center",
        borderRadius: 6,
        height: 30,
    },
    cardDetail: {
        justifyContent: "space-between",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
    },
    cardContainer: {
        flex: 1,
        justifyContent: "space-between",
        height: "100%",
    },
});

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

const RedeemableOptionCard = connect(mapStateToProps, mapDispatchToProps)(_RedeemableOptionCard);

export default RedeemableOptionCard;
