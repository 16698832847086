import React, { Component } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import CurrencyCard from "@components/cards/CurrencyCard";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import store from "@store/index";
import { selectExchangeFrom, selectExchangeTo, updateCurrencyEnabled } from "@store/actions/wallet";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { Chat } from "@custom-types/Chat";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import SwitchCard from "@components/cards/SwitchCard";
import BoldText from "@base/BoldText";
import Icon from "@components/icons";
import { ExchangeNavigatorScreens } from "@navigation/ExchangeNavigator";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";
import { colors } from "@styles/globalStyles";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import { Route } from "@react-navigation/native";
import SelectCurrencyComponent from "./SelectCurrencyComponent";

interface Props {
    navigation: NavigationType;
    route: Route<string, { address?: string }>;
    currencies: Array<Currency>;
    enabledCurrencies?: Array<Currency>;
    fiatCurrency: FiatCurrency;
    customMessage: CustomMessageModel;
    selectedChat: Chat;
    buttonActionsType: ButtonActionsType;
}

interface State {
    currencies: Array<Currency>;
}

const { t } = i18n;

export default class SelectCurrencyScreen extends Component<Props, State> {
    private from = true;
    focusListener: any;
    constructor(props: Props) {
        super(props);

        this.onPress = this.onPress.bind(this);
    }

    setFrom(value) {
        this.from = value;
    }

    onPress = (currency: Currency) => {
        if (this.from) {
            store.dispatch(selectExchangeFrom({ currency: currency }));
        } else {
            store.dispatch(selectExchangeTo({ currency: currency }));
        }
        this.props.navigation.navigate(ExchangeNavigatorScreens.Exchange.routeName);
    };

    async switchSecurity(currency) {
        store.dispatch(
            updateCurrencyEnabled({ currency: currency, enabled: currency.enabled == null ? true : !currency.enabled }),
        );
    }

    render() {
        return (
            <ScreenWrapper>
                <Header {...this.props} title={t("select_currency")} type={HeaderType.Light} />
                <Container style={styles.container}>
                    <SelectCurrencyComponent
                        currencies={this.props.currencies}
                        fiatCurrency={this.props.fiatCurrency}
                        onPress={this.onPress}
                    ></SelectCurrencyComponent>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

        overflow: "hidden",
    },
});
