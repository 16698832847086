import i18n from "i18n-js";
import { en } from "./languages/en";
import { es } from "./languages/es";
import { tr } from "./languages/tr";
import "moment/locale/es";
import * as Localization from "expo-localization";
import moment from "moment";
import { compareLanguages } from "./i18n.helper";

i18n.translations = {
    en: en,
    es: es,
    tr: tr,
};

i18n.default_locale = "en";
i18n.locale = Localization.locale;
i18n.fallbacks = true;

compareLanguages();

moment.locale(Localization.locale || "en");


export default i18n;
