import React, { Component } from "react"
import {  StyleSheet, Platform } from "react-native"
import { Header } from "@components/header/Header"
import Container from "@base/Container"
import { HeaderType } from "@custom-types/HeaderType"
import { NavigationType } from "@custom-types/NavigationType"
import { colors, settings } from "@styles/globalStyles"
import { connect } from "react-redux"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import RegularText from "@base/RegularText"
import Row from "@base/Row"
import Icon from "@components/icons"
import { SimpleLineIcons } from "@expo/vector-icons"
import KriptonMarket from "@assets/images/KriptonMarket"
import Simplex from "@assets/images/Simplex"
import Currency from "@core/currencies/Currency"
import Wallet from "@core/wallet/Wallet"
import { ProviderType } from "@custom-types/ProviderType"
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator"
import { BuyNavigatorScreens } from "@navigation/BuyNavigator"
import Onramper from "@assets/images/Onramper"
import * as WebBrowser from 'expo-web-browser'
import Constants from "expo-constants"
import { Route } from "@react-navigation/native"
import { ButtonActionsType } from "@components/wallet/WalletsButtons"
import PressableBase from "@base/PressableBase"

interface Props {
    navigation: NavigationType
    selectedCurrency: any
    buttonActionsType: ButtonActionsType;
    route: Route<string, { fromWallet: boolean }>
}

interface State { }

const { t } = i18n

export class _SelectProviderScreen extends Component<Props, State> {
    protected currency: Currency
    constructor(props: Props) {
        super(props)
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency)
        this.redirect()
        this.state = {}
    }

    redirect() {
        // TODO: PARAMS UNDEFINED DESDE SELECTCURRENCYWALLETSCREEN
        if (this.props.route) {
            if (Constants.expoConfig?.extra?.buyProviders?.length == 1) {
                switch (Constants.expoConfig?.extra?.buyProviders[0]) {
                    case "COINCAEX":
                        this.props.navigation.navigate(BuyNavigatorScreens.Coincaex.routeName)
                        break;
                    default:
                        break;
                }
            }
        }
    }

    render() {
        //@TODO: It should be a map
        return (
            <ScreenWrapper>
                <Header title={t("select_provider")} type={HeaderType.Light} {...this.props} />
                <Container style={{ flex: 1 }}>
                    {this.currency.isBuyableBy(ProviderType.KRIPTONMARKET) && (
                        <PressableBase
                            onPress={() => this.props.navigation.navigate(KriptonMarketNavigatorScreens.KriptonMarket.routeName)}
                            style={styles.wrapper}>
                            <Row
                                style={{
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}>
                                <Row
                                    style={{
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <KriptonMarket />
                                    <RegularText align='left' style={{ marginLeft: -10 }}>
                                        KriptonMarket
                                    </RegularText>
                                </Row>
                                <Icon style={styles.icon} name='bank' size={18} align='center' color={colors.text} />
                            </Row>
                        </PressableBase>
                    )}

                    {this.currency.isBuyableBy(ProviderType.SIMPLEX) && (
                        <PressableBase
                            onPress={() => this.props.navigation.navigate(BuyNavigatorScreens.Onramper.routeName)}
                            style={styles.wrapper}>
                            <Row
                                style={{
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}>
                                <Row
                                    style={{
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <Simplex />
                                </Row>
                                <Row
                                    style={{
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <SimpleLineIcons
                                        style={styles.icon}
                                        name='credit-card'
                                        size={18}
                                        align='center'
                                        color={colors.text}
                                    />
                                    <Icon
                                        style={styles.icon}
                                        name='bank'
                                        size={18}
                                        align='center'
                                        color={colors.text}
                                    />
                                </Row>
                            </Row>
                        </PressableBase>
                    )}

                    {this.currency.isBuyableBy(ProviderType.ONRAMPER) && (
                        <PressableBase
                            onPress={async () => {
                                const client = Constants.expoConfig?.extra?.client;
                                const apiKey = Constants.expoConfig?.extra?.keys?.onramper;
                                const wallet = `${this.currency.getName()}:${this.currency.getAddress()}`
                                //@TODO: Ask a new api key for lockertoken and update this.
                                const source = `https://${client=='lockertoken'?'widget':'buy'}.onramper.com/?apiKey=${apiKey}&onlyCryptos=${this.currency.getName()}&wallets=${wallet}&defaultFiat=USD&defaultPaymentMethod=${Platform.OS == 'ios' ? 'applePay' : 'creditCard'}`;
                                await WebBrowser.openBrowserAsync(source);
                            }}
                            style={styles.wrapper}>
                            <Row
                                style={{
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}>
                                <Row
                                    style={{
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}>

                                    <Onramper />

                                    <RegularText align='left' style={{ marginLeft: -10 }}>
                                        Onramper
                                    </RegularText>
                                </Row>
                                <Icon style={styles.icon} name={Platform.OS == 'ios' ? 'apple_pay' : 'bank'} size={30} align='center' color={colors.text} />
                            </Row>
                        </PressableBase>
                    )}

                    {this.currency.isBuyableBy(ProviderType.COINCAEX) && (
                        <PressableBase
                            onPress={() => this.props.navigation.navigate(BuyNavigatorScreens.Coincaex.routeName)}
                            style={styles.wrapper}>
                            <Row
                                style={{
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}>
                                <RegularText align='left' style={{ marginLeft: 5 }}>
                                    Coincaex
                                </RegularText>
                                <Icon style={styles.icon} name='bank' size={18} align='center' color={colors.text} />
                            </Row>
                        </PressableBase>
                    )}
                </Container>
            </ScreenWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return state.wallet
}

const mapDispatchToProps = (dispatch) => ({
    //getTransactions: (txid) => dispatch(getTransactions(txid)),
})
const SelectProviderScreen = connect(mapStateToProps, mapDispatchToProps)(_SelectProviderScreen)
export default SelectProviderScreen

const styles = StyleSheet.create({
    wrapper: {
        overflow: "hidden",
        paddingHorizontal: 20,
        paddingVertical: 10,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        marginVertical: 5,
    },
    icon: {
        //backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: 22,
        overflow: "hidden",

    },
})
