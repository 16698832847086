import { SearchCardInterface } from "@custom-types/SearchCardInterface";
import { BoldText } from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import React from "react";
import { OAuthType } from "@core/services/oauth/OAuthService";

export enum AuthType {
    ANON = "anon",
    GOOGLE = "google",
}

interface IAddresses {
    btc: string;
    eth: string;
    rbtc: string;
    trx?: string;
    matic?: string;
}

export class Client implements SearchCardInterface {
    _id: string;
    id: string;
    version: number;
    alias: string;
    address: string;
    addresses: { testnet: Partial<IAddresses>; mainnet: Partial<IAddresses> };
    ethCompatibility?: boolean;
    publicKey: string;
    tokenNotification: string;
    createdAt: Date;
    updatedAt: Date;
    keyChat: string;
    follow?: any;
    verified?: boolean;
    extendedPubKey: string;
    updated: boolean;
    isPrivateProfile?: boolean;
    authType: OAuthType;
    email?: string;
    image?: {
        uri?: string;
    };
    personalData?: {
        email?: string;
        name: string;
        lastname: string;
        phone: string;
    };
    profileImagePath: {
        square: string;
        thumbnail: string;
    };

    static create(data) {
        if (data.hasOwnProperty("_id")) {
            data.id = data._id;
        }

        data.updated = true;

        return Object.assign(new Client(), data);
    }

    getId() {
        return this._id || this.id;
    }

    getTitle() {
        return trimHelper(this.alias);
    }

    getSubtitle() {
        return "";
    }

    getClient() {
        return this;
    }
}
