import store from "@store/index";
import { hideModal, hideScanner, loading, ready, showModal, showPopup } from "@store/actions/global";
import Wallet from "@core/wallet/Wallet";
import { prePrepareTransaction, selectCurrency } from "@store/actions/wallet";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { SendNavigatorScreens } from "@navigation/SendNavigator";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import POAPService from "@core/services/POAPService";
import i18n from "@i18n/i18n";
import { nameSocialLengthHelper } from "@utils/helpers/social/social.helper";
import POAP from "@custom-types/POAP";
import { setPOAPs } from "@store/actions/profile.actions";
import ProfileService from "@core/services/ProfileService";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ProfileSections } from "@custom-types/ProfileType";
import { POAPsNavigatorScreens } from "@navigation/POAPsNavigator";
import { Platform } from "react-native";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";

const { t } = i18n;

export const isPOAP = (navigation, qrCode) => {
    const kioskPOAP = /^https?:\/\/kiosk\.poap\.xyz\/claim\/.*$/i;
    const claimPOAP = /^https?:\/\/.*poap\.xyz\/claim\/.*$/i;

    switch (true) {
        case kioskPOAP.test(qrCode):
            return getKiosk(navigation, qrCode);

        case claimPOAP.test(qrCode):
            return getPOAP(navigation, qrCode);
        default:
            return;
    }
};

const getKiosk = (navigation, url?: string) => {
    if (!ModuleControlService.getInstance().isModuleEnabled(Modules.poapsModule)) {
        store.dispatch(hideScanner());
        store.dispatch(
            showPopup({
                type: "ERROR",
                message: t("error_module_disabled"),
            }),
        );
        return;
    }
    store.dispatch(hideScanner());
    if (Platform.OS == "web") {
        store.dispatch(
            showPopup({
                type: "ERROR",
                message: t("an_error_has_occurred"),
            }),
        );
        return;
    }
    navigation.navigate("POAPs", {
        screen: POAPsNavigatorScreens.WebViewPOAPS.routeName,
        params: { url: url },
    });
};

export const getPOAP = async (navigation, data?: string) => {
    if (!ModuleControlService.getInstance().isModuleEnabled(Modules.poapsModule)) {
        store.dispatch(hideScanner());
        store.dispatch(
            showPopup({
                type: "ERROR",
                message: t("error_module_disabled"),
            }),
        );
        return;
    }
    const decode = data?.split("/") || [];

    if (decode.length > 3 && decode[3] == "claim") {
        const claimID = decode[decode.length - 1];

        const poapData = await POAPService.getInstance().getClaimData(claimID);

        if (poapData?.claimed) {
            store.dispatch(hideScanner());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("poap_claimed"),
                }),
            );
            return;
        }

        if (!poapData?.isActive) {
            store.dispatch(hideScanner());
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("poap_inactive"),
                }),
            );
            return;
        }

        store.dispatch(hideScanner());
        store.dispatch(
            showModal({
                title: nameSocialLengthHelper(poapData?.event?.name || " ", 30),
                avatar: poapData?.event?.imageUrl || " ",
                description: nameSocialLengthHelper(poapData?.event?.description, 100),
                question: t("poap_question"),
                btnTitle: "Mint POAP",
                onPress: () => mintPOAP(navigation, poapData),
                onPressClose: () => {
                    store.dispatch(hideModal());
                },
            }),
        );
    }
};

const mintPOAP = async (navigation, poapData) => {
    const address = Wallet.getInstance().findCurrencyById("ETH").getAddress();
    store.dispatch(hideModal());
    const resp = await POAPService.getInstance().claimPOAP(address, poapData?.qrHash, poapData?.secret);

    if (resp?.claimed) {
        const poap: POAP = {
            event: resp?.event,
            tokenId: resp?.id.toString(),
            owner: resp?.beneficiary,
            chain: "",
            created: resp?.createdDate,
        };
        const client = store.getState().auth.client;
        const profiles: any = store.getState().profile.profiles;

        const poaps = [poap].concat(profiles[client._id].POAPs);

        store.dispatch(
            setPOAPs({
                id: client._id,
                dataPOAPs: poaps || [],
            }),
        );

        setTimeout(() => {
            ProfileService.getInstance().setProfilePOAPs(client._id);
        }, 15000);

        navigateTo(navigation, client._id);
    }
};

const navigateTo = (navigation, clientID) => {
    store.dispatch(ready());
    store.dispatch(showPopup({ type: "SUCCESS" }));
    navigation.navigate("Home", {
        screen: "HomeMain",
    });
};
