import Currency, { IConstructorCurrency } from "@core/currencies/Currency";
import { Networks } from "@core/network/Networks";
import ETHImplementation from "@core/currencies/ethereum/eth/ETHImplementation";
import { isCurrencyEnabled } from "@core/utils/CurrencyEnabled";
import { ProviderType } from "@custom-types/ProviderType";

export default class BscUSDC extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            id: "BSCUSDC",
            name: "USDC",
            simbol: "USDC",
            fullName: "usdc",
            decimals: 18,
            implementation: ETHImplementation,
            chainId: parameters.testnet ? 97 : 56,
            networkID: parameters.testnet ? 61 : 60,
            color: "#2671c4",
            icon: "usdc",
            networkSymbol: "BSC",
            underlyingCurrency: "bnb",
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "BSCUSDC", true),
            buyableBy: [ProviderType.COINCAEX],
            sellableBy: [ProviderType.COINCAEX],
        });
    }
}
