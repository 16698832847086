import Currency, { IConstructorCurrency } from '@core/currencies/Currency';
import { FiatCurrency } from '@core/fiat/FiatCurrencies';
import { Networks } from '@core/network/Networks';
import ETHImplementation from '../../ethereum/eth/ETHImplementation';
import { ProviderType } from '@custom-types/ProviderType';
import TRXImplementation from '../trx/TRXImplementation';
import Constants from "expo-constants"
import { isCurrencyEnabled } from '@core/utils/CurrencyEnabled';

export default class TronUSDT extends Currency {
    constructor(
        parameters: IConstructorCurrency
    ) {
        super ({
            id: 'TRONUSDT',
            name: 'USDT',
            simbol: 'USDT',
            fullName: 'USDT',
            decimals: 6,
            implementation: TRXImplementation,
            networkID: parameters.testnet?195:195,
            color: '#4eae94',
            icon: 'usdt',
            networkSymbol: 'TRX',
            underlyingCurrency: 'trx',
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet?Networks.ethereum.testnet:Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "TRONUSDT", true),
            buyableBy: [ProviderType.COINCAEX],
            sellableBy: [ProviderType.COINCAEX],
        })
    }

}
