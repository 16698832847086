import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import Container from "@base/Container";
import { FlatList, View } from "react-native";

import { Contact } from "@custom-types/Contact";
import { SearchBar } from "@components/chat/SearchBar";
import { Chat } from "@custom-types/Chat";
import store from "@store/index";
import { selectContact } from "@store/actions/chat.actions";
import ContactClientCard from "@components/cards/ContactClientCard";
import { NavigationType } from '@custom-types/NavigationType';
import { hideActions, showActions } from "@store/actions/header";
import { ContactService } from "@core/services/ContactService";
import { setSearch } from "@store/actions/global";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n"
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";

interface Props {
    navigation: NavigationType,
    contacts: Array<Contact>,
    chats: Array<Chat>,
    search: string
}

interface State {
    contacts: Array<Contact>,
    selectedContact: Contact
}

const { t } = i18n

export class _ContactsScreen extends Component<Props, State> {
    contactService: ContactService;
    focusListener: any;

    constructor(props) {
        super(props);
        this.state = {
            selectedContact: null,
            contacts: ContactsListScreen.sortContacts(this.props.contacts)
        }

        this.filterContacts = this.filterContacts.bind(this);
    }

    static sortContacts(contacts) {
        return contacts.sort((a: Contact, b: Contact) => a.name.localeCompare(b.name));
    }

    async componentDidMount() {
        this.contactService = await ContactService.getInstance();
        store.dispatch(setSearch(''));

        this.focusListener = this.props.navigation.addListener('focus', () => {
            this.setState({
                selectedContact: null,
                contacts: ContactsListScreen.sortContacts(this.props.contacts)
            })
            store.dispatch(setSearch(''));
        })
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove()
        } catch (e) { }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.contacts !== this.props.contacts) {
            this.setState({ contacts: ContactsListScreen.sortContacts(this.props.contacts) })
        }

        if (prevProps.search !== this.props.search) {
            this.filterContacts();
        }
    }

    filterContacts() {
        const value = this.props.search;
        const contactsFilter = this.props.contacts.filter((c) => {
            return (c.client.alias.toLowerCase().includes(value.toLowerCase())
                || c.name.toLowerCase().includes(value.toLowerCase()));
        });

        this.setState({ contacts: ContactsListScreen.sortContacts(contactsFilter) });
    }

    onLongPressContact(contact: Contact) {
        if (this.state.selectedContact && this.state.selectedContact.id === contact.id) {
            this.setState({ selectedContact: null });
            store.dispatch(hideActions());
        } else {
            this.setState({ selectedContact: contact });
            store.dispatch(showActions());
        }
    }

    contactItemRender(item: Contact) {
        return (
            <View style={{ paddingHorizontal: 20 }}>
                <ContactClientCard
                    onLongPress={() => this.onLongPressContact(item)}
                    item={item}
                    navigation={this.props.navigation}
                    selected={this.state.selectedContact && this.state.selectedContact.id == item.id}
                />
            </View>
        )
    }


    render() {
        return (
            <ScreenWrapper>

                <Header
                    title={t('contacts_list')}
                    type={HeaderType.Light}
                    {...this.props}
                    leftBtn={{
                        onPress: () => {
                            this.props.navigation.navigate(MessagesNavigatorScreens.MessagesList.routeName);
                        },
                        icon: "arrow-left",
                        size: 24
                    }}
                    actions={[
                        {
                            onPress: () => {
                                store.dispatch(selectContact(this.state.selectedContact));
                                this.props.navigation.navigate(MessagesNavigatorScreens.ContactForm.routeName, { type: "edit" });
                            },
                            icon: "edit",
                            iconType: "custom"
                        },
                        {
                            onPress: async () => {
                                await this.contactService.delete(this.state.selectedContact._id)
                                this.setState({ selectedContact: null });
                            },
                            icon: "trash",
                            iconType: "custom"
                        }
                    ]}
                    hideActionsCallback={() => {
                        this.setState({ selectedContact: null })
                    }}
                />

                <SearchBar />


                <Container style={{
                    flex: 1,
                    overflow: "hidden",
                    borderRadius: 15,
                    paddingHorizontal: 0,
                    paddingVertical: 0,
                    paddingTop: 5
                }}>
                    <FlatList
                        data={this.state.contacts}
                        keyExtractor={item => item._id}
                        renderItem={({ item }) => this.contactItemRender(item)}
                        extraData={this.state}
                    />
                </Container>
            </ScreenWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        contacts: state.chat.contacts,
        chats: state.chat.chats,
        search: state.global.search
    };
};

const mapDispatchToProps = (dispatch) => (
    {}
);

const ContactsListScreen = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_ContactsScreen);

export default ContactsListScreen;

