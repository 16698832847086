import { eventChannel } from "redux-saga";
import { call, delay, put, spawn, take, takeEvery } from "redux-saga/effects";
import {
    CONFIRM_TRANSACTION,
    GET_TRANSACTIONS,
    RELOAD,
    ON_INIT,
    onChange,
    onTransactionReady,
    PREPARE_EXCHANGE,
    PREPARE_TRANSACTION,
    setCurrencies,
    SWITCH_NETWORK,
    SYNC,
    synced,
    UPDATE_CURRENCY_PATH,
    UPDATE_MNEMONIC,
    SYNC_TRANSACTIONS,
    PREPARE_BUY,
    SYNC_PURCHASE_ORDERS,
    ON_CHANGE,
    UPDATE_CURRENCY_ENABLED,
} from "@store/actions/wallet";
import Wallet from "@core/wallet/Wallet";
import { loading, ready, readyToRender, reset, setRedirection } from "@store/actions/global";
import store from "@store/index";
import TransactionType from "@custom-types/TransactionType";
import Currency from "@core/currencies/Currency";
import { Platform } from "react-native";
import SwapType from "@custom-types/SwapType";
import { ApiService } from "@core/services/ApiService";
import { RESET_CHAT, resetChat, selectChat, UPDATE_CHAT, updateChat, updateOrder } from "@store/actions/chat.actions";
import { ChatService } from "@core/services/ChatService";
import { ADD_MESSAGE } from "@store/actions/message.actions";
import { Message } from "@custom-types/Message";
import { chatByIdSelector, chatSelectedSelector } from "@store/reducers/chat.reducer";
import { Chat } from "@custom-types/Chat";
import { SimplexService } from "@core/services/SimplexService";
import AppStorage from "@utils/storage";
import { isNetworkAvailable } from "@utils/helpers/global/global";
import i18n from "@i18n/i18n";
import * as Localization from "expo-localization";
import WalletConnect from "@core/services/WalletConnectDataService";
import { setRequestStatusNFTs } from "@store/actions/nfts.action";
import { MarketPlaceStatus } from "@store/reducers/nfts.reducer";
import AuthStorage from "@utils/storage/storages/AuthStorage";
import { ModuleControlService } from "@core/services/ModuleControlService";
import { NotificationService } from "@core/services/NotificationService";
import { ClientService } from "@core/services/ClientService";
import WalletConnectV2Service from "@core/services/WalletConnectV2Service";
import moment from "moment";

function countdown(secs) {
    return eventChannel((emitter) => {
        const iv = setInterval(() => {
            emitter(secs);
        }, 1000 * secs);
        return () => {
            clearInterval(iv);
        };
    });
}

function* syncBalance(action) {
    const wallet = Wallet.getInstance();
    if (action && action.currency) {
        const currency: Currency = action.currency;
        currency.syncBalance();
    } else {
        yield wallet.syncData();
        yield wallet.syncBalance();
    }
    yield put(synced());
}

function* syncTransactions(action) {
    const wallet = Wallet.getInstance();

    if (action && action.currency) {
        const currency: Currency = action.currency;
        currency.syncTransactions();
    }
    yield put(synced());
}

function* syncPurchaseOrders(action) {
    const wallet = Wallet.getInstance();
    if (action && action.currency) {
        const currency: Currency = action.currency;
        const orders = yield SimplexService.getInstance().getPurchaseOrders(currency.getName());
        currency.setPurchaseOrders(orders);
        store.dispatch(onChange(wallet));
    }
    yield put(synced());
}

function* getLanguage() {
    const language = yield AppStorage.getItem("language");
    if (language) {
        i18n.locale = language;
        moment.locale(language);
    } else {
        i18n.locale = Localization.locale;
        yield AppStorage.setItem("language", Localization.locale.substring(0, 2));
    }
}

function* errorHandler() {
    if (Platform.OS !== "web") {
        const defaultErrorHandler = ErrorUtils.getGlobalHandler();
        const myErrorHandler = (e, isFatal) => {
            if (isFatal) {
                console.warn(e);
                //Updates.reloadAsync()
            }
            defaultErrorHandler(e, isFatal);
        };
        ErrorUtils.setGlobalHandler(myErrorHandler);
    }
}

function* reload(action) {
    //@TODO: Acá debería reiniciar el reducer
    //yield put(resetChat());

    yield put(loading());
    store.dispatch(readyToRender());
    yield call(syncBalance, {}); //->Probar sin el call no hace falta q sea síncrono
    yield setWalletConnect();
    yield NotificationService.getInstance().getNotifications();

    yield WalletConnectV2Service.getInstance();
    yield ChatService.restartInstance();
    yield put(ready());
    action.callBack();
}

function* afterAddMessage(action) {
    const message: Message = action.message;
    const chatInMemory = chatByIdSelector(store.getState(), message.chatId);

    if (chatInMemory) {
        const chat = Object.assign(new Chat(), chatInMemory);
        yield addMessageToChat(chat, message);
        return;
    }

    const instance = yield ChatService.getInstance();
    yield instance.getChatById(message.author._id);
    let chat = Object.assign(new Chat(), chatByIdSelector(store.getState(), message.chatId));
    chat.messages = [];
    yield addMessageToChat(chat, message);
    return;
}

function* addMessageToChat(chat, message) {
    const allMessages = store.getState().message.items;

    if (chat.messages.length === 0) {
        chat.messages.push(message._id);
        yield put(updateChat(chat));
    } else {
        for (let i = 0; i < chat.messages.length; i++) {
            const msg: Message = allMessages[chat.messages[i]];
            if (new Date(message.createdAt) > new Date(msg.createdAt)) {
                chat.messages.splice(i, 0, message._id);
                yield put(updateChat(chat));
                break;
            }

            if (i === chat.messages.length - 1) {
                chat.messages.push(message._id);
                yield put(updateChat(chat));
                break;
            }
        }
    }

    let order: Array<string> = [...store.getState().chat.order];
    const index = order.findIndex((o) => o === chat._id.toString());
    if (index > -1) {
        order.splice(index, 1);
    }
    order.unshift(chat._id);
    yield put(updateOrder(order));
}

function* afterUpdateChat(action) {
    const chat: Chat = action.chat;
    const selected: Chat = chatSelectedSelector(store.getState());
    if (selected) {
        if (chat.id === selected.id) {
            yield put(selectChat(Object.assign({}, chat)));
        }
    }
}

function* prepareTransaction(action) {
    yield put(loading());
    const parameters = action.parameters;
    const transaction: TransactionType = parameters.transaction;
    const promise = transaction.currency.newTransaction(transaction);
    promise.then((res) => {
        store.dispatch(ready());
        store.dispatch(onTransactionReady(res.data));
        parameters.onSuccess ? parameters.onSuccess() : null;
    });
    promise.catch((error) => {
        store.dispatch(ready());
        let res = "Server error";
        try {
            res = error.response.data.message;
        } catch (e) {}
        parameters.onError ? parameters.onError(res) : null;
    });
}

function* prepareExchange(action) {
    yield put(loading());
    const parameters = action.parameters;
    const swap: SwapType = parameters.swap;
    const promise = swap.from.newSwap(swap);
    promise.then((res) => {
        store.dispatch(ready());
        store.dispatch(onTransactionReady(res.data));
        if (res.data.approved) {
            swap.from.setApproved(false);
        }
        parameters.onSuccess ? parameters.onSuccess(res.data.approved) : null;
    });
    promise.catch((error) => {
        store.dispatch(ready());
        let res = "Server error";
        try {
            res = error.response.data.message;
        } catch (e) {}
        parameters.onError ? parameters.onError(res) : null;
    });
}

function* prepareBuy(action) {}

function* confirmTransaction(action) {
    console.log("confirmTransaction");
    yield put(loading());
    const parameters = action.parameters;
    const skeleton: any = parameters.skeleton;
    const promise = parameters.currency.sendTransaction(skeleton);
    promise
        .then((res) => {
            const wallet = Wallet.getInstance();
            store.dispatch(ready());
            store.dispatch(onTransactionReady(res.data));
            wallet.syncBalance();
            parameters.currency.syncTransactions();
            store.dispatch(onChange(wallet));
            parameters.onSuccess ? parameters.onSuccess(res.data) : null;
        })
        .catch((error) => {
            console.log("error");
            store.dispatch(ready());
            parameters.onError ? parameters.onError(error) : null;
        });
}

function* getTransactions(action) {
    yield put(loading());
    //Wallet.getInstance().syncBalance();
    yield put(ready());
}

function* updateCurrencyPath(action) {
    const wallet = Wallet.getInstance();
    const parameters = action.parameters;
    parameters.currency.setAccount(parameters.account);
    parameters.currency.setChange(parameters.change);
    parameters.currency.setIndex(parameters.index);
    parameters.currency.setSmart(parameters.smart);

    yield put(loading());
    yield parameters.currency.syncBalance();
    yield wallet.saveState();
    yield put(onChange(wallet));
    yield put(ready());
    parameters.onSuccess ? parameters.onSuccess() : null;
}

function* switchNetwork(action) {
    yield put(loading());
    const wallet = Wallet.getInstance();
    const parameters = action.parameters;
    wallet.setIsTestnet(parameters.testnet);
    yield wallet.saveState();
    //yield wallet.init();
    yield wallet.syncBalance();
    yield put(setCurrencies(wallet.getCurrencies()));
    yield wallet.syncData();
    yield put(onChange(wallet));
    yield setWalletConnect();
    yield setNFTsMarketPlaceStatus();
    yield put(ready());
    parameters.onSuccess ? parameters.onSuccess() : null;
}

function* updateCurrencyEnabled(action) {
    const wallet = Wallet.getInstance();
    const parameters = action.parameters;

    parameters.currency.setEnabled(parameters.enabled);
    yield put(loading());
    yield wallet.saveState();
    if (parameters.enabled) {
        yield parameters.currency.syncBalance();
    }
    yield put(onChange(wallet));
    yield put(ready());
}

function* setWalletConnect() {
    WalletConnect.getInstance().setStoredState();
}

function* setNFTsMarketPlaceStatus() {
    store.dispatch(setRequestStatusNFTs({ status: MarketPlaceStatus.Reload }));
}

function* setIsNetworkAvailable() {
    isNetworkAvailable();
}

export default function* main() {
    while (true) {
        yield spawn(errorHandler);
        yield spawn(getLanguage);
        yield spawn(setIsNetworkAvailable);
        yield spawn(reload, yield take(RELOAD));
        yield takeEvery(SYNC, syncBalance);
        yield takeEvery(SYNC_TRANSACTIONS, syncTransactions);
        yield takeEvery(SYNC_PURCHASE_ORDERS, syncPurchaseOrders);
        yield takeEvery(PREPARE_TRANSACTION, prepareTransaction);
        yield takeEvery(CONFIRM_TRANSACTION, confirmTransaction);
        yield takeEvery(GET_TRANSACTIONS, getTransactions);
        yield takeEvery(UPDATE_CURRENCY_PATH, updateCurrencyPath);
        yield takeEvery(SWITCH_NETWORK, switchNetwork);
        yield takeEvery(PREPARE_EXCHANGE, prepareExchange);
        yield takeEvery(ADD_MESSAGE, afterAddMessage);
        yield takeEvery(UPDATE_CHAT, afterUpdateChat);
        yield takeEvery(UPDATE_CURRENCY_ENABLED, updateCurrencyEnabled);

        // yield takeEvery(UPDATE_MESSAGE, modifySelectChat);
        //yield takeEvery(PREPARE_BUY, prepareBuy);
    }
}
