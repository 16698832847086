import Currency, { IConstructorCurrency } from "@core/currencies/Currency";
import { Networks } from "@core/network/Networks";
import ETHImplementation from "@core/currencies/ethereum/eth/ETHImplementation";
import { isCurrencyEnabled } from "@core/utils/CurrencyEnabled";
import { ProviderType } from "@custom-types/ProviderType";

export default class PolygonUSDT extends Currency {
    constructor(parameters: IConstructorCurrency) {
        super({
            id: "POLYGONUSDT",
            name: "USDT",
            simbol: "USDT",
            fullName: "usdt",
            decimals: 6,
            implementation: ETHImplementation,
            chainId: parameters.testnet ? 80001 : 137,
            networkID: parameters.testnet ? 61 : 60,
            color: "#4eae94",
            icon: "usdt",
            networkSymbol: "MATIC",
            underlyingCurrency: "matic",
            seed: parameters.seed,
            fiatCurrency: parameters.fiatCurrency,
            testnet: parameters.testnet,
            network: parameters.testnet ? Networks.ethereum.testnet : Networks.ethereum.mainnet,
            account: parameters.account,
            change: parameters.change,
            index: parameters.index,
            enabled: isCurrencyEnabled(parameters.enabled, "POLYGONUSDT", true),
            buyableBy: [ProviderType.COINCAEX],
            sellableBy: [ProviderType.COINCAEX],
        });
    }
}
