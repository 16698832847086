import React, { Component } from "react";
import { View, Text, StyleSheet, Button, Dimensions } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import Row from "@base/Row";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import Card from "@base/Card";
import store from "@store/index";
import { confirmTransaction, getTransactions } from "@store/actions/wallet";

import { loading, showPopup } from "@store/actions/global";
import Wallet from "@core/wallet/Wallet";
import { ChatService } from "@core/services/ChatService";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import {CustomMessageType} from '@custom-types/CustomMessageType'
import { setCustomMessage } from "@store/actions/chat.actions";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import InlineButton from "@base/InlineButton";
import * as WebBrowser from 'expo-web-browser';
import { SimplexService } from "@core/services/SimplexService";
import i18n from "@i18n/i18n"


interface Props {
  navigation: NavigationType
  currencies: Array<Currency>
  selectedCurrency: string
  selectedPurchaseOrder: number
}

interface State {

}

const { t } = i18n

export class _PurchaseOrderScreen extends Component<Props, State> {
  private currency: Currency;
  private fiatCurrency: FiatCurrency;
  private order: any;

  constructor(props: Props) {
    super(props);
    this.currency = Wallet.getInstance().findCurrencyById(
      props.selectedCurrency
    );
    this.order = this.currency.getPurchaseOrder(this.props.selectedPurchaseOrder);
  }

  render() {
    return (
      <ScreenWrapper type="scroll">
        <Header title={'Purchase Order'} type={HeaderType.Light} {...this.props} />
        <Container >
            {(this.order.id) &&
            <Card>  
                <RegularText color={colors.text} align='center'>
                    ID
                </RegularText>
                <BoldText color={colors.text} align='center'>
                    {this.order.id}
                </BoldText>
            </Card>
            }
            <Card>
                <RegularText color={colors.text} align='center'>
                    {t('status')}
                </RegularText>
                <BoldText color={colors.text} align='center'>
                    {this.order.state}
                </BoldText>
            </Card>
            <Card>
                <RegularText color={colors.text} align='center'>
                    {t('date')}
                </RegularText>
                <BoldText color={colors.text} align='center'>
                    {this.order.date}
                </BoldText>
            </Card>
            <Card>
                <RegularText color={colors.text} align='center'>
                    {t('total_received')}
                </RegularText>
                <BoldText color={colors.text} align='center'>
                    {this.order.digitalMoney.amount} {this.order.digitalMoney.currency}
                </BoldText>
            </Card>

            <Card>
                <RegularText color={colors.text} align='center'>
                    {t('total_payed')}
                </RegularText>
                <BoldText color={colors.text} align='center'>
                    {this.order.fiatMoney.totalAmount} {this.order.fiatMoney.currency}
                </BoldText>
            </Card>

            <Card>
                <RegularText color={colors.text} align='center'>
                    {t ('to')}
                </RegularText>
                <BoldText fontSize={14} color={colors.text} align='center'>
                    {this.order.payment?.transactionDetails?.paymentDetails?.destinationWallet.address }
                </BoldText>
            </Card>

          </Container>
      </ScreenWrapper>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    paddingVertical: 20,
  },
  buttons: {
    marginVertical: 15,
  },
  card: {},
});

const mapStateToProps = (state) => {
  return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const PurchaseOrderScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(_PurchaseOrderScreen);

export default PurchaseOrderScreen;
