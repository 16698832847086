import BoldText from '@base/BoldText';
import Card from '@base/Card';
import CircleButton from '@base/CircleButton';
import RegularText from '@base/RegularText';
import Row from '@base/Row';
import Icon from '@components/icons';
import i18n from '@i18n/i18n';
import { colors } from '@styles/globalStyles';
import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { Avatar } from 'react-native-elements';
import { Bank, CoincaexFiatCurrencies } from '../helper/coincaex.helper';
import store from '@store/index';
import { showSnackbar } from '@store/actions/global';
import * as Clipboard from "expo-clipboard";
import PressableBase from '@base/PressableBase';

const { t } = i18n;

interface Props {
    country: {
        icon: any;
        name: string;
    };
    item: any;
    selectedBank: Bank;
    setBank: (value: Bank) => void;
    submit: () => void
}

interface State {
    isOpen: boolean;
}

class DropdownComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    toggleDropdown = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    copyToClipboard = async (value) => {
        await Clipboard.setStringAsync(value);
        store.dispatch(showSnackbar({ type: "MESSAGE", message: t("copied") }));
    };

    render() {
        const { country } = this.props;
        const { isOpen } = this.state;

        return (
            country &&
            <View>
                <PressableBase onPress={this.toggleDropdown}>
                    <Card style={{ paddingVertical: 5 }}>
                        <Row style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Row style={{ alignItems: 'center', marginVertical: 10 }}>
                                <Avatar size={26} rounded source={country.icon} />
                                <BoldText style={{ paddingLeft: 15 }} fontSize={16}>
                                    {country.name.toUpperCase()}
                                </BoldText>
                            </Row>
                            <View>
                                <Icon name={isOpen ? 'arrow-up' : 'arrow-down'} size={20} color={colors.text} />
                            </View>
                        </Row>
                    </Card>
                </PressableBase>
                {isOpen && (
                    <View>
                        {this.props.item.banks?.map((bank) => {
                            return (
                                <View key={bank.Number}>
                                    <Card
                                        style={{
                                            alignItems: "flex-start",
                                            borderWidth: 1,
                                            opacity: bank.Number == this.props.selectedBank?.Number ? 1 : 0.6,
                                        }}
                                        onPress={() => this.props.setBank(bank)}
                                    >
                                        <Row
                                            style={{
                                                justifyContent: "space-between",
                                                width: "100%",
                                                alignItems: "center",
                                                marginBottom: 10,
                                            }}
                                        >
                                            <BoldText fontSize={18}>{bank.Bank}</BoldText>
                                            <Icon name="bank" color={colors.white} size={26}></Icon>
                                        </Row>
                                        <Row style={{ justifyContent: "flex-start", width: "100%", paddingBottom: 10, alignItems: "center" }}>
                                            <Avatar size={18} rounded source={CoincaexFiatCurrencies[bank.Code].icon} />
                                            <BoldText style={{ paddingLeft: 5 }} color={colors.text} fontSize={12}>
                                                {CoincaexFiatCurrencies[bank.Code].name}
                                                <RegularText color={colors.text} fontSize={12}>
                                                    {` (${CoincaexFiatCurrencies[bank.Code].fullName})`}
                                                </RegularText>
                                            </BoldText>
                                        </Row>
                                        <Row style={{ justifyContent: "flex-start", width: "100%", marginBottom: 5 }}>
                                            <RegularText style={{ paddingRight: 5 }} color={colors.text} fontSize={12}>
                                                {t("account_holder")}:
                                            </RegularText>
                                            <BoldText color={colors.text} fontSize={12}>
                                                {bank.Name}
                                            </BoldText>
                                        </Row>
                                        <Row style={{ justifyContent: "flex-start", width: "100%", alignItems: "center" }}>
                                            <RegularText style={{ paddingRight: 5 }} color={colors.text} fontSize={12}>
                                                {t("account_number")}:
                                            </RegularText>
                                            <PressableBase
                                                onPress={() => this.copyToClipboard(bank.Number)}
                                                style={{ paddingVertical: 2 }}
                                            >
                                                <Row style={{ alignItems: "baseline" }}>
                                                    <BoldText color={colors.text} fontSize={12}>
                                                        {bank.Number}
                                                    </BoldText>
                                                    <Icon
                                                        size={12}
                                                        name="content-copy"
                                                        color={colors.white}
                                                        style={{ marginLeft: 10 }}
                                                    />
                                                </Row>
                                            </PressableBase>
                                        </Row>
                                    </Card>
                                    {bank.Number == this.props.selectedBank?.Number && (
                                        <View style={{ position: "absolute", right: 5, bottom: 5, zIndex: 9999 }}>
                                            <CircleButton
                                                style={{ height: 40, width: 40, borderRadius: 20 }}
                                                iconSize={15}
                                                icon="right"
                                                onPress={this.props.submit}
                                            />
                                        </View>
                                    )}
                                </View>
                            );

                        })}
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
});

export default DropdownComponent;
