import { generateMnemonic, mnemonicToSeedSync } from "bip39";
import BTC from "@core/currencies/btc/BTC";
import RBTC from "@core/currencies/rsk/rbtc/RBTC";
import Currency, { IConstructorCurrency, CurrencyStore } from "@core/currencies/Currency";
import AppStorage from "@utils/storage/index";
import { reload, onChange, setCurrencies } from "@store/actions/wallet";
import store from "@store/index";
import BalanceService from "@core/services/BalanaceService";
import FiatCurrencies, { FiatCurrency } from "@core/fiat/FiatCurrencies";
import DataService from "@core/services/DataService";
import ETH from "@core/currencies/ethereum/eth/ETH";
import DAI from "@core/currencies/ethereum/dai/DAI";
import USDT from "@core/currencies/ethereum/usdt/USDT";
import RIF from "@core/currencies/rsk/rif/RIF";
import RDOC from "@core/currencies/rsk/rdoc/RDOC";
import FLIXX from "@core/currencies/ethereum/flixx/FLIXX";
import SmartRIF from "@core/currencies/rsk/rif/SmartRIF";
import MOS from "@core/currencies/rsk/mos/MOS";
import RARS from "@core/currencies/rsk/rars/RARS";
import RCOP from "@core/currencies/rsk/rcop/RCOP";
import RUSD from "@core/currencies/rsk/rusd/RUSD";
import REUR from "@core/currencies/rsk/reur/REUR";
const HDKey = require("hdkey");
import Constants from "expo-constants";
import TRX from "@core/currencies/tron/trx/TRX";
import TronUSDT from "@core/currencies/tron/usdt/TronUSDT";
import { Platform } from "react-native";
import LOCKER from "@core/currencies/ethereum/locker/LOCKER";
import DOC from "@core/currencies/rsk/doc/DOC";
import MATIC from "@core/currencies/polygon/matic/MATIC";
import PolygonUSDC from "@core/currencies/polygon/usdc/PolygonUSDC";
import WETH from "@core/currencies/ethereum/weth/WETH";
import BPRO from "@core/currencies/rsk/bpro/BPRO";
import WalletSecureStorage from "@utils/storage/storages/WalletSecureStorage";
import WalletAsyncStorage from "@utils/storage/storages/WalletAsyncStorage";
import SeedHistorySecureStorage from "@utils/storage/storages/SeedHistorySecureStorage";
import USDC from "@core/currencies/ethereum/usdc/USDC";
import BNB from "@core/currencies/bsc/bnb/BNB";
import BscUSDC from "@core/currencies/bsc/usdc/BscUSDC";
import XO from "@core/currencies/points/xo/XO";
import INNOVATION from "@core/currencies/points/innovation/INNOVATION";
import ARB from "@core/currencies/arbitrum/arb/ARB";
import ArbitrumLOCKER from "@core/currencies/arbitrum/locker/ArbitrumLKT";
import EthereumMatic from "@core/currencies/ethereum/matic/MATIC";
import PolygonUSDT from "@core/currencies/polygon/usdt/PolygonUSDT";
import OAuthStorage from "@utils/storage/storages/OAuthStorage";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import CurrenciesStorage from "@utils/storage/storages/CurrenciesStorage";
import RON from "@core/currencies/ronin/ronin/RON";
import RoninSLP from "@core/currencies/ronin/slp/RoninSLP";
import RoninUSDC from "@core/currencies/ronin/usdc/RoninUSDC";
import RoninAXS from "@core/currencies/ronin/axs/RoninAXS";
import RoninWETH from "@core/currencies/ronin/weth/RoninWETH";

let customCurrencies = [];
try {
    customCurrencies = require("@client/currencies").CustomCurrencies;
} catch (e) {
    console.warn(e);
}

interface WalletSecureStorage {
    mnemonic: string;
    seedHistory?: Array<string>;
    seed: string;
    mainAddress: string;
}

interface WalletAsyncStorage {
    fiatCurrency: FiatCurrency;
    currencies: Array<Partial<CurrencyStore>>;
    testnet: boolean;
}

interface SeedHistorySecureStorage {
    mnemonic: string;
    clientId: string;
    isWritten: boolean;
}

interface WalletState {
    secureStorage: WalletSecureStorage;
    asyncStorage: WalletAsyncStorage;
}

export default class Wallet {
    public mnemonic: string;
    public mainAddress: string;
    private seed: Buffer;
    public currencies: Array<Currency> = [];
    private static instance: Wallet;
    private balanceService: BalanceService;
    private dataService: DataService;
    private fiatCurrency: FiatCurrency;
    private testnet: boolean;

    constructor(mnemonic?: string) {
        this.balanceService = new BalanceService();
        this.dataService = new DataService();
        const m = mnemonic || generateMnemonic();
        this.mnemonic = m;
    }

    static getInstance(): Wallet {
        if (!Wallet.instance) {
            throw new Error("Wallet not intialized");
        }
        return Wallet.instance;
    }

    static async init(newSeed?: boolean | string) {
        if (newSeed) {
            await WalletSecureStorage.remove();
        }
        Wallet.instance = new Wallet(typeof newSeed == "string" ? newSeed : undefined);
        await Wallet.instance.load();
    }

    initCurrencies(constructors: { new (params: IConstructorCurrency): Currency }[], state: WalletState) {
        let currencies = constructors.map((c) => {
            const coin = new c({
                seed: this.seed,
                fiatCurrency: this.fiatCurrency,
                testnet: this.testnet,
            });

            const r = state.asyncStorage.currencies
                ? state.asyncStorage.currencies.find((x) => x.name === coin.getId())
                : null;

            if (r) {
                coin.setFromJSON(r);
            } 

            return coin;
        });

        const defaultClientCurrencies = Constants.expoConfig?.extra?.defaultCurrencies;
     
        
        if (defaultClientCurrencies) {
            const firstOnList = currencies.filter((c) => (defaultClientCurrencies[c.getId()]?.firstOnList)) || [];
            const otherCurrencies = currencies.filter((c) => (!defaultClientCurrencies[c.getId()]?.firstOnList)) || [];
            currencies = [...firstOnList, ...otherCurrencies]
        
        }

        return currencies;
    }

    getNetworkType() {
        return this.isTestnet() ? "Testnet" : "Mainnet";
    }

    isTestnet() {
        return this.testnet;
    }

    setIsTestnet(testnet: boolean) {
        this.testnet = testnet;
    }

    async load() {
        const stored = await this.isStateStored();
        const state: WalletState = await (stored ? this.loadState() : this.initState());
        let currencies = [];

        const currenciesConstructors = [
            
            BTC,
            ETH,
            TRX,
            MATIC,
            BNB,
            ARB,
            USDT,
            TronUSDT,
            PolygonUSDT,
            USDC,
            PolygonUSDC,
            BscUSDC,
            DAI,
            WETH,
            RBTC,
            RIF,
            DOC,
            RDOC,
            EthereumMatic,
        ];

        currencies = this.initCurrencies([...currenciesConstructors, ...customCurrencies], state);
        this.setCurrencies(currencies);

        if (!stored) {
            await this.updateAsyncState({ currencies });
        }
    }

    getBaseBitcoinAddress() {
        return new BTC({
            seed: this.seed,
            fiatCurrency: this.fiatCurrency,
            testnet: false,
        }).getAddress();
    }

    async loadState(): Promise<WalletState> {
        let secureStorage = <WalletSecureStorage>await WalletSecureStorage.get();
        let asyncStorage = <WalletAsyncStorage>await WalletAsyncStorage.get();

        this.mnemonic = secureStorage.mnemonic;
        this.fiatCurrency = FiatCurrency.fromJSON(asyncStorage.fiatCurrency);
        this.testnet = asyncStorage.testnet ? asyncStorage.testnet : false;
        this.seed = secureStorage.seed ? Buffer.from(secureStorage.seed, "hex") : mnemonicToSeedSync(this.mnemonic);
        this.mainAddress = secureStorage.mainAddress ? secureStorage.mainAddress : this.getBaseBitcoinAddress();

        if (!secureStorage.seed || !secureStorage.mainAddress) {
            await this.updateSecureState({
                seed: this.seed.toString("hex"),
                mainAddress: this.mainAddress,
            });
        }

        return {
            secureStorage,
            asyncStorage,
        };
    }

    async updateSecureState(update: Partial<WalletSecureStorage>) {
        let state = <WalletSecureStorage>await WalletSecureStorage.get();
        const newState = { ...state, ...update };
        await WalletSecureStorage.set(newState);
        return newState;
    }

    async getSeedHistorySecureStorage() {
        let state = <Array<SeedHistorySecureStorage>>await SeedHistorySecureStorage.get();
        return state;
    }

    async updateAsyncState(update: Partial<WalletAsyncStorage>) {
        let state = <WalletAsyncStorage>await WalletAsyncStorage.get();
        const newState = { ...state, ...update };
        await WalletAsyncStorage.set(newState);
        return newState;
    }

    async saveState(prevState?: { asyncStorage: WalletAsyncStorage }): Promise<WalletState> {
        const secureStorage: WalletSecureStorage = {
            mnemonic: this.mnemonic,
            seed: this.seed.toString("hex"),
            mainAddress: this.mainAddress,
        };
        
        let currencies: Partial<CurrencyStore>[] = this.getCurrencies().map((currency: Currency) => {
                return currency.toJSON()
        });

        //Conservate prev state
        if(currencies.length == 0 && prevState) {
            currencies = (prevState.asyncStorage?.currencies || []).map(c => {return {name: c.name, enabled: c.enabled}})
        }

        const asyncStorage: WalletAsyncStorage = {
            fiatCurrency: this.fiatCurrency,
            currencies: currencies,
            testnet: this.testnet,
        };

        await WalletSecureStorage.set(secureStorage);
        await WalletAsyncStorage.set(asyncStorage);

        return {
            secureStorage,
            asyncStorage,
        };
    }

    async isStateStored() {
        return await WalletSecureStorage.get();
    }

    async initState() {
        this.seed = mnemonicToSeedSync(this.mnemonic);
        this.fiatCurrency = FiatCurrencies.USD;
        this.testnet = this.testnet;
        this.mainAddress = this.getBaseBitcoinAddress();
        const asyncStorage = await WalletAsyncStorage.get();
        return await this.saveState({ asyncStorage });
    }

    getMainAddress(): string {
        return this.mainAddress;
    }

    getMnemonic(): string[] {
        return this.mnemonic.split(" ");
    }

    setMnemonic(words: Array<string>) {
        this.mnemonic = words.join(" ");
        this.seed = mnemonicToSeedSync(this.mnemonic);
        this.mainAddress = this.getBaseBitcoinAddress();
    }

    getSeed(): Buffer {
        return this.seed;
    }

    getFiatCurrency(): FiatCurrency {
        return this.fiatCurrency;
    }

    async syncBalance() {
        await this.balanceService.syncBalance(this);

        store.dispatch(onChange(this));
    }

    async syncData() {
        await this.dataService.syncData(this);

        store.dispatch(onChange(this));
    }

    getBalance() {
        let balance = 0;
        this.getEnabledCurrencies().map((currency: Currency) => (balance += currency.getFiatBalance()));
        return balance;
    }

    getTotalBalance() {
        let balance = 0;
        this.getEnabledCurrencies().map((currency: Currency) => (balance += currency.getFiatTotalBalance()));
        return balance;
    }

    getUnconfirmedBalance() {
        let balance = 0;
        this.getEnabledCurrencies().map((currency: Currency) => (balance += currency.getUnconfirmedFiatBalance()));
        return balance;
    }

    getCurrency(name: string) {
        try {
            return this.currencies.filter((currency: Currency) => currency.getName() === name)[0];
        } catch (err) {
            //console.warn(err);
        }
    }

    getCurrencies() {
        return this.currencies;
    }

    getEnabledCurrencies() {
        if (ModuleControlService.getInstance().isModuleEnabled(Modules.walletModule)) {
            const endabledCurrencies = this.currencies.filter((c) => {
                return c.isEnabled();
            });
            return endabledCurrencies;
        }
        return [];
    }

    getWalletConnectCurrencies() {
        const walletConnectCurrencies = this.currencies.filter((c: Currency) => {
            return c.hasWalletConnect();
        });
        return walletConnectCurrencies;
    }

    getBalancedCurrencies() {
        const currencies = this.currencies.filter((c: Currency) => {
            return c.getBalance() > 0;
        });
        return currencies;
    }

    getNftSupportedCurrencies() {
        const nftSupportedCurrencies = this.currencies.filter((c: Currency) => {
            return c.hasNftSupport();
        });

        return nftSupportedCurrencies;
    }

    setCurrencies(currencies: Array<Currency>) {
        this.currencies = currencies;
        store.dispatch(setCurrencies(this.getEnabledCurrencies()));
    }

    findCurrencyByName(name: string): Currency {
        name = name.toLowerCase();
        return this.getCurrencies().find(
            (currency) => currency.getFullName().toLowerCase() === name || currency.getName().toLowerCase() === name,
        );
    }

    findCurrencyById(name: string): Currency {
        name = name.toLowerCase();
        return this.getCurrencies().find((currency) => currency.getId().toLowerCase() === name);
    }

    findCurrencyByChainId(chainId: number): Currency {
        const c = this.getCurrencies().find((currency) => currency.getChainId() === chainId);
        return c?.getUnderlyingCurrency() || null;
    }

    findCurrencyByBlockchain(blockchain: string): Currency {
        return this.getCurrencies().find(
            (currency) => currency.getBlockchain() === blockchain && currency.getUnderlyingCurrency() == currency,
        );
    }
}
