import React, { Component } from "react";
import { FlatList, Linking, ScrollView, StyleSheet,  View } from "react-native";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import Currency from "@core/currencies/Currency";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import { CoincaexNavigatorScreens } from "@navigation/CoincaexNavigator";
import Row from "@base/Row";
import Card from "@base/Card";
import BoldText from "@base/BoldText";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import store from "@store/index";
import { hideModal, hideModalBottom, loading, ready, showModal, showModalBottom } from "@store/actions/global";
import RadiusButton from "@base/RadiusButton";
import CircleButton from "@base/CircleButton";
import * as WebBrowser from "expo-web-browser";
import { CoincaexService } from "@core/services/CoincaexService";
import TransactionHistoryComponent from "../components/TransactionHistoryComponent";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";
import { CoincaexBankAccount, TransactionsCoincaex } from "../helper/coincaex.helper";
import { Route } from "@react-navigation/native";
import BankCardComponent from "../components/BankCardComponent";
import CardButton from "@base/CardButton";
import Wallet from "@core/wallet/Wallet";
import SelectCurrencyComponent from "@screens/shared/SelectCurrencyComponent";
import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import { selectCurrency } from "@store/actions/wallet";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    fiatCurrency: FiatCurrency;
    selectedCurrency: string;
    buttonActionsType: ButtonActionsType;
    route: Route<string, { reload?: boolean }>;
}

interface State {
    personalInformation: {
        displayName: string;
        documentNumber: string;
        email: string;
        id: string;
        kycStatus: "none" | "init";
    } | null;
    transactions: Array<TransactionsCoincaex>;
    kycActive: boolean;
    userBanks: Array<CoincaexBankAccount>;
    user: any;
    ready: boolean;
}

const { t } = i18n;

export class _CoincaexHomeScreen extends Component<Props, State> {
    focusListener: any;

    constructor(props: Props) {
        super(props);

        this.onFocus = this.onFocus.bind(this);
        this.showSelectCurrency = this.showSelectCurrency.bind(this);

        this.state = {
            kycActive: false,
            personalInformation: null,
            transactions: [],
            userBanks: [],
            user: null,
            ready: false,
        };
    }

    async onFocus() {
        store.dispatch(loading());
        this.setState({ ready: false });

        let user: any = await CoincaexService.getInstance().getClientLoged();

        const sameUser = !this.state.user || (user && this.state.user?.id == user.id);

        if (!this.state.user || !user || !sameUser) {
            this.setState({ user: null, userBanks: [] });
            await this.init();

            if (this.state.kycActive && sameUser) {
                this.handleService(this.props.buttonActionsType);
            }
        }

        if (this.props.route?.params?.reload) {
            await this.init();
        }

        store.dispatch(ready());
        this.setState({ ready: true });
    }

    async componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", this.onFocus);
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove();
        } catch (e) { }
    }

    init = async () => {
        let user = await CoincaexService.getInstance().getClientLoged();
        if (user) {
            this.setState({ user });
        } else {
            this.props.navigation.navigate(CoincaexNavigatorScreens.Login.routeName);
            store.dispatch(ready());
            return;
        }

        // User data and KYC
        const data = await CoincaexService.getInstance().getClientInfo();
        let kycStatus = data?.personalInformation?.kycStatus == "approved";

        if (data) {
            this.setState({
                personalInformation: data?.personalInformation,
                transactions: data?.lastTenTransactions ? Object.values(data?.lastTenTransactions) : [],
                kycActive: kycStatus,
            });
        }

        if (!kycStatus) {
            return;
        }

        // Banks
        const banks = await CoincaexService.getInstance().getBankListUser();

        if (banks) {
            this.setState({ userBanks: [...banks?.Banks] });
        }

    };

    logOut = async () => {
        await CoincaexService.getInstance().logOut();
        this.props.navigation.navigate(CoincaexNavigatorScreens.Login.routeName);
    };

    getKYC = async () => {
        const data = await CoincaexService.getInstance().getClientKYC();
        if (data) {
            await WebBrowser.openAuthSessionAsync(data?.link);
            this.init();
        }
    };

    completeKycAlert = () => {
        store.dispatch(
            showModal({
                title: t("warning"),
                avatarIcon: "robot",
                description: t("kyc_alert"),
                btnTitle: t("personal_verification"),
                onPress: () => {
                    this.getKYC();
                    store.dispatch(hideModal());
                },
                onPressClose: async () => {
                    store.dispatch(hideModal());
                },
            }),
        );
    };

    handleService = (service?: string) => {
        if (this.state.kycActive) {
            service == "BUY" && this.props.navigation.navigate(CoincaexNavigatorScreens.Buy.routeName);
            service == "SELL" && this.props.navigation.navigate(CoincaexNavigatorScreens.Sell.routeName);
        } else {
            this.completeKycAlert();
        }
    };

    showSelectCurrency() {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <SelectCurrencyComponent
                        currencies={this.props.currencies.filter((c) => c.isBuyable())}
                        fiatCurrency={this.props.fiatCurrency}
                        onPress={(item) => {
                            store.dispatch(selectCurrency(item.getId()));
                            store.dispatch(hideModalBottom());
                        }}
                    ></SelectCurrencyComponent>
                ),
            }),
        );
    }

    render() {
        if (!this.state.ready) {
            return <></>;
        }

        return (
            <ScreenWrapper>
                <Header
                    type={HeaderType.Light}
                    backTo={WalletNavigatorScreens.SelectCurrencyButtons.routeName}
                    onPressCurrency={this.showSelectCurrency}
                    currency={Wallet.getInstance().findCurrencyById(this.props.selectedCurrency)}
                    {...this.props}
                />
                <Container style={{ flex: 1, paddingBottom: 20 }}>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <BoldText align="center" fontSize={24} style={{ marginBottom: 20, marginTop: 10 }}>
                            {t("welcome_to")} Coincaex
                        </BoldText>
                        <Card style={styles.buttons}>
                            <CircleButton
                                containerStyles={{ paddingHorizontal: 5 }}
                                fontSize={14}
                                title={t("buy")}
                                icon="cart"
                                onPress={() => this.handleService("BUY")}
                            />
                            <CircleButton
                                containerStyles={{ paddingHorizontal: 5 }}
                                fontSize={14}
                                title={t("sell")}
                                icon="dollar"
                                onPress={() => this.handleService("SELL")}
                            />
                        </Card>
                        {this.state.personalInformation && (
                            <View style={{ marginBottom: 20 }}>
                                <SectionTitle
                                    title={t("username").toUpperCase()}
                                    icon={"🔑"}
                                    counter={this.state.kycActive && t("verified").toUpperCase()}
                                    {...this.props}
                                ></SectionTitle>
                                <Card
                                    style={{ alignItems: "flex-start", marginTop: 10, justifyContent: "center" }}
                                    left={
                                        <View>
                                            <BoldText fontSize={14} style={{ textAlign: "left", marginBottom: 5 }}>
                                                {this.state.personalInformation?.displayName || ""}
                                            </BoldText>

                                            <RegularText fontSize={14} style={{ textAlign: "left", marginBottom: 5 }}>
                                                {this.state.personalInformation?.email || ""}
                                            </RegularText>
                                        </View>
                                    }
                                    right={
                                        <View>
                                            <CircleButton
                                                style={{ width: 35, height: 35, marginBottom: 5 }}
                                                iconSize={18}
                                                fontSize={11}
                                                title={t("signout")}
                                                icon="log-out"
                                                onPress={this.logOut}
                                            />
                                        </View>
                                    }
                                ></Card>
                            </View>
                        )}
                        {!this.state.kycActive && (
                            <View style={{ marginBottom: 20 }}>
                                <SectionTitle
                                    title={"KYC"}
                                    icon={"🕵🏻‍♂️"}
                                    counter={t("pending").toUpperCase()}
                                    {...this.props}
                                ></SectionTitle>
                                <Card style={{ alignItems: "flex-start", marginTop: 10, justifyContent: "center" }}>
                                    <RegularText fontSize={14} style={{ textAlign: "left", marginBottom: 5 }}>
                                        {t("kyc_alert")}
                                    </RegularText>
                                    <Row style={{ justifyContent: "flex-end", width: "100%", marginTop: 5 }}>
                                        <RadiusButton
                                            title={t("continue")}
                                            style={{ paddingVertical: 2 }}
                                            fontSize={14}
                                            onPress={this.getKYC}
                                        />
                                    </Row>
                                </Card>
                            </View>
                        )}
                        {this.state.kycActive && (
                            <View style={{ marginBottom: 20 }}>
                                <SectionTitle
                                    onPress={() =>
                                        this.props.navigation.navigate(
                                            CoincaexNavigatorScreens.Transactions.routeName,
                                            { transactions: this.state.transactions },
                                        )
                                    }
                                    title={t("recent_transactions").toUpperCase()}
                                    icon={"📂"}
                                    {...this.props}
                                ></SectionTitle>
                                <TransactionHistoryComponent
                                    navigation={this.props.navigation}
                                    currencies={this.props.currencies}
                                    selectedCurrency={this.props.selectedCurrency}
                                    transactions={this.state.transactions}
                                    sliceVersion={true}
                                />
                            </View>
                        )}
                        {this.state.kycActive && (
                            <View style={{ marginBottom: 20 }}>
                                <SectionTitle
                                    onPress={() =>
                                        this.props.navigation.navigate(CoincaexNavigatorScreens.AddBank.routeName)
                                    }
                                    title={t("banks").toUpperCase()}
                                    icon={"🏦"}
                                    {...this.props}
                                ></SectionTitle>
                                <FlatList
                                    data={this.state.userBanks}
                                    renderItem={({ item }: any) => <BankCardComponent bank={item} />}
                                    scrollEnabled={false}
                                    keyExtractor={(item: any) => item.bankAccountNumber}
                                    style={{ marginTop: 5 }}
                                    ListFooterComponent={
                                        <CardButton
                                            title={t("add_bank")}
                                            style={{ marginBottom: 20 }}
                                            onPress={() =>
                                                this.props.navigation.navigate(
                                                    CoincaexNavigatorScreens.AddBank.routeName,
                                                )
                                            }
                                        />
                                    }
                                />
                            </View>
                        )}
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    buttons: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 25,
    },
});

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const CoincaexHomeScreen = connect(mapStateToProps, mapDispatchToProps)(_CoincaexHomeScreen);

export default CoincaexHomeScreen;
