import { FiatCurrency } from "@core/fiat/FiatCurrencies";
import { Client } from "./Client";

export enum RedeemableType {
    "TICKET" = "ticket",
    "VOUCHER" = "voucher",
}

export enum ExperienceStatus {
    "CREATED" = "created",
    "ASSIGNED" = "assigned",
    "TRANSFERED" = "transfered",
    "USED" = "used",
    "EXPIRED" = "expired",
}

export interface ExperiencesRedux {
    membership: {
        docs: Array<MEMBERSHIP>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    ticket: {
        docs: Array<REDEEMABLE>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    voucher: {
        docs: Array<REDEEMABLE>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    asset: {
        docs: Array<ASSET>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    experiences: {
        docs: Array<any>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    merchants: {
        docs: Array<MERCHANT>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    selectedRedeemable?: REDEEMABLE;
    selectedMerchant?: MERCHANT;
    checkoutRedeemables?: Array<CartRedeemable>;
    selectedFiatCurrency: FiatCurrency;
}
export interface REDEEMABLE {
    _id: string;
    experience?: EXPERIENCE;
    redeemableOption?: {
        _id: string;
        experience: string;
        name: string;
        description: string;
        image?: { cover: string; thumbnail: string; carousel: string };
        transferable?: boolean;
        type: RedeemableType;
        limitedQuantity?: boolean;
        quantity?: number;
        location?: string;
        startDate: Date | string;
        endDate: Date | string;
    };
    history?: Array<RedeemableHistory>;
    status: ExperienceStatus;
    favorite?: boolean;
    archivedAt?: Date | string;
    redeemed: boolean;
    owner?: Partial<Client>;
    price?: number;
}

export interface ASSET {
    _id: string;
    assetOption?: {
        _id: string;
        name: string;
        description: string;
        image?: { cover: string; thumbnail: string; carousel: string };
        transferable?: boolean;
        limitedQuantity?: boolean;
        quantity?: number;
        properties?: Array<{
            property: { _id: string; name: string };
            value: {
                _id: string;
                name: string;
            };
        }>;
    };
    merchant?: MERCHANT;
    history?: Array<AssetHistory>;

    status: ExperienceStatus;
    favorite?: boolean;
    archivedAt?: Date | string;
    owner?: Partial<Client>;
}

export interface MEMBERSHIP {
    _id: string;

    membershipOption?: {
        _id: string;
        merchant: string;
        name: string;
        description: string;
        image?: { cover: string; thumbnail: string; carousel: string };
    };
    merchant?: MERCHANT;
    favorite?: boolean;
    archivedAt?: Date | string;
    owner?: Partial<Client>;
}

export interface RedeemableHistory {
    _id: string;
    action: ExperienceStatus;
    createdAt?: Date | string;
    currentOwner: Partial<Client>;
    currentStatus: ExperienceStatus;
    previousOwner: Partial<Client>;
    data?: any;
    gate?: {
        enabled: boolean;
        images: Array<{ cover: string; thumbnail: string }>;
        merchant: string;
        name: string;
        _id: string;
    };
    previousStatus: ExperienceStatus;
    redeemable: string;
}

export interface AssetHistory {
    _id: string;
    action: ExperienceStatus;
    createdAt?: Date | string;
    currentOwner: Partial<Client>;
    currentStatus: ExperienceStatus;
    previousOwner: Partial<Client>;
    previousStatus: ExperienceStatus;
    asset: string;
}

export interface MERCHANT {
    _id: string;
    name: string;
    wallet?: string;
    image?: { cover?: string; thumbnail?: string; carousel?: string };
    experiences?: Array<string>;
    gates?: Array<string>;
    memberships?: Array<string>;
    membershipOptions?: Array<string>;
    verifiers?: Array<string>;
    isAccessRestricted?: boolean;
    redeemableDropRules?: Array<string>;
}

export interface EXPERIENCE {
    _id: string;
    name: string;
    description: string;
    image?: { cover: string; thumbnail: string; carousel: string };
    location?: { description: string, lat: number, lng: number };
    startDate: Date | string;
    endDate: Date | string;
    merchant: MERCHANT;

    redeemableOptions?: Array<string>;
    redeemables?: Array<string>;
    type: "event" | "";
}

export interface GATE {
    _id: string;
    name: string;
    images?: { cover: string; thumbnail: string };
    enabled: boolean;
    merchant: MERCHANT;
}

export interface CartRedeemable {
    quantity: number;
    redeemable: REDEEMABLE;
}

export interface ExperienceSearchParams {
    name?: string,
    startDate?: string,
    endDate?: string,
    merchant?: string,
}