import React, { Component } from "react";
import { ScrollView, StyleSheet, View, } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import { CartRedeemable, REDEEMABLE } from "@custom-types/ExpercienceModel";
import BoldText from "@base/BoldText";
import Row from "@base/Row";
import store from "@store/index";
import { hideModal, hideModalBottom, hidePopupMessage, showModal, showModalBottom, showPopupMessage, showSnackbar } from "@store/actions/global";
import FlatListBase from "@base/FlatListBase";
import RedeemableOptionCard from "./components/RedeemableOptionCard";
import RedeemableDetailModal from "./components/RedeemableDetailModal";
import RegularText from "@base/RegularText";
import Card from "@base/Card";
import PaymentCard from "./components/PaymentCard";
import Icon from "@components/icons";
import ExperienceService from "@core/services/ExperienceService";
import { emptyRedeemableCart } from "@store/actions/experiences.actions";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    checkoutRedeemables: Array<CartRedeemable>;
}

interface State {
    checkoutRedeemables: Array<CartRedeemable>;
    total: number;
    paymentMethod: any
}

const { t } = i18n;
const payment: Array<any> = [{ icon: "pay-order", name: "Credit Card" }, { icon: "bitcoin", name: "Crypto" }, { icon: "ticket", name: "Points" },]

export class _CheckoutScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            checkoutRedeemables: this.props.checkoutRedeemables,
            paymentMethod: payment[0],
            total: 0
        };
    }

    renderRedeemable = ({ item }) => {
        return item.quantity > 0 ? (
            <RedeemableOptionCard quantity={item.quantity} redeemable={item.redeemable} onPress={() => this.redeemableDetail(item.redeemable)} fromExperience={false} />
        ) : null;
    };

    redeemableDetail = (redeemable: REDEEMABLE) => {
        store.dispatch(showModalBottom({
            modalContent: <RedeemableDetailModal navigation={this.props.navigation} redeemable={redeemable} />,
        }))
    }

    totalCart = () => {
        let total = 0
        this.state.checkoutRedeemables.map((item) => {
            if (item.redeemable?.price) {
                total += item.quantity * item.redeemable?.price
            }
        })
        return total.toFixed(2)
    }

    totalQuantity = () => {
        let total = 0
        this.state.checkoutRedeemables.map((item) => {
            if (item.quantity) {
                total += item.quantity
            }
        })
        return total
    }

    paymentList = () => {
        store.dispatch(showModalBottom({
            modalContent: (
                <FlatListBase
                    data={payment}
                    renderItem={({ item }) => <PaymentCard name={item.name} icon={item.icon} onPress={() => {
                        store.dispatch(hideModalBottom())
                        this.setState({ paymentMethod: item })
                    }} />
                    }
                    keyExtractor={({ item }) => item.name}
                />
            ),
        }))
    };

    checkQuantity = (redeemables: Array<CartRedeemable>) => {
        return redeemables.filter((item) => item.quantity > 0)
    }

    completePurchase = async () => {
        const redeemables = this.checkQuantity(this.state.checkoutRedeemables)

        if (redeemables.length) {
            try {
                store.dispatch(showPopupMessage({
                    type: "LOADING",
                    title: t('processing_payment'),
                }))

                const checkout = await ExperienceService.getInstance().checkout({
                    paymentMethod: 'fiat',
                    items: (redeemables || []).map(i => { return { id: i.redeemable._id, quantity: i.quantity } })
                });

                if (checkout) {
                    ExperienceService.getInstance().getRedeemables();
                    store.dispatch(hidePopupMessage())

                    setTimeout(() => {
                        store.dispatch(showPopupMessage({
                            type: "SUCCESS",
                            title: t('congratulations'),
                            message: t('enjoy_purchase'),
                            onPressAccept: () => {
                                store.dispatch(emptyRedeemableCart());
                                store.dispatch(hidePopupMessage());
                                this.props.navigation.navigate('HomeMain');
                            },
                        }))
                    }, 1000)
                }

            } catch (error) {
                store.dispatch(hidePopupMessage())
                console.error(error)
            }
        } else {
            store.dispatch(showSnackbar({ type: "MESSAGE", message: t('add_item_warning') }))
        }
    }


    render() {
        return (
            <ScreenWrapper>
                <Header title='Checkout' type={HeaderType.Light} {...this.props} />
                <ScrollView>
                    <Container style={{ flex: 1 }}>
                        <View style={{ marginBottom: 20 }}>
                            <BoldText style={{ marginBottom: 10 }}>{t("payment_method")}</BoldText>
                            <PressableBase onPress={this.paymentList}>
                                <Card>
                                    <Row style={{ width: '100%', alignItems: "center", justifyContent: "space-between", padding: 5 }}>
                                        <Row style={{ alignItems: "center" }}>
                                            <Icon style={{ marginRight: 10 }} name={this.state.paymentMethod.icon} size={22} color={colors.text} />
                                            <RegularText>{this.state.paymentMethod.name}</RegularText>
                                        </Row>
                                        {payment.length > 0 &&
                                            <Icon name="arrow-down" size={18} color={colors.text} />
                                        }
                                    </Row>
                                </Card>
                            </PressableBase>
                        </View>
                        <View style={{ marginBottom: 20 }}>
                            <BoldText style={{ marginBottom: 10 }}>{t("purchase_detail")}</BoldText>
                            <FlatListBase
                                data={this.state.checkoutRedeemables}
                                renderItem={this.renderRedeemable}
                                keyExtractor={(item) => item._id}
                            />
                            <Card>
                                <Row style={{ justifyContent: "space-between", width: "100%", marginBottom: 10 }}>
                                    <RegularText fontSize={14}>{t('quantity')}</RegularText>
                                    <RegularText fontSize={14}>{this.totalQuantity()}</RegularText>
                                </Row>
                                <Row style={{ justifyContent: "space-between", width: "100%", marginBottom: 10 }}>
                                    <RegularText fontSize={14}>Subtotal</RegularText>
                                    <RegularText fontSize={14}>$ {this.totalCart()}</RegularText>
                                </Row>
                                <Row style={{ justifyContent: "space-between", width: "100%", marginTop: 10 }}>
                                    <BoldText>Total</BoldText>
                                    <Row>
                                        {this.totalCart() == '0' && <View style={styles.bubble}>
                                            <BoldText
                                                align="center"
                                                fontSize={10}
                                                color={colors.labelText || colors.white}
                                                style={{ textTransform: "uppercase" }}
                                            >
                                                {t("free")}
                                            </BoldText>
                                        </View>}
                                        <BoldText fontSize={16}>$ {this.totalCart()}</BoldText>
                                    </Row>
                                </Row>
                            </Card>
                        </View>
                        <InlineButton style={{ marginVertical: 20 }} title={t('complete_purchase')} onPress={this.completePurchase} />
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flex: 1,
    },
    addItem: {
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        width: 30,
        backgroundColor: colors.secondary,
        borderRadius: 50,
        alignContent: "center",
        margin: "auto",
    },
    quantityContainer: {
        alignItems: "center",
        borderRadius: 6,
        height: 30,
    },
    bubble: {
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 8,
        paddingVertical: 3,
        borderRadius: 4,
        marginRight: 20
    }
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        checkoutRedeemables: state.experiences.checkoutRedeemables,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const CheckoutScreen = connect(mapStateToProps, mapDispatchToProps)(_CheckoutScreen);

export default CheckoutScreen;
