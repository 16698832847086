import React, { Component } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { MainNavigator } from "./MainNavigator";
import NavigatorOptions from "./NavigatorOptions";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import OnboardingScreen from "@screens/onboarding/OnboardingScreen";
import { LoginNavigator } from "./LoginNavigator";
import InitializerScreen from "../screens/initializer/InitializerScreen";

const AuthNavigatorScreens = {
    Initializer: {
        routeName: "Initializer",
        title: "Initializer",
        component: InitializerScreen,
    },
  
    Login: {
        routeName: "Login",
        title: "Login",
        component: LoginNavigator,
    },

    Main: {
        routeName: "Main",
        title: "Main",
        component: MainNavigator,
    },
};

const AuthNavigator = NavigationInstantiator(AuthNavigatorScreens);

export { AuthNavigator, AuthNavigatorScreens };
