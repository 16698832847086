import React, {Component} from "react"
import {View, Text, StyleSheet, Button, Dimensions, FlatList, RefreshControl} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import {NavigationType} from "@custom-types/NavigationType"
import {format} from "date-fns"
import {colors} from "@styles/globalStyles"
import RegularText from "@base/RegularText"
import {connect} from "react-redux"

import Icon from "@components/icons"
import BoldText from "@base/BoldText"
import Card from "@base/Card"
import store from "@store/index"
import {confirmTransaction, getTransactions} from "@store/actions/wallet"

import {loading, ready, showPopup} from "@store/actions/global"

import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import {FloatButton} from "@base/FloatButton"
import {KriptonService} from "@core/services/KriptonService"
import Row from "@base/Row"
import {setCountry} from "@store/actions/kriptonmarket.actions"
import { BuyNavigatorScreens } from "@navigation/BuyNavigator"
import { KriptonMarketNavigatorScreens } from "@navigation/KriptonMarketNavigator"
interface Props {
    navigation: NavigationType
    selectedCurrency: string
}

interface State {
    kyc_approved: boolean
    declared_domicile: string
    operations: any
    syncing: boolean
}
const {t} = i18n

export class _KriptonMarketScreen extends Component<Props, State> {
    private kriptonService: KriptonService
    focusListener: any
    constructor(props: Props) {
        super(props)
        store.dispatch(loading())
        this.kriptonService = KriptonService.getInstance()
        this.state = {
            kyc_approved: false,
            operations: [],
            declared_domicile: "",
            syncing: false,
        }
    }

    async componentDidMount() {
        this.onRefresh()
        this.focusListener = this.props.navigation.addListener("focus", this.onRefresh)
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove()
        } catch(e){}
    }

    onRefresh = async () => {
        this.setState({syncing: true})
        const status = await this.kriptonService.getClientStatus()

        if (status) {
            this.setState(
                {
                    kyc_approved: status.kyc_approved,
                    declared_domicile: status.declared_domicile || "ARG",
                },
                () => store.dispatch(setCountry(this.state.declared_domicile))
            )
        }

        if (!this.state.kyc_approved) {
            this.props.navigation.navigate(KriptonMarketNavigatorScreens.ClientStatusKripton.routeName, { backTo: 'SelectProviderBuy' })
        }
        const operations = await this.kriptonService.getOperations(this.props.selectedCurrency)
        store.dispatch(ready())

        this.setState({operations: operations, syncing: false})
    }

    getStatusColor(status: string) {
        return (
            (status == "cancel" && colors.red) ||
            (status == "wait" && colors.grey) ||
            (status == "received" && colors.yellow) ||
            (status == "complete" && colors.green) ||
            (status == "pending_by_validate" && colors.grey)
        )
    }

    renderItem = ({item}) => {
        return (
            <View>
                <Card
                    borderLeft={this.getStatusColor(item.status)}
                    onPress={() => this.props.navigation.navigate(KriptonMarketNavigatorScreens.OperationDetailKripton.routeName, {operation_id: item.id})}
                    icon={
                        <View style={styles.icon}>
                            <Icon name='bank' size={22} align='center' color={colors.text} />
                        </View>
                    }
                    left={
                        <View>
                            <Row>
                                <RegularText fontSize={18}> {item.currency_out.toUpperCase()} </RegularText>
                                {!item.url_voucher_image && (
                                    <View
                                        style={{
                                            width: 20,
                                            height: 20,
                                            backgroundColor: colors.secondary,
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            alignSelf: "center",
                                            marginLeft: 5,
                                        }}>
                                        <BoldText>!</BoldText>
                                    </View>
                                )}
                            </Row>
                            <RegularText fontSize={10}>
                                {" "}
                                {format(new Date(item.created_at), "MMM dd, yyyy H:mma")}{" "}
                            </RegularText>
                        </View>
                    }
                    right={
                        <View>
                            <RegularText> {item.amount_out} </RegularText>
                        </View>
                    }
                />
            </View>
        )
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={'KriptonMarket'}
                    type={HeaderType.Light}
                    {...this.props}
                    backTo={BuyNavigatorScreens.SelectProviderBuy.routeName}
                    rightBtn={{
                        onPress: () => {
                            this.props.navigation.navigate(KriptonMarketNavigatorScreens.ClientStatusKripton.routeName, {route: KriptonMarketNavigatorScreens.KriptonMarket.routeName})
                        },
                        iconType: "custom",
                        icon: "fingerprint",
                        size: 26,
                    }}
                />
                {this.state.kyc_approved && (
                    <Container style={styles.container}>
                        <View>
                            <FlatList
                                data={this.state.operations}
                                renderItem={this.renderItem}
                                keyExtractor={(item) => item.id}
                                contentContainerStyle={{paddingBottom: 20}}
                                initialNumToRender={4}
                                maxToRenderPerBatch={10}
                                refreshControl={
                                    <RefreshControl
                                        tintColor={colors.text}
                                        onRefresh={this.onRefresh}
                                        refreshing={this.state.syncing}
                                    />
                                }
                            />
                        </View>
                        <FloatButton
                            onPress={() => this.props.navigation.navigate(KriptonMarketNavigatorScreens.BuyMethodKripton.routeName)}
                            iconName={"cart"}
                        />
                    </Container>
                )}
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

        paddingVertical: 20,
    },
    buttons: {
        marginVertical: 15,
    },
    icon: {
        width: 25,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        marginRight: 12,
    },
})

const mapStateToProps = (state) => {
    return state.wallet
}

const mapDispatchToProps = (dispatch) => ({})

const KriptonMarketScreen = connect(mapStateToProps, mapDispatchToProps)(_KriptonMarketScreen)

export default KriptonMarketScreen
