import React, {Component} from "react"
import { View,  FlatList} from "react-native"
import {Header} from "@components/header/Header"
import Container from "@base/Container"
import {HeaderType} from "@custom-types/HeaderType"
import Wallet from "@core/wallet/Wallet"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import SocialNetworkService from "@screens/social/service/SocialNetworkService"
import store from "@store/index"
import {loading, ready} from "@store/actions/global"
import Card from "@base/Card"
import AvatarBase from "@components/avatar/AvatarBase"
import RegularText from "@base/RegularText"
import {nameSocialLengthHelper} from "@utils/helpers/social/social.helper"
import { colors } from "@styles/globalStyles"
import BotCard from "@base/BotCard"
import PressableBase from "@base/PressableBase"


interface Props {
    navigation: any;
}

interface State {
    blockedUsers: Array<any>
}

const {t} = i18n

export default class BlockedUsersScreen extends Component<Props, State> {
    protected wallet: Wallet
    socialNetworkService: SocialNetworkService

    constructor(props: Props) {
        super(props)
        this.state = {
            blockedUsers: [],
        }

        this.socialNetworkService = SocialNetworkService.getInstance()
    }

    async componentDidMount() {
        store.dispatch(loading())
        const users = await this.socialNetworkService.getBlockedUsers()
        this.setState({blockedUsers: users})
        store.dispatch(ready())
    }

    async unblockUser(id) {
        
        store.dispatch(loading())
        await this.socialNetworkService.unblockUser(id)
        const users = await this.socialNetworkService.getBlockedUsers()
        this.setState({blockedUsers: users})
        store.dispatch(ready())
    }

    renderItem(item) {
        return (
            <Card
                left={
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignContent: "center",
                            alignItems: "center",
                        }}>
                        <AvatarBase
                            uri={item.blocked.profileImagePath ? item.blocked.profileImagePath.thumbnail : ""}
                            alias={item.blocked.alias}
                            size={35}
                        />

                        <RegularText style={{marginLeft: 10, textAlignVertical: "center"}}>
                            @{nameSocialLengthHelper(item.blocked.alias)}
                        </RegularText>
                    </View>
                }
                right={
                    <View >
                        <PressableBase
                            style={{
                                zIndex: 999,
                                alignSelf: "center",
                                backgroundColor: colors.secondary,
                                paddingHorizontal: 15,
                                borderRadius: 15,
                                paddingVertical: 5,
                                overflow: "hidden",
                            }}
                            onPress={()=>this.unblockUser(item.blocked._id)}>
                            <RegularText color={colors.white} align={"center"} fontSize={12}>
                                {t("unblock_user")}
                            </RegularText>
                        </PressableBase>
                    </View>
                }
            />
        )
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("blocked_user")} type={HeaderType.Light} {...this.props} />
                <Container style={{flex: 1}}>
                    <FlatList
                        data={this.state.blockedUsers}
                        keyExtractor={(item) => item._id}
                        renderItem={({item}) => this.renderItem(item)}
                        extraData={this.state}
                        ListEmptyComponent={
                            <BotCard message={t("empty_blocked_users")}></BotCard>
                        }
                    />
                </Container>
            </ScreenWrapper>
        )
    }
}


