import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { REDEEMABLE, RedeemableType, ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { Route } from "@react-navigation/native";
import SearchBase from "@components/search/SearchBase";
import { FlatList, View } from "react-native";
import RedeemableWideCard from "./components/RedeemableWideCard";
import SwitchCard from "@components/cards/SwitchCard";
import ExperienceService from "@core/services/ExperienceService";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";

interface Props {
    navigation: NavigationType;
    route: Route<string, { sectionType?: RedeemableType }>;
    client: Client;
    experiences: ExperiencesRedux;
}

interface State {
    type?: RedeemableType;

    redeemables: Array<REDEEMABLE>;
}

const { t } = i18n;

export class _SectionRedeemableScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.navigateToRedeemable = this.navigateToRedeemable.bind(this);
        this.state = {
            type: this.props.route?.params?.sectionType || RedeemableType.TICKET,

            redeemables: [],
        };
    }

    navigateToRedeemable(redeemable: REDEEMABLE) {
        this.props.navigation.navigate(ExperienceNavigatorScreens.Redeemable.routeName, {
            redeemable: redeemable,
        });
    }

    renderItem = ({ item }) => {
        return (
            <RedeemableWideCard
                onPress={this.navigateToRedeemable}
                redeemable={item}
                navigation={this.props.navigation}
            />
        );
    };

    onSearch(value) {}

    async loadMore() {
        await ExperienceService.getInstance().getRedeemables(this.state.type, true);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t(this.state.type)} type={HeaderType.Light} {...this.props} />

                <Container style={{ flex: 1 }}>
                    <FlatList
                        data={this.props.experiences[this.state.type].docs}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                        initialNumToRender={6}
                        maxToRenderPerBatch={6}
                        showsHorizontalScrollIndicator={false}
                        onEndReachedThreshold={1}
                        onEndReached={this.loadMore}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SectionRedeemableScreen = connect(mapStateToProps, mapDispatchToProps)(_SectionRedeemableScreen);

export default SectionRedeemableScreen;
